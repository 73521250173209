import {Component, ElementRef, Input, OnInit} from '@angular/core';
import {ColorGenerator} from './color-generator';

@Component({
  selector: 'text-img',
  templateUrl: './text-img.component.html',
  styleUrls: ['./text-img.component.scss'],
  providers: [ColorGenerator]
})
export class TextImgComponent {
  public initials = '';
  color: any = ColorGenerator.getColor("");

  constructor() { }
  @Input() textBig = false;
  
  @Input()
  set text(txt: string) {
    if (txt) {
      const name = txt.split(' ');
      this.initials = name[0].charAt(0);
      if (name[1]) {
        this.initials += name[1].charAt(0);
      }
      this.color = ColorGenerator.getColor(txt);
    }
  }

}
