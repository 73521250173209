import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ubaEmailFormat'
})
export class UbaEmailFormatPipe implements PipeTransform {

  transform(value): unknown {
    return value.replace(/{.*}/, '');
  }

}
