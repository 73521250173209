import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customPhone'
})
export class PhoneTextPipe implements PipeTransform {

  transform(number) {    
    let newStr = "";
    let i = 0;
  
    for (; i < Math.floor(number.length / 3) - 1; i++) {
      newStr = newStr + number.substr(i * 3, 3) + " ";
    }
  
    return newStr + number.substr(i * 3);
  }

}
