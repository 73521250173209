import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Geolocation } from '@capacitor/geolocation';


@Injectable({
  providedIn: 'root'
})
export class GeolocationService {
  public currentLocation$: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(public http: HttpClient) {

  }

  public getAddress(coordinates = false) {
    Geolocation.watchPosition({}, ((position, err) => {
      this.currentLocation$.next(position);
    }));
    if (coordinates) return this.currentLocation$.pipe(
      filter(x => x));

    return this.currentLocation$.pipe(
      filter(x => x),
      map(loc => ({ lat: loc.coords.latitude, lng: loc.coords.longitude })),
      switchMap(
        (location) => {
          return this.http.post<any>('https://www.mapquestapi.com/geocoding/v1/reverse',
            { location: { latLng: location } },
            { params: new HttpParams().set('key', '7jN5AoEAR6FaCLJHh8Gz0aL7FViJZpZa') }
          );
        }
      ),
      map((x: any) => x.results[0].locations[0]),
      map((x: any) => {
        const result = {} as any;
        if (x.adminArea1) { result[x.adminArea1Type] = x.adminArea1; }
        if (x.adminArea2) { result[x.adminArea2Type] = x.adminArea2; }
        if (x.adminArea3) { result[x.adminArea3Type] = x.adminArea3; }
        if (x.adminArea4) { result[x.adminArea4Type] = x.adminArea4; }
        if (x.adminArea5) { result[x.adminArea5Type] = x.adminArea5; }
        if (x.adminArea6) { result[x.adminArea6Type] = x.adminArea6; }
        if (x.street) { result.street = x.street; }
        return result;
      }),
    );
  }


}
