import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'image-loader',
  templateUrl: './image-loader.component.html',
  styleUrls: ['./image-loader.component.scss'],
})
export class ImageLoaderComponent implements OnInit {

  @Input() public text: string = '';
  @Input() public loadingSmall: boolean = false;
  @Output() public load = new EventEmitter<void>();
  @Input() public group: boolean = false;
  @Input()
  set src(url: string) {
    if (!url) {
      this.loaded = false;
      this.imageSrc = void 0;
      this.loading = false;
      return;
    }

    // if ((process.env.MEDXAT_MODE === 'web') || this.platform.is('android') || (typeof url !== 'string')) {
    this._setImageSrc(url);
    return;
    // }

    //DEBUG: REMEMBER: this needs to be commented. It loads images as web in mobile devices. ALWAYS DOWNLOAD FORM FIREBASE
    // if ((process.env.MEDXAT_MODE != 'web') || (typeof url !== 'string')) {
    //   this._setImageSrc(url);
    //   return;
    // }

    // this.imageService
    //   .getFromURL(url)
    //   .then((localURL: SafeUrl) => {
    //     if (localURL) {
    //       this._setImageSrc(localURL);
    //     } {
    //       this._setImageSrc(url);
    //     }
    //   }, (error) => {
    //     this._setImageSrc(url);
    //   })
    //   .catch((error: any) => this._setImageSrc(url));
  }

  public imageSrc: string | SafeUrl;
  public loaded: boolean = false;
  public placeholder1: string = 'assets/avatars/_default.jpg';
  public placeholder2: string = 'assets/avatars/_defaultgroup.png';
  public loading: boolean = true;

  constructor() { }

  ngOnInit() { }

  public _setImageSrc(url: string | SafeUrl) {
    if (this.imageSrc === url) {
      this.loaded = true;
    } else {
      this.loaded = false;
      this.imageSrc = url;
    }
    if (!url) {
      this.loaded = false;
      this.imageSrc = void 0;
    }
  }



  public onLoad() {
    this.loaded = true;
    this.loading = false;
    this.load.next();
  }

  public onLoadError() {
    this.loaded = true;
    this.loading = false;
    if (this.loadingSmall) {
      this.imageSrc = null;
      this.loaded = false;
    } else {
      this.imageSrc = this.group ? this.placeholder2 : this.placeholder1;
    }
  }

}
