import { Component, OnInit, OnDestroy, ViewChild, Input } from '@angular/core';
import { ModalController, IonSearchbar, NavController } from '@ionic/angular';
import { Observable, Subject } from 'rxjs';
import { UserService } from 'src/services/user.service';
import { takeUntil } from 'rxjs/operators';
import { MenuService } from 'src/services/menu-service';

@Component({
  selector: 'app-contact-selector',
  templateUrl: './contact-selector.component.html',
  styleUrls: ['./contact-selector.component.scss'],
})
export class ContactSelectorComponent implements OnInit, OnDestroy {

  users$: Observable<any>;
  onDestroy$: Subject<void> = new Subject<void>();
  public searchText = '';
  @ViewChild("searchBar", { static: false }) public searchBar: IonSearchbar;

  public exclude?: { [uid: string]: boolean } = {};

  @Input() mode: string;
  @Input() chat: any;
  @Input() patientMode: boolean;
  usersToAdd = [];
  public searchAll = false;
  public step = 0;
  public groupName;
  public usersToAddKeys = [];
  public groupNameError = false;

  constructor(public modalCtrl: ModalController,
    public navCtrl: NavController,
    public ms: MenuService,
    public userService: UserService) { }


  ngOnInit() {
  }

  async ionViewDidEnter() {
    this.exclude[this.userService.getUser().id] = true;
    if (this.mode === 'group_add_member') {
      Object.keys(this.chat.users).forEach(key => {
        if (this.chat.users[key] === true) this.exclude[key] = true;
      })
    }

    if ((this.mode === 'new_group' && this.patientMode) || (this.mode === 'group_add_member' && this.chat.patient)) this.searchAll = true;
    this.users$ = this.userService.getFilteredSearchbarUsers(this.searchBar, this.exclude, this.mode, this.searchAll, false, this.patientMode);
    this.users$.pipe(takeUntil(this.onDestroy$))
      .subscribe((users) => {
        if (!users || (users && users.length > 0) || (users && users.length === 0)) {
          this.userService.doingQuery$.next(false);
        }
      });
  }

  select(user, event) {
    if (this.mode != 'new_group')
      this.dismiss({ user });
    else {
      if (this.usersToAdd[user.id]) delete this.usersToAdd[user.id];
      else this.usersToAdd[user.id] = user;
      this.usersToAddKeys = Object.keys(this.usersToAdd);
    }
  }

  dismiss(data = null, role?) {
    if (this.mode === 'new_group' && this.groupName) {
      data = {
        groupName: this.groupName,
        usersToAdd: this.usersToAddKeys
      }
    }
    if (role) {
      this.modalCtrl.dismiss(null, role);
      return;
    }
    this.modalCtrl.dismiss(data);
  }

  async goToUD(){
    await this.navCtrl.navigateForward('/users-directory')
    await this.dismiss();
  }

  userInList(uid) {
    return this.usersToAdd[uid];
  }

  removeUser(uid) {
    if (this.usersToAdd[uid]) delete this.usersToAdd[uid];
    this.usersToAddKeys = Object.keys(this.usersToAdd);
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }
}
