import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'splitEmail'
})
export class SplitEmailPipe implements PipeTransform {

  transform(value): unknown {
    if(!value || value.indexOf("@") === -1) return value;
    return value.split("@")[0];
  }

}
