import { Component, OnInit, AfterViewInit, Input, ElementRef } from '@angular/core';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { AlertController } from '@ionic/angular';
import { CommunicationService } from 'src/services/communication.service';
import * as moment from 'moment';

@Component({
  selector: 'custom-html',
  templateUrl: './custom-html.component.html',
  styleUrls: ['./custom-html.component.scss'],
})
export class CustomHTMLComponent implements OnInit, AfterViewInit {

  public forceLoadBloc$ = new Subject<boolean>();
  public loading: boolean = false;
  public loaded: boolean = false;
  public loadingMore: boolean = false;
  public maxLoaded: boolean = false;
  public bloc: any;
  public module_id: any;
  public item: any;
  public selectedFilter;

  clinicalCourse;
  blocToggled = [];
  filtresAplicats = [];
  @Input() set htmlBloc(bloc) {
    this.bloc = bloc;
    if (bloc.event_load) {
      this.forceLoadBloc$.next(true);
    }
  }

  @Input()
  set moduleId(module_id) {
    if (module_id) {
      this.module_id = module_id;
      this.forceLoadBloc$.next(true);
    }
  };


  @Input()
  set detailItem(item) {
    if (item) {
      this.item = item;
      this.forceLoadBloc$.next(true);
    }
  }

  @Input() set filters(_filters) {
    this.filtresAplicats = _filters;
  }

  constructor(
    public tr: TranslateService,
    public alertCtrl: AlertController,
    public elRef: ElementRef,
    public commService: CommunicationService) {
  }

  async ngOnInit() {
    this.loading = true;
  }

  async ngAfterViewInit() {
    if (!this.loaded && this.item && this.bloc && this.bloc.event_load && this.module_id) {
      this.loadBloc(this.bloc.event_load);
    }
  }


  async loadBloc(event) {
    this.loading = true;
    if (!event) {
      this.loading = false;
      return;
    }
    try {
      let params = Object.assign({}, event.params);
      var keys = Object.keys(params);

      keys.forEach(key => {
        let paramsKeys = Object.keys(params[key]);

        if (params[key].tag) {
          let tag = params[key].tag;
          params[key] = this.getValue(tag);
        } else {
          params[key] = event.params[key];
        }
      });

      if (this.bloc.filters) {
        this.bloc.filters.forEach(filter => {
          if (filter.default) {
            params[filter.tag] = filter.value;
            this.selectedFilter = filter;
          }
        });
      }

      if (this.filtresAplicats) {
        params['filters'] = this.filtresAplicats;
      }

      let op = event.op ? event.op : this.bloc.op;
      let event_parent = this.bloc.event_parent ? this.bloc.event_parent : event.event_parent;

      params.module_id = this.module_id;

      let result = await this.commService.getFunctionPromise(op, params, event_parent)
      if (result && result['data'] && result['data'].cursclinic) {
        this.clinicalCourse = result['data'].cursclinic;
        let i = 1;
        this.clinicalCourse.forEach(element => {
          if (element.id) {
            if (this.bloc && this.bloc.header && this.bloc.header.click && this.bloc.header.click.toggle) {
              this.blocToggled[element.id] = this.bloc.header.click.default_opened ? true : ((i <= this.bloc.header.click.num_opened) ? true : false);
            }
          }
          i++;
        });
        this.loaded = true;
      } else {
        await this.showErrorAlert();
      }
      this.loading = false;
    } catch (error) {
      this.showErrorAlert(error.message);
    }

  }

  async loadMore(event) {
    if (event && event.op && event.event_parent) {
      this.loadingMore = true;
      let params = Object.assign({}, event.params);
      var keys = Object.keys(params);

      keys.forEach(key => {
        if (params[key].tag) {
          let tag = params[key].tag;
          params[key] = this.getValue(tag);
        } else {
          params[key] = event.params[key];
        }
      });

      if (this.filtresAplicats) {
        params['filters'] = this.filtresAplicats;
      }

      let op = event.op;
      let event_parent = event.event_parent;
      params.module_id = this.module_id;
      try {
        let result = await this.commService.getFunctionPromise(op, params, event_parent)
        if (result && result['data'] && result['data'].cursclinic) {
          let oldLength = this.clinicalCourse.length;
          this.clinicalCourse = this.clinicalCourse.concat(result['data'].cursclinic);
          if (oldLength === this.clinicalCourse.length) {
            this.maxLoaded = true;
          } else {
            this.maxLoaded = false;
          }
          result['data'].cursclinic.forEach(element => {
            if (element.id) {
              if (this.bloc && this.bloc.header && this.bloc.header.click && this.bloc.header.click.toggle) {
                if (!this.blocToggled[element.id] && this.bloc.header.click.default_opened) {
                  this.blocToggled[element.id] = true;
                } else {
                  this.blocToggled[element.id] = false;
                }
              }
            }
          });
          this.loadingMore = false;
        } else {
          await this.showErrorAlert();
        }
        this.loadingMore = false;
      } catch (error) {
        await this.showErrorAlert(error.message);
      }
    }
  }

  getValue(val: string, format?, origin?) {
    let itm;
    if (origin) {
      itm = origin;
    } else if (this.item) {
      itm = this.item;
    }

    let params = val.split('.');
    let bloc = params[0];
    let tag = params[1];
    let value = null;
    let type = '';

    if (bloc === 'medxat') {
      if (tag === 'length') {
        return this.clinicalCourse.length;
      }
    }

    if (itm[bloc]) {
      itm[bloc].forEach(field => {
        if (field['tag'] === tag) {
          value = field.value;
          type = field.type;
        }
      });
    }

    if (format && format.type && format.type != type) {
      type = format.type;
      format = format.datetime_format;
    }
    if (type && type === 'datetime') {
      let _form = format || 'DD/MM/YYYY HH:mm'
      value = moment(value).format(_form);
    }
    return value;
  }

  dateFormatValue(cc, tag, format) {
    let value = cc[tag];
    let _form = format || 'DD/MM/YYYY HH:mm'
    value = moment(value).format(_form);
    return value;
  }

  getLabel(val: string, origin?) {
    let itm;
    if (origin) {
      itm = origin;
    } else if (this.item) {
      itm = this.item;
    }
    let params = val.split('.');
    let bloc = params[0];
    let tag = params[1];
    let value = null;

    if (itm[bloc]) {
      itm[bloc].forEach(field => {
        if (field['tag'] === tag) {
          value = field.label;
        }
      });
    }
    return value;
  }

  toggleBloc(cc) {
    if (this.bloc && this.bloc.header && this.bloc.header.click && this.bloc.header.click.toggle) {
      this.blocToggled[cc.id] = (this.blocToggled[cc.id] === true) ? false : true;
    }
  }

  async showErrorAlert(message?: string) {
    let translations = [];

    await this.tr.get(['general']).forEach(tr => translations = tr);
    let alert = await this.alertCtrl.create({
      header: 'Error',
      subHeader: message,
      buttons: [{
        text: translations['general'].ok,
        role: 'destructive'
      }]
    });
    this.loading = false;

    return alert.present();
  }

}
