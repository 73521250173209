export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyBHdPo5YfJLqHxeENa4Xl1NmkhUR4z3HD0',
    authDomain: 'medxat-app-demo.firebaseapp.com',
    databaseURL: 'https://medxat-app-demo.firebaseio.com',
    projectId: 'medxat-app-demo',
    storageBucket: 'medxat-app-demo.appspot.com',
    messagingSenderId: '812775800833',
    appId: '1:812775800833:web:81a53f5cf01c2d7e'
  },
  API_ENDPOINT: 'https://apidev.medxat.com/api',
  IG_ENDPOINT: 'https://api.mhce-dev.guttmann.com/api',
  JITSI: {
    APPID: "vpaas-magic-cookie-3ed06addf93b40919181da58d606cd70",
    DOMAIN: "8x8.vc"
  },
  FUNCTIONS_ENDPOINT: "https://europe-west1-medxat-app-demo.cloudfunctions.net",
  HOST_URL: 'https://guttmann-dev.web.app',
  secureCommUrl: 'https://europe-west1-medxat-app-demo.cloudfunctions.net/api_secureComm',
  FULLCALENDAR_LICENSE_KEY: "0402644140-fcs-1666361283",
  MINERVA_AS: "https://minerva.atheneasolutions.com",
  VERSION: "1.0"
};

