import { Component, OnInit, Input, EventEmitter, Output, OnDestroy } from '@angular/core';

@Component({
  selector: 'header-alert',
  templateUrl: './header-alert.component.html',
  styleUrls: ['./header-alert.component.scss'],
})
export class HeaderAlertComponent implements OnInit, OnDestroy {

  @Input() alertType = 'warning';
  @Input() button: string;

  @Input() icon: string;
  @Input() color: string;
  @Input() spinner: string;

  @Output() show = new EventEmitter();
  @Output() hide = new EventEmitter();
  @Output() buttonClick = new EventEmitter();


  constructor() { }

  ngOnInit() {
    this.show.next(true);

    if (this.alertType) {
      switch (this.alertType) {
        case 'info':
          this.icon = this.icon || 'information-circle';
          this.color = this.color || 'secondary';
          break;
        case 'warning':
          this.icon = this.icon || 'warning';
          this.color = this.color || 'warning';
          break;
        case 'danger':
          this.icon = this.icon || 'alert';
          this.color = this.color || 'danger';
          break;
        case 'internet':
          this.icon = this.icon || null;
          break;
        case 'success':
          this.icon = this.icon || 'information-circle';
          this.color = this.color || 'success';
          break;
      }
    }

  }

  ngOnDestroy() {
    this.hide.next(true);
  }

  clickButton() {
    if (this.button)
      this.buttonClick.next(true);
  }

}
