import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BarcodeScanner, SupportedFormat } from '@capacitor-community/barcode-scanner';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'barcode-scanner',
  templateUrl: './barcode-scanner.component.html',
  styleUrls: ['./barcode-scanner.component.scss'],
})
export class BarcodeScannerComponent implements OnInit, AfterViewInit, OnDestroy {

  result = null;
  scanActive = true;
  @Output() public scanResult: EventEmitter<any> = new EventEmitter();
  @Input() public keepOpen = false;
  @Input() public scanTrigger;
  onDestroy$ = new Subject<void>();

  constructor(public alertCtrl: AlertController,
    public tr: TranslateService) { }

  ngOnInit() {
    this.scanTrigger
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((val) => {
        console.log("START SCANNER 1", val);
        console.log("START SCANNER 2", this.scanActive);
        if (val && !this.scanActive) this.startScanner();
      })
  }

  async ngAfterViewInit() {
    this.startScanner();
  }

  async startScanner() {
    const allowed = await this.checkPermission();
    if (allowed) {
      this.scanActive = true;
      try {
        const result = await BarcodeScanner.startScan({ targetedFormats: [SupportedFormat.QR_CODE, SupportedFormat.CODE_128, SupportedFormat.DATA_MATRIX] });
        if (result.hasContent) this.result = result.content;
        this.scanResult.emit(this.result);
        if (!this.keepOpen) this.scanActive = false;
        else {
          setTimeout(() => {
            this.startScanner();
          }, 1000)
        }
      } catch (e) {
      }
    }
  }

  async checkPermission() {
    BarcodeScanner.prepare();
    return new Promise(async (resolve, reject) => {
      const status = await BarcodeScanner.checkPermission({ force: true });
      if (status.granted) {
        resolve(true);
      } else if (status.denied) {
        const alert = await this.alertCtrl.create({
          header: this.tr.instant('barcodescanner.a_header'),
          message: this.tr.instant('barcodescanner.a_message'),
          buttons: [{
            text: this.tr.instant('general.no'),
            role: 'cancel'
          }, {
            text: this.tr.instant('barcodescanner.a_text'),
            handler: () => {
              resolve(false);
              BarcodeScanner.openAppSettings();
            }
          }]
        });
        await alert.present();
      } else resolve(false);

    })
  }

  async stopScanner() {
    await BarcodeScanner.stopScan();
    this.scanActive = false;
    this.scanResult.emit(null);
    this.onDestroy$.next();
  }

  ngOnDestroy() {
    BarcodeScanner.stopScan();
    this.onDestroy$.next();
  }
}
