import { Injectable } from '@angular/core';
import { IonNav } from '@ionic/angular';
import { DetailPlaceholderComponent } from '../app/module-navigation/detail-placeholder/detail-placeholder.component';



@Injectable()
export class NavProxyService {
  _masterNav: IonNav = null;

  get masterNav(): IonNav {
    return this._masterNav;
  }
  set masterNav(value: IonNav) {
    this._masterNav = value;
  }


  _detailNav: IonNav = null;
  get detailNav(): IonNav {
    return this._detailNav;
  }
  set detailNav(value: IonNav) {
    this._detailNav = value;
  }


  _isOn: boolean = false;

  get isOn(): boolean {
    return this._isOn;
  }
  set isOn(value: boolean) {
    this._isOn = value;
  }


  async pushDetail(page: any, params: any) {
    await ((this.isOn) ?
      this.detailNav.setRoot(page, params) :
      this.masterNav.push(page, params));
    if (this.isOn) {
      const currentView = await this.detailNav.getActive();
      currentView.component.prototype.detail = true;
    }

  }

  pop() {
    try {

      return (this.isOn) ? this.detailNav.setRoot(DetailPlaceholderComponent) : this.masterNav.popToRoot();
    } catch (e) { }
  }


  pushMaster(page: any, params: any) {
    return this.masterNav.push(page, params);
  }

  onSplitPaneChanged(event) {
    let isOn = event.detail.visible;
    // set local 'isOn' flag...
    this.isOn = isOn;
    // if the nav controllers have been instantiated...
    if (this.masterNav && this.detailNav) {
      (isOn) ? this.activateSplitView() :
        this.deactivateSplitView();
    }
  }

  async activateSplitView() {
    let currentView = await this.masterNav.getActive();

    if (currentView && currentView.component.prototype && currentView.component.prototype.detail) {
      // if the current view is a 'Detail' page...
      // - remove it from the 'master' nav stack...
      this.masterNav.pop();
      // - and add it to the 'detail' nav stack...
      this.detailNav.setRoot(
        currentView.component,
        currentView.params);
    }
  }


  async deactivateSplitView() {
    let currentView = await this.detailNav.getActive();
    await this.detailNav.setRoot(DetailPlaceholderComponent);
    if (currentView && currentView.component.prototype && currentView.component.prototype.detail) {
      // if the current detail view is a 'Detail' page...
      // ...so, not the placeholder page:

      // add it to the master view...
      this.masterNav.push(
        currentView.component,
        currentView.params);
    }
  }
}
