// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-item-row {
  padding: 0 15px;
  cursor: default;
}
.user-item-row ion-avatar {
  width: 35px;
  height: 35px;
}
.user-item-row .user-item-reaction {
  font-size: 22px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/user-item/user-item.component.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,eAAA;AACJ;AACI;EACI,WAAA;EACA,YAAA;AACR;AAEI;EACI,eAAA;AAAR","sourcesContent":[".user-item-row {\n    padding: 0 15px;\n    cursor: default;\n    \n    ion-avatar {\n        width: 35px;\n        height: 35px;\n    }\n\n    .user-item-reaction {\n        font-size: 22px;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
