import { Component, OnInit, Input } from '@angular/core';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'text-loader',
  templateUrl: './text-loader.component.html',
  styleUrls: ['./text-loader.component.scss'],
})
export class TextLoaderComponent {


  txt: string = '';
  subtxt: string = '';
  foottxt: string = '';

  @Input()
  set text(txt: string) {
    this.txt = txt;
  }
  
  @Input()
  set subText(subtxt: string){
    this.subtxt = subtxt;
  }

  @Input()
  set footer(foottxt: string){
    this.foottxt = foottxt;
  }

  @Input() style;

  @Input() squareStyle;

  @Input() small;

  constructor(public plt: Platform) {
  }

}
