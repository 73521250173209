import { Injectable } from '@angular/core';
import { ModalController, AlertController } from '@ionic/angular';
import { VideoComponent } from 'src/app/shared/video/video.component';
import { AuthService } from './auth.service';
import { distinctUntilChanged, filter, first, take, takeUntil } from 'rxjs/operators';
import { UserService } from './user.service';
import { DeviceService } from './device.service';
import { AudioService } from './audio.service';
import { VideoService } from './video.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VideoModalService {
  existingCallModal;
  externalCallAlert;

  currentCallAlert;

  callingAlert;
  started = false;
  firstAlertWindow = true;
  
  constructor(public modalCtrl: ModalController,
    public userService: UserService,
    public deviceService: DeviceService,
    public audioService: AudioService,
    public alertController: AlertController,
    public tr: TranslateService,
    public vs: VideoService,
    public router: Router,
    public auth: AuthService) {


    //VIDEO
    this.auth.isAuthenticated$
      .pipe(
        distinctUntilChanged((a, b) => a === b)
      ).subscribe((auth) => {
        if (auth) {
          this.userService.user$.pipe(
            filter(user => user),
            distinctUntilChanged((a, b) => a.id === b.id && JSON.stringify(a.externalCall) === JSON.stringify(b.externalCall) && JSON.stringify(a.incomingCall) === JSON.stringify(b.incomingCall))
          ).subscribe(async (user) => {
            if (user && user.profile) {
              if (user.incomingCall && user.incomingCall.id) {
                this.started = true;
                await new Promise((resolve, reject) => {
                  this.userService.org$
                    .pipe(filter(org => org), first())
                    .subscribe((org) => {
                      if (org) resolve(null);
                    })
                })
                if (this.vs.chatIsOpened) this.vs.toggleChat$.next(false);
                let snap = await this.vs.getRoomSnap(user.incomingCall.id, true, user.incomingCall.orgKey);
                if (!this.callingAlert && (!this.auth.videoWindow || (this.auth.videoWindow && !this.firstAlertWindow))) {
                  this.audioService.playCallingTone();
                  await this.presentCallingAlert({ id: snap.id, ...snap.data() }, user.incomingCall.orgKey);
                } else if(this.auth.videoWindow){
                  setTimeout(()=>{
                    this.firstAlertWindow = false;
                    // this.auth.videoWindow = false;
                  },5000)
                }
              } else {
                if (this.callingAlert) {
                  await this.callingAlert.dismiss();
                  this.callingAlert = null;
                  await this.audioService.stopRingTone(false);
                }
              }
            }
            if (user && user.externalCall) {
              if (this.currentCallAlert === user.externalCall.randId) return;
              this.currentCallAlert = user.externalCall.randId;
              if (this.deviceService.info && user.externalCall.source != this.deviceService.info.uuid) {
                if (user.externalCall.type === 'video') {
                  await this.showExternalVideoCallAlert(user.externalCall, true);
                } else if (user.externalCall.type === 'call') {
                  await this.showExternalPhoneCallAlert(user.externalCall);
                }
              }
            } else {
              if (this.externalCallAlert) await this.externalCallAlert.dismiss();
              this.currentCallAlert = null;
            }
          })
        }
      })
  }


  async showExternalVideoCallAlert(call, external = false) {
    if (this.externalCallAlert) {
      await this.externalCallAlert.dismiss();
      this.externalCallAlert = null;
    }
    let translations = {};
    await this.tr.get(['tm', 'general', 'loader']).forEach(x => translations = x);

    this.externalCallAlert = await this.alertController.create({
      header: translations['loader'].warning_title,
      subHeader: call.contactName,
      message: translations['tm'].start_video_here,
      backdropDismiss: false,
      buttons: [
        {
          text: translations['general'].yes,
          handler: async () => {
            call.external = external;
            await this.startLocalVideoCall(call);
            await this.userService.deleteUserProperty('externalCall');
          }
        },
        {
          text: translations['general'].no,
          role: 'cancel',
          handler: async () => {
            if (this.externalCallAlert) this.externalCallAlert.dismiss();
          }
        }
      ]
    });
    return this.externalCallAlert.present();
  }



  async showExternalPhoneCallAlert(call) {
    if (this.externalCallAlert) {
      await this.externalCallAlert.dismiss();
      this.externalCallAlert = null;
    }
    let translations = {};
    await this.tr.get(['tm', 'general', 'loader']).forEach(x => translations = x);

    this.externalCallAlert = await this.alertController.create({
      header: translations['loader'].warning_title,
      subHeader: (call && call.visit && call.visit.patient && call.visit.patient.name) ? call.visit.patient.name : '',
      message: translations['tm'].start_call_here,
      backdropDismiss: false,
      buttons: [
        {
          text: translations['general'].yes,
          handler: async () => {
            let snap: any = await this.userService.getUserSnapById(call.visit.patient.id);
            await this.startLocalPhoneCall(null, { id: snap.id, ...snap.data() });
            await this.userService.deleteUserProperty('externalCall');
          }
        },
        {
          text: translations['general'].no,
          role: 'cancel',
          handler: async () => {
            if (this.externalCallAlert) this.externalCallAlert.dismiss();
          }
        }
      ]
    });
    return this.externalCallAlert.present();
  }

  async startLocalVideoCall(params) {
    if (this.vs.disabledVideoWebView) 
    await this.vs.openExternal(params);
    else await this.openVideo(params);
  }

  startLocalPhoneCall(id?, patient?) {
    if (id) {
      let link = document.getElementById(id);
      if (link) link.click();
    } else {
      if (!patient || !patient.profile || !patient.profile.phone) return;
      let a = document.createElement('a');
      a.href = 'tel:%2331%23' + patient.profile.phone;
      a.click();
    }
  }

  init() {
    this.auth.openVideoModal$.subscribe(async (params) => {
      if (params) {
        params = JSON.parse(params);
        if(params && params.room && params.room.call && params.room.call.id) 
        {
          let id = params.room.call.id;
          let snap = await this.vs.getRoomSnap(id, true, params.orgKey);
          let roomParams = {
            id: snap.id,
            ...snap.data(),
            ...params,
          }
          if(params.status) delete  params.status;
          if(params.room && params.room.call && params.room.call.status) delete  params.room.call.status;
          this.openVideo(roomParams);
        }else this.openVideo(params);
      }
    })
  }



  async presentCallingAlert(data, orgKey?) {
    let translations = [];
    await this.tr.get(['general', 'video']).forEach((tr) => translations = tr);
    if (this.callingAlert) {
      this.callingAlert.dismiss();
      this.callingAlert = null;
      await this.audioService.stopRingTone(false);
    }
    let header = translations['video'].alert_title;
    header += data.callMode ? ' (' + translations['video'].voice + ')' : ' (' + translations['video'].video + ')'
    this.callingAlert = await this.alertController.create({
      header,
      subHeader: data.callerName + ' ' + translations['video'].alert_message,
      message: '',
      backdropDismiss: false,

      buttons: [{
        text: translations['general'].cancel,
        role: 'cancel',
        cssClass: 'secondary',
        handler: async () => {
          if (data && data.id) await this.vs.cancelCall(data.id);
          await this.audioService.stopRingTone(false);
        }
      }, {
        text: translations['video'].answer,
        handler: async () => {
          if (data && data.visitId) {
            let url = this.router.url.includes('visit') && this.router.url.includes(data.visitId);
            if (!url) await this.router.navigate([`visit/${data.visitId}`]);
          }
          if (orgKey && this.userService.getOrganization().id != orgKey) {
            const orgs: any[] = this.userService.organizations$.getValue();
            if (orgs) {
              const org = orgs.find((x) => x.id === orgKey);
              if (org) {
                this.userService.org$.next(org);
                let subs$ = new Subject<void>();
                await new Promise((resolve, reject) => {
                  if (this.userService.getOrganization().id === orgKey) {
                    subs$.next();
                    resolve(null);
                  }
                  this.userService.org$
                    .pipe(takeUntil(subs$))
                    .subscribe((org) => {
                      if (org && orgKey === org.id) {
                        subs$.next();
                        resolve(null);
                      }
                    })
                })
              }
            }
          }
          this.audioService.stopRingTone(true);
          this.vs.startVideo(data);
        }
      }]
    });
    this.callingAlert.onDidDismiss(async () => {
      await this.audioService.stopRingTone(true);
      this.callingAlert = null;
    })
    return this.callingAlert.present();
  }


  async openVideo(params) {
    if (this.existingCallModal) await this.existingCallModal.dismiss();
  
    this.existingCallModal = await this.modalCtrl.create({
      component: VideoComponent,
      backdropDismiss: false,
      componentProps: params,
      cssClass: 'videoFrame',
      id: 'videoModal'
    });
    this.existingCallModal.onDidDismiss().then(() => this.existingCallModal = null)
    return this.existingCallModal.present();

  }
}
