// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
  position: absolute;
  bottom: 0px;
  color: lightgray;
}
.footer a {
  color: lightgray;
}
.footer ion-label {
  font-size: 0.7rem;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/awards-info/awards-info.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,WAAA;EACA,gBAAA;AACF;AAAE;EACE,gBAAA;AAEJ;AAAE;EACE,iBAAA;AAEJ","sourcesContent":[".footer {\n  position: absolute;\n  bottom: 0px;\n  color: lightgray;\n  a {\n    color: lightgray;\n  }\n  ion-label{\n    font-size: 0.7rem;;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
