// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  white-space: normal;
  text-align: center;
  font-size: 0.9em;
  padding: 5px 8px;
  border-radius: 5px;
  box-shadow: 0 1px 0.5px var(--ion-color-light) !important;
  background: var(--ion-color-lightGray) !important;
  color: var(--ion-color-dark) !important;
}

ion-icon {
  vertical-align: middle;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/info-message/info-message.component.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,kBAAA;EACA,gBAAA;EACA,gBAAA;EACA,kBAAA;EACA,yDAAA;EACA,iDAAA;EACA,uCAAA;AACF;;AAEA;EACE,sBAAA;AACF","sourcesContent":[".container {\n  white-space: normal;\n  text-align: center;\n  font-size: 0.9em;\n  padding: 5px 8px;\n  border-radius: 5px;\n  box-shadow: 0 1px 0.5px var(--ion-color-light) !important;\n  background: var(--ion-color-lightGray) !important;\n  color:var(--ion-color-dark) !important;\n}\n\nion-icon{\n  vertical-align: middle;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
