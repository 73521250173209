import { Injectable } from '@angular/core';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { Subject } from 'rxjs';
import { UserService } from './user.service';
import { distinctUntilChanged, filter, switchMap } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { AlertController, ModalController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { TimeService } from './time.service';
import { AudioService } from './audio.service';
import { CommunicationService } from './communication.service';
import { LoadingService } from './loading.service';

import { Browser } from '@capacitor/browser';
import { environment } from '../environments/environment';
import { DeviceService } from './device.service';
import WebViewPlugin from 'src/capacitor_plugins/web-view-plugin';
import { deleteField, serverTimestamp } from 'firebase/firestore';
import { FirebaseService } from './firebase.service';

@Injectable({
  providedIn: 'root'
})
export class VideoService {

  calling$ = new Subject<any>();
  readMessage$ = new Subject<any>();
  servers;
  pc1Local: any;//RTCPeerConnection;
  pc2Local;

  pcs: any = {};
  pcStatus;
  roomId;
  roomRef;
  roomOffer;
  callTrigger$;
  room$;
  roomOther$;
  hangUp$ = new Subject<boolean>();
  callingAlert;

  videoEnabled = false;
  createVideo = false;

  callEnabled = false;
  createCall = false;
  disabledVideoWebView = false;

  roomUsers = 0;

  //External call
  externalCallAlert;
  started = false;

  //visits link
  toggleChat$ = new Subject<any>();
  chatIsOpened = false;
  chatModal;

  constructor(
    public authService: AuthService,
    public alertController: AlertController,
    public userService: UserService,
    public modalCtrl: ModalController,
    public tr: TranslateService,
    public plt: Platform,
    public timeService: TimeService,
    public fs: FirebaseService,
    public audioService: AudioService,
    public comm: CommunicationService,
    public loadingService: LoadingService,
    public deviceService: DeviceService,
    public alertCtrl: AlertController) {


    this.authService.isAuthenticated$
      .pipe(
        distinctUntilChanged((a, b) => a === b)
      ).subscribe((auth) => {
        if (auth) {
          this.userService.user$.pipe(
            filter(user => user),
            distinctUntilChanged((a, b) => a.id === b.id),
            switchMap((user) => this.userService.org$
              .pipe(
                filter(org => org),
                distinctUntilChanged((a, b) => a.id === b.id)
              ))).subscribe((org) => {
                if (org && org.params && org.params.call) {
                  let call = org.params.call;
                  if (call.video && call.video.active) {
                    if (this.userService.getUser().profile.patient === true) {
                      if (call.video.patients) this.videoEnabled = true;
                      else this.videoEnabled = false;
                      this.createVideo = false;
                    } else {
                      this.videoEnabled = true;
                      this.createVideo = true;
                    }
                  } else {
                    this.videoEnabled = false;
                    this.createVideo = false;
                  }
                  if (call.voice && call.voice.active) {
                    if (this.userService.getUser().profile.patient === true) {
                      if (call.voice.patients) this.callEnabled = true;
                      else this.callEnabled = false;
                      this.createCall = false;
                    } else {
                      this.callEnabled = true;
                      this.createCall = true;
                    }
                  } else {
                    this.callEnabled = false;
                    this.createCall = false;
                  }
                } else {
                  this.videoEnabled = false;
                  this.createVideo = false;
                  this.callEnabled = false;
                  this.createCall = false;
                }
                if (this.videoEnabled || this.callEnabled) this.init();
              });
        }
      });

    this.authService.onSignOut$.subscribe(() => {
      if (this.callTrigger$) this.callTrigger$();
    })

    if (this.plt.is('capacitor') && !this.plt.is('mobileweb')) {
      this.disabledVideoWebView = true;
    } else this.disabledVideoWebView = false;
  }

  init() {
    this.servers = {
      iceServers: [
        {
          urls: 'stun:54.93.229.120:3478'
        },
        {
          urls: 'turn:54.93.229.120:3478',
          username: 'prouser',
          credential: '3TptDG7cAfz5TaXsda'
        }
      ]
    };

    // this.checkTURNServer(this.servers);
  }


  async startVideo(snap) {
    this.calling$.next(snap);
  }

  startPC() {
    this.servers = {
      iceServers: [
        {
          urls: 'stun:54.93.229.120:3478'
        },
        {
          urls: 'turn:54.93.229.120:3478',
          username: 'prouser',
          credential: '3TptDG7cAfz5TaXsda'
        }
      ]
    };
    this.pc1Local = new RTCPeerConnection(this.servers);
  }

  async createRoomOffer() {
    const offer = await this.pc1Local.createOffer({
      offerToReceiveAudio: true,
      offerToReceiveVideo: true
    });
    // console.log("OFFER", offer)
    await this.pc1Local.setLocalDescription(offer);
    this.roomOffer = {
      ...this.roomOffer,
      status: 'calling',
      offer: {
        type: offer.type,
        sdp: offer.sdp
      }
    }
    return this.roomRef.set(this.roomOffer, { merge: true });
  }

  async createRoom(receiver, contactName, chat, origin, callMode = false) {

    this.roomUsers = 1;
    this.roomOffer = {
      caller: this.userService.getUser().id,
      users: {
        [this.userService.getUser().id]: true,
        [receiver]: false
      },
      callerName: this.userService.getUser().profile.name,
      date: serverTimestamp()
    }
    if (callMode) this.roomOffer.callMode = true;
    else this.roomOffer.videoMode = true;

    if (contactName) this.roomOffer.receiverName = contactName;
    if (receiver) {
      this.roomOffer.receiverId = receiver;
      this.roomOffer.users[receiver] = false;
    }
    if (origin === 'chat' && chat && chat.id) this.roomOffer.chatId = chat.id;
    if (origin === 'tm' && chat && chat.id) this.roomOffer.visitId = chat.id;

    if (origin) this.roomOffer.origin = origin;

    this.roomOffer.type = "room";
    this.roomRef = doc(this.fs.firestore, 'orgs', this.userService.getOrganization().id, 'calls')

    this.roomId = this.roomRef.id;
    return Promise.resolve();
  }

  async joinRoom(data, roomId = null) {
    let offer = data.offer;
    await this.pc1Local.setRemoteDescription(new RTCSessionDescription(offer));
    const answer = await this.pc1Local.createAnswer();
    // console.log('Created answer:', answer);
    await this.pc1Local.setLocalDescription(answer);
    const roomWithAnswer = {
      status: 'answered',
      answer: {
        type: answer.type,
        sdp: answer.sdp
      },
      users: {
        [this.userService.getUser().id]: true
      }
    }

    return this.roomRef.set(roomWithAnswer, { merge: true });
  }

  getRoomSnap(roomId, room = true, orgKey = null) {
    if (!roomId) return;
    if (!orgKey && (!this.userService.getOrganization() || !this.userService.getOrganization().id)) {
      return;
    }
    return getDoc(doc(this.fs.firestore, 'orgs', orgKey ? orgKey : this.userService.getOrganization().id, 'calls', roomId));
  }

  async collectIceCandidates(roomRef, peerConneciton, localName, remoteName) {
    const candidatesCollection = roomRef.collection(localName);
    return new Promise((resolve, reject) => {
      this.pc1Local.onicecandidate = event => {
        if (!event.candidate) { console.log('Got final candidate!'); resolve(true); return; }
        if (event.candidate) candidatesCollection.add(event.candidate.toJSON());
      };
    })
  }

  registerPeerConnectionListeners() {
    this.pc1Local.addEventListener('icegatheringstatechange', () => {
      // console.log(`ICE gathering state changed: ${this.pc1Local.iceGatheringState}`);
    });

    this.pc1Local.addEventListener('connectionstatechange', () => {
      // console.log(`Connection state change: ${this.pc1Local.connectionState}`);
    });

    this.pc1Local.addEventListener('signalingstatechange', () => {
      // console.log(`Signaling state change: ${this.pc1Local.signalingState}`);
    });

    this.pc1Local.addEventListener('iceconnectionstatechange ', () => {
      if (this.pc1Local.iceConnectionState === 'disconnected') {
        this.hangUp('other');
      }
      // console.log(`ICE connection state change: ${this.pc1Local.iceConnectionState}`);
    });
  }

  addCallerCandidate(data) {
    return this.roomRef.collection('callerCandidates').add(data);
  }

  addCalleeCandidate(data) {
    return this.roomRef.collection('calleeCandidates').add(data);
  }

  async setRoomRef(id, room = false) {
    this.roomRef = doc(this.fs.firestore, 'orgs', this.userService.getOrganization().id, 'calls', id);
    this.roomId = id;
  }

  async updateRoomUsers(users) {
    let total = 0;
    let keys = Object.keys(users);
    keys.forEach(key => {
      let user = users[key];
      if (user) total++;
    });
    this.roomUsers = total;
  }


  async hangUp(other?) {

    if (this.roomRef) {
      const calleeCandidates = await this.roomRef.collection('calleeCandidates').get();
      for (let i = 0; i < calleeCandidates.length; i++) {
        await calleeCandidates[i].ref.delete();
      }

      const callerCandidates = await this.roomRef.collection('callerCandidates').get();
      for (let i = 0; i < callerCandidates.length; i++) {
        await callerCandidates[i].ref.delete();
      }
      if (other) this.hangUp$.next(true);
      return this.roomRef.set({
        status: 'finished',
        endAt: serverTimestamp(),
        users: { [this.userService.getUser().id]: false }
      }, { merge: true });

    } else return Promise.resolve();
  }

  async cancelCall(id) {
    await setDoc(doc(this.fs.firestore, 'users', this.userService.getUser().id), { incomingCall: deleteField() }, { merge: true })
    await setDoc(doc(this.fs.firestore, 'orgs', this.userService.getOrganization().id, 'calls', id), { status: 'finished' }, { merge: true })
  }

  //TELEMEDICINE video STATUS
  updateStartVideoSetting(visit) {
    // console.log("UPDATE VISIT", visit)
    return setDoc(doc(this.fs.firestore, 'orgs', this.userService.getOrganization().id, 'visits', visit.id), {
      call: {
        patient: {
          settingAt: serverTimestamp()
        }
      }
    }, { merge: true });
  }

  updatePatientVideoSettings(visit, data) {
    data.device = {
      ...this.deviceService.info
    }
    return setDoc(doc(this.fs.firestore, 'orgs', this.userService.getOrganization().id, 'visits', visit.id), {
      call: {
        patient: data
      }
    }, { merge: true });
  }

  updatePatientDeviceSettings(visit) {
    let device = {
      ...this.deviceService.info
    }
    return setDoc(doc(this.fs.firestore, 'orgs', this.userService.getOrganization().id, 'visits', visit.id), {
      call: {
        patient: {
          device
        }
      }
    }, { merge: true })
  }

  forceHangUp() {
    this.hangUp$.next(true);

  }

  async openExternal(params) {
    try {
      await this.loadingService.presentLoading();
      let token = await (await this.fs.auth.currentUser).getIdToken();
      let videoToken = await this.comm.getVideoToken(token);
      if (videoToken && videoToken.token) {
        videoToken = videoToken.token;

        params.orgKey = this.userService.getOrganization().id;
        if (params.offer) delete params.offer;
        if (params.room && params.room.call && params.room.call.offer) delete params.room.call.offer;
        let urlParams = encodeURIComponent(JSON.stringify(params))
        // environment.HOST_URL = 'http://localhost:8100'
        let url = `${environment.HOST_URL}/?videoParams=${urlParams}&withToken=${videoToken}`;
        if (this.plt.is('ios'))
          await WebViewPlugin.openURL({ url });
        else Browser.open({ url });
        await this.loadingService.dismissLoading();
      } else await this.loadingService.dismissLoading();
    } catch (e) {
      // console.log("ERROR", e.message);
      await this.loadingService.dismissLoading();
    }
  }


  async startRemoteCall(params) {
    params.source = this.deviceService.info.uuid;
    params.type = 'video';
    params.randId = Math.random() * (999999 - 0) + 0;
    await this.userService.deleteUserProperty('externalCall');
    await this.userService.updateUserProperty({ externalCall: params });
  }


  getVisit(id) {
    return getDoc(doc(this.fs.firestore, 'orgs', this.userService.getOrganization().id, 'visits', id))
  }


  async checkTURNServer(turnConfig) {
    //https://stackoverflow.com/questions/28772212/stun-turn-server-connectivity-test
    // console.log("CHECK TURN SERVER IS AWAKE");
    let pc = new RTCPeerConnection(turnConfig);
    await pc.createOffer({
      offerToReceiveAudio: true,
      offerToReceiveVideo: true
    })
    const sdp = await pc.createOffer({
      offerToReceiveAudio: true,
      offerToReceiveVideo: true
    });
    await pc.setLocalDescription(sdp);
    // create offer and set local description
    pc.onicecandidate = function (ice) {  //listen for candidate events
      if (!ice || !ice.candidate || !ice.candidate.candidate || !(ice.candidate.candidate.indexOf('typ relay') > -1)) {
        // console.log("NO OK!")
        //NO ICE
      }
      console.log("OK!", ice.candidate)
    };

  }
}
