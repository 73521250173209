// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.session-bar {
  height: 17px;
}
.session-bar p {
  margin: 0;
  padding: 0;
}

.progress-text {
  bottom: 18px;
  font-weight: normal;
  font-size: 14px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/progress-bar/progress-bar.component.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;AACJ;AAAI;EACI,SAAA;EACA,UAAA;AAER;;AAGA;EACI,YAAA;EACA,mBAAA;EACA,eAAA;AAAJ","sourcesContent":[".session-bar {\n    height: 17px;\n    p{\n        margin: 0;\n        padding: 0;\n        \n    }\n}\n\n.progress-text {\n    bottom: 18px;\n    font-weight: normal;\n    font-size: 14px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
