//ANGULAR
import { ViewChild, OnInit, OnDestroy, Input, Injectable, Component, Directive } from '@angular/core';
//RXJS
import { Subject } from 'rxjs';
//SERVICES
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from "rxjs/operators";
import { ToastController, LoadingController, AlertController, Platform, NavParams } from '@ionic/angular';
import { NetworkService } from 'src/services/network.service';
import { ModulesService } from 'src/services/modules.service';
import { DeviceService } from 'src/services/device.service';
import { NavigationService } from 'src/services/navigation.service';
import { NavProxyService } from 'src/services/nav-proxy-service.service';

@Component({
    template: ''
})
export abstract class ViewSuperPage implements OnInit, OnDestroy {

    //INIT
    @ViewChild('content', { 'static': true }) content;
    public onDestroy$ = new Subject<void>();
    public loading;
    public refresher: any;

    //GENERAL CONFIG
    public connection: boolean = true;
    public connectionError: boolean = false;
    public today: any = new Date();
    public selectedDate;
    public tabsSelectedInit$: Subject<any> = new Subject<any>();

    //VIEWS
    public vistaForms: any = [];
    public vistaLlista: any = [];
    public vistaDetall: any = [];
    public vistaDashboard: any = [];
    public data: any;

    //VIEW CONFIG
    public showBlock: any = [];
    public selectedSection = "Ficha";
    public showFilters: boolean = false;
    public showSearchBar: boolean = false;
    public forceLoadBloc$ = new Subject<boolean>();

    //VIEW PARAMS
    public view;
    @Input() app_id: string;
    @Input() id: string; //module_id
    @Input() event_parent: string = "";
    @Input() event: string;
    @Input() params: {};
    @Input() title: string = "";
    @Input() log: boolean = false;
    @Input() pushOver: boolean = false;
    @Input() queryParam: string;
    @Input() queryValue: string;


    //VIEW RESULTS
    public resultats: any;
    public response: any;
    public dataTimestamp: any = '';
    public itemSelected: any;
    public searchWarning: string = '';
    public item;


    constructor(
        public navProxy: NavProxyService,
        public navParams: NavParams,
        public tr: TranslateService,
        public toastController: ToastController,
        public loadingCtrl: LoadingController,
        public networkService: NetworkService,
        public mcs: ModulesService,
        public navigationService: NavigationService,
        public alertCtrl: AlertController,
        public deviceService: DeviceService,
        public platform: Platform
    ) {
    }


    async ngOnInit() {
        this.id = this.navParams.get('module_id');
        this.data = await this.mcs.getModule(this.id);
        this.app_id = this.data.app_id;
        // console.log("MODULE DATA", this.data);
        this.event = this.data.event;
        this.event_parent = this.data.event_parent ? this.data.event_parent : false;
        this.params = this.data.params;
        // console.log("this.params", this.params)
        this.title = this.data.title;
        this.log = this.data.log;
        this.pushOver = this.data.pushOver || false;

        //check network
        this.networkService.firebaseConnection$
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(value => {
                this.connection = value;
                if (!value) this.loadingCtrl.dismiss();
            });

        this.mcs.closeDetail$.pipe(takeUntil(this.onDestroy$))
            .subscribe((val) => {
                this.itemSelected = null;
                if (!val) this.navProxy.pop();
            });


        this.tr.get(['census'])
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(translations => {
                this.searchWarning = translations['census'].applyFilterinit;
            });

        //TODO: resize because of keyboard issue
        // this._navigationService._closeChat$
        //     .pipe(takeUntil(this._onDestroy$))
        //     .subscribe(() => {
        //         if (this.content) {
        //             this.content.resize();
        //             this._changeDetectorRef.markForCheck();
        //         }
        //     });

    }

    getFuncio(event, load = false) {
    }

    initView() {
        //INIT CONFIG
        if (this.view && this.view['config']) {
            if (this.view['config'].refresh) {
                let timer = this.view['config'].refresh.timer || null;
                if (timer) {
                    this.deviceService.isActive$
                        .pipe(takeUntil(this.onDestroy$))
                        .subscribe((val) => {
                            setTimeout(() => {
                                if (!this.deviceService.isActive) {
                                    this.refresh();
                                }
                            }, timer);
                        })
                }
            }
            if (this.view['config'].segments) {
                let segments = this.view['config'].segments;
                let i = 0;
                segments.forEach(segment => {
                    segment.content.forEach(element => {
                        if (i === 0) {
                            this.selectedSection = element.label;
                            this.segmentChanged(this.selectedSection);
                            i++;
                        } else if (element.initial) {
                            this.selectedSection = element.initial;
                            this.segmentChanged(this.selectedSection);
                        }
                    });
                });
                this.tabsSelectedInit$.next(this.selectedSection);
            }

            if (this.view['config']['event_load']) {
                this.forceLoadBloc$.next(this.view['config']['event_load']);
            }
        }

    }

    itemHasValue(item, val) {
        let result = false;
        let fields = Object.keys(item);
        fields.forEach(field => {
            item[field].forEach(camp => {
                if (!result) {
                    result = (camp.value === val);
                }
                if (!result && camp.value) {
                    try {
                        result = (("" + camp.value).toLowerCase().indexOf(val.toLowerCase()) > -1);
                    } catch (e) {
                        // console.log("search error", e);
                    }
                }
            });
        });
        return result;
    }

    segmentChanged(event: any) {
        //SHOWS BLOCS DEPENDING ON WHICH SEGMENT IS SELECTED
        let segments;
        if (this.view && this.view['config'] && this.view['config'].segments) {
            segments = this.view['config'].segments;
            segments.forEach(segment => {
                segment.content.forEach(element => {
                    if (element.label === this.selectedSection) {
                        this.view['content'].forEach(bloc => {
                            if (element.blocks.indexOf(bloc.id) != -1) {
                                this.showBlock[bloc.id] = true;
                                if (bloc.show_when && bloc.show_when.tag) {
                                    let field = bloc.show_when.tag.split('.')[0];
                                    let tag = bloc.show_when.tag.split('.')[1];
                                    this.showBlock[bloc.id] = false;
                                    if (this.item[field]) {
                                        this.item[field].forEach(itm => {
                                            if (itm && itm.tag === tag) {
                                                this.showBlock[bloc.id] = true;
                                            }
                                        });
                                    }
                                }
                                if (bloc.conditional_value) {
                                    this.showBlock[bloc.id] = false;
                                    bloc.conditional_value.forEach(element => {
                                        if (this.getItemValue(this.item, element)) {
                                            this.showBlock[bloc.id] = true;
                                        }
                                    })
                                }
                            } else {
                                this.showBlock[bloc.id] = false;
                            }
                        });
                    }
                });
            });
        } else {
            this.view['content'].forEach(bloc => {
                if (bloc.conditional_value) {
                    this.showBlock[bloc.id] = false;
                    bloc.conditional_value.forEach(element => {
                        if (this.getItemValue(this.item, element)) {
                            this.showBlock[bloc.id] = true;
                        }
                    })
                }
                if (bloc.show_when && bloc.show_when.tag) {
                    let field = bloc.show_when.tag.split('.')[0];
                    let tag = bloc.show_when.tag.split('.')[1];
                    this.showBlock[bloc.id] = false;
                    if (this.item[field]) {
                        this.item[field].forEach(itm => {
                            if (itm && itm.tag === tag) {
                                this.showBlock[bloc.id] = true;
                            }
                        });
                    }
                }
            });
        }


    }

    getItemValue(item: any, element: any) {
        let params = element.split('.');
        let bloc = params[0];
        let tag = params[1];
        let value = null;
        let type = '';
        if (item[bloc]) {
            item[bloc].forEach(field => {
                if (field['tag'] === tag) {
                    value = field.value;
                }
            });
        }
        return value;
    }

    async refresh(event?, fab?) {
        if (event) {
            this.refresher = event.target;
        } else {
            this.refresher = null;
        }
        this.mcs.closeDetail$.next(true);
        this.itemSelected = null;
        await this.getFuncio(event, false);
        //TODO: ara amaguem la vistadetall en cas que s'actualitzi. Guardar el pacient (per algun id o algo s'ha de passar des del MIRTH)
        // i actualitzar la seva vista detall sense amagar-la.
        let currentView = await this.navProxy.masterNav.getActive();
        if (currentView && currentView.component.prototype && currentView.component.prototype.detail)
            await this.navProxy.pop();
    }


    async dismissRefresher() {
        if (this.refresher) {
            await this.refresher.complete();
            this.refresher = null;
        }
    }

    async showToast(message: string = '') {
        let translations = [];
        await this.tr.get(['general']).forEach(tr => translations = tr);

        if (!message || message === '') {
            message = translations['general'].error_loading;
        }
        let done = await this.toastController.create({
            message,
            duration: 4000,
            position: 'bottom',
        });

        await done.present();
    }


    async showAlert(header, message) {
        let translations = [];
        await this.tr.get(['download', 'general']).forEach(tr => translations = tr);

        let prompt = await this.alertCtrl.create({
            header,
            message,
            buttons: [
                {
                    text: translations['general'].ok,
                    handler: () => {

                    }
                }
            ]
        });
        await prompt.present();
    }

    async showError(error?) {
        this.connectionError = true;
        await this.dismissLoading();
        await this.showToast();
    }

    toogleSearchBar() {
        this.showSearchBar = !this.showSearchBar;
    }

    onSearchCancel(event) {
        this.showSearchBar = false;
    }

    async presentLoading() {
        if (this.loading) {
            await this.dismissLoading();
        }
        let translations = [];
        await this.tr.get(['general']).forEach(tr => translations = tr);

        this.loading = await this.loadingCtrl.create({
            message: translations['general'].loading
        });
        await this.loading.present();
    }

    async dismissLoading() {
        if (this.loading) {
            this.loading.dismiss();
            this.loading = null;
        }

        this.loading = null;
        await this.dismissRefresher();
    }

    startLoading() {
        this.presentLoading();
    }

    endLoading() {
        this.dismissLoading();
    }

    ionViewWillEnter() {
        if (this.platform.is('capacitor') === true) {
            // this._splashScreen.hide(); //TODO
        }
    }

    ionViewDidLeave() {
        this.onSearchCancel(null);
        //TODO
        // this._navigationService._closeChat$.next(true);
    }

    getItemByQuery(results) {
        let match;
        if (!this.queryParam || !this.queryValue) return null;
        let field: string;
        let param: string;
        if (this.queryParam.includes('.')) {
            let split = this.queryParam.split('.');
            field = split[0];
            param = split[1];
        } else {
            field = 'form';
            param = this.queryParam;
        }
        for (let i = 0; i < results.length; i++) {
            let result: any = results[i];
            if (!result[field]) continue;
            let obj = result[field].find((obj) => obj.tag === param);
            if (obj && obj.value && obj.value.toString().toLowerCase() === "" + this.queryValue.toLocaleLowerCase()) {
                match = result;
                break;
            }
        }
        return match;
    }

    ngOnDestroy() {
        this.itemSelected = null;
        //TODO
        // this._navigationService._closeChat$.next(true);

        this.onDestroy$.next();
    }

}
