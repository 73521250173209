import { Injectable } from '@angular/core';

import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { CommunicationService } from './communication.service';
import { UserService } from './user.service';
import { Network, ConnectionStatus } from '@capacitor/network';
import { getDatabase, onValue, ref } from 'firebase/database';

@Injectable({
  providedIn: 'root'
})
export class NetworkService {
  public firebaseConnection$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public networkStatus$: BehaviorSubject<ConnectionStatus> = new BehaviorSubject({ connected: false, connectionType: undefined });
  public firebaseDisconnected$: Subject<void> = new Subject();
  private firebaseConnectionSubscription: Subscription;
  private timeout: any;
  private timeoutNetwork: any;
  public speedInfo;
  public downloadSlow = false;
  public uploadSlow = false;
  public UP_KBPS: any = 15; //kbps
  public DOWN_KBPS: any = 100; //kbps

  constructor(
    public userService: UserService,
    public comm: CommunicationService) {
    this.subscribeNetworkStatusChange();
    this.subscribeFirebaseConnection();
    this.checkConnection();
  }

  async subscribeFirebaseConnection() {
    if (this.firebaseConnectionSubscription) this.firebaseConnectionSubscription.unsubscribe();
    const db = getDatabase();
    const connectedRef = ref(db, ".info/connected");
    onValue(connectedRef, (connected) => {
      const isConnected = connected ? connected.val() === true : false;
      this.firebaseConnection$.next(isConnected);
      if (!isConnected) {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => { if (!this.isFirebaseConnected()) this.subscribeFirebaseConnection() }, 5000);
        if (this.isNetworkConnected()) {
          this.comm.speedTest().then((a) => {
            this.firebaseConnection$.next(true);
          })
        }
        this.firebaseDisconnected$.next();
      }
    })
  }

  isFirebaseConnected() {
    return this.firebaseConnection$.getValue();
  }

  isNetworkConnected() {
    return this.networkStatus$.getValue();
  }

  async checkConnection() {
    const status = await Network.getStatus();
    this.networkStatus$.next(status);
  }

  private subscribeNetworkStatusChange() {
    // create network status observable
    Network.addListener('networkStatusChange', (status) => {
      this.networkStatus$.next(status);

      this.subscribeFirebaseConnection();
      if (!status.connected) {
        if (this.timeoutNetwork) clearTimeout(this.timeoutNetwork);
        this.timeoutNetwork = setTimeout(() => this.subscribeNetworkStatusChange(), 5000);
        this.comm.speedTest().then(() => {
          this.networkStatus$.next({ connected: true, connectionType: 'unknown' });
        })
      }
    });
  }

  speedTest(reset = false) {
    if (reset) {
      this.uploadSlow = false;
      this.downloadSlow = false;
    }

    return this.comm.getDownloadSpeed()
      .then((speed) => {

        this.UP_KBPS = (this.userService.getOrganization() && this.userService.getOrganization().params
          && this.userService.getOrganization().params.call && this.userService.getOrganization().params.call.up_kbps) ? this.userService.getOrganization().params.call.up_kbps : this.UP_KBPS;
        this.DOWN_KBPS = (this.userService.getOrganization() && this.userService.getOrganization().params
          && this.userService.getOrganization().params.call && this.userService.getOrganization().params.call.down_kbps) ? this.userService.getOrganization().params.call.down_kbps : this.DOWN_KBPS;

        if (speed) {
          this.speedInfo = speed;
          if (this.speedInfo && this.speedInfo.down_average) {
            if (parseFloat("" + this.speedInfo.down_average.kbps) < parseFloat("" + this.DOWN_KBPS))
              this.downloadSlow = true;
            else this.downloadSlow = false;
          }
          if (this.speedInfo && this.speedInfo.up_average) {
            if (parseFloat("" + this.speedInfo.up_average.kbps) < parseFloat("" + this.UP_KBPS)) this.uploadSlow = true;
            else this.uploadSlow = false;
          }
        }
      })
  }

  checkDownload(speed) {
    this.DOWN_KBPS = (this.userService.getOrganization() && this.userService.getOrganization().params
      && this.userService.getOrganization().params.call && this.userService.getOrganization().params.call.down_kbps) ? this.userService.getOrganization().params.call.down_kbps : this.DOWN_KBPS;
    if (speed < this.DOWN_KBPS) return true;
    return false;
  }

  checkUpload(speed) {
    this.UP_KBPS = (this.userService.getOrganization() && this.userService.getOrganization().params
      && this.userService.getOrganization().params.call && this.userService.getOrganization().params.call.up_kbps) ? this.userService.getOrganization().params.call.up_kbps : this.UP_KBPS;
    if (speed < this.UP_KBPS) return true;
    return false;
  }
}

