// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.reactionsContentLists {
  overflow: auto;
  margin-bottom: auto;
}

.reactions-segment-top {
  padding-top: 15px;
  width: 100%;
}
.reactions-segment-top ion-segment-button {
  min-width: 85px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/reactions-info-modal/reactions-info-modal.component.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;EACA,mBAAA;AACJ;;AAEE;EACE,iBAAA;EACA,WAAA;AACJ;AACI;EACE,0BAAA;AACN","sourcesContent":[".reactionsContentLists {\n    overflow: auto;\n    margin-bottom: auto;\n  }\n  \n  .reactions-segment-top {\n    padding-top: 15px;\n    width: 100%;\n  \n    ion-segment-button {\n      min-width: 85px!important;\n    }\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
