import { KeyValue } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ChatService } from 'src/services/chat.service';

@Component({
  selector: 'reactions-info-modal',
  templateUrl: './reactions-info-modal.component.html',
  styleUrls: ['./reactions-info-modal.component.scss'],
})
export class ReactionsInfoModal implements OnInit, OnDestroy {
  @Input() message;
  @Input() currentUserId;
  @Input() chat;
  @Input() origin;
  selectedPageReactions = 'all';

  constructor(
    public modalController: ModalController,
    public chatService: ChatService
  ) { }

  ngOnInit() {

  }


  // Ordenar dins la pipe keyvalue
  keyDescOrder = (a: KeyValue<any, any>, b: KeyValue<any, any>): number => {
    return a.value.date > b.value.date ? -1 : (b.value.date > a.value.date ? 1 : 0);
  }

  deleteReaction() {
    if (this.message?.reactions?.total == 1) {
      this.dismissReactionsInfo()
    }
    try {
      return this.chatService.deleteReaction(this.message, this.chat, this.origin, this.currentUserId);
    } catch (e) { }
  }

  dismissReactionsInfo() {
    this.modalController.dismiss(null, 'cancel', 'reactions-info-modal')
    this.selectedPageReactions = 'all';
  }

  ngOnDestroy(): void {
  }
}
