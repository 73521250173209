// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.panel-container {
  width: 100%;
  height: 100%;
  display: grid;
  place-content: center;
}

.search-panel {
  min-width: 300px;
  max-width: 450px;
}
.search-panel ion-row {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.search-panel ion-button {
  margin: 0 auto;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/search-patient-modal/search-patient-modal.component.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,YAAA;EACA,aAAA;EACA,qBAAA;AACJ;;AAEA;EACI,gBAAA;EACA,gBAAA;AACJ;AAAI;EACI,gBAAA;EACA,mBAAA;AAER;AAAI;EACI,cAAA;AAER","sourcesContent":[".panel-container{\n    width: 100%;\n    height: 100%;\n    display: grid;\n    place-content: center;\n}\n\n.search-panel{\n    min-width: 300px;\n    max-width: 450px;\n    ion-row{\n        margin-top: 1rem;\n        margin-bottom: 1rem;\n    }\n    ion-button{\n        margin: 0 auto;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
