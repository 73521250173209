// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-loader {
  width: 100% !important;
  height: 100% !important;
  display: block;
}

img {
  display: flex;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0) !important;
  object-fit: cover;
  border-radius: 50%;
  height: 100%;
  width: 100%;
}

.container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.container img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  object-fit: cover;
  border-radius: 50%;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/image-loader/image-loader.component.scss"],"names":[],"mappings":"AAAA;EACI,sBAAA;EACA,uBAAA;EACA,cAAA;AACJ;;AACA;EACI,aAAA;EACA,uBAAA;EACA,6CAAA;EACA,iBAAA;EACA,kBAAA;EACA,YAAA;EACA,WAAA;AAEJ;;AAAA;EACI,kBAAA;EACA,WAAA;EACA,YAAA;EACA,gBAAA;AAGJ;AAFI;EACI,kBAAA;EACA,SAAA;EACA,QAAA;EACA,gCAAA;EACA,iBAAA;EACA,kBAAA;AAIR","sourcesContent":[".image-loader{\n    width: 100% !important;\n    height: 100% !important;\n    display: block;\n}\nimg {\n    display: flex;\n    justify-content: center;\n    background-color: rgba(0, 0, 0, 0) !important;\n    object-fit: cover;\n    border-radius: 50%;\n    height: 100%;\n    width: 100%;\n}\n.container {\n    position: relative;\n    width: 100%;\n    height: 100%;\n    overflow: hidden;\n    img {\n        position: absolute;\n        left: 50%;\n        top: 50%;\n        transform: translate(-50%, -50%);\n        object-fit: cover;\n        border-radius: 50%;\n    }\n}\n  \n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
