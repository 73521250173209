import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { collection as collectionSnapshots } from 'rxfire/firestore';
import { collection, query } from 'firebase/firestore';
import { FirebaseService } from './firebase.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalAppService {
  public globalParams$ = new ReplaySubject(1);

  constructor(public fs: FirebaseService) {
    let globalQuery = query(collection(this.fs.firestore, 'global'))
    collectionSnapshots(globalQuery).pipe(
      distinctUntilChanged(),
      map((snaps) => snaps.map((snap: any) => ({ id: snap.id, ...snap.data() }))),
    ).subscribe((params) => {
      let _params: any = {};
      params.forEach(param => {
        _params[param.id] = param;
        delete _params[param.id].id;
      });
      if (environment['orgKey'] && _params.versions[environment['orgKey']]) {
        _params.versions.app = _params.versions[environment['orgKey']].app;
      }
      this.globalParams$.next(_params);
    });
  }


}
