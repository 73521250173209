// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-content {
  --ion-background-color: var(--ion-color-extraLightGray);
}

ion-footer {
  background-color: var(--ion-color-extraLightGray);
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  height: 100%;
}
.container .logo {
  width: 300px;
  height: 300px;
  background: url("/assets/logo_text.png") no-repeat 50% 50%;
  background-size: contain;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container .logo img {
  width: 100%;
  background-size: contain;
  max-height: 500px;
}
.container .customLogo {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container .customLogo img {
  width: auto;
  max-height: 400px;
  background-size: contain;
}
.container .footer-logo {
  background-color: red;
  width: 80px;
  height: 80px;
  background: url("/assets/logo_text.png") no-repeat 50% 50%;
  background-size: contain;
  display: flex;
  justify-content: center;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/app/module-navigation/detail-placeholder/detail-placeholder.component.scss"],"names":[],"mappings":"AAAA;EACE,uDAAA;AACF;;AACA;EACE,iDAAA;AAEF;;AAAA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;EACA,YAAA;AAGF;AADE;EACE,YAAA;EACA,aAAA;EACA,0DAAA;EACA,wBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AAGJ;AAFI;EACE,WAAA;EACA,wBAAA;EACA,iBAAA;AAIN;AAAE;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AAEJ;AADI;EACE,WAAA;EACA,iBAAA;EACA,wBAAA;AAGN;AACE;EACE,qBAAA;EACA,WAAA;EACA,YAAA;EACA,0DAAA;EACA,wBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AACJ","sourcesContent":["ion-content {\n  --ion-background-color: var(--ion-color-extraLightGray);\n}\nion-footer {\n  background-color: var(--ion-color-extraLightGray);\n}\n.container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  padding: 20px;\n  height: 100%;\n\n  .logo {\n    width: 300px;\n    height: 300px;\n    background: url(\"/assets/logo_text.png\") no-repeat 50% 50%;\n    background-size: contain;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    img {\n      width: 100%;\n      background-size: contain;\n      max-height: 500px;\n    }\n  }\n\n  .customLogo {\n    width: 100%;\n    height: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    img {\n      width: auto;\n      max-height: 400px;\n      background-size: contain;\n    }\n  }\n\n  .footer-logo {\n    background-color: red;\n    width: 80px;\n    height: 80px;\n    background: url(\"/assets/logo_text.png\") no-repeat 50% 50%;\n    background-size: contain;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
