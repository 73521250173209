import { Component, Input, OnInit } from '@angular/core';
import { GuttmannService } from 'src/services/guttmann.service';
import { NavigationService } from 'src/services/navigation.service';

@Component({
  selector: 'app-appointments-table',
  templateUrl: './appointments-table.component.html',
  styleUrls: ['./appointments-table.component.scss'],
})
export class AppointmentsTableComponent implements OnInit {
  @Input() subroute;
  @Input() appointments;
  @Input() user;

  constructor(
    public ns: NavigationService,
    public gs: GuttmannService
  ) { }

  ngOnInit() {
    this.subroute = true;
  }

  openVideoRoom(appointment){
    this.gs.selectedVideoCall = appointment;
    if(this.subroute) this.ns.navController.navigateForward(`/videovisites/video-room/${true}`);
    else this.ns.navController.navigateForward('/videovisites/video-room');
  }
}
