import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

@Pipe({
  name: 'dateText'
})
export class DateTextPipe implements PipeTransform {
  constructor(
    public tr: TranslateService
  ){}

  transform(value, now) {
    let time = moment(parseInt("" + value));
    now = moment(parseInt("" + now));
    let tomorrow = moment(now);
    let yesterday = moment(now);

    if (now.isSame(time, 'day')) {
      return this.tr.instant('dates.today') + ', ' + time.format('DD/MM/YYYY');
    }

    if (yesterday.subtract(1, 'day').isSame(time, 'day')) {
      return this.tr.instant('dates.yesterday') + ', ' + time.format('DD/MM/YYYY');
    }

    if (tomorrow.add(1, 'day').isSame(time, 'day')) {
      return this.tr.instant('dates.tomorrow') + ', ' + time.format('DD/MM/YYYY');
    }

    return time.format('DD/MM/YYYY');
  }

}
