import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { IonicModule, Platform, PopoverController } from '@ionic/angular';

@Component({
  selector: 'popover',
  template: `<ion-content class="messageOptionsDisabledClick">
              <ion-row detail="false" lines="full" class="reaction-icons" *ngIf="isMobile">
                <ion-col button (click)="click('reaction','👍')" detail="false" lines="none">👍</ion-col>
                <ion-col button (click)="click('reaction','👎')" detail="false" lines="none">👎</ion-col>
                <ion-col button (click)="click('reaction','😀')" detail="false" lines="none">😀</ion-col>
                <ion-col button (click)="click('reaction','😂')" detail="false" lines="none">😂</ion-col>
                <ion-col button (click)="click('reaction','😊')" detail="false" lines="none">😊</ion-col>
                <ion-col button detail="false" lines="none" id="emoji-mart">
                  <ion-icon name="add-outline"></ion-icon>
                </ion-col>
                <ion-popover trigger="emoji-mart" class="reaction-icons-popover-global-scss">
                  <ng-template>
                    <emoji-mart [darkMode]="false" [showPreview]="false" (emojiSelect)="addReactionEmojiMart($event.emoji.native)" [exclude]="['flags']" [virtualize]="true"></emoji-mart>
                  </ng-template>
                </ion-popover>
              </ion-row>
              <div class="content">
                <ng-container *ngFor="let item of items; last as isLast">
                    <ion-item button (click)="click(item.role)" detail="false" [lines]="isLast ? 'none' : 'full'" *ngIf="item?.if ?? true">
                        <ion-icon *ngIf="item?.icon" [name]="item.icon"></ion-icon>
                        {{item.text}}
                    </ion-item>
                </ng-container>
              </div>
            </ion-content>`,
    standalone: true,
    imports: [CommonModule, IonicModule, PickerModule],
    styles: [`
      @keyframes disableClickWhilePresentingModal {
        0% {pointer-events: none;}
        99% {pointer-events: none;}
        100% {pointer-events: auto;}
      }

      .messageOptionsDisabledClick {
        animation-name: disableClickWhilePresentingModal;
        animation-duration: 0.5s;
        --background: transparent;

        ion-item {
            animation-name: disableClickWhilePresentingModal;
            animation-duration: 0.5s;
        }
      }
      
      .content {
        background-color: #fff;
        border-radius: 20px;

        ion-item {
          --ion-item-background: transparent;
        }

        ion-icon {
          font-size: 20px;
          margin-right: 10px;
        }
      }

      .reaction-icons {
        margin: auto;
        justify-content: space-between;
        text-align: center;
        font-size: 20px;
        margin-bottom: .5em;
        background-color: #fff;
        border-radius: 20px;
        height: 48px;
        padding: 0 6px;

        ion-col {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
        }
      }
    `]
})
export class PopoverComponent {

  @Input() items: Items[] = [];
  private isMobile = false;

  constructor(
    private popoverCtrl: PopoverController,
    platform: Platform
  ) { 
    this.isMobile = platform.is('capacitor');
  }

  click(role: string, data: any = null) {
    this.popoverCtrl.dismiss(data, role);
  }


  addReactionEmojiMart(emoji){
    this.popoverCtrl.dismiss(emoji, 'reaction');
  }

}

type PositionSide = 'top' | 'right' | 'bottom' | 'left' | 'start' | 'end';
type PositionAlign = 'start' | 'center' | 'end';
type TriggerAction = 'click' | 'press';
export interface Items {
  text: string,
  icon?: string,
  if?: boolean,
  role: string
};