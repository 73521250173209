import { Component, OnInit } from '@angular/core';
import {UserService} from '../../../services/user.service';

@Component({
  selector: 'app-detail-placeholder',
  templateUrl: './detail-placeholder.component.html',
  styleUrls: ['./detail-placeholder.component.scss'],
})
export class DetailPlaceholderComponent implements OnInit {

  constructor(public userService: UserService) { }

  ngOnInit() {}

}
