import { Component, OnInit } from '@angular/core';
import {NavController, Platform} from '@ionic/angular';

@Component({
  selector: 'app-base-split-detail',
  template: ''
})
export class BaseSplitPaneListComponent implements OnInit {
  public MAX_MOBILE_WIDTH = 900;

  constructor(public platform: Platform,
              public navController: NavController) { }

  ngOnInit() {}

  loadDetail(listRoute, detailRouteMobile, detailRouteDesktop){
    if(this.platform.width() < this.MAX_MOBILE_WIDTH) this.navController.navigateForward(detailRouteMobile);
    else {
      this.navController.navigateRoot(listRoute).then(() => {
        this.navController.navigateForward(detailRouteDesktop);
      });
    }
  }

}
