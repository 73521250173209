import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Subject, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WindowService {

  public minerva = false;
  public minervaUserEmail;
  public authToken;
  public module;
  public userId;
  public orgKey;
  public queryParam;
  public queryValue;

  public parentMessages$ = new Subject<any>();
  public authWithCustomToken$ = new ReplaySubject<any>(1);
  public forceSignOut$ = new Subject<any>();
  public windowState$ = new Subject<boolean>();

  constructor(public platform: Platform) {
    if (this.platform.getQueryParam('module')) this.module = decodeURI(this.platform.getQueryParam('module'));
    if (this.platform.getQueryParam('userId')) this.userId = decodeURI(this.platform.getQueryParam('userId'));
    if (this.platform.getQueryParam('orgKey')) this.orgKey = decodeURI(this.platform.getQueryParam('orgKey'));
    if (this.platform.getQueryParam('param')) this.queryParam = decodeURI(this.platform.getQueryParam('param'));
    if (this.platform.getQueryParam('value')) this.queryValue = decodeURI(this.platform.getQueryParam('value'));

    if (this.platform.getQueryParam('minerva')) {
      this.minerva = true;
      this.minervaUserEmail = decodeURI(this.platform.getQueryParam('userEmail'));
      this.listenForWindowMessages();
    } else {
      this.minerva = false;
    }
  }

  sendMessageToParentWindow(data) {
    if (window && window.parent) {
      window.parent.postMessage(JSON.stringify(data), '*');
    }
    return;
  }

  listenForWindowMessages() {
    // We listen to messages passed by other windows (minerva) to ours
    if (window) {
      if (window.addEventListener) {
        window.addEventListener('message', this.receiveWindowMessage.bind(this), false);
      } else {
        (<any>window).attachEvent('onmessage', this.receiveWindowMessage.bind(this));
      }
    }
  }

  receiveWindowMessage(event: MessageEvent) {
    try {
      const data = JSON.parse(event.data);
      this.parentMessages$.next(data);
      switch (data.type) {
        case 'frameVisible':
          this.windowState$.next(data.visible);
          break;
        case 'token':
          this.authWithCustomToken$.next(data.token);
          break;
        case 'signOut':
          this.forceSignOut$.next(true);
      }
    } catch (err) {
      // Something went wrong parsing the message, don't do anything
    }
  }

  public updateWindowTitle(count) {
    document.title = ((count) > 0 ? `(${count}) ` : '') + 'Medxat';
    return;
  }
}
