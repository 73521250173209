import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { Injectable, Injector } from '@angular/core';
import { AuthService } from './auth.service';
import { catchError, filter, switchMap, take, takeUntil, timeout } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { CommunicationService } from './communication.service';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {


  public refreshTokenInProgress = false;
  // Refresh Token Subject tracks the current token, or is null if no token is currently
  // available (e.g. refresh pending).
  public refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  public auth: AuthService;

  constructor(public inj: Injector, public cs: CommunicationService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    this.auth = this.inj.get(AuthService);

    const include = (request.url.includes(environment.API_ENDPOINT) || request.url.includes(environment.IG_ENDPOINT))
      && !request.url.includes(environment.API_ENDPOINT + '/login');

    const cloned = request.clone({
      headers: request.headers.set('Medxat-Version', `${environment.VERSION}`)
    });
    request = cloned;

    return this.addAuthenticationToken(request, include)
      .pipe(
        switchMap(req => {
        return next.handle(req).pipe(
          takeUntil(this.cs.cancelPendingRequests$),
          timeout(30000),
          catchError((error: HttpErrorResponse) => {
            if (
              req.url.includes('login')
            ) {
              // We do another check to see if refresh token failed
              // In this case we want to logout user and to redirect it to login page

              if (req.url.includes('login')) {
                this.auth.clearTokens();
              }
              throw error;
            }

            if (!(error instanceof HttpErrorResponse) || error.status !== 401) { throw error; }

            if (this.refreshTokenInProgress) {
              // If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
              // – which means the new token is ready and we can retry the request again
              return this.refreshTokenSubject.pipe(
                filter(result => result !== null),
                take(1),
                switchMap(() => this.addAuthenticationToken(req, true).pipe(
                  switchMap(newReq => next.handle(newReq))
                )
                )
              );
            }
            else {
              this.refreshTokenInProgress = true;

              // Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
              this.refreshTokenSubject.next(null);
              return this.auth.refreshAccessToken().pipe(
                switchMap((token: string) => {
                  // When the call to refreshToken completes we reset the refreshTokenInProgress to false
                  // for the next time the token needs to be refreshed
                  this.refreshTokenInProgress = false;
                  this.refreshTokenSubject.next(token);
                  return this.addAuthenticationToken(req, true).pipe(switchMap(newReq => next.handle(newReq)));
                }),
                catchError((err: any) => {
                  this.refreshTokenInProgress = false;
                  this.auth.clearTokens();
                  throw err;
                })
              );
            }
          }));
      }));
  }

  addAuthenticationToken(request, include) {
    if (!include) { return of(request); }
    // Get access token from Local Storage
    const accessToken = this.auth.getToken();
    if (!accessToken) { return of(request); }
    const cloned = request.clone({
      headers: request.headers.set('Authorization', `Bearer ${accessToken}`)
    });
    // We clone the request, because the original request is immutable
    return of(cloned);
  }
}
