import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';

import { Jodit } from 'jodit';


@Component({
  selector: 'jodit-editor',
  templateUrl: './jodit-editor.component.html',
  styleUrls: ['./jodit-editor.component.scss'],
})
export class JoditEditorComponent implements OnInit, AfterViewInit {



  @Input() html;
  @Output() htmlChange = new EventEmitter<any>();

  private editor;

  constructor() {
  }


  ngOnInit() {
    //this.initEditor(this.html);
  }

  ngAfterViewInit(){
    this.initEditor(this.html);
  }

  getText(){
    return this.editor.value;
  }

  setText(text: string){
    this.editor.value = text;
  }

  initEditor(startText: string){
    this.editor = Jodit.make('#editor', {
      uploader: {
        insertImageAsBase64URI: true
      },
      spellcheck: false,
      toolbarAdaptive: false,
      buttons: "source,bold,italic,underline,strikethrough,eraser,ul,ol,indent,outdent,left,font,fontsize,paragraph,brush,superscript,subscript,image,video"
    });
    this.editor.value = startText;
    this.editor.events.on('change', (change) => {
      this.html = change;
      this.htmlChange.emit(this.html);
    });
  }


}
