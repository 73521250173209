import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, from, of } from 'rxjs';
import { UserService } from './user.service';
import { distinctUntilChanged, filter, map, mapTo, pluck, reduce, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ModulesService } from './modules.service';
import { WindowService } from './window.service';
import { TelemedicineService } from './telemedicine.service';
import { DeviceService } from './device.service';
import { SecureComService } from './secure-com.service';


@Injectable({
    providedIn: 'root'
})
export class BadgeService {

    public globalBatch$: BehaviorSubject<number> = new BehaviorSubject(0);
    public orgBatches$: BehaviorSubject<any> = new BehaviorSubject(null);
    public internalChatsBatch$: BehaviorSubject<any> = new BehaviorSubject(null);
    public patientsChatsBatch$: BehaviorSubject<any> = new BehaviorSubject(null);
    public internalGroupsBatch$: BehaviorSubject<any> = new BehaviorSubject(null);
    public patientsGroupBatch$: BehaviorSubject<any> = new BehaviorSubject(null);
    public internalBatch$: BehaviorSubject<any> = new BehaviorSubject(null);
    public patientsBatch$: BehaviorSubject<any> = new BehaviorSubject(null);
    public chatsBatch$: BehaviorSubject<any> = new BehaviorSubject(null);
    public groupsBatch$: BehaviorSubject<any> = new BehaviorSubject(null);
    public helpBatch$: BehaviorSubject<any> = new BehaviorSubject(null);
    public visitsBatch$: BehaviorSubject<any> = new BehaviorSubject(null);
    public secureComBadge$: BehaviorSubject<any> = new BehaviorSubject(null);
    public uploadComBadge$: BehaviorSubject<any> = new BehaviorSubject(null);
    public publicFormBadge$: BehaviorSubject<any> = new BehaviorSubject(null);


    public modulesBatch$: BehaviorSubject<any> = new BehaviorSubject(null);
    public appsBatch$: BehaviorSubject<any> = new BehaviorSubject(null);
    public totalBatch$: BehaviorSubject<any> = new BehaviorSubject(null);

    constructor(
        public userService: UserService,
        public modulesService: ModulesService,
        public telemedicineService: TelemedicineService,
        public windowService: WindowService,
        public deviceService: DeviceService,
        public secureComService: SecureComService
    ) {
        this.init();
    }

    public init() {
        this.globalBatch$.next(0);
        this.orgBatches$.next(null);
        this.internalChatsBatch$.next(null);
        this.patientsChatsBatch$.next(null);
        this.internalGroupsBatch$.next(null);
        this.patientsGroupBatch$.next(null);
        this.modulesBatch$.next(null);
        this.totalBatch$.next(null);
        this.helpBatch$.next(null);
        this.visitsBatch$.next(null);
        this.secureComBadge$.next(null);
        this.uploadComBadge$.next(null);

        this.publicFormBadge$.next(null);

        // Collect chat badges
        this.userService.userChatInfo$.pipe(
            filter(x => x),
            pluck('info'),
            switchMap(
                (infoChats) => from(infoChats).pipe(
                    map((info: any) => {
                        const orgKey = info.orgKey;
                        const unreadCount = info.unreadCount;
                        if (!Array.isArray(unreadCount)) { return { unreadCount: 0, orgKey }; }
                        return { ...info, unreadCount: (unreadCount.length > 0 ? 1 : 0), orgKey };
                    }),
                    reduce((acc, val: any) =>
                    ({
                        t: { ...acc.t, [val.orgKey]: ((val.telemedicine ? val.unreadCount : 0) || 0) + (acc.t[val.orgKey] || 0) },
                        p: { ...acc.p, [val.orgKey]: ((val.patient ? val.unreadCount : 0) || 0) + (acc.p[val.orgKey] || 0) },
                        i: { ...acc.i, [val.orgKey]: ((val.patient ? 0 : val.unreadCount) || 0) + (acc.i[val.orgKey] || 0) },
                    })
                        , { i: {}, p: {}, t: {} }))
            ),
            distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
        ).subscribe((x) => {
            const isPatient = ((this.userService.getUser().profile) || {}).patient || false;
            if (isPatient) {
                const swap = x.p;
                x.p = x.i;
                x.i = swap;
            }
            this.chatsBatch$.next(x);
            this.internalChatsBatch$.next(x.i);
            this.patientsChatsBatch$.next(x.p);
        });


        // Collect group badges
        this.userService.userGroupInfo$.pipe(
            filter(x => x),
            pluck('info'),
            switchMap(
                (infoChats) => from(infoChats).pipe(
                    map((info: any) => {
                        const orgKey = info.orgKey;
                        const unreadCount = info.unreadCount;
                        if (!Array.isArray(unreadCount)) { return { unreadCount: 0, orgKey }; }
                        return { ...info, unreadCount: (unreadCount.length > 0 ? 1 : 0), orgKey };
                    }),
                    reduce((acc, val: any) =>
                    ({
                        t: { ...acc.t, [val.orgKey]: ((val.patient ? val.unreadCount : 0) || 0) + (acc.t[val.orgKey] || 0) },
                        p: { ...acc.p, [val.orgKey]: ((val.patient ? val.unreadCount : 0) || 0) + (acc.p[val.orgKey] || 0) },
                        i: { ...acc.i, [val.orgKey]: ((val.patient ? 0 : val.unreadCount) || 0) + (acc.i[val.orgKey] || 0) },
                    })
                        , { i: {}, p: {}, t: {} }),
                )
            ),
            distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
        ).subscribe((x) => {
            const isPatient = ((this.userService.getUser().profile) || {}).patient || false;
            if (isPatient) {
                const swap = x.p;
                x.p = x.i;
                x.i = swap;
            }
            this.groupsBatch$.next(x);
            this.internalGroupsBatch$.next(x.i);
            this.patientsGroupBatch$.next(x.p);
        });


        this.modulesService.modules$.pipe(
            switchMap((modules) => this.userService.userAlerts$.pipe(
                filter(x => x),
                switchMap(
                    (alerts: any[]) => from(alerts || []).pipe(
                        filter(x => x),
                        switchMap((alert: any) => from(alert.alerts || []).pipe(
                            reduce((acc: any, val: any) => {
                                const org = val.orgKey || 'all';
                                const orgValue = acc[org] || 0;
                                return { ...acc, [org]: orgValue + 1 };
                            }, {}),
                            map(x => ({ id: alert.id, count: x }))
                        )),
                        reduce((acc: any, val: any) => {
                            const count = {};
                            for (const key of Object.keys(val.count || {})) {
                                const orgValue = (acc[key] || {});
                                const idValue = (orgValue[val.id] || 0);
                                const s = val.count[key] || 0;
                                count[key] = { ...orgValue, [val.id]: idValue + s };
                            }
                            return { ...acc, ...count };
                        }, {})
                    )
                ),
                map((badges) => ({ ...badges, modules }))
            ))
        );
        // Collect module batches
        this.userService.userAlerts$.pipe(
            filter(x => x),
            switchMap(
                (alerts: any[]) => from(alerts || []).pipe(
                    filter(x => x),
                    switchMap((alert: any) => from(alert.alerts || []).pipe(
                        reduce((acc: any, val: any) => {
                            const org = val.orgKey || 'all';
                            const orgValue = acc[org] || 0;
                            return { ...acc, [org]: orgValue + 1 };
                        }, {}),
                        map(x => ({ id: alert.id, count: x }))
                    )),
                    reduce((acc: any, val: any) => {
                        const count = {};
                        for (const key of Object.keys(val.count || {})) {
                            const orgValue = (acc[key] || {});
                            const idValue = (orgValue[val.id] || 0);
                            const s = val.count[key] || 0;
                            count[key] = { ...orgValue, [val.id]: idValue + s >= 10 ? "9+" : idValue + s};
                        }
                        return { ...acc, ...count };
                    }, {})
                )
            ),
            distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
        ).subscribe((x) => {
            this.modulesBatch$.next(x);
        });


        // collect visits badges
        this.userService.user$.pipe(
            filter(x => x),
            pluck('organizations'),
            filter(x => x),
            distinctUntilChanged((a, b) => {
                const aOrgs = Object.keys(a).sort().filter((x) => a[x]);
                const bOrgs = Object.keys(b).sort().filter((x) => b[x]);
                return JSON.stringify(aOrgs) === JSON.stringify(bOrgs);
            }),
            switchMap(
                orgs =>
                    combineLatest(
                        Object.keys(orgs).map((org) =>
                            this.telemedicineService.getAttentionVisits(org, this.userService.getUser().id)
                        )
                    ).pipe(
                        map(orgVisits => orgVisits.reduce((acc, visits, index) => {
                            const orgKey = Object.keys(orgs)[index];
                            return { ...acc, [orgKey]: visits.length };
                        }, {}))
                    )
            )
        ).subscribe((visits) => {
            this.visitsBatch$.next(visits);
        });

        // public form badges (assigned to user)
        this.userService.user$.pipe(
            filter(x => x),
            pluck('organizations'),
            filter(x => x),
            distinctUntilChanged((a, b) => {
                const aOrgs = Object.keys(a).sort().filter((x) => a[x]);
                const bOrgs = Object.keys(b).sort().filter((x) => b[x]);
                return JSON.stringify(aOrgs) === JSON.stringify(bOrgs);
            }),
            switchMap(
                orgs =>
                    combineLatest(
                        Object.keys(orgs).map((org) =>
                        this.secureComService.getPublicForms$('assigned', true, null, null, org)
                        )
                    ).pipe(
                        map(pfs => pfs.reduce((acc, pf, index) => {
                            const orgKey = Object.keys(orgs)[index];
                            return { ...acc, [orgKey]: pf.length };
                        }, {}))
                    )
            )
        ).subscribe((pf) => {
            this.publicFormBadge$.next(pf);
        });

        // Calculate help badge
        this.deviceService.newVersionAvailable$.subscribe((newVersion) => {
            if (newVersion) {
                this.helpBatch$.next(1);
            } else {
                this.helpBatch$.next(null);
            }
        });

        // Calculate derived badges
        combineLatest([this.chatsBatch$, this.groupsBatch$, this.modulesBatch$, this.visitsBatch$, this.publicFormBadge$, this.modulesService.modules$])
            .pipe(
            )
            .subscribe(([chatBatches, groupBatches, modulesBatch, visitsBatch, publicFormBatch, modules]) => {
                chatBatches = chatBatches || { i: {}, p: {} };
                groupBatches = groupBatches || { i: {}, p: {} };
                modulesBatch = modulesBatch || {};
                visitsBatch = visitsBatch || {};
                publicFormBatch = publicFormBatch || {};

                modules = modules || {};
                const merged = { ...chatBatches.i, ...groupBatches.i, ...chatBatches.p, ...groupBatches.p, ...modulesBatch, ...publicFormBatch, ...visitsBatch };
                const internalBatch = {};
                const patientsBatch = {};
                const organizationsBatch = {};
                let totalBatch = 0;
                const appsBatch = {};
                for (const k of Object.keys(merged)) {
                    if (k === 'undefined') { continue; }
                    internalBatch[k] = (chatBatches.i[k] || 0) + (groupBatches.i[k] || 0);
                    patientsBatch[k] = (chatBatches.p[k] || 0) + (groupBatches.p[k] || 0);
                    let appBatch = {};
                    if (modules) {
                        const moduleBatch = modulesBatch[k] || {};
                        for (const m of Object.keys(moduleBatch)) {
                            const app = (modules[m] || {}).app;
                            const batch = appBatch[app] || 0;
                            appBatch[app] = batch + 1;
                        }
                        appsBatch[k] = appBatch;
                    }
                    // TODO: app batch count in organization ?
                    let appBatchleng = Object.keys(appBatch);
                    organizationsBatch[k] = (internalBatch[k] ? 1 : 0)
                        + (patientsBatch[k] ? 1 : 0)
                        + (visitsBatch[k] > 0 ? 1 : 0)
                        + (publicFormBatch[k] > 0 ? 1 : 0)
                        + (appBatchleng.length > 0 ? 1 : 0);
                    totalBatch += organizationsBatch[k];
                    // if(k === '-KYDDnBmxXkbF3FXSy_'){
                    // console.log("k", k);
                    // console.log("internalBatch[k] ", internalBatch[k] );
                    // console.log("patientsBatch[k] ", patientsBatch[k] );
                    // console.log("visitsBatch", visitsBatch);
                    // console.log("publicFormBatch", publicFormBatch);
                    // console.log("appBatchleng", appBatchleng);
                    // }

                }
        
                this.internalBatch$.next(internalBatch);
                this.patientsBatch$.next(patientsBatch);
                this.orgBatches$.next(organizationsBatch);
                this.totalBatch$.next(totalBatch);
                this.windowService.updateWindowTitle(totalBatch);
                if (modules) { this.appsBatch$.next(appsBatch); }
            });
    }
}
