import { Component, OnInit, Input } from '@angular/core';
import { UserService } from 'src/services/user.service';
import { ReplaySubject, BehaviorSubject } from 'rxjs';
import { GlobalAppService } from 'src/services/global-app.service';
import { ModalController } from '@ionic/angular';
import { AuthService } from 'src/services/auth.service';
import { LoadingService } from 'src/services/loading.service';

import { Browser } from '@capacitor/browser';
@Component({
  selector: 'app-privacity',
  templateUrl: './privacity.component.html',
  styleUrls: ['./privacity.component.scss'],
})
export class PrivacityComponent implements OnInit {

  public user$: ReplaySubject<any>;
  public organizations$: BehaviorSubject<any>;
  public globalParams$: ReplaySubject<any>;
  public showWarning: boolean = false;
  public privacyMedxat: boolean = false;
  public privacy: { [k: string]: boolean } = {};

  @Input() viewMode: boolean;

  constructor(public userService: UserService,
    public globalAppService: GlobalAppService,
    public modalCtrl: ModalController,
    public loadingService: LoadingService,
    public authService: AuthService) { }

  ngOnInit() {
    this.user$ = this.userService.user$;
    this.organizations$ = this.userService.organizations$;
    this.globalParams$ = this.globalAppService.globalParams$;
  }


  async dismiss() {
    await this.modalCtrl.dismiss(null, null, "privacy")
  }

  async openUrl(user, params) {
    if (!user || !params) return;
    let url = "";
    if (user && user.profile && user.profile.patient) {
      url = params.privacy_patient ? params.privacy_patient : params.privacy;
    } if (user.profile && user.profile.external) {
      url = params.privacy_external ? params.privacy_external : params.privacy;
    }
    else {
      url = params.privacy;
    }

    if (!url) {
      return;
    }
    await Browser.open({ url });
  }

  async openUrlOrg(user, org) {
    if (!user || !org) return;
    let url = "";

    if (user && user.profile && user.profile.patient) {
      url = org.privacy_patient ? org.privacy_patient : org.privacy;
    } if (user.profile && user.profile.external && user.profile.external[org.id]) {
      url = org.privacy_external ? org.privacy_external : org.privacy;
    }
    else {
      url = org.privacy;
    }

    if (!url) {
      return;
    }
    await Browser.open({ url });
  }

  async accept(user, appParams, userOrgs) {
    let global;
    if(!user || !appParams || !appParams.params) return;

    if (user && user.profile && user.profile.patient) {
      global = (appParams.params.privacy_patient ? appParams.params.privacy_patient : appParams.params.privacy)
    } else if(user && user.profile && user.profile.external){
      global = (appParams.params.privacy_external ? appParams.params.privacy_external : appParams.params.privacy)
    }
    else {
      global = appParams.params.privacy
    }

    if (!userOrgs) {
      return;
    }
    let accepted: boolean = true;

    if (!this.privacyMedxat) {
      this.showWarning = true;
      accepted = false;
      return;
    }
    let orgNames = Object.keys(this.privacy);

    if (orgNames.length === 0 || orgNames.length != userOrgs.length) {
      this.showWarning = true;
      accepted = false;
    }

    userOrgs.forEach(org => {
      if (this.privacy[org.name] === false) {
        this.showWarning = true;
        accepted = false;
      }
    });

    if (accepted) {
      this.showWarning = false;
      try {
        await this.userService.acceptPrivacy(global, userOrgs);
        this.authService.privacyAccepted = true;
        await this.dismiss();
      } catch (e) {
        this.showWarning = false;
      }
    }
  }

  async cancel() {
    await this.loadingService.presentLoading();
    await this.dismiss();
    await this.authService.logout();
    await this.loadingService.dismissLoading();

  }

}
