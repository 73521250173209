import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserService } from 'src/services/user.service';

@Component({
  selector: 'app-user-item',
  templateUrl: './user-item.component.html',
  styleUrls: ['./user-item.component.scss'],
})
export class UserItemComponent implements OnInit {

  @Input() userId;
  @Input() reaction?;
  @Input() timeStamp?;
  @Output() deleteReaction?: EventEmitter<any> = new EventEmitter();
  public user;
  constructor(private userService: UserService) { }

  ngOnInit() {
    this.users();
  }

  async users() {
    this.user = await this.userService.getUserSnapByIdLocalCheck(this.userId);
  }

  deleteReactionFunc() {
    this.deleteReaction?.emit();
  }

}
