import { registerPlugin } from '@capacitor/core';

export interface FileOpenerPlugin {
  open(options: {url: string, type: string, name: string}): Promise<any>;
  view(options: {url: string, type: string, name: string}): Promise<any>;
  download(options: {url: string, type: string, name: string, toast: string}): Promise<any>;
}

const FileOpenerPlugin = registerPlugin<FileOpenerPlugin>('FileOpenerPlugin');

 export default FileOpenerPlugin;