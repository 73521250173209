import { Injectable, ViewChild } from '@angular/core';
import { Platform, NavController, ModalController, AlertController, ActionSheetController, PopoverController, IonRouterOutlet } from '@ionic/angular';
import { AwardsComponent } from 'src/app/shared/awards/awards.component';
import { UserService } from './user.service';
import { Router, Navigation } from '@angular/router';
import { NavProxyService } from './nav-proxy-service.service';
import { Location } from '@angular/common';

import { App } from '@capacitor/app';
import { TranslateService } from '@ngx-translate/core';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  public MAX_MOBILE_WIDTH = 900;
  public inChat;
  public inDetail;
  public chatTab = "chats";
  public chatTab$ = new ReplaySubject<any>(1);;
  public routeIgnorePin = false;
  
  constructor(public platform: Platform,
    public modalCtrl: ModalController,
    public navController: NavController,
    public userService: UserService,
    public router: Router,
    public location: Location,
    public alertCtrl: AlertController,
    public actionSheet: ActionSheetController,
    public popoverCtrl: PopoverController,
    public tr: TranslateService,
    public navProxy: NavProxyService) { }

  init(routerOutlet) {
    // Android back button navigation logic
    if (this.platform.is('android') && this.platform.is('capacitor')) {
      this.platform.backButton.subscribeWithPriority(-1, (processNextHandler) => {
        if (!routerOutlet.canGoBack()) {
          this.closeAppAlert();
        }
        processNextHandler();
      });
      this.platform.backButton.subscribeWithPriority(10, async (processNextHandler) => {
        if (this.router.url.includes('/chat_module/mobile') ||
          this.router.url.includes('/patients/mobile') ||
          this.router.url.includes('/login')) {
          if (!this.inChat) this.closeAppAlert();
        }
        else if (this.router.url.includes('/module-navigation/')) {
          try {
            if (this.navProxy.isOn) this.navProxy.pop(); // pop detail 
            else if (await this.navProxy.masterNav.canGoBack()) await this.navProxy.pop();
            else processNextHandler();
          } catch (e) {
            processNextHandler();
          }
        }
        else if (this.router.url.includes('patients/') || this.router.url.includes('chat_module/')) {
          let patients = this.router.url.includes('patients/');
          let route = (patients ? 'patients/list' : 'chat_module/list')
          if (this.inChat) {
            this.removeDetail(route, patients);
          } else if (!this.inDetail) {
            this.location.back();
          }
          if (this.inDetail) this.inDetail = false;
        } else {
          processNextHandler();
        }
      });
    }
  }

  async closeAppAlert() {
    let translations = this.tr.instant(['general']);
    let alert = await this.alertCtrl.create({
      header: translations['general'].leave,
      message: translations['general'].closeWarning,
      backdropDismiss: true,
      buttons: [
        {
          text: translations['general'].no,
          handler: async () => {

          }
        },
        {
          text: translations['general'].yes,
          handler: async () => {
            App.exitApp();
          }
        }
      ]
    });
    await alert.present();
  }
  openLink(type, value) {
    switch (type) {
      case 'email':
        window.open(`mailto:${value}`, "_blank");
        break;
      case 'phone':
      case 'mobile':
        window.open(`tel:${value}`, "_blank");
        break
    }
  }

  loadDetail(listRoute, detailRouteMobile, detailRouteDesktop) {
    if (this.platform.width() < this.MAX_MOBILE_WIDTH) this.navController.navigateForward(detailRouteMobile);
    else {
      this.navController.navigateRoot(listRoute).then(() => {
        this.navController.navigateForward(detailRouteDesktop);
      });
    }
  }

  loadChatPage(chatRoute) {
    this.chatTab = 'chats';
    this.chatTab$.next(this.chatTab);
    this.navController.navigateForward(chatRoute);
  }

  async loadGroupPage(listRoute, detailRouteMobile, detailRouteDesktop) {
    this.chatTab = 'groups';
    this.chatTab$.next(this.chatTab);
    if (this.platform.width() < this.MAX_MOBILE_WIDTH) {
      await this.navController.navigateRoot('chat_module/mobile');
      this.navController.navigateForward(detailRouteMobile);
    } else {
      await this.navController.navigateRoot(listRoute);
      this.navController.navigateForward(detailRouteDesktop);
    }
  }

  async removeDetail(listRoute = 'chat_module/list', patientMode = false, forceOrg = false) {
    if (this.platform.width() < this.MAX_MOBILE_WIDTH) {
      return this.navController.navigateBack(patientMode ? 'patients/mobile' : 'chat_module/mobile');
    } else {
      if (forceOrg) await this.navController.navigateRoot('chat_module/')
      return this.navController.navigateBack(listRoute);
    }
  }

  async showAwards() {
    let modal = await this.modalCtrl.create({
      component: AwardsComponent,
      swipeToClose: true,
      cssClass: this.platform.is('capacitor') ? '' : 'awards-modal',

    });
    await modal.present();
  }
}
