// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error {
  color: var(--ion-color-danger) !important;
}

#sidenav {
  margin-bottom: 0px;
}
#sidenav ion-item-group[submenu] {
  overflow: hidden;
  display: block;
  height: 0;
  transition: height 0.3s linear;
  padding-left: 60px;
}
#sidenav ion-item-group[submenu].visible {
  height: inherit;
}

.active {
  --ion-text-color: var(--ion-color-primary);
}
.active ion-icon {
  --ion-text-color-rgb: var(--ion-color-primary);
}

.active-parent {
  font-weight: 500;
}

.sub-item {
  padding-left: 20px;
}

ion-label {
  white-space: break-spaces !important;
}

ion-content::part(scroll) {
  overflow-y: hidden !important;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/menu-content/menu-content.component.scss"],"names":[],"mappings":"AAAA;EACE,yCAAA;AACF;;AAEA;EACE,kBAAA;AACF;AAAE;EACE,gBAAA;EACA,cAAA;EACA,SAAA;EACA,8BAAA;EACA,kBAAA;AAEJ;AADI;EACE,eAAA;AAGN;;AAEA;EACE,0CAAA;AACF;AAAE;EACE,8CAAA;AAEJ;;AAEA;EACE,gBAAA;AACF;;AAEA;EACE,kBAAA;AACF;;AAEA;EACE,oCAAA;AACF;;AAEA;EACE,6BAAA;AACF","sourcesContent":[".error {\n  color: var(--ion-color-danger) !important;\n}\n\n#sidenav {\n  margin-bottom: 0px;\n  ion-item-group[submenu] {\n    overflow: hidden;\n    display: block;\n    height: 0;\n    transition: height 0.3s linear;\n    padding-left: 60px; // maybe margin-left would work better to move the border as well\n    &.visible {\n      height: inherit; // this is just sample value, calculate how much space you need for your menu items\n    }\n  }\n}\n\n.active {\n  --ion-text-color: var(--ion-color-primary);\n  ion-icon {\n    --ion-text-color-rgb: var(--ion-color-primary);\n  }\n}\n\n.active-parent {\n  font-weight: 500;\n}\n\n.sub-item {\n  padding-left: 20px;\n}\n\nion-label {\n  white-space: break-spaces !important;\n}\n\nion-content::part(scroll) {\n  overflow-y: hidden !important;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
