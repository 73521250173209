// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-item div.message {
  font-size: 1.6rem;
}

.status-received,
.status-seen {
  position: relative;
  left: -0.15em;
}

.status-seen {
  color: var(--ion-color-primary);
}

.status-received {
  color: gray;
}

@media all and (min-height: 600px) and (min-width: 768px) {
  ion-modal.page-message-info > .modal-wrapper {
    width: 350px;
    left: calc(50% - 175px);
    height: 450px;
    top: calc(50% - 225px);
  }
}
.status-received,
.status-seen {
  position: relative;
  left: -0.15em;
  display: inline;
}

.status-seen {
  color: var(--ion-color-primary);
}

.status-received {
  color: gray;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/message-info/message-info.component.scss"],"names":[],"mappings":"AACI;EACI,iBAAA;AAAR;;AAGA;;EAEI,kBAAA;EACA,aAAA;AAAJ;;AAGA;EACI,+BAAA;AAAJ;;AAEA;EACI,WAAA;AACJ;;AAEA;EAEQ;IACA,YAAA;IACA,uBAAA;IAEA,aAAA;IACA,sBAAA;EADN;AACF;AAKA;;EAEI,kBAAA;EACA,aAAA;EACA,eAAA;AAHJ;;AAMA;EACI,+BAAA;AAHJ;;AAKA;EACI,WAAA;AAFJ","sourcesContent":["ion-item {\n    div.message {\n        font-size: 1.6rem;\n    }\n}\n.status-received,\n.status-seen {\n    position: relative;\n    left: -0.15em;\n}\n\n.status-seen {\n    color: var(--ion-color-primary);\n}\n.status-received{\n    color: gray;\n}\n\n@media all and (min-height: 600px) and (min-width: 768px) {\n    ion-modal.page-message-info {\n        > .modal-wrapper {\n        width: 350px;\n        left: calc(50% - (350px / 2));\n\n        height: 450px;\n        top: calc(50% - (450px / 2));\n        }\n    }\n}\n  \n.status-received,\n.status-seen {\n    position: relative;\n    left: -0.15em;\n    display: inline;\n}\n\n.status-seen {\n    color: var(--ion-color-primary);\n}\n.status-received{\n    color:gray;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
