import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { FileService } from './file.service';
import { UserService } from './user.service';
import { distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';
import { AlertService } from './alert.service';
import { Subject } from 'rxjs';
import AudioPlugin from 'src/capacitor_plugins/audio-plugin';
import * as RecordRTC from 'recordrtc';

@Injectable({
  providedIn: 'root'
})
export class AudioService {

  //AUDIO
  public recording = false;
  public micRecorder;
  public downloadAudioLink;
  public audioBlob;
  public audioSuccListener;
  public audioStopListener;

  public time: { [chatKey: string]: any } = {};
  public MAX_RECORD_TIME = 60;
  public interval;
  public audioEnabled = false;
  public audioTimeout$ = new Subject<any>();
  public audioEnded$ = new Subject<void>();

  public ringingTone: HTMLAudioElement;
  public callingTone: HTMLAudioElement;
  public callInTone: HTMLAudioElement;
  public callOffTone: HTMLAudioElement;

  audioStream;

  constructor(public platform: Platform,
    public userService: UserService,
    public fileService: FileService,
    public alertService: AlertService) {

    this.userService.org$
      .pipe(
        filter(x => x),
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)))
      .subscribe((org: any) => {
        let user = this.userService.getUser();
        if (user && user.profile && !user.profile.patient) {
          if (org && org.params && org.params.chat && org.params.chat.voiceMsg) this.audioEnabled = true;
          else this.audioEnabled = false;
        } else if (user && user.profile && user.profile.patient) {
          if (org && org.params && org.params.chat && org.params.chat.voiceMsgPatients) this.audioEnabled = true;
          else this.audioEnabled = false;
        }
        if (org && org.params && org.params.chat && org.params.chat.voiceDuration) this.MAX_RECORD_TIME = parseInt(org.params.chat.voiceDuration);
      })
  }

  async recordAudio(chat) {
    this.time[chat.id] = 0;

    if (!this.audioEnabled) return;
    this.recording = true;
    try {
      if (this.platform.is('capacitor')) {
        let data = await AudioPlugin.recordAudio();
        if (data && data.permission) {
          await this.cancelAudio(chat);
          return Promise.reject();
        }
      } else if (navigator && navigator.mediaDevices) {
        let mediaConstraints = {
          video: false,
          audio: true
        };
        try {
          this.audioStream = await navigator.mediaDevices.getUserMedia(mediaConstraints)
          this.successCallback(this.audioStream);
        } catch (e) {
          this.errorCallback(e)
        };
      } else {
        await this.alertService.showError('errors.voiceDisabled');
        return;
      }

      if (this.interval) await clearInterval(this.interval);

      this.interval = setInterval(() => {
        if (this.time[chat.id] >= this.MAX_RECORD_TIME) {
          this.stopAudio(chat, true);
        } else {
          this.time[chat.id]++;
        }
      }, 1000);
    } catch (error) {
      this.recording = false;
      if (this.interval) await clearInterval(this.interval);
      this.time[chat.id] = 0;
      await this.alertService.showError('errors.voiceDisabled');
    }
    return Promise.resolve();
  }

  successCallback(stream) {
    var options = {
      mimeType: "audio/mp3",
      numberOfAudioChannels: 2,
      sampleRate: 50000,
    };
    //Start Actuall Recording
    var StereoAudioRecorder = RecordRTC.StereoAudioRecorder;
    this.micRecorder = new StereoAudioRecorder(stream, options);
    this.micRecorder.record();
  }

  async stopAudio(chat, timeout = false) {
    let file: any;
    await clearInterval(this.interval);

    this.time[chat.id] = 0;
    this.recording = false;
    if (this.platform.is('capacitor')) {

      let data = await AudioPlugin.stopAudio();
      if (data && data.fileStream) {
        let blob: any = await this.fileService.dataURItoBlob(data.fileStream, 'audio/mp3');
        file = await new File([blob], "audio.mp3");
      }
    } else {
      file = await new Promise((resolve, reject) => {
        try {
          this.micRecorder.stop((blob) => {
            resolve(new File([blob], "audio.mp3", { type: blob.type, lastModified: Date.now() }));
          });
        } catch (e) { reject(e) }
      })
      if (this.audioStream) {
        this.audioStream.stop();
        this.audioStream.getTracks().forEach(function (track) {
          track.stop();
        });
      }
      this.recording = false;
    }
    if (timeout) this.audioTimeout$.next(file);
    this.audioEnded$.next();

    return Promise.resolve(file);
  }


  async cancelAudio(chat) {
    this.audioEnded$.next();
    try {
      await clearInterval(this.interval);
      this.time[chat.id] = 0;
      if (this.platform.is('capacitor')) {
        await AudioPlugin.stopAudio();
      } else {
        this.micRecorder.stop();
      }
      this.recording = false;
    } catch (e) {
      this.recording = false;
    }
    this.recording = false;
  }


  playRingTone() {
    this.ringingTone = new Audio();
    this.ringingTone.src = "../assets/sounds/ringing.mp3";
    this.ringingTone.loop = true;
    this.ringingTone.load();
    this.ringingTone.play();
  }

  playCallingTone() {
    this.callingTone = new Audio();
    this.callingTone.src = "../assets/sounds/calling.mp3";
    this.callingTone.loop = true;
    this.callingTone.load();
    this.callingTone.play();
  }

  stopRingTone(answered) {
    if (this.ringingTone) {
      this.ringingTone.pause();
      this.ringingTone.currentTime = 0;
      this.ringingTone = null;
    }
    if (this.callingTone) {
      this.callingTone.pause();
      this.callingTone.currentTime = 0;
      this.callInTone = null;
    }

    if (answered) {
      this.callInTone = new Audio();
      this.callInTone.src = "../assets/sounds/call-in.mp3";
      this.callInTone.load();
      this.callInTone.play();
    } else {
      this.callOffTone = new Audio();
      this.callOffTone.src = "../assets/sounds/call-off.mp3";
      this.callOffTone.load();
      this.callOffTone.play();
    }

  }


  errorCallback(error) {
    console.log("ERROR CALLBACK", error)
  }
}
