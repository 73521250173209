import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  loader: HTMLIonLoadingElement;
  public loading$ = new ReplaySubject<boolean>(1);

  constructor(public loadingController: LoadingController, public tr: TranslateService) { }

  async presentLoading(message = '') {
    let translations = [];
    await this.tr.get(['general']).forEach(tr => translations = tr);
    if (this.loader) {
      await this.loader.dismiss();
    }
    if (!message) {
      message = translations['general'].loading;
    }
    this.loader = await this.loadingController.create({
      message,
      backdropDismiss: false,
      showBackdrop: true,
      keyboardClose: true
    });
    return await this.loader.present();
  }

  async dismissLoading() {
    try {
      if (this.loader)
        return await this.loader.dismiss();
    } catch (e) {
      return;
    }
    return;
  }

  // async presentLoadingWithOptions() {
  //   const loading = await this.loadingController.create({
  //     spinner: null,
  //     duration: 5000,
  //     message: 'Please wait...',
  //     translucent: true,
  //     cssClass: 'custom-class custom-loading'
  //   });
  //   return await loading.present();
  // }
}
