import { Injectable } from '@angular/core';
import { DeviceService } from './device.service';
import { AlertService } from './alert.service';
import { Platform } from '@ionic/angular';
import { AuthService } from './auth.service';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { doc, DocumentSnapshot, getDoc } from 'firebase/firestore';
import { doc as docSnapshots } from 'rxfire/firestore';

import { UserService } from './user.service';

import { App, AppInfo } from '@capacitor/app';
import { FirebaseService } from './firebase.service';

@Injectable({
  providedIn: 'root'
})
export class UpdateService {

  public medxatGlobalAlert;
  public medxatOrgAlert;
  public medxatAppAlert;

  public info: AppInfo;

  constructor(public authService: AuthService,
    public platform: Platform,
    public alertService: AlertService,
    public deviceService: DeviceService,
    public fs: FirebaseService,
    public userService: UserService) {
    this.authService.getAuthenticationState().pipe(
      distinctUntilChanged()
    ).subscribe((isAuth) => {
      if (isAuth) {
        this.init();
      }
    });

    docSnapshots(doc(this.fs.firestore, 'global', 'params'))
      .pipe(distinctUntilChanged(),
        map((snap: DocumentSnapshot) => ({ id: snap.id, ...snap.data() }))
      ).subscribe(async (global: any) => {
        if (global && global.status === 'stopped') {
          this.medxatGlobalAlert = await this.alertService.showMedxatStopAlert(global.message);
          await this.medxatGlobalAlert.present();
        } else if (this.medxatGlobalAlert) this.medxatGlobalAlert.dismiss();
      })
  }

  init() {
    if (this.platform.is('capacitor')) {
      this.watchAppVersion();
    }

    this.userService.org$
      .pipe(
        takeUntil(this.authService.onSignOut$),
        distinctUntilChanged())
      .subscribe(async (org) => {
        if (org && org.status === 'stopped') {
          this.medxatOrgAlert = await this.alertService.showMedxatStopAlert(org.message);
          await this.medxatOrgAlert.present();
        } else if (this.medxatOrgAlert) await this.medxatOrgAlert.dismiss();
      });
  }

  async watchAppVersion() {
    this.info = await App.getInfo();
    let versions: any = await getDoc(doc(this.fs.firestore, 'global', 'versions'));

    versions = versions.data();
    let maxDate = versions.guttmann.maxDate;
    if (this.platform.is('ios')) {
      if (this.info && this.info.version < versions.guttmann.ios) {
        this.medxatAppAlert = await this.alertService.showMedxatVersionAlert(maxDate);
        await this.medxatAppAlert.present();
      } else if (this.medxatAppAlert) await this.medxatAppAlert.dismiss();
    }

    if (this.platform.is('android')) {
      if (this.info && this.info.version < versions.guttmann.android) {
        this.medxatAppAlert = await this.alertService.showMedxatVersionAlert(maxDate);
        await this.medxatAppAlert.present();
      } else if (this.medxatAppAlert) await this.medxatAppAlert.dismiss();
    }
  }



}
