export class ColorGenerator {
    static COLORS: any[] = ['#e57373', '#f06292', '#ba68c8', '#9575cd', '#7986cb', '#64b5f6',
        '#4fc3f7', '#4dd0e1', '#4db6ac', '#81c784', '#aed581', '#ff8a65', '#d4e157', '#673ab7',
        '#ffb74d', '#a1887f', '#90a4ae', '#666666', '#018786', '#03dac6', '#ff0266', '#ff8749', '#c7006e',
        '#B39DDB', '#F48FB1', '#90CAF9', '#80CBC4', '#00695C', '#0097A7', '#00B8D4', '#1DE9B6', '#9CCC65',
        '#7CB342', '#8D6E63', '#CFD8DC', '#00E676', '#FF8F00', '#f6ff00', '#ff0000', '#00ff00', '#0000ff',
        '#0000a5'];

    static COLORS_TEXT: any[] = ['#154629', '#009FD4', '#B381B3', '#AA8F00', '#D47500', '#F64747', '#561B8D',
        '#1BA39C', '#3455DB', '#E26A6A', '#B8860B', '#FF4500', '#B11030', '#DB0A5B',
        '#5D995D', '#008080', '#BF6EE0', '#5A440D', '#D46A43', '#2B0000', '#F37021',
        '#6B8F3C', '#34385E', '#FF00FF', '#0000E0', '#804028', '#FF0000', '#6A1B9A',
        '#08AA4B', '#406098', '#8a2be2', '#5C97BF', '#9F6B3F', '#D4533B', '#9E9D24',
        '#40806A', '#67809F', '#5E50B5', '#808080', '#E65722', '#4D6066', '#00BFA5'
    ];

    public static getColor(str: string): string {
        return this.COLORS[Math.abs(this.generateHashCode(str)) % this.COLORS.length];
    }

    public static  getTextColor(str: string): string {
        return this.COLORS_TEXT[Math.abs(this.generateHashCode(str)) % this.COLORS_TEXT.length];
    }

    static generateHashCode(str: string): number {
        let h = 0;
        if (str.length > 0) {
            for (let i = 0; i < str.length; i++) {
                h = 31 * h + str.charCodeAt(i);
                h |= 0; // Convert to 32bit integer
            }
        }
        return h;
    }
}
