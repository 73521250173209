// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.swiper-zoom-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.swiper-zoom-container > img,
.swiper-zoom-container > svg,
.swiper-zoom-container > canvas {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.swiper-slide {
  overflow: hidden;
}

.swiper-container {
  width: 100%;
  height: 100%;
}

ion-slides {
  background: #000;
  color: #000;
  margin: 0;
  padding: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/gallery/gallery.component.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,kBAAA;AACJ;;AACE;;;EAGE,eAAA;EACA,gBAAA;EACA,mBAAA;AAEJ;;AACE;EACE,gBAAA;AAEJ;;AACE;EACE,WAAA;EACA,YAAA;AAEJ;;AACE;EACE,gBAAA;EACA,WAAA;EACA,SAAA;EACA,UAAA;AAEJ","sourcesContent":[".swiper-zoom-container {\n    width: 100%;\n    height: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    text-align: center;\n  }\n  .swiper-zoom-container > img,\n  .swiper-zoom-container > svg,\n  .swiper-zoom-container > canvas {\n    max-width: 100%;\n    max-height: 100%;\n    object-fit: contain;\n  }\n  \n  .swiper-slide {\n    overflow: hidden;\n  }\n  \n  .swiper-container {\n    width: 100%;\n    height: 100%;\n  }\n  \n  ion-slides {\n    background: #000;\n    color:#000;\n    margin: 0;\n    padding: 0;\n  }\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
