import { Component, OnInit, HostBinding, Input } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'ion-avatar[connectionStatus], user-connection-status[value]',
  templateUrl: './user-connection-status.component.html',
  styleUrls: ['./user-connection-status.component.scss'],
})
export class UserConnectionStatusComponent implements OnInit {

  statusClass: string;
  public _size = 13;
  
  constructor() { }


  @HostBinding('style.height.px')
  public _hostHeight: number;

  @Input() showText: boolean;

  @Input()
  set size(px: number) {
    this._size = px;
    this._hostHeight = px;
  }

  @Input()
  set connectionStatus(status) {
    switch (status) {
      case "ONLINE":
        this.statusClass = 'online';
        break;
      case "OFFLINE":
        this.statusClass = 'offline';
        break;
      case "AWAY":
        this.statusClass = 'away';
        break;
      case "UNAVAILABE":
        this.statusClass = 'unavailable';
        break;
      case "UNVERIFIED":
        this.statusClass = 'unverified';
        break;
      case "DISABLED":
        this.statusClass = 'disabled';
        break;
      default:
        this.statusClass = status;
    }
  }

  @Input()
  set value(value) {
    this.connectionStatus = value;
  }

  ngOnInit() {}

}
