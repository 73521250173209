import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent implements OnInit {

  @Input() id;
  @Input() title;
  @Input() color;

  constructor(public popOverCtrl: PopoverController) { }

  ngOnInit() {}

  dismiss(){
    if(this.id) this.popOverCtrl.dismiss(null, null, this.id);
    else this.popOverCtrl.dismiss();
  }
}
