import { Component, OnInit, Input, HostListener } from '@angular/core';
import { Platform } from '@ionic/angular';
import { FileService } from 'src/services/file.service';
import { ChatService } from 'src/services/chat.service';

@Component({
  selector: 'image-thumb',
  templateUrl: './image-thumb.component.html',
  styleUrls: ['./image-thumb.component.scss'],
})
export class ImageThumbComponent implements OnInit {

  @Input() originalWidth: number;
  @Input() originalHeight: number;
  @Input() key?: string;
  @Input() chatId: string;

  imageSrc;
  loading = true;
  loaded;
  width: number;
  imgData: any;

  public image;

  @Input()
  set imageData(data) {
    this.imgData = data;
    this.image = data.image;

    this.loaded = this.fileService.imageIsLoaded(this.imgData.chat.id, this.imgData.message.id);
    if(this.loaded){
      this.imageSrc = this.loaded.url;
    }else{
      this.imageSrc = this.image.thumb;
    }

    // if (this.platform.is('capacitor')) {
    // const localURL = <SafeUrl | void>this.imageService.getFromURLSync(this.image_.url);
    // if (localURL) {
    //   this.imageSrc = localURL;
    //   this.loaded = true;
    //   return;
    // }
    // this.imageService
    //   .getFromURL(this.image_.url, {download: false})
    //   .then((localURL: SafeUrl) => {
    //     // The image may have changed while we were trying to retrieve it from storage,
    //     // make sure it's still the same object
    //     if (useImage === this.image_) {
    //       if (localURL) {
    //         this.imageSrc = localURL;
    //         this.loading = true;
    //         this.ngZone.run(() => this.changeDetectorRef.markForCheck());
    //         // this.changeDetectorRef.markForCheck();
    //       }
    //     }
    //   });
    // } else {
    // if (this.imageService.isLoading(this.key)) {
    //   if (this.image_.url) {
    //     this.imageSrc = this.image_.url;
    //   }
    //   this.loading = true;
    // } else if (this.imageService.isLoaded(this.image_.url)) {
    //   this.imageSrc = this.image_.url;
    //   this.loaded = true;
    // }
    // }
  }


  constructor(public platform: Platform, public fileService: FileService, public chatService: ChatService) { }

  ngOnInit() { }


  @HostListener('click')
  onClick() {
    if (!this.fileService.imageIsLoaded(this.imgData.chat.id, this.imgData.message.id) && !this.loading) {
      this.imageSrc = this.image.url;
      this.fileService.pushLoadedImage(this.imgData.chat.id, this.imgData.message.id, this.image)
      this.loading = false;
    }else{
      this.chatService.showImageModal(this.imgData.message, this.imgData.chat.id);
    }
  }

  imageLoaded() {
    this.loading = false;
  }

  imageError() {
    this.loading = false;
    this.imageSrc = this.image.thumb;
  }

  // public async _downloadImage() {
  //   this.loading = true;
  //   const useImage = this.image_;

  //   if (this.platform.is('capacitor') === true) {
  //     try {
  //       // const localURL = await this.imageService.getFromURL(this.image_.url);
  //       // The image may have changed while we were trying to retrieve it from storage,
  //       // make sure it's still the same object
  //       // if (useImage === this.image_) {
  //       //   if (localURL) {
  //       //     this.imageSrc = localURL;
  //       //   } else {
  //       //     this.loading = false;
  //       //   }
  //       // }

  //     } catch (error) {
  //       console.error(error);
  //       this.loading = false;
  //     }
  //   } else {
  //     this.imageSrc = this.image_.url;
  //   }
  // }
}
