import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { Filesystem } from '@capacitor/filesystem';
import ShareIntentPlugin from 'src/capacitor_plugins/share-intent-plugin';

@Injectable({
    providedIn: 'root'
})
export class ShareService {

    public shareEvent$: BehaviorSubject<any> = new BehaviorSubject(null);

    constructor(
        public platform: Platform
    ) {
        if (platform.is('android') && platform.is('capacitor')) {
            ShareIntentPlugin.addListener('shareRequest', async (info: any) => {
                if (info) {
                    switch (info.type) {
                        case 'text':
                            this.shareEvent$.next(info);
                            break;

                        case 'image':
                        case 'file':
                            const content = await Filesystem.readFile({
                                path: info.data
                            });
                            this.shareEvent$.next({...info, content});
                            break;

                        case 'default':
                            break;
                    }
                }
            });
            ShareIntentPlugin.init();
        }
    }





}
