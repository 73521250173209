import { Component, OnInit, OnDestroy } from '@angular/core';
import { NetworkService } from 'src/services/network.service';
import { ModalController } from '@ionic/angular';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-internet-warning',
  templateUrl: './internet-warning.component.html',
  styleUrls: ['./internet-warning.component.scss'],
})
export class InternetWarningComponent implements OnInit, OnDestroy {

  public onDestroy$ = new Subject<void>();
  constructor(
    public modalCtrl: ModalController,
    public networkService: NetworkService
  ) {

  }

  ngOnInit() {
    this.networkService.networkStatus$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(status => {
        if (status.connected) this.dismiss();
      })
  }

  async dismiss() {
    window.location.reload();
    await this.modalCtrl.dismiss(null, null, "iw")
  }


  async refresh(event?) {
    await this.networkService.checkConnection();
    if (!event) this.dismiss();
    else setTimeout(() => {
      event.target.complete();
      this.dismiss();
    }, 100);
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }

}
