import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from '../services/auth-guard.service';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },
  { path: 'chat_module', loadChildren: () => import('./chats/chats.module').then(m => m.ChatsModule), canActivate: [AuthGuardService] },
  // { path: 'patients', loadChildren: () => import('./patients/patients.module').then(m => m.PatientsModule), canActivate: [AuthGuardService] },
  { path: 'blank', loadChildren: () => import('./blank/blank.module').then(m => m.BlankPageModule) },
  { path: 'pin', loadChildren: () => import('./pin/pin.module').then(m => m.PinPageModule) },
  { path: 'profile', loadChildren: () => import('./profile/profile.module').then(m => m.ProfilePageModule) },
  { path: 'status', loadChildren: () => import('./status/status.module').then(m => m.StatusPageModule) },
  { path: 'help', loadChildren: () => import('./help/help.module').then(m => m.HelpPageModule) },
  { path: 'contact-details', loadChildren: () => import('./contact-details/contact-details.module').then(m => m.ContactDetailsPageModule) },
  { path: 'contact-details/:id', loadChildren: () => import('./contact-details/contact-details.module').then(m => m.ContactDetailsPageModule) },
  { path: 'group-details', loadChildren: () => import('./group-details/group-details.module').then(m => m.GroupDetailsPageModule) },
  // { path: 'patient-options', loadChildren: () => import('./patient-options/patient-options.module').then(m => m.PatientOptionsPageModule) },
  { path: 'module-navigation/:id', loadChildren: () => import('./module-navigation/module-navigation.module').then(m => m.ModuleNavigationPageModule) },
  { path: 'view-iframe/:id', loadChildren: () => import('./module-navigation/views/view-iframe/view-iframe.module').then(m => m.ViewIFramePageModule) },
  { path: 'home', loadChildren: () => import('./home/home.module').then(m => m.HomePageModule), canActivate: [AuthGuardService] },
  // { path: 'visits', loadChildren: () => import('./visits/visits.module').then(m => m.VisitsPageModule), canActivate: [AuthGuardService] },
  // { path: 'visit/:id', loadChildren: () => import('./visits/visit/visit.module').then(m => m.VisitPageModule), canActivate: [AuthGuardService] },
  // { path: 'secure-com', loadChildren: () => import('./secure-com/secure-com.module').then(m => m.SecureComPageModule), canActivate: [AuthGuardService] },
  // { path: 'sc-detail/:id', loadChildren: () => import('./secure-com/sc-detail/sc-detail.module').then(m => m.ScDetailPageModule), canActivate: [AuthGuardService] },
  // { path: 'public-form', loadChildren: () => import('./public-form/public-form.module').then(m => m.PublicFormPageModule), canActivate: [AuthGuardService] },
  // { path: 'pf-detail/:id', loadChildren: () => import('./public-form/pf-detail/pf-detail.module').then(m => m.PfDetailPageModule), canActivate: [AuthGuardService] },
  // { path: 'upload-com', loadChildren: () => import('./upload-com/upload-com.module').then(m => m.UploadComPageModule), canActivate: [AuthGuardService] },
  // { path: 'template-detail/:id', loadChildren: () => import('./upload-com/template-detail/template-detail.module').then(m => m.TemplateDetailPageModule), canActivate: [AuthGuardService] },
  // { path: 'uc-summary', loadChildren: () => import('./upload-com/uc-summary/uc-summary.module').then(m => m.UcSummaryPageModule), canActivate: [AuthGuardService] },
  // { path: 'uc-stats/:id/:release', loadChildren: () => import('./upload-com/uc-stats/uc-stats.module').then(m => m.UcStatsPageModule), canActivate: [AuthGuardService] },
  // { path: 'new-campaign/:id', loadChildren: () => import('./upload-com/new-campaign/new-campaign.module').then(m => m.NewCampaignPageModule), canActivate: [AuthGuardService] },
  // { path: 'form-detail/:id', loadChildren: () => import('./upload-com/form-detail/form-detail.module').then(m => m.FormDetailPageModule), canActivate: [AuthGuardService] },
  // { path: 'uc-releases/:id', loadChildren: () => import('./upload-com/uc-releases/uc-releases.module').then(m => m.UcReleasesPageModule), canActivate: [AuthGuardService] },
  // { path: 'com-detail/:id', loadChildren: () => import('./upload-com/com-detail/com-detail.module').then(m => m.ComDetailPageModule), canActivate: [AuthGuardService] },
  // { path: 'users-directory', loadChildren: () => import('./users-directory/users-directory.module').then(m => m.UsersDirectoryPageModule) },
  // { path: 'users-directory/search/:mode', loadChildren: () => import('./users-directory/search/search.module').then(m => m.SearchPageModule) },
  // { path: 'users-directory/search/:mode/:id', loadChildren: () => import('./users-directory/search/search.module').then(m => m.SearchPageModule) },
  // { path: 'users-directory/location/:id', loadChildren: () => import('./users-directory/location/location.module').then( m => m.LocationPageModule) },
  { path: 'videovisites', loadChildren: () => import('./visits-custom/visits-custom.module').then( m => m.VisitsCustomPageModule) },
  { path: 'patients-dashboard', loadChildren: () => import('./patients-dashboard/patients-dashboard.module').then( m => m.PatientsDashboardPageModule) },
  // { path: 'workday-register', loadChildren: () => import('./workday-register/workday-register.module').then( m => m.WorkdayRegisterPageModule) }

];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
