import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Subject } from 'rxjs';
import { UserService } from 'src/services/user.service';
import { NavigationService } from 'src/services/navigation.service';
import { collection, doc, getDoc, getDocs } from 'firebase/firestore';
import { FirebaseService } from 'src/services/firebase.service';

@Component({
  selector: 'app-message-info',
  templateUrl: './message-info.component.html',
  styleUrls: ['./message-info.component.scss'],
})
export class MessageInfoComponent implements OnInit, OnDestroy {

  @Input() public set _message(value) {
    this.message = value;
    this.init()
  };
  @Input() public set _group(value){
    this.group = value;
    this.init()
  };

  message;
  group;

  public user_keys: any[] = [];
  public users_seen: any[] = [];
  public users_received: any[] = [];
  public users_not_received: any[] = [];
  public group_users: any;
  public dest_users = 0;
  public seen = 0;
  public received = 0;
  public neither = 0;

  public ready: boolean = false;
  public onDestroy$ = new Subject<void>();
  public timer: any;
  public broadcast: boolean = false;
  public loading = true;

  constructor(public modalCtrl: ModalController,
    public fs: FirebaseService,
    public navService: NavigationService,
    public userService: UserService) { }

  ngOnInit(): void {

  }
  async init() {

    console.log('inici msg: ', this.message, this.group)
    if(!this.message||!this.group)return;
    console.log('inici 2')

    this.group_users = this.group.users;
    this.navService.inDetail = true;


    this.broadcast = this.group.broadcast;
    this.seen = 0;
    this.received = 0;
    this.neither = 0;

    this.loading = true;
    if (!this.broadcast) {
      Object.keys(this.group_users).forEach(key => {
        if (this.group_users[key] === true && key != this.userService.getUser().id) this.user_keys.push(key);
      });

      if (!this.user_keys || this.user_keys.length == 0) return;
      if (!this.message) return
      this.dest_users = (this.message.destUsers - 1) || (this.user_keys.length);

      this.users_seen = [];
      this.users_received = [];
      this.users_not_received = [];
      let checked_keys = this.user_keys;


      let colRef = collection(this.fs.firestore, `orgs/${this.userService.getOrganization().id}/groups/${this.group.id}/messages/${this.message.id}/userInfo`);
      let usersInfoSnap = (await getDocs(colRef)).docs;

      for (let i = 0; i < usersInfoSnap.length; i++) {
        let userSnap = usersInfoSnap[i];
        let id = userSnap.id;
        let index = checked_keys.indexOf(id);
        if (id != this.userService.getUser().id) {
          checked_keys.splice(index, 1);
          let usr_msg_data = userSnap.data();

          let docUser = await getDoc(doc(this.fs.firestore, `users/${id}`));
          var user = docUser.data();
          this.controlUserMsg(user, usr_msg_data);

        }
      };
      if (checked_keys.length > 0) {
        checked_keys.forEach(async (id) => {
          if (id != this.userService.getUser().id) {
            let docUser = await getDoc(doc(this.fs.firestore, `users/${id}`));
            var user = docUser.data();
            this.controlUserMsg(user);
          }
        })
      }
    } else {
      this.loading = false;
      this.dest_users = Object.keys(this.group_users).length - 1;
      this.seen = this.message.seenUsers;
      this.neither = this.message.destUsers - this.message.seenUsers;
    }
  }

  controlUserMsg(user, usr_msg_data?) {
    let item = {};
    if (user) {
      item['name'] = user.profile.name;
      item['avatar'] = user.profile.smallPicture;
    }
    if (!usr_msg_data) {
      this.users_not_received.push(item);
      this.neither++;
    } else if (usr_msg_data.seen && usr_msg_data.seen != false) {
      item['seen'] = usr_msg_data.seen.toDate();
      this.users_seen.push(item);
      this.seen++;
    } else if (usr_msg_data.delivered) {
      item['received'] = usr_msg_data.delivered.toDate();
      this.users_received.push(item);
      this.received++;
    } else {
      this.users_not_received.push(item);
      this.neither++;
    }
    this.ready = true;

    if ((this.neither + this.seen + this.received) === (this.user_keys.length)) {
      this._ready();
    } else if (this.user_keys.length === 0 || !this.group_users) {
      this._ready();
    }

    if (!this.timer) {
      //TODO: a vegades falla la suma de neither+seen+rcvd i el numero d'usuaris. Per ara fiquem un timer de màxim 3s per desactivar el loader i mostrar resultats.
      this.timer = setTimeout(() => { if (!this.ready) this._ready(); }, 3000);
    }
  }

  _ready() {
    this.ready = true;
    this.loading = false;
  }

  dismiss() {
    this.navService.inDetail = false;

    this.modalCtrl.dismiss();
    this.onDestroy$.next();
  }

  ngOnDestroy() {
    this.navService.inDetail = false;
    this.onDestroy$.next();
  }



}
