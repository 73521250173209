import { distinctUntilChanged, filter, first, map, mapTo, startWith, switchMap, takeUntil, takeWhile, switchMapTo } from 'rxjs/operators';
import { AlertService } from './alert.service';
import { PrivacityComponent } from 'src/app/shared/privacity/privacity.component';
import { DeviceService } from './device.service';
import { BehaviorSubject, Subject, fromEvent, merge, interval } from 'rxjs';
import { EventEmitter, Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { UserService } from './user.service';
import { Platform, ModalController, NavController } from '@ionic/angular';
import { LocalStorageService } from './local-storage.service';
import { EncryptionService } from './encryption.service';
import { PinPage } from 'src/app/pin/pin.page';
import { TimeService } from './time.service';
import { NetworkService } from './network.service';
import { InternetWarningComponent } from 'src/app/shared/internet-warning/internet-warning.component';
import FingerprintPlugin from 'src/capacitor_plugins/fingerprint-plugin';
import { serverTimestamp } from 'firebase/firestore';
import { NavigationService } from './navigation.service';

@Injectable({
    providedIn: 'root'
})
export class PinService {

    public static MAX_PIN_TIMEOUT_WEB = 3600 * 5; // 2 hours
    public static MAX_PIN_TIMEOUT_CORDOVA: number = 60 * 60; // 30 minutes
    public static MAX_ATTEMPTS = 3; // 30 minutes

    public useFingerprint$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public usePin$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public isAuthenticated$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public onDestroy$ = new Subject<void>();
    public onSignOutBegin$: EventEmitter<void> = new EventEmitter();
    public pin: any;
    public msgPinChanged: string;
    public errorGeneric: string;
    public activeModal: HTMLIonModalElement;
    public maxAttempts = 3;
    public timeoutMobile: number = PinService.MAX_PIN_TIMEOUT_CORDOVA;
    public timeoutWeb: number = PinService.MAX_PIN_TIMEOUT_WEB;
    public lastConn: number;
    public usePin = false;
    public isAuthenticated = false;
    public firstLogin = false;
    public checkStarted$: EventEmitter<void> = new EventEmitter();
    public checkEnded$: EventEmitter<void> = new EventEmitter();
    public modal: HTMLIonModalElement;
    public privacyModal: HTMLIonModalElement;
    public iwModal: HTMLIonModalElement;

    constructor(public authService: AuthService,
        public userService: UserService,
        public platform: Platform,
        public alertService: AlertService,
        public modalController: ModalController,
        public timeService: TimeService,
        public navCtrl: NavController,
        public networkService: NetworkService,
        public deviceService: DeviceService,
        public ns: NavigationService) {
        this.authService.getAuthenticationState().pipe(
            distinctUntilChanged()
        ).subscribe((isAuth) => {
            if (isAuth) {
                this.init();
            } else { this.isAuthenticated$.next(false); }
        });

    }

    init() {

        /**
         *  Observable that selects org params related to pin. It emits when any parameter is different than before.
         */
        const orgParams$ = this.userService.organizations$
            .pipe(
                filter(orgs => orgs),
                map(orgs => {
                    let usePin = false;
                    this.usePin$.next(false);
                    let maxAttempts = PinService.MAX_ATTEMPTS;
                    let timeoutMobile = PinService.MAX_PIN_TIMEOUT_CORDOVA;
                    let timeoutWeb = PinService.MAX_PIN_TIMEOUT_WEB;
                    let user = this.userService.getUser();
                    if (!user) return;
                    if (!this.authService.ignorePin) {
                        orgs.forEach(org => {
                            if (!org || !org.params) return;
                            if (org.params.usePin && !user.profile.patient) {
                                usePin = true;
                                this.usePin$.next(true);
                            } else if (org.params.usePinPatient && user.profile.patient) {
                                usePin = true;
                                this.usePin$.next(true);
                            }
                            if (usePin) {
                                if (org.params.timeoutMobile < timeoutMobile) {
                                    timeoutMobile = org.params.timeoutMobile;
                                }
                                if (org.params.timeoutWeb < timeoutWeb) {
                                    timeoutWeb = org.params.timeoutWeb;
                                }
                                if (org.params.maxAttempts < maxAttempts) {
                                    maxAttempts = org.params.maxAttempts;
                                }
                            }
                        });
                    }
                    return {
                        usePin,
                        maxAttempts,
                        timeoutMobile,
                        timeoutWeb
                    };
                }),
                distinctUntilChanged((a, b) => {
                    return a.usePin === b.usePin
                        && a.timeoutMobile === b.timeoutMobile
                        && a.timeoutWeb === b.timeoutWeb
                        && a.maxAttempts === b.maxAttempts;
                })
            );

        /**
         * Ens subscribim als paràmetres i si el use.pin no ha canviat és que hem entrat per primer cop després de l'autenticació.
         * Si use.pin és true demanem el pin. Altrament tancar qualsevol moda obert prèviament i autenticar ja que no es fa servir
         * el pin.
         */
        this.userService.userPrivacy$.pipe(
            distinctUntilChanged(),
            takeUntil(this.authService.onSignOut$),
            filter(val => val),
            switchMapTo(orgParams$),
            distinctUntilChanged((a, b) => a.usePin === b.usePin),
            takeUntil(this.authService.onSignOut$),
        ).subscribe(params => {
            if (params && params.usePin) {
                console.log("IS_AUTH NEXT FALSE 3")

                this.isAuthenticated$.next(false);
                this.checkPin(params);
            } else {
                this.isAuthenticated$.next(true);
                if (this.modal) { this.modal.dismiss({ close: true }); }
            }
        });

        this.userService.user$.pipe(
            filter(user => user),
            distinctUntilChanged((a, b) => a && b && a.validation && b.validation && a.validation.privacy === b.validation.privacy),
            takeUntil(this.authService.onSignOut$)
        ).subscribe((user) => {
            if (user && user.validation && user.validation.privacy) {
                this.userService.userPrivacy$.next(true);
            }
            else if (!this.networkService.isNetworkConnected().connected) {
                this.showInternetWarningModal();
            } else {
                if (user && !user.validation) return; //TODO: mirar perquè amb "Minerva/Web" a vegades mostra el privacyModal
                if (!this.authService.privacyAccepted) this.showPrivacyModal();
            }
        });
        const userInteractions$ = merge(
            fromEvent(document, 'touchstart'),
            fromEvent(document, 'touchend'),
            fromEvent(document, 'touchmove'),
            fromEvent(document, 'touchcancel'),
            fromEvent(document, 'wheel'),
            fromEvent(document, 'mousemove'),
            fromEvent(document, 'mousedown'),
            fromEvent(document, 'mouseup'),
            fromEvent(document, 'keyup'),
            fromEvent(document, 'keydown')
        ).pipe(
            startWith(true),
            mapTo(true)
        );


        /**
         * Explicació: Estem subscrits a l'autenticació. Quan auth=true ens subscribim als parametres.
         * Quan tenim els parametres el primer cop (gràcies a startWith$ de user interaction)
         * posem un timer segons la plataforma i quan salta mirem pin. A partir de llavors qualsevol interacció
         * de teclat reinicia el contador. Si en qualsevol moment apareix el modal de check pin ens desubscribim
         * del timer i dels paràmetres. Quan el modal desapareixi ja estarem autenticats i es tornarà a subscriure
         * als paràmetres i de nou al contador. Si en qualsevol moment canvien els paràmetres el contador s'actualitza
         * en conseqüència. Si l'org no necessita el pin ens desubscribim de les interaccions i de l'interval.
         */
        this.isAuthenticated$.pipe(
            distinctUntilChanged(),
            filter(x => x),
            switchMap(() => orgParams$.pipe(takeUntil(this.checkStarted$))),
            switchMap((params) => userInteractions$.pipe(mapTo(params), takeWhile(() => params.usePin), takeUntil(this.checkStarted$))),
            switchMap((params: any) => {
                return interval(1000 * (this.platform.is('capacitor') ? params.timeoutMobile : params.timeoutWeb)).pipe(
                    first(),
                    mapTo(params),
                    takeWhile(() => params.usePin),
                    takeUntil(this.checkStarted$)
                );
            }),
            takeUntil(this.authService.onSignOut$)
        ).subscribe((params) => {
            console.log("PARAMS CHECKPIN")
            if (params && params.usePin) {
                console.log("IS_AUTH NEXT FALSE 4")

                this.isAuthenticated$.next(false);
                this.checkPin(params);
            }
        });


    }


    async checkPin(params) {
        this.checkStarted$.emit();
        const user = this.userService.getUser();
        if (this.ns.routeIgnorePin) {
            //ignorem mostrar el pin però validem autenticació per resetejar el timer de timeoutMobile o timeoutWeb
            this.isAuthenticated$.next(true);
        } else if (!user || !user.validation || !user.validation.pin || !user.validation.pin.pin) {
            // Si no té pin mostrem diàleg de establir pin
            await this.showSetPinDialog();
        } else {
            const useFingerprint = await LocalStorageService.getByKey('use_fingerprint');
            let use = useFingerprint && useFingerprint.use ? true : false;
            this.useFingerprint$.next(use);
            await this.showEnterPinDialog(user.validation.pin.pin, useFingerprint);
        }

        this.checkEnded$.emit();

    }

    async showSetPinDialog() {
        try {
            const data = await this.showModal('set');
            const pin = data.pin;
            await this.userService.updateValidationProperty('pin', {
                pin: EncryptionService.SHA512Encrypt(pin),
                date: serverTimestamp()
            });
            await this.showSetFingerprintDialog();
            this.isAuthenticated$.next(true);
        } catch (e) {
            console.error(e);
            await this.showSetPinDialog();
        }
    }

    async showChangePinDialog() {
        try {
            const currentPin = await this.userService.getUser().validation.pin.pin;
            const data = await this.showModal('change', currentPin);
            if (data && data.pin) {
                const pin = data.pin;
                await this.userService.updateValidationProperty('pin', {
                    pin: EncryptionService.SHA512Encrypt(pin),
                    date: serverTimestamp()
                });
            }
        } catch (e) {
            console.error(e);
            await this.showChangePinDialog();
        }
    }

    async showEnterPinDialog(pin, useFingerprint: any = {}) {
        try {
            const use = this.platform.is('capacitor') && (useFingerprint || { use: false }).use;
            const data = await this.showModal('enter', pin, use);
            if (data) {
                const success = data.success;
                if (!success) {
                    this.signOut();
                } else {
                    if (!useFingerprint) {
                        await this.showSetFingerprintDialog();
                    }
                    this.isAuthenticated$.next(true);
                }
            }
        } catch (e) {
            console.log("Error", e);
            await this.alertService.presentAlert(e.toString());
            // await this.showEnterPinDialog(pin, useFingerprint);
        }
    }

    async isFingerprintAvailable() {
        if (!this.platform.is('capacitor')) return false;
        const data = await FingerprintPlugin.isAvailable();
        return data.isAvailable;
    }

    async showSetFingerprintDialog() {
        if (this.platform.is('capacitor')) {
            const data = await FingerprintPlugin.isAvailable();
            const isAvailable = data.isAvailable;
            if (!isAvailable) { return; }
            const fingerData = await this.showModal('fingerprint');
            const useFinger = fingerData ? fingerData.use_fingerprint : false;
            this.useFingerprint$.next(useFinger);
            await this.setFingerprint(useFinger);
            return useFinger;
        }
        return;
    }

    setFingerprint(value) {
        if (!value) { value = false; }
        this.useFingerprint$.next(value);
        try {
            return Promise.all(
                [
                    LocalStorageService.setByKey('use_fingerprint', { use: value, date: new Date() }),
                    this.deviceService.saveFingerprint({ use: value, date: serverTimestamp() })
                ]
            );
        } catch (e) {
            console.log("ERROR STORING PIN");
            return Promise.resolve();
        }
    }


    async showModal(viewMode, firebasePin = null, useFingerprint = null) {
        if (this.modal) { return; }
        this.modal = await this.modalController.create({
            component: PinPage,
            cssClass: 'pinModal',
            componentProps: {
                mode: viewMode,
                firebase_pin: firebasePin,
                user_fingerprint: useFingerprint
            },
            showBackdrop: viewMode === 'change',
            backdropDismiss: viewMode === 'change'
        });
        await this.modal.present();
        const { data } = await this.modal.onDidDismiss();
        this.modal = null;
        return data;
    }

    public async signOut() {
        await this.userService.updateUser('', { 'validation.pin': {} });
        await LocalStorageService.removeByFullKey('use_fingerprint');
        await this.authService.logout();
    }

    async showPrivacyModal() {
        if (this.privacyModal) return;

        this.privacyModal = await this.modalController.create({
            component: PrivacityComponent,
            componentProps: {
                viewMode: false
            },
            showBackdrop: false,
            backdropDismiss: false,
            id: 'privacy'
        });
        await this.privacyModal.present();
        await this.privacyModal.onDidDismiss();
        this.privacyModal = null;
    }

    async showInternetWarningModal() {
        if (this.iwModal) {
            this.iwModal.dismiss();
        }

        this.iwModal = await this.modalController.create({
            component: InternetWarningComponent,
            showBackdrop: false,
            backdropDismiss: false,
            id: 'iw'
        });
        await this.iwModal.present();
        await this.iwModal.onDidDismiss();
        this.iwModal = null;
    }

}
