import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'hoursDiference'
})
export class HoursDiferencePipe implements PipeTransform {

  transform(register: any): string {
    if (register.hinicial && register.hfinal) {
      let hhinicial = register.hinicial.split(':')[0];
      let mhinicial = register.hinicial.split(':')[1];
      let hhfinal = register.hfinal.split(':')[0];
      let mhfinal = register.hfinal.split(':')[1];

      let start = moment().hour(hhinicial).minutes(mhinicial);
      let end = moment().hour(hhfinal).minutes(mhfinal);
      var duration = moment.duration(end.diff(start));
      var hours = duration.asHours();
      let n = new Date(0, 0);
      n.setMinutes(+hours * 60);
      let result = n.toTimeString().slice(0, 5);
      return result;

    } else return "--";
  }

}
