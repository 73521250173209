// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `text-img {
  background-color: transparent;
  color: white;
}

text-img, text-img::after {
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 1.125rem;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

text-img::after {
  content: attr(value);
}

.avatar-circle {
  width: 2.25rem;
  height: 2.25rem;
  background-color: #9575CD;
  text-align: center;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.avatar-circle .initials {
  position: relative;
  font-size: 1.125rem;
  line-height: 1.125rem;
  color: #fff;
}
.avatar-circle .textBig {
  font-size: 3.15rem;
  line-height: 3.15rem;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/text-img/text-img.component.scss"],"names":[],"mappings":"AAEA;EACE,6BAAA;EACA,YAAA;AADF;;AAIA;EACE,cARgB;EAShB,eATgB;EAUhB,uBAAA;EACA,mBAAA;EACA,sBAAA;EACA,kBAAA;AADF;;AAIA;EACE,oBAAA;AADF;;AA0BA;EApBE,cAtBgB;EAuBhB,eAvBgB;EAwBhB,yBAAA;EACA,kBAAA;EACA,kBAAA;EACA,0BAAA;EACA,uBAAA;EAgBA,YAAA;EACA,WAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AAjBF;AADE;EACE,kBAAA;EACA,mBAAA;EACA,qBAAA;EACA,WAAA;AAGJ;AADE;EACE,kBAAA;EACA,oBAAA;AAGJ","sourcesContent":["$text-image-size: 2.25rem !default;\n\ntext-img {\n  background-color: transparent;\n  color: white;\n}\n\ntext-img, text-img::after {\n  width: $text-image-size;\n  height: $text-image-size;\n  border-radius: calc($text-image-size / 2);\n  display: table-cell;\n  vertical-align: middle;\n  text-align: center;\n}\n\ntext-img::after {\n  content: attr(value);\n}\n\n\n@mixin avatar($size) {\n  width: $size;\n  height: $size;\n  background-color: #9575CD;\n  text-align: center;\n  border-radius: 50%;\n  -webkit-border-radius: 50%;\n  -moz-border-radius: 50%;\n\n  .initials {\n    position: relative;\n    font-size: $size*0.5;\n    line-height: $size*0.5;\n    color: #fff;\n  }\n  .textBig{\n    font-size: $size*1.4;\n    line-height: $size*1.4;\n  }\n}\n\n.avatar-circle      { \n  @include avatar($text-image-size); \n  height: 100%;\n  width: 100%;\n  display: flex;\n  align-items: center; \n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
