import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { catchError, distinctUntilChanged, filter, first, map, switchMap } from 'rxjs/operators';
import { Observable, Subject, Subscription } from 'rxjs';
import { UserService } from './user.service';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from '../environments/environment';
import { Platform } from '@ionic/angular';
import { Browser } from '@capacitor/browser';
import { FirebaseService } from './firebase.service';
import { getAuth } from '@firebase/auth';

@Injectable({
  providedIn: 'root'
})
export class CommunicationService {
  public currentRequest$: Observable<any>;
  public currentRequestSubs: Subscription;
  public cancelPendingRequests$ = new Subject<void>();

  public userOrg$: Observable<{ org; user }>;
  public uploadData = "TnW4Cwsl4toXFyqsAKtRUawNSOzzAXIr7c5hDB1kVlP06JFaprbPt1J0ZYidyIG5I5tnAUd0iptYsMbD1WlbFRulzdG3duxGKYEzngj9k298wnfQkWBsdNtGVWIu0q8zYzD1eozy4DEOZe0qSSQPb3U2sarpo77OoTkw8a4eytZlAoa6CB26RVxpnj6MYuPiETTTZu9HFcJotaGQ0ruovZyfGu8R8aLg5vzoC9sXCK3cVttvG9sW6wrk4FIal2ji9uMromlppVR6ZXNXMmf1y636x4NbJ9wPhDZ9KITI1c9y5y8Vpvg3wFwasWZXF9J00kTVfPtGjZKEwiA4APG8oXV5iMIuCfskG6ogHrVmisonUEFHbpwRque5lkg4KuJ5EnqCrKanMgFrDTnq9FHN9Hc3HQ3sfa7K1rotreDHYPSHybY3HbVfnLYAU60w4MTSoTK8vDXfU3aanhT0gUSJ3Dl45pSKskZrPJ3Fq5uzR4Od8rDM2nqbmfXlVksF2ZcpUEGIUe0gDa5zvqWUD96OR9K3GoCZ5v6XfS5WPAWu5BJ3hEC69gGOO9I0Jc4eyUVvjfIXUsuWgbJxAjX7xI8qgudDNWVU73VZSTPcoPwyOA5WVrBWvKdSSt7g47sjtPzzcPy3eJMnEZE2MvRhx9FrtzhPy53kZvseIzAkDHRKh6bp8kuN8So2FmrD6sPeDQf53w5L4a2N1ZPjOLEeFDbESDUDrbxPIMqtXsZZK93Xy0Itoby9WrcuQpaNJYE9moIpneT14hS7NMfAI7t4KyjYVwZgEVtMa8ZuIQJMXGiCuSYSQP4uRzvd4C6WTnd4A7alXwisct5CK1RFhS2ioLLH8PHflA1KO07Q6UQKjeBqGWFSCqEZLf7cQvRahQdHgxjno4P8v4CFzaGzfj5Jf5W9uK3brp3zGi1wxaQHwUWM1zwmONDNrnpeCBS2gNoxWzyXd1l2ZdzrgahgFRVChrHDpWTsjKSt9yYCGuNTfG83";

  constructor(
    public userService: UserService,
    public http: HttpClient,
    public fs: FirebaseService,
    public auth: AuthService,
    public plt: Platform
  ) {
    this.init();
  }

  public init() {
    // userOrg$ id's helper observable
    this.userOrg$ =
      this.userService.user$.pipe(
        filter(user => user),
        distinctUntilChanged((a, b) => a && b && a.id === b.id),
        switchMap((user) => this.userService.org$.pipe(
          filter(org => org),
          distinctUntilChanged((a, b) => a && b && a.id === b.id),
          map(org => ({ user, org }))
        )
        )
      );

    // this.auth.externalLogout$
    //   .subscribe(async (url) => {
    //     if (url) {
    //       return Browser.open({ url, windowName: "idty" });
    //     }
    //   })
  }

  get baseURL() {
    return environment.API_ENDPOINT;
  }

  getModulesMenu() {

    return this.userOrg$.pipe(
      first(),
      switchMap(({ user, org }) => {
        return this.http.get(environment.API_ENDPOINT + '/menu', {
          params: (new HttpParams())
            .set('orgKey', org.id)
            .set('uid', user.id)
        });
      })
    );

  }

  getFunction(op: string, param: any = {}, eventParent: string = "") {
    return this.userOrg$.pipe(
      first(),
      switchMap(({ user, org }) => {
        const postParams = {
          Authentication: 'WLK-5cn-ufG-zYZ',
          op,
          orgKey: org.id,
          uid: user.id,
          lang: user.profile.lang,
          email: user.profile.email,
          source: this.plt.platforms().toString(),
          eventParent,
          params: param,
        };
        return this.http.post(environment.API_ENDPOINT + '/function', postParams);
      })
    );
  }

  getFunctionPromise(op: string, param: any = {}, eventParent: string = ""): Promise<any> {
    const postParams = {
      Authentication: 'WLK-5cn-ufG-zYZ',
      op,
      orgKey: this.userService.getOrganization().id,
      uid: this.userService.getUser().id,
      lang: this.userService.getUser().profile.lang,
      email: this.userService.getUser().profile.email,
      source: this.plt.platforms().toString(),
      eventParent,
      params: param,
    };
    return this.http.post(environment.API_ENDPOINT + '/function', postParams).toPromise();
  }

  async postWarningFuncio(url, op, param: any = {}): Promise<any> {
    const token = await this.auth.getFirebaseToken();

    const postParams = {
      token,
      op,
      orgKey: this.userService.getOrganization().id,
      uid: this.userService.getUser().id,
      name: this.userService.getUser().profile.name,
      lang: this.userService.getUser().profile.lang,
      email: this.userService.getUser().profile.email,
      source: this.plt.platforms().toString(),
      params: param
    };
    const headers = new HttpHeaders();
    headers.append("Content-Type", 'application/json');
    headers.append("Accept", 'application/json');
    return this.http.post<any>(url, postParams, { headers }).toPromise();
  }


  async postFile(op: string, param: any = {}, file) {

    const postParams = {
      op,
      orgKey: this.userService.getOrganization().id,
      uid: this.userService.getUser().id,
      lang: this.userService.getUser().profile.lang,
      email: this.userService.getUser().profile.email,
      source: this.plt.platforms().toString(),
      file: await this.toBase64(file),
      ...param
    };
    return this.http.post(environment.API_ENDPOINT + '/file', postParams).toPromise();
  }

  toBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }


  speedTest(): Promise<any> {
    const httpParams = new HttpParams();
    return this.http.get(environment.API_ENDPOINT + '/speedTest', { params: httpParams }).toPromise();
  }


  notifyPriority(message, chat, origin: string): Promise<any> {
    const messageStringified = { ...message, sent: message.sent.toDate() };
    const postParams = {
      op: "NotifyPriority",
      orgKey: this.userService.getOrganization().id,
      from: this.userService.getUser().id,
      message: JSON.stringify(messageStringified),
      messageKey: message.id,
      chat: JSON.stringify(chat),
      origin,
      fromName: this.userService.getUser().profile.name
    };
    const headers = new HttpHeaders();
    headers.append("Content-Type", 'application/json');
    headers.append("Accept", 'application/json');
    return this.http.post(environment.API_ENDPOINT + '/notify_priority', postParams, { headers }).toPromise();
  }


  getServerTime(): Promise<any> {
    const postParams = {
      orgKey: this.userService.getOrganization().id,
      uid: this.userService.getUser().id,
      clientDate: Date.now()
    };

    return this.http.post(environment.FUNCTIONS_ENDPOINT + '/api_serverTime', postParams).toPromise();
  }

  getDownloadSpeed(): Promise<any> {
    return new Promise(async (resolve, _) => {
      let down_stats: any = [];
      let up_stats: any = [];
      let down_average: any = {
        bps: 0,
        kbps: 0,
        mbps: 0
      };
      let up_average: any = {
        bps: 0,
        kbps: 0,
        mbps: 0
      };
      let repetitions = 3;
      for (let i = 0; i < repetitions; i++) {
        let startTime = new Date().getTime();
        let response: any = await this.http.get(environment.FUNCTIONS_ENDPOINT + '/api_speed').toPromise();
        let endTime = new Date().getTime();
        let duration = (endTime - startTime) / 1000;
        let fileSizeInBytes = response.fileSizeInBytes || 2048;
        let bitsLoaded = fileSizeInBytes * 8;
        let bps: any = parseFloat((bitsLoaded / duration).toFixed(2));
        let kbps: any = parseFloat((bps / 1000).toFixed(2));
        let mbps: any = parseFloat((kbps / 1000).toFixed(2));
        down_average.bps += bps;
        down_average.kbps += kbps;
        down_average.mbps += mbps;
        down_stats.push({
          bps: parseFloat(parseFloat(bps).toFixed(2)),
          kbps: parseFloat(parseFloat(kbps).toFixed(2)),
          mbps: parseFloat(parseFloat(mbps).toFixed(2)),
        })

      }
      down_average.bps = parseFloat((parseFloat("" + down_average.bps) / 3).toFixed(2));
      down_average.kbps = parseFloat((parseFloat("" + down_average.kbps) / 3).toFixed(2));
      down_average.mbps = parseFloat((parseFloat("" + down_average.mbps) / 3).toFixed(2));


      const postParams = {
        data: this.uploadData
      };
      for (let i = 0; i < repetitions; i++) {
        let startTime = new Date().getTime();
        await this.http.post(environment.FUNCTIONS_ENDPOINT + '/api_speed', { postParams }).toPromise();
        let endTime = new Date().getTime();
        let duration = (endTime - startTime) / 1000;
        let fileSizeInBytes = this.uploadData.length;
        let bitsLoaded = fileSizeInBytes * 8;
        let bps: any = parseFloat((bitsLoaded / duration).toFixed(2));
        let kbps: any = parseFloat((bps / 1000).toFixed(2));
        let mbps: any = parseFloat((kbps / 1000).toFixed(2));
        up_average.bps += bps;
        up_average.kbps += kbps;
        up_average.mbps += mbps;
        up_stats.push({
          bps: parseFloat(parseFloat(bps).toFixed(2)),
          kbps: parseFloat(parseFloat(kbps).toFixed(2)),
          mbps: parseFloat(parseFloat(mbps).toFixed(2)),
        })
      }
      up_average.bps = parseFloat((parseFloat("" + up_average.bps) / 3).toFixed(2));
      up_average.kbps = parseFloat((parseFloat("" + up_average.kbps) / 3).toFixed(2));
      up_average.mbps = parseFloat((parseFloat("" + up_average.mbps) / 3).toFixed(2));


      // console.log({ down_stats, down_average, up_stats, up_average });
      resolve({ down_stats, down_average, up_stats, up_average });
    })
  }

  getVideoToken(token): Promise<any> {
    const postParams = {
      orgKey: this.userService.getOrganization().id,
      uid: this.userService.getUser().id,
      clientDate: Date.now(),
      egea: true,
      token
    };

    return this.http.post(environment.FUNCTIONS_ENDPOINT + '/users_createToken', postParams).toPromise();
  }

  async doAdvancedSearch(filters, mode, includeDisabled) {
    let token = await getAuth().currentUser.getIdToken()
    let headers = {
      client_token: token
    };
    let params: any = {
      filters,
      orgKey: this.userService.getOrganization().id,
      uid: this.userService.getUser().id,
      mode,
      includeDisabled
    }
    if (headers) Object.keys(headers).forEach(key => (!headers[key] || headers[key] === undefined) && delete headers[key]);
    return this.http.post(environment.FUNCTIONS_ENDPOINT + '/api_users/search', params, { headers }).toPromise();
  }

  async request(app, path, type, params?, throwError = { throw: true, show: true }, canCancelReq = false): Promise<any> {
    let res;
    let endpoint;
    if(app === 'ig') endpoint = environment.IG_ENDPOINT;
    else endpoint = environment.API_ENDPOINT;

    let headers = {};
    try {
      headers = {
        'Accept-Language': this.userService.getUser().profile.lang
      };
    } catch (error) {
      headers = {
        'Accept-Language': 'ca'
      };
    }
    try {
      switch (type) {
        case 'get':
          if (params) Object.keys(params).forEach(key => (!params[key] || params[key] === undefined) && delete params[key]);
          this.currentRequest$ = this.http.get(endpoint + path, { params, headers }).pipe(first());
          break;
        case 'patch':
          this.currentRequest$ = this.http.patch(endpoint + path, params, {headers}).pipe(first());
          break;
        case 'post':
          this.currentRequest$ = this.http.post(endpoint + path, params, {headers}).pipe(first());
          break;
        case 'put':
          this.currentRequest$ = this.http.put(endpoint + path, params, {headers}).pipe(first());
          break;
        case 'delete':
          this.currentRequest$ = this.http.delete(endpoint + path, { body: params, headers}).pipe(first());
          break;
      }

      res = await new Promise((resolve, reject) => {
        if (canCancelReq) {
          this.cancelRequest();
          this.currentRequestSubs = this.currentRequest$
            .pipe(catchError(err => {
              return this.handleError(path, type, params, throwError, err);
            }))
            .subscribe(
              (data) => { resolve(data); },
              (error) => {
                return this.handleError(path, type, params, throwError, error)
              }
            );
        } else {
          this.currentRequest$
            .pipe(catchError(err => {
              return this.handleError(path, type, params, throwError, err)
            }))
            .subscribe(
              (data) => { resolve(data); },
              (error) => {
                reject(error);
              }
            );
        }
      })
    } catch (e) {
      return Promise.reject(e);

    }
    return Promise.resolve(res);
  }

  cancelRequest() {
    if (this.currentRequestSubs) this.currentRequestSubs.unsubscribe();
  }
  async handleError(path, type, params, throwError, e) { 
    throw e;
  }

}


