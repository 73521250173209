import { Component, OnInit, ViewChild, HostListener, Input } from '@angular/core';
import { Platform, ModalController } from '@ionic/angular';
import { FileService } from 'src/services/file.service';
import { TranslateService } from '@ngx-translate/core';

import { Browser } from '@capacitor/browser';
import { Share } from '@capacitor/share';


@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss'],
})
export class GalleryComponent implements OnInit {

  public slideOpts: any = {
    zoom: true,

  };

  @ViewChild('slides', { static: false }) slides;
  @Input() chatId: any;
  @Input() messageId: any;

  @HostListener('window:resize')
  onResize() {
    if (this.platform.is('ios')) {
      setTimeout(() => {
        if (this.slides) {
          this.slides.update();
        }
      }, 100);
    }
  }

  public image;

  constructor(public platform: Platform,
    public modalCtrl: ModalController,
    public tr: TranslateService,
    public fileService: FileService) {
  }

  async dismiss() {
    await this.modalCtrl.dismiss(null, null, "imageModal");
  }

  ngOnInit() {
  }

  ionViewWillEnter() {
    this.image = this.fileService.loadedImages[this.chatId][this.messageId];
    // console.log("THIS IMAGE", this.image);
    // let keys = Object.keys(this.images);
    // let i = 1;
    // console.log("msgID", this.messageId);
    // console.log("KEYS", keys);
    // keys.forEach(key => {
    //   if(key === this.messageId)
    //     this.slideOpts.initialSlide = i;
    //   i++;
    // });
    // if(this.slides)
    // this.slides.update();
  }

  async download() {
    if(!this.platform.is('capacitor')) await Browser.open({ url: this.image.url });
    else await this.fileService.showDownloadWarning(this.image.url, this.image.name, this.image.type, true )
  }

  async share(){
    let translations = [];
    await this.tr.get(['documents']).forEach(tr => translations = tr);
    let shareRet = await Share.share({
      title: this.image.name,
      text: this.image.name,
      url:  this.image.url,
      dialogTitle: translations['documents'].share
    });
  }

}
