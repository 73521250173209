import { Component, OnInit } from '@angular/core';
import {NavController, Platform} from '@ionic/angular';

@Component({
  selector: 'app-base-split-pane',
  template: ''
})
export class BaseSplitPaneLandingComponent implements OnInit {
  public MAX_MOBILE_WIDTH = 900;

  constructor(public platform: Platform,
              public navController: NavController) { }

  ngOnInit() {}

  loadList(desktopRoute, mobileRoute){
    if (this.platform.width() > this.MAX_MOBILE_WIDTH) { this.navController.navigateRoot(desktopRoute); }
    else { this.navController.navigateRoot(mobileRoute); }
  }

}
