import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { first, timeout } from 'rxjs/operators';
import { CommunicationService } from './communication.service';
import { UntypedFormControl, Validators, UntypedFormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ModulesService {

  public modules$: BehaviorSubject<any> = new BehaviorSubject(null);
  public landingPage$: BehaviorSubject<any> = new BehaviorSubject(null);
  public firstLanding$: BehaviorSubject<boolean> = new BehaviorSubject(false);


  newItem$ = new Subject<any>();
  updateItem$ = new Subject<any>();
  deleteItem$ = new Subject<any>();
  closeDetail$ = new Subject<any>();

  constructor(public communicationService: CommunicationService) { }

  setModules(modules: any) {
    this.modules$.next(modules);
  }

  setLandingPage(landingPage: any) {
    this.landingPage$.next(landingPage);
  }

  getModule(id: string) {
    const modules = this.modules$.getValue();
    return modules[id];
  }

  getModuleData(id) {
    const module = this.getModule(id);
    const params = {
      module_id: module.id
    };
    return this.communicationService
      .getFunction(module.event, params, module.event_parent)
      .pipe(first(), timeout(10000))
      .toPromise();

  }

  toFormGroup(questions: any[]) {
    let group: any = {};

    questions.forEach(question => {
      group[question.tag] = question.required ? new UntypedFormControl(question.value || '', Validators.required)
        : new UntypedFormControl(question.value || '');
    });
    return new UntypedFormGroup(group);
  }
}
