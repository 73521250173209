// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-item div.message {
  font-size: 1.6rem;
}

@media all and (min-height: 600px) and (min-width: 768px) {
  ion-modal.page-config-unavailable > .modal-wrapper {
    width: 350px;
    left: calc(50% - 175px);
    height: 450px;
    top: calc(50% - 225px);
  }
}`, "",{"version":3,"sources":["webpack://./src/app/shared/add-unavailable-rules/add-unavailable-rules.component.scss"],"names":[],"mappings":"AACM;EACE,iBAAA;AAAR;;AAKE;EAEI;IACE,YAAA;IACA,uBAAA;IAEA,aAAA;IACA,sBAAA;EAJN;AACF","sourcesContent":["    ion-item {\n      div.message {\n        font-size: 1.6rem;\n      }\n    }\n  \n  \n  @media all and (min-height: 600px) and (min-width: 768px) {\n    ion-modal.page-config-unavailable {\n      > .modal-wrapper {\n        width: 350px;\n        left: calc(50% - (350px / 2));\n  \n        height: 450px;\n        top: calc(50% - (450px / 2));\n      }\n    }\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
