import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { authCodeFlowConfig } from 'src/sso.config';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { firstValueFrom } from 'rxjs';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class AzureService {
  public userCreationInProgress = false;
  public showMicrosoftLogout = false;

  constructor(
    private oauthService: OAuthService,
    private http: HttpClient
  ) {
  }

  private readonly azureLogin = 'azure_login';
  private token;

  async initOuath() {
    if (this.checkFromLogin()) {
      await this.configSetup();
      return this.handleLogin();
    }
    return null;
  }
  
  private async handleLogin() {
    if (this.checkToken()) {
      let tokensReceived = false;
      try {
        setTimeout(() => {
          if (!tokensReceived) this.userCreationInProgress = true;
        }, 3000);
        const res = await firstValueFrom(this.http.post(environment.IG_ENDPOINT + '/ig/public/auth/azure', { token: this.token }));
        tokensReceived = true;
        this.setAzureLogin(false);
        return res;
      } catch (error) {
        console.error(error);
        this.logout(false);
      }
    }
    return null;
  }

  async login() {
    this.setAzureLogin(true);
    this.showMicrosoftLogout = false;
    this.oauthService.initCodeFlow();
  }

  logout(show = true) {
    sessionStorage.clear();
    localStorage.clear();
    this.showMicrosoftLogout = show;
    this.setAzureLogin(false);
  }

  azureLogout() {
    this.oauthService.logOut();
  }

  checkToken() {
    return this.token = this.oauthService.getAccessToken();
  }

  private async configSetup() {
    this.oauthService.configure(authCodeFlowConfig);
    this.oauthService.tokenValidationHandler = new JwksValidationHandler();
    this.oauthService.setStorage(localStorage);
    await this.oauthService.loadDiscoveryDocumentAndTryLogin();
  }

  async checkFromLogin() {
    return await LocalStorageService.getByKey(this.azureLogin);
  }

  async setAzureLogin(set) {
    if (set) await LocalStorageService.setByKey(this.azureLogin, 'true');
    else await LocalStorageService.removeByFullKey(this.azureLogin);
  }
}
