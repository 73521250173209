import { Component, OnInit, ViewChild, Input } from '@angular/core';
import Chart from 'chart.js/auto';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { Platform, AlertController } from '@ionic/angular';
import { CommunicationService } from 'src/services/communication.service';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'chart-canvas',
  templateUrl: './chart-canvas.component.html',
  styleUrls: ['./chart-canvas.component.scss'],
})
export class ChartCanvasComponent implements OnInit {

  public forceLoadBloc$ = new Subject<boolean>();
  public onDestroy$ = new Subject<void>();
  public loading: boolean = false;
  public loaded: boolean = false;
  public bloc: any;
  public module_id: any;
  public item: any;
  public selectedFilter;

  public chart;
  public chartData;
  public statistics = [{ mean: 0, min: 0, max: 0, title: '', options: {} }];
  public numberOfStats = 0;
  public tables;

  @ViewChild('chartCanvas', { static: true }) canvas;

  @Input() set chartBloc(bloc) {
    this.bloc = bloc;
    if (bloc.event_load) {
      this.forceLoadBloc$.next(true);
    }
  }

  @Input() set data(val) {
    if (val) {
      this.loaded = true;
      this.loading = false;
      this.chartData = val;
      if (this.canvas && this.chartData.chart != false) {
        this.setChartData();
      }
      // if (this.chartData && this.chartData.data) {
      //   this.chartData.unit = this.chartData.data.unit;
      // }
      this.numberOfStats = this.chartData.data.length | 1;
      if (this.chartData.statistics) {
        this.processStatistics();
      }

      if (this.chartData.table) {
        this.processTable();
      }

    }
  };

  @Input()
  set moduleId(module_id) {
    if (module_id) {
      this.module_id = module_id;
      this.forceLoadBloc$.next(true);
    }
  }

  @Input()
  set detailItem(item) {
    if (item) {
      this.item = item;
      this.forceLoadBloc$.next(true);
    }
  }

  constructor(
    public platform: Platform,
    public commService: CommunicationService,
    public tr: TranslateService,
    public alertCtrl: AlertController) {
    this.forceLoadBloc$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((val) => {
        if (!this.loaded && this.item && this.bloc && this.bloc.event_load && this.module_id) {
          this.loadBloc(this.bloc.event_load);
        }
      });
  }

  async ngOnInit() {
  }


  async loadBloc(event, filter?) {
    this.loading = true;
    if (!event) {
      this.loading = false;
      return;
    }
    try {
      let params = Object.assign({}, event.params);
      var keys = Object.keys(params);

      keys.forEach(key => {
        let paramsKeys = Object.keys(params[key]);

        if (params[key].tag) {
          let tag = params[key].tag;
          params[key] = this.getValue(tag);
        } else {
          params[key] = event.params[key];
        }
      });

      if (filter) {
        params[filter.tag] = filter.value;
        this.selectedFilter = filter;
      } else if (this.bloc.filters) {
        this.bloc.filters.forEach(filter => {
          if (filter.default) {
            params[filter.tag] = filter.value;
            this.selectedFilter = filter;
          }
        });
      }

      let op = event.op ? event.op : this.bloc.op;
      let event_parent = this.bloc.event_parent ? this.bloc.event_parent : event.event_parent;

      params.module_id = this.module_id;
      let result = await this.commService.getFunctionPromise(op, params, event_parent)
      if (result && result['data'] && result['data'].results) {
        this.chartData = this.getBlockStats(this.bloc, result['data'].results);
        if (this.canvas && this.chartData.chart != false) {
          this.setChartData();
        }

        this.numberOfStats = this.chartData.data.length | 1;
        if (this.chartData.statistics) {
          this.processStatistics();
        }

        if (this.chartData.table) {
          this.processTable();
        }

        this.loaded = true;
      } else {
        await this.showErrorAlert();
      }

      this.loading = false;

    } catch (error) {
      await this.showErrorAlert(error.message);
    }
  }


  setChartData() {
    if (this.chartData) {
      let plot_options: any = {};
      plot_options = Object.assign({}, this.chartData.plot_options);

      if (this.chartData.data.length && this.chartData.data.length >= 1) {
        let datasets = [];
        let i = 0;
        this.chartData.data.forEach(dataset => {
          datasets.push({ ...dataset, ...this.chartData.options[i] });
          if ((dataset.length > 30 || (this.selectedFilter && this.selectedFilter.tag === 'days' && this.selectedFilter.value > 60)) && this.chartData.plot_options) {
            if (this.chartData.plot_options && this.chartData.plot_options.scales && this.chartData.plot_options.scales.x && this.chartData.plot_options.scales.x.time) {
              plot_options.scales.x.time.unit = 'month';
            }
          } else {
            if (this.chartData.plot_options && this.chartData.plot_options.scales && this.chartData.plot_options.scales.x && this.chartData.plot_options.scales.x.time) {
              plot_options.scales.x.time.unit = 'day';
            }
          }
          i++;
        });
        if (this.chart) {
          this.chart.destroy();
        }
        this.chart = new Chart(this.canvas.nativeElement, {
          type: this.chartData.type,
          data: {
            labels: this.chartData.data[0].labels,
            datasets,
          },
          options: (plot_options ? plot_options : { responsive: true })
        });
      } else {
        if ((this.chartData.data.data.length > 30 || (this.selectedFilter && this.selectedFilter.tag === 'days' && this.selectedFilter.value > 60)) && this.chartData.plot_options) {
          if (this.chartData.plot_options && this.chartData.plot_options.scales && this.chartData.plot_options.scales.x && this.chartData.plot_options.scales.x.time) {
            plot_options.scales.xAxes[0].time.unit = 'month';
          }
        } else {
          if (this.chartData.plot_options && this.chartData.plot_options.scales && this.chartData.plot_options.scales.x && this.chartData.plot_options.scales.x.time) {
            plot_options.scales.x.time.unit = 'day';
          }
        }
        if (this.chart) {
          this.chart.destroy();
        }
        this.chart = new Chart(this.canvas.nativeElement, {
          type: this.chartData.type,
          data: {
            labels: this.chartData.data.labels,
            datasets: [
              {
                label: this.chartData.data.label,
                data: this.chartData.data.data,
                ...this.chartData.options
              }
            ]
          },
          options: (plot_options ? plot_options : { responsive: true })
        });
      }
    }
  }

  processStatistics() {
    this.statistics = [];
    if (this.chartData.data.length && this.chartData.data.length >= 1) {
      let i = 0;
      this.chartData.data.forEach(chart => {
        let min;
        let max;
        let mean = 0;
        chart.data.forEach(element => {
          element = parseFloat(element);
          if (!min && !max) {
            min = element;
            max = element;
          }

          if (element < min) {
            min = element
          }

          if (element > max) {
            max = element;
          }

          mean += element;
        });

        mean = mean / chart.data.length;
        let options;
        if (this.chartData.options[i]) {
          options = this.chartData.options[i];
        }
        this.statistics.push({ min, max, mean, title: chart.label, options });
        i++;
      })

    } else {
      let min;
      let max;
      let mean = 0;
      this.chartData.data.data.forEach(element => {
        element = parseFloat(element);
        if (!min && !max) {
          min = element;
          max = element;
        }

        if (element < min) {
          min = element
        }

        if (element > max) {
          max = element;
        }

        mean += element;
      });

      mean = mean / this.chartData.data.data.length;
      let options;
      if (this.chartData.options) {
        options = this.chartData.options;
      }
      this.statistics.push({ min, max, mean, title: '', options });
    }
  }

  processTable() {
    if (this.chartData.data.length && this.chartData.data.length >= 1) {
      let j = 0;
      this.tables = [];
      this.chartData.data.forEach(chart => {
        let table: any = {};
        let i = 0;
        table.rows = [];
        table.options = this.chartData.options[j];
        chart.data.forEach(element => {
          table.rows.push({ value: element, date: chart.labels[i], title: chart.label });
          i++;
        });
        table.rows.reverse();
        this.tables.push(table);
        j++;
      });

    } else {
      let i = 0;
      this.tables = [];
      let table: any = {};
      table.rows = [];
      table.options = this.chartData.options;
      this.chartData.data.data.forEach(element => {
        table.rows.push({ value: element, date: this.chartData.data.labels[i], title: '' });
        i++;
      });
      table.rows.reverse();
      this.tables.push(table);
    }
  }


  getBlockStats(bloc, results) {
    let tempStats = [];
    let block = bloc.content;
    if (!block) {
      return;
    }
    let label = block.label;

    if (block.tags) {
      let data = [];
      block.tags.forEach(tag => {
        let res;
        res = this.getValuesFromResults(tag, block.label, results);
        data.push(res);
      });
      block.data = data;
    } else {
      let data = this.getValuesFromResults(block.tag, block.label, results);
      block.data = data;
    }
    tempStats.push(block);

    let stat = { ...block };
    return stat;
  }

  getValuesFromResults(val, label, results) {
    let values = [];
    let labels = [];
    let sublabel;
    let _label;
    let unit;
    results.forEach(result => {
      let res_value = this.getValue(val, null, result); //Y axes
      let res_label = this.getValue(label, null, result); //X axes
      if (!sublabel) {
        sublabel = this.getSubLabel(val, result);
        _label = this.getLabel(val, result);
      }

      if (!res_value) {
        res_value = 0;
      }
      if (!unit) {
        unit = this.getUnit(val, result);
      }
      values.push(res_value);
      labels.push(res_label);
    });
    values.reverse();
    labels.reverse();
    return { data: values, labels, label: _label, sublabel, unit };
  }


  getValue(val: string, format?, origin?) {
    let itm;
    if (origin) {
      itm = origin;
    } else if (this.item) {
      itm = this.item;
    }

    let params = val.split('.');
    let bloc = params[0];
    let tag = params[1];
    let value = null;
    let type = '';

    if (itm[bloc]) {
      itm[bloc].forEach(field => {
        if (field['tag'] === tag) {
          value = field.value;
          type = field.type;
        }
      });
    }

    if (format && format.type && format.type != type) {
      type = format.type;
      format = format.datetime_format;
    }
    if (type && type === 'datetime') {
      let _form = format || 'DD/MM/YYYY HH:mm'
      value = moment(value).format(_form);
    }
    return value;
  }

  getLabel(val: string, origin?) {
    let itm;
    if (origin) {
      itm = origin;
    } else if (this.item) {
      itm = this.item;
    }
    let params = val.split('.');
    let bloc = params[0];
    let tag = params[1];
    let value = null;
    if (itm[bloc]) {
      itm[bloc].forEach(field => {
        if (field['tag'] === tag) {
          value = field.label;
        }
      });
    }
    return value;
  }

  getUnit(val: string, origin?) {
    let itm;
    if (origin) {
      itm = origin;
    } else if (this.item) {
      itm = this.item;
    }
    let params = val.split('.');
    let bloc = params[0];
    let tag = params[1];
    let value = null;
    if (itm[bloc]) {
      itm[bloc].forEach(field => {
        if (field['tag'] === tag) {
          value = field.unit;
        }
      });
    }
    return value;
  }

  getSubLabel(val: string, origin?) {
    let itm;
    if (origin) {
      itm = origin;
    } else if (this.item) {
      itm = this.item;
    }
    let params = val.split('.');
    let bloc = params[0];
    let tag = params[1];
    let value = null;
    if (itm[bloc]) {
      itm[bloc].forEach(field => {
        if (field['tag'] === tag) {
          value = field.sublabel;
        }
      });
    }
    return value;
  }

  filterIsSelected(filter) {
    return filter === this.selectedFilter;
  }

  async showErrorAlert(message?: string) {
    let translations = [];
    await this.tr.get(['general']).forEach(tr => translations = tr);
    let alert = await this.alertCtrl.create({
      header: 'Error',
      subHeader: message,
      buttons: [{
        text: translations['general'].ok,
        role: 'destructive'
      }]
    });
    this.loading = false;

    return alert.present();

  }

  isMobile() {
    return this.platform.is('mobile') || this.platform.is('tablet') || this.platform.is('phablet') || this.platform.is('mobileweb');
  }

  get splitView() {
    if (this.chartData && this.chartData.hide_chart === true) {
      return false;
    }
    if (this.chartData) {
      return this.chartData.table === true || this.chartData.statistics === true;
    }
    return false;
  }

  arrayOf(number): any {
    let array = [];
    for (let i = 0; i < number; i++) {
      array.push(i);
    }
    return array;
  }
}
