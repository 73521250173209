import { Injectable } from '@angular/core';
// CryptoJs
import CryptoES from 'crypto-es/'; // For AES encryption/decryption // For characters encodages types (Utf-8, latin1...)
//import { AES, enc, SHA512 } from 'crypto-js'; // For AES encryption/decryption // For characters encodages types (Utf-8, latin1...)
import { Md5 } from 'ts-md5/dist/md5';

// moment
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';


@Injectable({
  providedIn: 'root'
})
export class EncryptionService {

  constructor(public tr: TranslateService) { }

  version = 1; // version of encrypted message
  public firstUpdate = '2018-04-04'; // change on app cryptografy update

  static MD5encrypt(value: string): string {
    const md5 = new Md5();
    return md5.appendStr(value).end().toString();
  }

  static SHA512Encrypt(value: string): string {
    return CryptoES.SHA512(value).toString();
  }

  public AESdecrypt(value: string, key: string, sent?: any) {
    const dateSent = moment(sent).format('YYYY-MM-DD');
    // encrytpion change date constrain
    // if (sent && dateSent < this.firstUpdate) {
    //     return value;
    // }
    try {
      if (!value) return value;
      if (value === 'message_deleted') {
        let deleted = this.tr.instant('messages.deleted')
        return deleted;
      }
      const bytes = CryptoES.AES.decrypt(value.toString(), key);
      if (!bytes || bytes.toString(CryptoES.enc.Utf8) === '' || !bytes.toString(CryptoES.enc.Utf8)) {
        return value;
      }
      return bytes.toString(CryptoES.enc.Utf8);
    } catch (error) {
      return value;
    }
  }



  static AESencrypt(msg: string, key: string) {
    const today = moment().format('YYYY-MM-DD');
    if (!msg || msg === '') return msg;
    return CryptoES.AES.encrypt(msg, key).toString();
  }

  getFirstUpdate() {
    return this.firstUpdate;
  }


}
