// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-col {
  text-align: center;
}

ion-card {
  margin: 0px 0px;
  --background: white;
}

.divider {
  --background: var(--ion-color-primary);
  color: white;
  justify-content: center;
}

.table-content-desktop {
  overflow-y: scroll;
  max-height: 30rem;
}

.table-content-mobile {
  overflow-y: scroll;
  max-height: 15rem;
}

.label .label-md {
  margin-top: 0px;
  margin-bottom: 0px;
}

.stripped {
  background-color: var(--ion-color-light);
}

.loader {
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.filter-button {
  height: 1.4rem;
  font-size: 0.9rem;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/chart-canvas/chart-canvas.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;;AAEA;EACE,eAAA;EACA,mBAAA;AACF;;AAEA;EACE,sCAAA;EACA,YAAA;EACA,uBAAA;AACF;;AAEA;EACE,kBAAA;EACA,iBAAA;AACF;;AACA;EACE,kBAAA;EACA,iBAAA;AAEF;;AACA;EACE,eAAA;EACA,kBAAA;AAEF;;AACA;EACE,wCAAA;AAEF;;AACA;EACE,uBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AAEF;;AACA;EACE,cAAA;EACA,iBAAA;AAEF","sourcesContent":["ion-col {\n  text-align: center;\n}\n\nion-card {\n  margin: 0px 0px;\n  --background: white;\n}\n\n.divider {\n  --background: var(--ion-color-primary);\n  color: white;\n  justify-content: center;\n}\n\n.table-content-desktop {\n  overflow-y: scroll;\n  max-height: 30rem;\n}\n.table-content-mobile {\n  overflow-y: scroll;\n  max-height: 15rem;\n}\n\n.label .label-md {\n  margin-top: 0px;\n  margin-bottom: 0px;\n}\n\n.stripped {\n  background-color: var(--ion-color-light);\n}\n\n.loader {\n  background-color: white;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.filter-button {\n  height: 1.4rem;\n  font-size: 0.9rem;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
