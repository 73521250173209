import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { PipesModule } from '../pipes/pipes.module';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { ReactionIconsComponent } from './reaction-icons/reaction-icons.component';

@NgModule({
  declarations: [
      ReactionIconsComponent
  ],
  imports: [
    FormsModule,
    CommonModule,
    IonicModule,
    TranslateModule,
    RouterModule,
    ReactiveFormsModule,
    PipesModule,
    MatTooltipModule,
    PickerModule,
    // VisitsComponentsModule
  ],
  exports: [
    ReactionIconsComponent
  ]
})
export class ReactionsModule { }
