import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';
import { ChatService } from 'src/services/chat.service';
import { UserService } from 'src/services/user.service';

@Component({
  selector: 'app-reaction-icons',
  templateUrl: './reaction-icons.component.html',
  styleUrls: ['./reaction-icons.component.scss']
})
export class ReactionIconsComponent implements OnInit {

  @Input() message;
  @Input() chat;
  @Input() origin;
  @Output() dismiss?: EventEmitter<any> = new EventEmitter();
  @Input() iconSize?;
  lastReaction;
  emojiPopoverOpen = false;
  
  constructor(
    public chatService: ChatService,
    public userService: UserService,
    public popoverCtrl: PopoverController
  ) { }

  ngOnInit() {
    this.lastReaction = this.message?.reactions?.users[this.userService.getUser().id]?.reaccion;
  }

  addReaction(emoji: string) {
    this.dismiss.emit();
    this.popoverCtrl.dismiss();
    this.chatService.addReaction(this.message, this.chat, this.origin, emoji);
  }

  addReactionEmojiMart(emoji){
    this.emojiPopoverOpen = false;
    this.addReaction(emoji);
    this.popoverCtrl.dismiss();
  }
}
