import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'wr-custom-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent  implements OnInit {
  @Input() item;
  @Input() sessionBar = false;
  
  constructor() { }

  ngOnInit() {
    console.log("PRBAR", this.item)
  }

}
