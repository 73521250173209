import { NgModule } from '@angular/core';
import { SafeHtmlPipe } from './safe-html/safe-html.pipe';
import { SafeBase64Pipe } from './safe-url/safe-base64.pipe';
import { SafeUrlPipe } from './safe-url/safe-url.pipe';
import { DateTextPipe } from './date-text/date-text.pipe';
import { PhoneTextPipe } from './phone-text/phone-text.pipe';
import { SplitEmailPipe } from './split-email/split-email.pipe';
import { UbaEmailFormatPipe } from './uba-email-format/uba-email-format.pipe';
import { HoursMinutesPipe } from './hours-minutes/hours-minutes.pipe';
import { HoursDiferencePipe } from './hours-diference/hours-diference.pipe';
import { CustomCapitalizePipe } from './custom-capitalize/custom-capitalize.pipe';

@NgModule({
    declarations: [SafeHtmlPipe, SafeUrlPipe, SafeBase64Pipe, DateTextPipe, PhoneTextPipe, SplitEmailPipe, UbaEmailFormatPipe, HoursMinutesPipe, HoursDiferencePipe, CustomCapitalizePipe],
    imports: [],
    exports: [SafeHtmlPipe, SafeUrlPipe, SafeBase64Pipe, DateTextPipe, PhoneTextPipe, SplitEmailPipe, UbaEmailFormatPipe, HoursMinutesPipe, HoursDiferencePipe, CustomCapitalizePipe],
})

export class PipesModule { }