import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import intlTelInput, { Iti } from 'intl-tel-input';

@Component({
  selector: 'app-phone-input',
  templateUrl: './phone-input.component.html',
  styleUrls: ['./phone-input.component.scss'],
})
export class PhoneInputComponent  implements OnInit {

  @Input() value: string;
  @Output() valueChange = new EventEmitter<string>();

  public telInput;

  constructor() { }

  ngOnInit() {
    this.init();
  }

  async emit(num) {    
    this.valueChange.emit(num);
  }

  async init() {
    const inputElement = <HTMLInputElement>document.getElementById('phone');

    if (inputElement) {
      this.telInput = intlTelInput(inputElement, {
        initialCountry: 'ES',
        separateDialCode: true,
        utilsScript: 'node_modules/intl-tel-input/build/js/utils.js'
      });;
      
      this.telInput.getNumber();
    }
  }

}
