// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scanner-buttons {
  margin: 0px;
  position: absolute;
  bottom: 0px;
  width: 100vw;
  height: 50px;
  z-index: 11;
}

.scan-box {
  border: 2px solid #fff;
  box-shadow: 0 0 0 100vmax rgba(0, 0, 0, 0.5);
  content: "";
  display: block;
  left: 50%;
  height: 300px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
}

ion-content::part(background) {
  background-color: transparent;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/barcode-scanner/barcode-scanner.component.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;EACA,YAAA;EACA,WAAA;AACJ;;AAEA;EACI,sBAAA;EACA,4CAAA;EACA,WAAA;EACA,cAAA;EACA,SAAA;EACA,aAAA;EACA,kBAAA;EACA,QAAA;EACA,gCAAA;EACA,YAAA;AACJ;;AAEA;EACI,6BAAA;AACJ","sourcesContent":[".scanner-buttons {\n    margin: 0px;\n    position: absolute;\n    bottom: 0px;\n    width: 100vw;\n    height: 50px;\n    z-index: 11;\n}\n\n.scan-box {\n    border: 2px solid #fff;\n    box-shadow: 0 0 0 100vmax rgba(0, 0, 0, 0.5);\n    content: \"\";\n    display: block;\n    left: 50%;\n    height: 300px;\n    position: absolute;\n    top: 50%;\n    transform: translate(-50%, -50%);\n    width: 300px;\n}\n\nion-content::part(background) {\n    background-color: transparent;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
