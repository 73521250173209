import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {
  public auth: boolean;

  constructor(public authenticationService: AuthService) {
    this.authenticationService.getAuthenticationState().subscribe(val => this.auth = val);

  }

  canActivate(): boolean {
    return this.auth;
  }
}
