// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader {
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loader.bottom {
  background-color: transparent;
}

.html-container {
  overflow: auto;
}

ion-card-header {
  padding: 0.5rem;
}
ion-card-header ion-label {
  margin: 0px;
}

img {
  width: auto;
}

table {
  margin-bottom: 2rem;
}
table th,
table td {
  border: 1px solid #ddd;
}

ion-card {
  --background: white;
}

ion-card-content {
  color: black;
  padding-top: 13px;
}

.custom-card-heaeder {
  padding: 0px;
}

.custom-avatar {
  margin-right: 8px;
}

.action-button {
  margin-left: 10px;
}

.title {
  display: inline-block;
}

.dropdown-icon {
  float: right;
}

.CC09 {
  font-size: 9pt;
}

.CC10 {
  font-size: 10pt;
}

.CC11 {
  font-size: 11pt;
}

.CC12 {
  font-size: 12pt;
}

.CC16 {
  font-size: 16pt;
}

.CCb {
  font-weight: bold;
}

.CCul {
  text-decoration: underline;
}

.CClt {
  text-decoration: line-through;
}

.CCit {
  font-style: italic;
}

.CCjus {
  text-align: justify;
}

.CCmarj {
  margin-top: 9px;
}

.CCbot {
  vertical-align: bottom;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/custom-html/custom-html.component.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AACF;AAAE;EACE,6BAAA;AAEJ;;AAEA;EACE,cAAA;AACF;;AAEA;EACI,eAAA;AACJ;AAAI;EACI,WAAA;AAER;;AAEA;EACE,WAAA;AACF;;AAEA;EACE,mBAAA;AACF;AAAE;;EAEE,sBAAA;AAEJ;;AAEA;EACE,mBAAA;AACF;;AAEA;EACI,YAAA;EACA,iBAAA;AACJ;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,iBAAA;AACF;;AAEA;EACE,iBAAA;AACF;;AAEA;EACE,qBAAA;AACF;;AACA;EACE,YAAA;AAEF;;AACA;EACE,cAAA;AAEF;;AAAA;EACE,eAAA;AAGF;;AADA;EACE,eAAA;AAIF;;AAFA;EACE,eAAA;AAKF;;AAHA;EACE,eAAA;AAMF;;AAJA;EACE,iBAAA;AAOF;;AALA;EACE,0BAAA;AAQF;;AANA;EACE,6BAAA;AASF;;AAPA;EACE,kBAAA;AAUF;;AARA;EACE,mBAAA;AAWF;;AATA;EACE,eAAA;AAYF;;AAVA;EACE,sBAAA;AAaF","sourcesContent":[".loader {\n  background-color: white;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  &.bottom {\n    background-color: transparent;\n  }\n}\n\n.html-container {\n  overflow: auto;\n}\n\nion-card-header{\n    padding: 0.5rem;\n    ion-label{\n        margin: 0px;\n    }\n}\n\nimg {\n  width: auto;\n}\n\ntable {\n  margin-bottom: 2rem;\n  th,\n  td {\n    border: 1px solid #ddd;\n  }\n}\n\nion-card {\n  --background: white;\n}\n\nion-card-content{\n    color: black;\n    padding-top: 13px;\n}\n\n.custom-card-heaeder {\n  padding: 0px;\n}\n\n.custom-avatar {\n  margin-right: 8px;\n}\n\n.action-button {\n  margin-left: 10px;\n}\n\n.title {\n  display: inline-block;\n}\n.dropdown-icon {\n  float: right;\n}\n\n.CC09 {\n  font-size: 9pt;\n}\n.CC10 {\n  font-size: 10pt;\n}\n.CC11 {\n  font-size: 11pt;\n}\n.CC12 {\n  font-size: 12pt;\n}\n.CC16 {\n  font-size: 16pt;\n}\n.CCb {\n  font-weight: bold;\n}\n.CCul {\n  text-decoration: underline;\n}\n.CClt {\n  text-decoration: line-through;\n}\n.CCit {\n  font-style: italic;\n}\n.CCjus {\n  text-align: justify;\n}\n.CCmarj {\n  margin-top: 9px;\n}\n.CCbot {\n  vertical-align: bottom;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
