import { Injectable } from '@angular/core';
import { BehaviorSubject, of, combineLatest } from 'rxjs';
import { BadgeService } from './badge.service';
import { distinctUntilChanged, filter, map, switchMap, pluck, catchError, first, tap, takeUntil } from 'rxjs/operators';
import { UserService } from './user.service';
import { ModulesService } from './modules.service';
import { CommunicationService } from './communication.service';
import { AuthService } from './auth.service';
import { WindowService } from './window.service';
import { environment } from 'src/environments/environment';
import { Platform } from '@ionic/angular';
import { DeviceService } from './device.service';


@Injectable({
  providedIn: 'root'
})
export class MenuService {

  patientsModuleActive = false;

  public org$ = this.userService.org$.pipe(
    filter(org => org),
    distinctUntilChanged((a, b) => a.id === b.id && JSON.stringify(a) === JSON.stringify(b))
  );


  // Menu items based on apps and modules from backend. every time user rereshes menuItems$ emits new values
  public menuItems$ = new BehaviorSubject([]);

  // Fixed menu items, they are always there
  public fixedMenuItems = [
    {
      label: 'tabs.status',
      icon: 'time',
      path: '/status'
    },
    {
      label: 'help.title',
      icon: 'help-circle',
      path: '/help',
      badge: this.badgeService.helpBatch$.pipe(distinctUntilChanged())
    },
    {
      label: 'Logout',
      icon: 'log-out',
      command: 'logout'
    }
  ];

  // Chats item
  public chatMenuItem = {
    label: 'Chats',
    icon: 'chatbubbles',
    path: '/chat_module',
    badge: this.userService.org$.pipe(
      filter(org => org),
      distinctUntilChanged((a, b) => a.id === b.id),
      switchMap(
        org => this.badgeService.internalBatch$.pipe(
          filter(x => x),
          pluck(org.id),
          distinctUntilChanged()
        )
      )
    )
  };

  // Patients item
  public patientMenuItem = {
    label: 'menu.patients_chat',
    icon: 'heart',
    path: '/patients',
    badge: this.userService.org$.pipe(
      filter(org => org),
      distinctUntilChanged((a, b) => a.id === b.id),
      switchMap(
        org => this.badgeService.patientsBatch$.pipe(
          filter(x => x),
          pluck(org.id),
          distinctUntilChanged()
        )
      )
    )
  };

  // Chat and patient menu items depending on organization and user configuration
  public chatMenuItems$ = this.userService.orgUserInfo$.pipe(
    filter(x => x),
    map(orgInfo => {
      const org = this.userService.getOrganization();
      const items = [];
      if (org
        && org.modules
        && ((!this.userService.getUser().profile.patient && org.modules.chat === true)
          || org.modules.patients === true && this.userService.getUser().profile.patient
        )
      ) {
        items.push(this.chatMenuItem);
      }
      if (
        (
          !this.userService.getUser()
          || !this.userService.getUser().patient
          || this.userService.getUser().profile.patient !== true
        )
        && org
        && org.modules
        && org.modules.patients
        && orgInfo.modules
        && orgInfo.modules.patients
      ) {
        this.patientsModuleActive = true;
        items.push(this.patientMenuItem);
      }
      return items;
    }),
  );

  public visitsBadge$ = this.userService.org$.pipe(
    filter(org => org),
    distinctUntilChanged((a, b) => a.id === b.id),
    switchMap(
      org => this.badgeService.visitsBatch$.pipe(
        filter(x => x),
        pluck(org.id),
        distinctUntilChanged()
      )
    )
  );

  // custom-visits && visits are visible if user.orgInfo.$orgkey.modules.visits === true (a) and organization.modules.visits === true (b)
  // custom-visits are visible if user.orgInfo.$orgkey.modules.visits === true and organization.params.visits.custom === true && organization.params.visits.visibility === 'all' (c)
  public visitsVisible$ = combineLatest([
    this.org$.pipe(
      filter(x => x && x.modules),
      map(x => x && x.modules && x.modules.visits)
    ),
    this.userService.orgUserInfo$.pipe(
      map(x => x && x.modules && x.modules.visits)
    ),
    this.org$.pipe(
      filter(x => x && x.modules),
      map(x => x && x.modules && x.modules.visits && x.params && x.params.visits && x.params.visits.custom && x.params.visits.visibility === 'all')
    ),
  ]).pipe(
    map(([a, b, c]) => a && b || c)
  );

  public patientsDashboardVisible$ = combineLatest([
    this.org$.pipe(
      filter(x => x && x.modules),
      map(x => x && x.modules && x.modules.patientsDashboard)
    ),
    this.userService.orgUserInfo$.pipe(
      map(x => x && x.modules && x.modules.patientsDashboard)
    ),
    this.org$.pipe(
      filter(x => x && x.modules),
      map(x => x && x.modules && x.modules.patientsDashboard && x.params && x.params.patientsDashboard && x.params.patientsDashboard.visibility === 'all')
    ),
  ]).pipe(
    map(([a, b, c]) => a && b || c)
  );

  // usersDirectory if org.modules.usersDirectory === true
  // public usersDirectoryVisible$ = combineLatest([
  //   this.org$.pipe(
  //     filter(x => x && x.modules),
  //     map(x => x && x.modules && x.modules.usersDirectory)
  //   ),
  //   this.userService.user$.pipe(
  //     filter(x => x && x.profile),
  //     map(x => x && x.profile && !x.profile.patient && !x.profile.external)
  //   )
  // ]).pipe(
  //   map(([a, b]) => a && b)
  // );

  // workdayRegister if org.modules.workdayRegister === true
  // public workdayRegisterVisible$ = combineLatest([
  //   this.org$.pipe(
  //     filter(x => x && x.modules),
  //     map(x => x && x.modules && x.modules.workdayRegister)
  //   )
  // ]).pipe(
  //   map(([a]) => a)
  // );

  // securecomm are visible if user.orgInfo.$orgkey.modules.secureCom === true and organization.modules.secureCom === true
  // public secureComVisible$ = combineLatest([
  //   this.org$.pipe(
  //     filter(x => x && x.modules),
  //     map(x => x && x.modules && x.modules.secureCom)
  //   ),
  //   this.userService.orgUserInfo$.pipe(
  //     map(x => x && x.modules && x.modules.secureCom)
  //   )
  // ]).pipe(
  //   map(([a, b]) => a && b)
  // );

  // public secureComBadge$ = this.userService.org$.pipe(
  //   filter(org => org),
  //   distinctUntilChanged((a, b) => a.id === b.id),
  //   switchMap(
  //     org => this.badgeService.secureComBadge$.pipe(
  //       filter(x => x),
  //       pluck(org.id),
  //       distinctUntilChanged()
  //     )
  //   )
  // );

  // publicform are visible if user.orgInfo.$orgkey.modules.uploadCom === true and organization.modules.uploadCom === true
  // public uploadComVisible$ = combineLatest([
  //   this.org$.pipe(
  //     filter(x => x && x.modules),
  //     map(x => x && x.modules && x.modules.uploadCom)
  //   ),
  //   this.userService.orgUserInfo$.pipe(
  //     map(x => x && x.modules && x.modules.uploadCom)
  //   )
  // ]).pipe(
  //   map(([a, b]) => a && b)
  // );

  // public uploadComBadge$ = this.userService.org$.pipe(
  //   filter(org => org),
  //   distinctUntilChanged((a, b) => a.id === b.id),
  //   switchMap(
  //     org => this.badgeService.uploadComBadge$.pipe(
  //       filter(x => x),
  //       pluck(org.id),
  //       distinctUntilChanged()
  //     )
  //   )
  // );

  // publicform are visible if user.orgInfo.$orgkey.modules.publicForm === true and organization.modules.publicForm === true
  // public publicFormVisible$ = combineLatest([
  //   this.org$.pipe(
  //     filter(x => x && x.modules),
  //     map(x => x && x.modules && x.modules.publicForm)
  //   ),
  //   this.userService.orgUserInfo$.pipe(
  //     map(x => x && x.modules && x.modules.publicForm)
  //   )
  // ]).pipe(
  //   map(([a, b]) => a && b)
  // );

  // public publicFormBadge$ = this.userService.org$.pipe(
  //   filter(org => org),
  //   distinctUntilChanged((a, b) => a.id === b.id),
  //   switchMap(
  //     org => this.badgeService.publicFormBadge$.pipe(
  //       filter(x => x),
  //       pluck(org.id),
  //       distinctUntilChanged()
  //     )
  //   )
  // );

  // same as visits
  // public homeMenuVisible$ = combineLatest([
  //   this.org$.pipe(
  //     filter(x => x && x.modules),
  //     map(x => x && x.modules && x.modules.homeMenu)
  //   ),
  //   this.userService.user$.pipe(
  //     map(x => x && x.profile && x.profile.homeMenu)
  //   )
  // ]).pipe(

  //   map(([a, b]) => a && b)
  // );

  public loadingModules = false;
  public first = true;
  public menuError = false;

  constructor(
    public badgeService: BadgeService,
    public modulesService: ModulesService,
    public communicationService: CommunicationService,
    public authService: AuthService,
    public windowService: WindowService,
    public plt: Platform,
    public ds: DeviceService,
    public userService: UserService
  ) {

    this.authService.isAuthenticated$.pipe(
      distinctUntilChanged(),
      filter(x => x),
      switchMap(() => this.org$.pipe(
        filter(org => org),
        distinctUntilChanged((a, b) => a.id === b.id)
      ))
    ).subscribe((x) => {
      this.loadingModules = false;
      this.first = true;
      this.menuError = false;
      this.menuItems$.next([]);
      if (x) this.loadModules(x)

    });

    // this.authService.onSignOut$.subscribe(() => this.clear());
  }


  async loadModules(org?, forceLanding = true) {
    console.log("@VERSION", this.ds?.info?.appVersion);
    this.loadingModules = true;
    this.modulesService.setModules(null);
    this.modulesService.setLandingPage(null);
    if (!org) {
      org = this.userService.getOrganization();
    }
    this.menuItems$.next([]);
    if (forceLanding) this.modulesService.setLandingPage("none");
    const apps: any = await this.communicationService.getModulesMenu().pipe(
      // If error is caught apps is considered empty
      first(),
      catchError(() => of({}))
    ).toPromise();
    if (!apps || !apps.data) {
      this.menuError = true;
    }
    if (apps.data) {
      const modules = [];
      /**
       * Build menu from app modules
       */
      const menuModules = [];
      let i = 0;
      
      for (const app of apps.data.apps) {
        let comparisonResult = this.compareVersions(app.max_version, environment.VERSION);

        if (!app.max_version || app.max_version && comparisonResult) { //Mostrem antics mis domicilios si la versió del dispositiu és menor o igual a la versió maxima dels domicilis antics
          let menuApp: any = { label: app.title };
          if (app.modules) {
            if (app.role === 'child') {
              app.modules.forEach((child: any) => {
                menuApp = { label: app.title };
                const module: any = { ...child, app: i, app_id: app.id };
                if (module.id) {
                  modules[module.id] = module;
                }
                menuApp.icon = module.icon;
                menuApp.label = module.title;
                menuApp.badge = this.moduleObservable(module.id);
                if (module.view === 'external_view_browser') {
                  menuApp.inAppBrowser = true;
                  menuApp.id = module.id;
                } else {
                  menuApp.path = (module.view === 'external_view_iframe') ? `view-iframe/${module.id}` : `module-navigation/${module.id}`;
                }
                menuModules.push(menuApp);
                ++i;
              });
            } else {
              menuApp.children = [];
              for (let module of app.modules) {
                module = { ...module, app: i, app_id: app.id };
                if (module.id) {
                  modules[module.id] = module;
                }
                menuApp.badge = this.appObservable(i);
                if (module.view === 'external_view_browser') {
                  menuApp.children.push({
                    icon: module.icon,
                    label: module.title,
                    inAppBrowser: true,
                    id: module.id,
                    badge: this.moduleObservable(module.id),
                  });
                } else {
                  menuApp.children.push({
                    icon: module.icon,
                    label: module.title,
                    path: (module.view === 'external_view_iframe') ? `view-iframe/${module.id}` : `module-navigation/${module.id}`,
                    badge: this.moduleObservable(module.id),
                  });
                }
              }
              menuModules.push(menuApp);
              ++i;
            }
          }
        }
      }
      const menuItems = [...menuModules];
      /**
       * Set landing menu and modules data in modules service
       */
      if (forceLanding) this.modulesService.setLandingPage(apps.data.home);


      this.modulesService.setModules(modules);
      this.menuItems$.next(menuItems);
      if (this.windowService.module) this.modulesService.setLandingPage({ id: this.windowService.module });

    } else {
      if (forceLanding) this.modulesService.setLandingPage('none');
    }

    if (this.first) {
      this.first = false;
      this.modulesService.firstLanding$.next(true);
    }
    this.loadingModules = false;
  }


  public moduleObservable(id) {
    return this.org$.pipe(
      switchMap(
        org => this.badgeService.modulesBatch$.pipe(
          filter(x => x),
          pluck(org.id, id),
          distinctUntilChanged()
        )
      )
    );
  }

  compareVersions(app_version, env_version) {
    if (!app_version || !env_version) return true;

    const appVersionParts = app_version.split('.').map(Number);
    const futureVersionParts = env_version.split('.').map(Number);
    const maxLength = Math.max(appVersionParts.length, futureVersionParts.length);

    for (let i = 0; i < maxLength; i++) {
      const appPart = appVersionParts[i] || 0;
      const futurePart = futureVersionParts[i] || 0;
      if (appPart < futurePart) {
        return false; // app_version is less than env_version
      } else if (appPart > futurePart) {
        return true; // app_version is greater than env_version
      }
    }

    return true; // app_version is equal to env_version
  }


  public appObservable(id) {
    return this.org$.pipe(
      switchMap(
        org => this.badgeService.appsBatch$.pipe(
          filter(x => x),
          pluck(org.id, id),
          distinctUntilChanged()
        )
      )
    );
  }

}
