import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ModalController, ToastController, Platform, AlertController } from '@ionic/angular';
import { VideoService } from 'src/services/video.service';
import { UserService } from 'src/services/user.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { AudioService } from 'src/services/audio.service';
import { NetworkService } from 'src/services/network.service';
import { Clipboard } from '@capacitor/clipboard';

const qvgaConstraints = {
  video: { width: { exact: 100 }, height: { exact: 100 } }
};

const vgaConstraints = {
  video: { width: { exact: 640 }, height: { exact: 480 } }
};
const hdConstraints = {
  video: { width: { exact: 1280 }, height: { exact: 720 } }
};

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss'],
})
export class VideoComponent implements OnInit, OnDestroy {

  // Component inputs
  @Input() join: any;
  @Input() room: any;
  @Input() roomMode = false;
  @Input() receiverId: any;
  @Input() contactName: any;
  @Input() contactPicture: any;
  @Input() chat: any;
  @Input() visit: any;
  @Input() visitId: any;

  @Input() origin: any;
  @Input() callMode: any = false;
  @Input() testMode: any = false;
  @Input() setting: any = false;
  @Input() external: any = false;

  // Video HTML elements
  video1;
  video2;
  video3
  voiceDisabled = true;
  videoDisabled = true;

  // WebRTC elements
  localStream;
  remoteStream;
  callerCandidates$;
  calleeCandidates$;
  localTrack;

  // Room parameters
  roomSnap;
  roomId;
  status;
  showId = false;
  selectedScreen = 1;
  videoEnabled = true;
  callee$;

  // Resize and Position elements
  videoHeader;
  videoDiv;
  pos1 = 0;
  pos2 = 0;
  pos3 = 0;
  pos4 = 0;
  fullScreen = true;

  // Settings
  step = 0;
  messages = [];

  // Video/Audio sources
  videoCameras;
  audioIN;
  audioOUT;
  selectedCamera;
  selectedAudioIN;
  selectedAudioOUT;
  showOptions = false;

  // Others
  toast;
  pictureInP = false;
  pipAvailable = true;
  onDestroy$ = new Subject<void>();
  forcedDismiss = false;
  hideCallingStatus = false;
  lastResult;

  constructor(
    public modalCtrl: ModalController,
    public userService: UserService,
    public vs: VideoService,
    public tr: TranslateService,
    public toastCtrl: ToastController,
    public plt: Platform,
    public alertCtrl: AlertController,
    public network: NetworkService,
    public audioService: AudioService) {

  }

  async ngOnInit() {
    this.network.speedTest()
    if (this.plt.is('capacitor')) this.videoHeader = document.getElementById("divHeaderCapacitor");
    else this.videoHeader = document.getElementById("mydivheader");

    this.videoDiv = document.getElementsByClassName('videoFrame')[0];

    if ('pictureInPictureEnabled' in document && !this.plt.is('ios')) {
      this.pipAvailable = true;
    } else {
      this.pipAvailable = false;
    }

    if (this.capacitorOrMW)
      this.external = true;


    if (this.videoHeader) {
      if (this.plt.is('capacitor')) this.videoHeader.ontouchstart = event => { this.dragMouseDown(event) };
      else this.videoHeader.onmousedown = event => { this.dragMouseDown(event) };
    } else {
      if (this.plt.is('capacitor')) this.videoDiv.ontouchstart = event => { this.dragMouseDown(event) };
      else this.videoDiv.onmousedown = event => { this.dragMouseDown(event) };
    }

    this.roomId = "";
    await this.checkMediaDevices();
    await this.showMyFace();

    if (this.join && this.room) this.joinRoom(this.room);

    this.vs.hangUp$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(async (val) => {
        if (val) {
          await this.hangUp(false);
          await this.showMyFace();
        }
      })

    if (this.visit && this.userService.getUser() && this.userService.getUser().profile && this.userService.getUser().profile.patient) {
      this.vs.updateStartVideoSetting(this.visit);
    }
    if (this.visitId) {
      let visitSnap = await this.vs.getVisit(this.visitId);
      if (!this.visit) this.visit = { id: visitSnap.id, ...visitSnap.data() }
    }
  }


  nextStep() {
    this.step++;
  }

  async checkMediaDevices(force = false) {
    try {
      await navigator.mediaDevices.getUserMedia({ audio: true });
      this.voiceDisabled = false;
    } catch (e) {
      this.voiceDisabled = true;
      if (e && e.message === "Permission denied") {
        this.presentToast(this.tr.instant('errors.voiceDisabled'), 8000);
      }
    }
    try {
      if (!this.callMode) {
        await navigator.mediaDevices.getUserMedia({ video: true });
        this.videoDisabled = false;
      }
    } catch (e) {
      this.videoDisabled = true;
      if (e && e.message === "Permission denied") {
        this.presentToast(this.tr.instant('errors.videoDisabled'), 8000);
      }
    }
    if (this.voiceDisabled) this.messages.unshift(this.tr.instant('errors.voiceDisabled'));
    else this.messages.unshift(this.tr.instant('video.voice') + ' OK');
    if (this.videoDisabled && !this.callMode) this.messages.unshift(this.tr.instant('errors.videoDisabled'));
    else if (!this.callMode) this.messages.unshift(this.tr.instant('video.video') + ' OK');

    if (this.visit && this.userService.getUser() && this.userService.getUser().profile && this.userService.getUser().profile.patient) {
      this.vs.updatePatientVideoSettings(this.visit, { videoEnabled: !this.videoDisabled, voiceEnabled: !this.voiceDisabled });
    }
    if (force) this.showMyFace();
  }

  async showMyFace() {

    this.video1 = document.getElementById("video1");
    if (this.video1) {
      this.video1.volume = 0;
      this.video1.muted = 0;
    }

    this.video2 = document.getElementById("video2");
    this.switchScreen(1);
    await this.updateStreams(true);
  }

  async updateStreams(initial = true, force = false) {
    try {
      this.localStream = await navigator.mediaDevices
        .getUserMedia(
          {
            audio: this.voiceDisabled ? false : true,
            //TODO: (this.capacitorOrMW ? true : { deviceId: this.selectedAudioIN.deviceId ? { exact: this.selectedAudioIN.deviceId } : undefined }),
            video: this.videoDisabled ? false :
              (this.callMode ? false : true)
            //TODO: (this.capacitorOrMW ? true : { deviceId: this.selectedCamera.deviceId ? { exact: this.selectedCamera.deviceId } : undefined }))
          });
    } catch (e) {
      if (e && e.message === "Permission denied") {
        this.messages.unshift(e.message);
      }
    }

    this.setLocalStream();
    // TODO: if (!force) this.checkMediaDevices();
    if (initial) this.setRemoteStream();
    // console.log("this.localStream", eam)
    return;
  }

  setLocalStream() {
    try {
      this.video1.srcObject = this.localStream;
    } catch (error) {
      this.video1.src = window.URL.createObjectURL(this.localStream);
    }
  }

  setRemoteStream() {
    this.remoteStream = new MediaStream();
    if (!this.video2) return;
    try {
      this.video2.srcObject = this.remoteStream;
    } catch (e) {
      this.video2.src = window.URL.createObjectURL(this.remoteStream);
    }
  }

  switchVideo() {
    this.videoDisabled = !this.videoDisabled;
    this.updateStreams(false, true);
  }

  async dismiss() {
    this.roomId = null;
    this.onDestroy$.next();
    this.forcedDismiss = true;
    await this.modalCtrl.dismiss(true, null, 'videoModal');
    return;
  }


  async callAction() {

    if (this.status === 'calling') {
      await this.hangUp(false);
      return;
    }

    // 1. Start PeerConnection
    this.vs.startPC();
    this.vs.registerPeerConnectionListeners();
    // 2. Get Local Tracks
    this.localStream.getTracks().forEach(track => {
      this.localTrack = this.vs.pc1Local.addTrack(track, this.localStream);
    });
    // 3. Create Room
    await this.vs.createRoom(this.receiverId, this.contactName, this.chat ? this.chat : this.visit, this.origin, this.callMode);
    // 4. Collect ICE candidates
    this.vs.collectIceCandidates(this.vs.roomRef, this.vs.pc1Local, 'callerCandidates', 'calleeCandidates');
    // 5. Create Room Offer
    await this.vs.createRoomOffer();
    // 6. Listen to new track events
    if (this.vs.pc1Local.addTrack != undefined) {
      this.vs.pc1Local.ontrack = event => { this.gotRemoteStream1(event) };
    } else {
      this.vs.pc1Local.onaddstream = event => { this.gotRemoteStream1(event) };
    }
    this.audioService.playRingTone();
    // 7. Listening for SDP remote session description && room
    if (this.vs.room$) this.vs.room$;
    this.vs.room$ = this.vs.roomRef
      .onSnapshot(async snapshot => {
        const data = snapshot.data();
        if (!this.vs.pc1Local.currentRemoteDescription && data && data.answer) {
          // console.log('Got remote description: ', data.answer);
          const rtcSessionDescription = new RTCSessionDescription(data.answer);
          await this.vs.pc1Local.setRemoteDescription(rtcSessionDescription);
        }
        this.vs.updateRoomUsers(data.users);
        if (data.status === 'finished') {
          this.audioService.stopRingTone(false);
          this.vs.hangUp$.next(true);
          await this.hangUp();
        } else if (data.status === 'answered') {
          this.audioService.stopRingTone(true);
          this.presentToast(this.contactName + this.tr.instant('video.joined'), 2000);
          setTimeout(() => { this.hideCallingStatus = true }, 3000)
        }
        this.status = data.status;

      });
    // 8. Listen for remote ICE candidates 
    if (this.callee$) this.callee$();
    this.callee$ = this.vs.roomRef
      .collection('calleeCandidates')
      .onSnapshot(snapshot => {
        snapshot.docChanges().forEach(async change => {
          if (change.type === 'added') {
            let data = change.doc.data();
            // console.log(`Got new remote ICE candidate:`, data);
            await this.vs.pc1Local.addIceCandidate(new RTCIceCandidate(data));
            // 9. GET ROOM STATS
            let stats = await this.vs.pc1Local.getStats(null);
            stats.forEach(stat => {
              if (stat.type === "candidate-pair") {
                let candidate = stats.get(stat.localCandidateId);
                if (candidate.candidateType == "relayed") {
                  // console.log("USING TURN SERVER: " + candidate.ipAddress);
                }
              }
            });
          }
        });
      });


    this.status = 'calling';
    if (this.roomMode) {
      this.showId = true;
      setTimeout(() => { this.showId = false }, 3000);
    }
  }

  async joinRoom(message) {
    try {
      this.status = 'answered';
      if (message && message.call && message.call.id) {
        this.roomId = message.call.id;
        let res = await this.vs.getRoomSnap(this.roomId);
        this.roomSnap = { id: res.id, ...res.data() };
        this.contactName = this.roomSnap.callerName;
      }

      if (this.roomId) await this.vs.setRoomRef(this.roomId, this.roomMode);
      else await this.vs.setRoomRef(this.roomSnap.id, this.roomMode);

      // 1. Start PeerConnection
      this.vs.startPC();
      this.vs.registerPeerConnectionListeners();
      // 2. Get Local Tracks
      this.localStream.getTracks().forEach((track: MediaStreamTrack) => {
        this.vs.pc1Local.addTrack(track, this.localStream);
      });
      // 3. collecting ICE candidates
      this.vs.collectIceCandidates(this.vs.roomRef, this.vs.pc1Local, 'calleeCandidates', 'calleeCandidates');
      // 4. Listen to new track events
      if (this.vs.pc1Local.addTrack != undefined) {
        this.vs.pc1Local.ontrack = event => { this.gotRemoteStream1(event); }
      } else {
        this.vs.pc1Local.onaddstream = event => { this.gotRemoteStream1(event); }
      }
      // 5. Code for creating SDP answer below
      await this.vs.joinRoom(this.roomSnap);
      // 6. Listening for remote ICE candidates
      if (this.callee$) this.callee$();
      this.vs.roomRef.collection('callerCandidates')
        .onSnapshot(snapshot => {
          snapshot.docChanges().forEach(async change => {
            if (change.type === 'added') {
              let data = change.doc.data();
              // console.log(`Got new remote ICE candidate: ${JSON.stringify(data)}`);
              await this.vs.pc1Local.addIceCandidate(new RTCIceCandidate(data));
            }
          });
        });

      // 7. Listen for call finished event
      if (this.vs.room$) this.vs.room$();
      this.vs.room$ = this.vs.roomRef
        .onSnapshot(async updates => {
          const data = updates.data();
          this.vs.updateRoomUsers(data.users);
          if (data.status === 'finished') {
            this.vs.hangUp$.next(true);
            this.presentToast(this.callMode ? this.tr.instant('video.call_finished') : this.tr.instant('video.video_finished'), 3000);
            await this.hangUp();
          }
          this.status = data.status;
        });
      this.status = 'answered';
      setTimeout(() => { this.hideCallingStatus = true }, 3000)
      // 8. Get caller picture
      let other = await this.userService.getUserSnapById(this.roomSnap.caller);
      if (other && other.data() && other.data().profile) this.contactPicture = other.data().profile.picture;
    } catch (e) {
      console.log("JOIN ERROR", e);
      this.audioService.stopRingTone(true);
    }
  }

  async pictureInPicture() {
    try {
      await this.video2.requestPictureInPicture();
      this.video2.autoPictureInPicture = true;
    } catch (e) {
    }
  }

  async hangUp(dismiss = false) {
    if (dismiss && (this.status === 'calling' || this.status === 'answered')) {
      let alert = await this.alertCtrl.create({
        header: this.tr.instant('video.close_title'),
        message: this.tr.instant('video.close_message'),
        buttons: [
          {
            text: this.tr.instant('general.yes'),
            handler: () => {
              this._hangUp(dismiss)
            }
          },
          {
            text: this.tr.instant('general.no'),
            role: 'cancel',
            handler: () => {
            }
          }
        ]
      });
      await alert.present();

    } else this._hangUp(dismiss)
  }

  async _hangUp(dismiss) {
    this.status = "";
    try {
      let tracks = this.video1 && this.video1.srcObject ? this.video1.srcObject.getTracks() : [];
      // if (!dismiss) {
      for (let i = 0; i < tracks.length; i++) {
        await tracks[i].stop();
      };
      if (this.video1) {
        this.video1.srcObject = null;
        if (this.localStream) {
          tracks = this.localStream.getTracks()
          for (let i = 0; i < tracks.length; i++) {
            await tracks[i].stop();
          }
        }
      }
      // }

      // if(!dismiss && !this.receiverId) this.receiverId = this.roomSnap.caller;

      if (this.remoteStream) {
        tracks = this.remoteStream.getTracks()
        for (let i = 0; i < tracks.length; i++) {
          await tracks[i].stop();
        }
      }
      this.audioService.stopRingTone(false);
      if (this.vs.pc1Local) this.vs.pc1Local.close();

      if (this.callerCandidates$) await this.callerCandidates$();
      if (this.calleeCandidates$) await this.calleeCandidates$();

      await this.vs.hangUp();
      this.roomId = "";
      this.join = null;
      this.room = null;
      // if (!dismiss) await this.showMyFace();
      if (this.vs.room$) await this.vs.room$();
      if (this.vs.roomOther$) await this.vs.roomOther$();
      if (this.callee$) this.callee$();
      // if (dismiss) 
      return this.dismiss();
      // else return Promise.resolve();
    } catch (e) {
      console.log("HANG UP ERROR", e);
      if (dismiss) this.dismiss();
      await this.vs.hangUp();
    }
  }

  gotRemoteStream1(e) {
    this.video2.srcObject = e.streams[0];
    // console.log('pc1: received remote stream', e);
    // console.log('pc1:  remote streams', e.streams);
    e.streams[0].getTracks().forEach(track => {
      this.switchScreen(0);
      this.remoteStream.addTrack(track);
    });

    // this.remoteStream = e.streams[0];
  }

  handleCandidate(candidate, dest, prefix, type) {
    dest.addIceCandidate(candidate);
    // console.log(`${prefix}New ${type} ICE candidate: ${candidate ? candidate.candidate : '(null)'}`);
  }

  async presentBetaAlert() {
    let alert = await this.alertCtrl.create({
      header: this.tr.instant('video.beta'),
      message: this.tr.instant('video.beta_warning'),
      buttons: [
        {
          text: this.tr.instant('general.ok'),
          role: 'cancel',
          handler: () => {
          }
        }
      ]
    });
    await alert.present();
  }

  async callExternal() {
    let params: any = {}
    if (this.visit) {
      params = {
        'visit': this.visit,
        'receiverId': this.receiverId,
        'contactName': this.contactName,
        'origin': this.origin,
        'callMode': this.callMode
      }
    } else if (this.chat && this.origin === 'chat') {
      params = {
        'receiverId': this.receiverId,
        'contactName': this.contactName,
        'chat': this.chat,
        'origin': this.origin,
        'callMode': this.callMode
      }
      if (this.contactPicture) params.contactPicture = this.contactPicture;
    }
    await this.vs.startRemoteCall(params);
    this.dismiss();
  }
  //TELEMEDICINE
  async markAsReady() {
    await this.vs.updatePatientVideoSettings(this.visit, { ready: true });
    this.visit.call.patient.ready = true;
  }
  /**
   * CONFIG METHODS
   */
  async changeResolution(res = 0) {
    let resolution = qvgaConstraints.video;
    if (res === 1) resolution = vgaConstraints.video;
    if (res === 2) resolution = hdConstraints.video;
    let stream : any = await navigator.mediaDevices.getUserMedia({ audio: true, video: resolution })
    this.localStream = stream;
    try {
      this.video1.srcObject = stream;
    } catch (e) {
      this.video1.src = window.URL.createObjectURL(stream);

    }

    this.vs.pc1Local.removeTrack(this.localTrack);

    const track = this.localStream.getTracks()[0];
    track.applyConstraints({ audio: true, video: resolution });

    this.localStream.getTracks().forEach(track => {
      this.vs.pc1Local.addTrack(track, this.localStream);
    });
  }


  switchScreen(val?) {
    if (val || val === 0) this.selectedScreen = val;
    else this.selectedScreen = val;
  }

  async copyClipboard(msg) {
    await Clipboard.write({ string: msg });
    this.presentToast(this.tr.instant('general.copied'));
  }

  public async presentToast(msg: string, duration = 2000) {
    if (this.toast) {
      await this.toast.dismiss();
    }
    this.toast = await this.toastCtrl.create({
      message: msg,
      duration: duration,
      position: 'top',
      buttons: [
        {
          text: 'ok',
          role: 'cancel',
          handler: () => {
          }
        }
      ]
    });
    await this.toast.present();
  }

  preparingCall() {
    return this.receiverId && this.status !== 'calling' && this.status != 'answered';
  }

  async ngOnDestroy() {
    this.roomId = null;
    if (!this.forcedDismiss && this.video1 && this.video1.srcObject) {
      let tracks = this.video1.srcObject.getTracks();
      // if (!dismiss) {
      for (let i = 0; i < tracks.length; i++) {
        await tracks[i].stop();
      };
      this.video1.srcObject = null;

      if (this.localStream) {
        tracks = this.localStream.getTracks()
        for (let i = 0; i < tracks.length; i++) {
          await tracks[i].stop();
        }
      }
    }
    this.onDestroy$.next();
  }


  dragMouseDown(e) {
    e = e || window.event;
    e.preventDefault();
    // get the mouse cursor position at startup:
    this.pos3 = e.clientX;
    this.pos4 = e.clientY;
    if (this.capacitorOrMW) document.ontouchend = event => { this.closeDragElement() };
    else document.onmouseup = event => { this.closeDragElement() };

    // call a function whenever the cursor moves:
    if (this.capacitorOrMW) document.ontouchmove = event => { this.elementDrag(event) };
    else document.onmousemove = event => { this.elementDrag(event) };
  }

  elementDrag(e) {
    e = e || window.event;
    e.preventDefault();
    // calculate the new cursor position:
    this.pos1 = this.pos3 - (this.capacitorOrMW ? e.touches[0].clientX : e.clientX);
    this.pos2 = this.pos4 - (this.capacitorOrMW ? e.touches[0].clientY : e.clientY);
    this.pos3 = (this.capacitorOrMW ? e.touches[0].clientX : e.clientX);
    this.pos4 = (this.capacitorOrMW ? e.touches[0].clientY : e.clientY);
    // set the element's new position:

    let top = this.videoDiv.offsetTop - this.pos2 > 0 ? this.videoDiv.offsetTop - this.pos2 : 0;
    let left = this.videoDiv.offsetLeft - this.pos1 > 0 ? this.videoDiv.offsetLeft - this.pos1 : 0;

    this.videoDiv.style.top = top + "px";
    this.videoDiv.style.left = left + "px";

  }

  closeDragElement() {
    /* stop moving when mouse button is released:*/
    if (this.capacitorOrMW) {
      document.ontouchend = null;
      document.ontouchmove = null;
    } else {
      document.onmouseup = null;
      document.onmousemove = null;
    }

  }

  resizeWindow() {
    let width = '100%';
    let height = '100%';
    if (this.capacitorOrMW) {
      width = '100%';
      height = '100%';
    }

    if (this.fullScreen) {
      width = '45%';
      height = '45%';
    } else {
      this.videoDiv.style.top = '0px';
      this.videoDiv.style.left = '0px';
    }
    this.videoDiv.style.width = width;
    this.videoDiv.style.height = height;
    this.fullScreen = !this.fullScreen;
  }

  async getConnectedDevices(type) {
    const devices = await navigator.mediaDevices.enumerateDevices();
    return devices.filter(device => device.kind === type)
  }

  changeDevice(event, type) {
    if (!event || !event.target || !event.target.value) return;

    let value = event.target.value;
    switch (type) {
      case 'video':
        this.videoCameras.forEach(elem => {
          if (elem.deviceId === value) this.selectedCamera = elem;
        });
        break;
      case 'audioIN':
        this.audioIN.forEach(elem => {
          if (elem.deviceId === value) this.selectedAudioIN = elem;
        });
        break;
      case 'audioOUT':
        this.audioOUT.forEach(elem => {
          if (elem.deviceId === value) this.selectedAudioOUT = elem;
        });
        break;
    }
    if (type === 'audioOUT') this.attachSinkId(this.video1, this.selectedAudioOUT.deviceId);
    else this.updateStreams(false);
  }


  // Attach audio output device to video element using device/sink ID.
  attachSinkId(element, sinkId) {
    if (typeof element.sinkId !== 'undefined') {
      element.setSinkId(sinkId)
        .then(() => {
          // console.log(`Success, audio output device attached: ${sinkId}`);
        })
        .catch(error => {
          let errorMessage = error;
          if (error.name === 'SecurityError') {
            errorMessage = `You need to use HTTPS for selecting audio output device: ${error}`;
          }
          console.error(errorMessage);
          // Jump back to first output device in the list as it's the default.

        });
    } else {
      console.warn('Browser does not support output device selection.');
    }
  }

  showChat() {
    this.vs.chatIsOpened = !this.vs.chatIsOpened;
    this.vs.toggleChat$.next({ open: this.vs.chatIsOpened, visit: this.visit });
  }

  get capacitorOrMW() {
    return this.plt.is('capacitor') || this.plt.is('android') || this.plt.is('ios') ? true : false;
  }

  measureBandwidthOnThefly() {
    // query getStats every second
    window.setInterval(() => {
      if (!this.vs.pc1Local) {
        return;
      }
      const sender = this.vs.pc1Local.getSenders()[0];
      const receivers = this.vs.pc1Local.getReceivers()[0];

      if (!sender) {
        return;
      }
      sender.getStats().then(res => {
        res.forEach(report => {
          let bytes;
          let headerBytes;
          let packets;
          if (report.type === 'outbound-rtp') {
            if (report.isRemote) {
              return;
            }
            const now = report.timestamp;
            bytes = report.bytesSent;
            headerBytes = report.headerBytesSent;

            packets = report.packetsSent;
            if (this.lastResult && this.lastResult.has(report.id)) {
              // calculate bitrate
              const bitrate = 8 * (bytes - this.lastResult.get(report.id).bytesSent) /
                (now - this.lastResult.get(report.id).timestamp);
              const headerrate = 8 * (headerBytes - this.lastResult.get(report.id).headerBytesSent) /
                (now - this.lastResult.get(report.id).timestamp);
              // console.log("bitrate", bitrate + ' kbps')
            }
          }
        });
        this.lastResult = res;
      });
    }, 1000);
  }
}
