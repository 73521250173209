import { Injectable } from '@angular/core';

import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import 'moment/locale/es';
import 'moment/locale/ca';

@Injectable({
  providedIn: 'root'
})
export class TimeService {


  constructor(public tr: TranslateService) {
    this.tr.onLangChange.subscribe((lang: any) => {
      moment().locale(lang ? lang.lang : 'ca');
    })
  }

  newDate(hours: number, minutes: number): any {
    let date = new Date(2017, 1, 1, hours, minutes, 0);
    return moment(date).format("HH:mm");
  }

  getTime(date) {
    return moment(date).format("HH:mm");
  }

  timestampToDate(date) {
    return moment(date).format("dd/MM/yyyy HH:mm");
  }

  now() {
    return moment.now();
  }

  getDayAndHour(): any {
    return moment().format('DD/MM/YYYY, HH:mm:ss');
  }


  isDifferentDate(message, messages, index): boolean {
    if (index === 0) return;
    let previousMsg = messages[index - 1];
    if (!message || !message.sent || !previousMsg || !previousMsg.sent) return false;
    let date1 = message.sent.toDate();
    let date2 = previousMsg.sent.toDate();
    if (!date1 || !date2) return false;

    return true;
  }

  getDate(timestamp: number, { timeForToday = false }: { timeForToday?: boolean } = {}): string {
    const now = moment();
    const time = moment(timestamp);
    let tomorrow = moment(now);
    let yesterday = moment(now);

    if (now.isSame(time, 'day')) {
      return timeForToday
        ? time.format('HH:mm')
        : this.tr.instant('dates.today');
    }

    if (yesterday.subtract(1, 'day').isSame(time, 'day')) {
      return this.tr.instant('dates.yesterday');
    }

    if (tomorrow.add(1, 'day').isSame(time, 'day')) {
      return this.tr.instant('dates.tomorrow');
    }

    return time.format('DD/MM/YYYY');
  }

  getDateAndTime(timestamp: number): string {
    const now = moment();
    const time = moment(timestamp);

    if (now.isSame(time, 'day')) {
      return this.tr.instant('dates.today') + ', ' + time.format('HH:mm');
    }

    if (now.subtract(1, 'day').isSame(time, 'day')) {
      return this.tr.instant('dates.yesterday') + ', ' + time.format('HH:mm');
    }

    return time.format('DD/MM/YYYY HH:mm');
  }

  formatTimestamp(timestamp: any) {
    const time = moment(timestamp).format("DD-MM-YYYY HH:mm:ss");
    return time;
  }
  getDayAndHourCompactString(): any {
    return moment().format('DDMMYYYYHHmmss');
  }

  isSameDate(timestamp1: number, timestamp2: number) {
    return moment(timestamp1).isSame(moment(timestamp2), 'day');
  }

  getDateToday(ms?): any {
    if (ms) return moment().format();
    return moment().format('YYYY-MM-DD');
  }

  getDateForms(timestamp: number, { timeForToday = false }: { timeForToday?: boolean } = {}): string {
    const now = moment();
    const time = moment(timestamp);
    if (now.isSame(time, 'day')) {
      return timeForToday
        ? time.format('LT')
        : this.tr.instant('dates.today') + ', ' + time.format('DD/MM/YYYY');
    }

    if (now.subtract(1, 'day').isSame(time, 'day')) {
      return this.tr.instant('dates.yesterday') + ', ' + time.format('DD/MM/YYYY');
    }

    return time.format('DD/MM/YYYY');
  }

  getDateText(timestamp, now) {
    let time = moment(parseInt("" + timestamp));
    now = moment(parseInt("" + now));
    let tomorrow = moment(now);
    let yesterday = moment(now);

    if (now.isSame(time, 'day')) {
      return this.tr.instant('dates.today') + ', ' + time.format('DD/MM/YYYY');
    }

    if (yesterday.subtract(1, 'day').isSame(time, 'day')) {
      return this.tr.instant('dates.yesterday') + ', ' + time.format('DD/MM/YYYY');
    }

    if (tomorrow.add(1, 'day').isSame(time, 'day')) {
      return this.tr.instant('dates.tomorrow') + ', ' + time.format('DD/MM/YYYY');
    }

    return time.format('DD/MM/YYYY');
  }

  dbToViewDateFormat(date, format?) {
    format = format ? format : 'DD/MM/YYYY'
    return moment(date, 'YYYY/MM/DD').format(format);
  }

  dbToViewDateTimeFormat(date, format?) {
    format = format ? format : 'DD/MM/YY HH:mm'
    return moment(date, 'YYYY/MM/DD HH:mm').format(format);
  }

  formatViewDateToDBTime(date, format) {
    return moment(date, 'DD/MM/YYYY').format(format);
  }


}
