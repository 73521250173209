// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.center-loader { /* OLD - iOS 6-, Safari 3.1-6, BB7 */ /* TWEENER - IE 10 */ /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex; /* NEW, Spec - Firefox, Chrome, Opera */
  justify-content: center;
  align-items: center;
}

.connection-status {
  position: absolute;
  top: 0.9rem;
  left: 1.1rem;
  margin-left: 1.7rem;
}
.connection-status.padding-top {
  top: 1.5rem;
}

ion-checkbox {
  margin-right: 1rem !important;
}

h3 {
  font-weight: bold;
}

.users-to-add {
  max-height: 15rem;
  overflow-y: scroll;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/contact-selector/contact-selector.component.scss"],"names":[],"mappings":"AAAA,iBACI,oCAAA,EACA,oBAAA,EACA,sCAAA;EACA,aAAA,EAAA,uCAAA;EAEA,uBAAA;EACA,mBAAA;AAAJ;;AAIA;EACE,kBAAA;EACA,WAAA;EACA,YAAA;EACA,mBAAA;AADF;AAEE;EACE,WAAA;AAAJ;;AAIA;EACE,6BAAA;AADF;;AAIA;EACE,iBAAA;AADF;;AAIA;EACE,iBAAA;EACA,kBAAA;AADF","sourcesContent":[".center-loader{\n    display: -webkit-box;  /* OLD - iOS 6-, Safari 3.1-6, BB7 */\n    display: -ms-flexbox;  /* TWEENER - IE 10 */\n    display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */\n    display: flex;         /* NEW, Spec - Firefox, Chrome, Opera */\n    \n    justify-content: center;\n    align-items: center;\n  }\n\n\n.connection-status{\n  position: absolute;\n  top: 0.9rem;\n  left: 1.1rem;\n  margin-left: 1.7rem;\n  &.padding-top{\n    top: 1.5rem;\n  }\n}\n\nion-checkbox{\n  margin-right: 1rem !important;\n}\n\nh3{\n  font-weight: bold;\n}\n\n.users-to-add{\n  max-height: 15rem;\n  overflow-y: scroll;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
