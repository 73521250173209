// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.clicable {
  cursor: pointer;
}

.right {
  float: right;
}

.left {
  float: left;
}

.divider-header {
  color: #000;
}
.divider-header .notes-icon {
  margin-right: 5px;
}

ion-card-header {
  font-weight: 500;
  font-size: 14px;
}

.loader {
  --background: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.note-content {
  -webkit-user-select: text !important;
          user-select: text !important;
  white-space: pre-line !important;
}

ion-note {
  font-size: 1.4rem;
  align-self: flex-start;
  margin-top: 14px;
}

ion-col.name {
  padding-left: 0 !important;
}

ion-list {
  overflow-y: scroll;
  max-height: 50rem;
}

.no-margin {
  margin-top: 0px;
  margin-bottom: 0px;
}

.bottom-lines {
  border-bottom: 1px solid var(--ion-color-lightGray) !important;
}

.header-container {
  text-align: center;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.header-center {
  display: inline-block;
  margin: 0 auto;
}

.header-left {
  float: left;
}

.header-right {
  float: right;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/notes/notes.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;AACF;;AACA;EACE,YAAA;AAEF;;AAAA;EACE,WAAA;AAGF;;AAAA;EACE,WAAA;AAGF;AADE;EACE,iBAAA;AAGJ;;AACA;EACE,gBAAA;EACA,eAAA;AAEF;;AACA;EACE,mBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AAEF;;AACA;EACE,oCAAA;UAAA,4BAAA;EACA,gCAAA;AAEF;;AACA;EACE,iBAAA;EACA,sBAAA;EACA,gBAAA;AAEF;;AACA;EACE,0BAAA;AAEF;;AACA;EACE,kBAAA;EACA,iBAAA;AAEF;;AACA;EACE,eAAA;EACA,kBAAA;AAEF;;AACA;EACE,8DAAA;AAEF;;AACA;EACE,kBAAA;EAGA,aAAA;EAMA,mBAAA;EACA,mBAAA;AAAF;;AAEA;EACE,qBAAA;EACA,cAAA;AACF;;AACA;EACE,WAAA;AAEF;;AAAA;EACE,YAAA;AAGF","sourcesContent":[".clicable {\n  cursor: pointer;\n}\n.right {\n  float: right;\n}\n.left {\n  float: left;\n}\n\n.divider-header {\n  color: #000;\n\n  .notes-icon {\n    margin-right: 5px;\n  }\n}\n\nion-card-header {\n  font-weight: 500;\n  font-size: 14px;\n}\n\n.loader {\n  --background: white;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.note-content {\n  user-select: text !important;\n  white-space: pre-line !important;\n}\n\nion-note {\n  font-size: 1.4rem;\n  align-self: flex-start;\n  margin-top: 14px;\n}\n\nion-col.name {\n  padding-left: 0 !important;\n}\n\nion-list {\n  overflow-y: scroll;\n  max-height: 50rem;\n}\n\n.no-margin {\n  margin-top: 0px;\n  margin-bottom: 0px;\n}\n\n.bottom-lines {\n  border-bottom: 1px solid var(--ion-color-lightGray) !important;\n}\n\n.header-container {\n  text-align: center;\n  display: -ms-flexbox;\n  display: -webkit-flex;\n  display: flex;\n\n  -ms-flex-align: center;\n  -webkit-align-items: center;\n  -webkit-box-align: center;\n\n  align-items: center;\n  margin-bottom: 1rem;\n}\n.header-center {\n  display: inline-block;\n  margin: 0 auto;\n}\n.header-left {\n  float: left;\n}\n.header-right {\n  float: right;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
