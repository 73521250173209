import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-edit-user-profile',
  templateUrl: './edit-user-profile.component.html',
  styleUrls: ['./edit-user-profile.component.scss'],
})
export class EditUserProfileComponent implements OnInit {
  fieldName;
  header;
  value;
  filter;
  newValue;
  currentLang;

  constructor(public modalCtrl: ModalController, public tr: TranslateService) { }

  ngOnInit() {
    this.newValue = this.value; 
    this.currentLang = this.tr.currentLang;
  }

  change(){
    this.modalCtrl.dismiss(this.newValue, 'save');
  }
  dismiss(){
    this.modalCtrl.dismiss();
  }
}
