import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Subject, fromEvent } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'pin-code',
  templateUrl: './pin.component.html',
  styleUrls: ['./pin.component.scss'],
})
export class PinComponent implements OnInit, OnDestroy {

  pin: any[] = [false, false, false, false];
  current_pos = 0;
  shake: boolean = false;

  public _onDestroy$ = new Subject<void>();

  @Input() public showFingerprint: boolean;
  @Input() public help_text: string;
  @Output() public onFingerprint: EventEmitter<any> = new EventEmitter();
  @Output() public onPinCompleted: EventEmitter<string> = new EventEmitter();
  @Output() public onHelpTextClicked: EventEmitter<any> = new EventEmitter();
  @Input() public disabled: boolean = false;

  keyboardPressed: any = {};

  constructor() {

    fromEvent(document, 'keyup')
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((e: any) => {
        if (e.key === ' ') {
          return;
        }
        if (!isNaN(e.key) || e.key === 'Backspace') {
          this.keyboardPressed[e.key] = false;
          if (!isNaN(e.key)) this.type(e.key);
          else this.back();
        }

      });
    fromEvent(document, 'keydown')
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((e: any) => {
        if (!isNaN(e.key) || e.key === 'Backspace') {
          this.keyboardPressed[e.key] = true;
        }
      });
  }

  ngOnInit() { }

  public clear() {
    this.pin = [false, false, false, false];
    this.current_pos = 0;
  }

  public errorShake() {
    this.shake = true;
    setTimeout(() => { this.shake = false; }, 500);
  }

  showHelpText(help_text) {
    this.onHelpTextClicked.emit(help_text);
  }

  type(n: number) {
    if (this.current_pos < 4) {
      this.pin[this.current_pos] = n;
      ++this.current_pos;
      if (this.current_pos === 4) {
        let pin = this.pin.reduce((previousValue, currentValue) => previousValue + currentValue, "");
        this.onPinCompleted.emit(pin);
      }
    }
  }

  back() {
    if (this.current_pos > 0) {
      this.pin[this.current_pos - 1] = false;
      --this.current_pos;
    }
  }

  fingerprint() {
    this.onFingerprint.emit();
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
  }

}
