// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-icon {
  font-size: 1em !important;
}

.status-delivered,
.status-seen {
  position: relative;
  left: -0.15em;
}

.status-seen {
  color: var(--ion-color-primary);
}`, "",{"version":3,"sources":["webpack://./src/app/shared/receipt-status/receipt-status.component.scss"],"names":[],"mappings":"AAEA;EACE,yBAAA;AADF;;AAIA;;EAEE,kBAAA;EACA,aAAA;AADF;;AAIA;EACE,+BAAA;AADF","sourcesContent":["// display: inline-block;\n// width: 0.6em;\nion-icon {\n  font-size: 1em !important;\n}\n\n.status-delivered,\n.status-seen {\n  position: relative;\n  left: -0.15em;\n}\n\n.status-seen {\n  color: var(--ion-color-primary);\n  // color: #FFB35B;\n  // color: #09d261;\n  // color: #4fc3f7;\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
