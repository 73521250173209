import { Injectable } from '@angular/core';
import { ReplaySubject, Subject, first, switchMap, tap } from 'rxjs';
import { WindowService } from './window.service';
import { DocumentSnapshot, writeBatch } from 'firebase/firestore';
import { FirebaseService } from './firebase.service';
import { AzureService } from './azure.service';
import { idToken, user } from 'rxfire/auth';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { NavController, Platform } from '@ionic/angular';
import { signInWithCustomToken } from 'firebase/auth';
import { LoadingService } from './loading.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public isAuthenticated$: ReplaySubject<boolean> = new ReplaySubject(1);
  public batchBeforeSignOut = writeBatch(this.fs.firestore);
  public onSignOut$: Subject<any> = new Subject();
  public openVideoModal$ = new ReplaySubject<any>(1);

  public privacyAccepted = false;
  public backendToken = '';
  public firebase_token;
  public ignorePin = false;
  public videoWindow = false;

  constructor(
    private windowService: WindowService,
    private fs: FirebaseService,
    private azure: AzureService,
    private http: HttpClient,
    private platform: Platform,
    private ls: LoadingService,
    private navCtrl: NavController
  ) {
  }

  async init() {
    //Salta pantalla /login quan hi ha token
    if (!(await this.azure.checkFromLogin())) this.navCtrl.navigateRoot('blank');
    DocumentSnapshot
    const params: { [k: string]: any } = {};

    // params.withToken = this.platform.getQueryParam('withToken') || '';
    // params.lms = this.platform.getQueryParam('lms') || '';
    params.videoParams = this.platform.getQueryParam('videoParams') || '';

    if (this.getAuthenticationState() && params.videoParams){
      this.videoWindow = true;
      this.openVideoModal$.next(params.videoParams);
    }

    this.windowService.forceSignOut$
      .subscribe((val) => {
        if (val) this.logout();
      });

    user(this.fs.auth).subscribe((user) => {
      if (user) {
        console.log("ALREADY AUTH");
        this.isAuthenticated$.next(true);
        this.windowService.sendMessageToParentWindow({ type: 'auth-signin' });
        this.batchBeforeSignOut = writeBatch(this.fs.firestore);
      } else {
        console.log("NOT AUTHENTICATED");
        if (this.windowService.minerva) {
          this.windowService.sendMessageToParentWindow({ type: 'auth-signout' });
        }
        this.azureLogin();
      }
    });
  }

  async azureLogin() {
    this.ls.loading$.next(true);
    console.log("AZURE LOGIN");
    try {
      let initTokens = await this.azure.initOuath();
      if (initTokens) 
        {
          if (initTokens['fbToken']) await this.singInWithCustomToken(initTokens['fbToken']);
          if (initTokens['token']) this.backendToken = initTokens['token'];
        }
      else this.navCtrl.navigateRoot('login');
    } catch (error) {
      console.error(error);
    }
    this.ls.loading$.next(false);
  }

  async singInWithCustomToken(token) {
    console.log("LOGIN WITH CUSTOM TOKEN");
    // if (!this.platform.is('capacitor') && this.windowService.minerva) await this.auth.setPersistence('session');
    // if (!this.platform.is('capacitor') || this.platform.is('mobileweb') || this.windowService.minerva) {
    //   this.fs.auth.setPersistence(browserSessionPersistence);
    // }
    // this.fs.auth.setPersistence(browserLocalPersistence);
    return signInWithCustomToken(this.fs.auth, token)
      .catch((err) => {
        console.log("Error", err);
        throw err;
      })
      .then(() => {
        this.isAuthenticated$.next(true);
      });
  }

  /**
   * Log out from firebase
   * Log out from azure if logged
   */
  public async logout() {
    this.ls.presentLoading();
    await this.clearTokens();
    this.privacyAccepted = false;
    await this.windowService.updateWindowTitle(0);
    try{
      await Promise.all([this.batchBeforeSignOut.commit()]);
    }catch(e){}
    await this.windowService.sendMessageToParentWindow({ type: 'auth-signout' });

    await this.fs.auth.signOut();
    this.azure.logout();

    console.log("IS_AUTH NEXT FALSE 2")
    this.isAuthenticated$.next(false);
    this.onSignOut$.next(true);
    this.ls.dismissLoading();
  }

  /**
   * Get if authenticated
   * @returns bool
   */
  getAuthenticationState() {
    return this.isAuthenticated$;
  }

  /**
   * Get api token
   * @returns String
   */
  getToken() {
    return this.backendToken;
  }

  async clearTokens() {
    this.backendToken = '';
  }

  /**
   * Get firebase token
   * @returns string
   */
  getFirebaseToken() {
    return idToken(this.fs.auth).pipe(first()).toPromise();
  }

  /**
   * Refresh firebase token
   * @returns string
   */
  refreshAccessToken() {  
    this.firebase_token = idToken(this.fs.auth).pipe(first());
    return this.firebase_token
      .pipe(
        switchMap((token) => {
          return this.http.post(environment.API_ENDPOINT + '/login', { token });
        }),
        tap((resp: any) => {
          const token = resp.token;
          this.backendToken = token;
        })
      );
  }

  /**
   * Returns firebase user
   * @returns User
   */
  getUser() {
    return user(this.fs.auth);
  }
}
