import {Injectable} from '@angular/core';
import { Preferences } from '@capacitor/preferences';

@Injectable()
export class LocalStorageService {

  constructor() {
    this.ready = Promise.resolve();
  }
  public ready: Promise<any>;

  static async getByKey(key: string): Promise<any> {
    const obj = await Preferences.get({key});
    return JSON.parse(obj.value);
  }

  static setByKey(key: string, value: any): Promise<any> {
    return Preferences.set({key, value: JSON.stringify(value)});
  }

  static async get(orgKey: string, uid: string, key: string): Promise<any> {
    const obj = await Preferences.get({key: LocalStorageService._generateKey(orgKey, uid, key)});
    if (!obj) { throw new Error('Item not found'); }
    return await(JSON.parse(obj.value));

  }

  static set(orgKey: string | null, uid: string | null, key: string, value: any): Promise<any> {
    return Preferences.set({key: LocalStorageService._generateKey(orgKey, uid, key), value: JSON.stringify(value)});
  }

  static removeByFullKey(fullKey: string): Promise<any> {
    return Preferences.remove({key: fullKey});
  }

  public static _generateKey(orgKey: string | null, uid: string | null, key: string): string {
    const keyParts: string[] = [];

    if (orgKey !== null) {
      keyParts.push(`o:${orgKey}`);
    }

    if (uid !== null) {
      keyParts.push(`u:${uid}`);
    }

    keyParts.push(`k:${key}`);

    return keyParts.join('|');
  }

  public static _isKeyMatch(
      fullKey: string,
      orgKey: string | null,
      uid: string | null,
      key: string | null = null,
      prefixed = false
  ): boolean {
    const isMatch = {
      orgKey: orgKey === null,
      uid: uid === null,
      key: key === null,
    };

    const keyParts = fullKey.split('|');

    keyParts.forEach(keyPart => {
      if (/^o:/.test(keyPart)) {
        isMatch.orgKey = (orgKey === null) || (keyPart === `o:${orgKey}`);
      }

      if (/^u:/.test(keyPart)) {
        isMatch.uid = (uid === null) || (keyPart === `u:${uid}`);
      }

      if (/^k:/.test(keyPart)) {
        isMatch.key = (key === null) || (prefixed ? (keyPart.indexOf(`k:${key}`) === 0) : (keyPart === `k:${key}`));
      }
    });

    return isMatch.orgKey && isMatch.uid && isMatch.key;
  }

  static remove(orgKey: string | null, uid: string | null, key: string): Promise<any> {
    return LocalStorageService.removeByFullKey(LocalStorageService._generateKey(orgKey, uid, key));
  }

  static removeAll(): Promise<void> {

    return Preferences.clear();
  }

  static async getAlKeys(){
    return await Preferences.keys();
  }

  async keys(orgKey: string, uid: string, prefix?: string): Promise<any> {
      const keysObj = await Preferences.keys();
      const keys = keysObj.keys;
      return (typeof prefix !== 'string') || !prefix.length
          ? keys
          : keys.filter((key: string) => LocalStorageService._isKeyMatch(key, orgKey, uid, prefix, true));

  }

}
