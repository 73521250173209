import { Component, OnInit, ViewChild } from '@angular/core';
import { Platform, ModalController } from '@ionic/angular';
import { AwardsService } from 'src/services/awards.service';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from 'src/services/user.service';
import Chart from 'chart.js/auto';
import * as moment from 'moment';
import { AwardsInfoComponent } from '../awards-info/awards-info.component';



@Component({
  selector: 'app-awards',
  templateUrl: './awards.component.html',
  styleUrls: ['./awards.component.scss'],
})
export class AwardsComponent implements OnInit {

  public chats = 0;
  public chats_sent = 0;
  public chats_rcvd = 0;

  public groups = 0;
  public groups_sent = 0;
  public groups_rcvd = 0;

  public loading_chats = true;
  public loading_groups = true;

  public devices = false;
  public photo = false;
  public department = false;
  public position = false;
  public pin = false;
  public unavailableRules = false;
  public privacy = false;
  public groupCreator = false;
  public chats0 = false;
  public chats1 = false;
  public groups0 = false;
  public groups1 = false;
  public messages0 = false;
  public messages1 = false;
  public messages2 = false;
  public messages3 = false;
  public recieve0 = false;
  public recieve1 = false;

  public overall_level = 0;
  public scale_position = "5%";
  public scale_level = "awards.begginer";
  public chats_level = 0;
  public groups_level = 0;

  public BEGGINER_COL = "#0070c0";
  public LEARNER_COL = "#048ba8";
  public ADVANCED_COL = "#079d98";
  public PROFESSIONAL_COL = "#09af88";
  public MASTER_COL = "#0cc178";
  public WISE_COL = "#10dc60";

  public chatsSegment = true;
  public groupsSegment = false;
  public maxInARow = 0;
  public inARow = 0;

  @ViewChild('chartCanvas', { static: true }) canvas;
  public chart;
  public chartData = [];
  public chartLabels = [];
  public chartFilter = 7;


  constructor(public platform: Platform,
    public awService: AwardsService,
    public modalCtrl: ModalController,
    public userService: UserService,
    public tr: TranslateService) { }

  ngOnInit() {

    this.getStats();
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  getStats() {

    if (this.userService.getUser().access) {
      this.maxInARow = this.userService.getUser().access.maxDaysInARow;
      this.inARow = this.userService.getUser().access.daysInARow;

      if (this.maxInARow >= 5) { this.overall_level++; this.updateScaleLevel(); }
      // let max_access_progress = document.getElementById("max_access_progress");
      // max_access_progress.style.width = this.getLevel(this.maxInARow, 3).percent + "%";

    }



    this.awService.getUserStats().then((snap: any) => {
      let stats = snap.data();

      if (stats && stats.chats) {
        this.chats_sent = stats.chats.sent;
        this.chats_rcvd = stats.chats.rcvd;
        let chats_sent_progress = document.getElementById("chats_sent_progress");
        let chats_rcvd_progress = document.getElementById("chats_rcvd_progress");
        chats_sent_progress.style.width = this.getLevel(this.chats_sent).percent + "%";
        chats_rcvd_progress.style.width = this.getLevel(this.chats_rcvd).percent + "%";
      }

      if (stats && stats.groups) {
        this.groups_sent = stats.groups.sent;
        this.groups_rcvd = stats.groups.rcvd;
        let groups_sent_progress = document.getElementById("groups_sent_progress");
        let groups_rcvd_progress = document.getElementById("groups_rcvd_progress");
        groups_sent_progress.style.width = this.getLevel(this.groups_sent).percent + "%";
        groups_rcvd_progress.style.width = this.getLevel(this.groups_rcvd).percent + "%";
      }

      if ((this.groups_sent + this.chats_sent) >= 100) this.messages0 = true;
      if ((this.groups_sent + this.chats_sent) >= 500) this.messages1 = true;
      if ((this.groups_sent + this.chats_sent) >= 1000) this.messages2 = true;
      if ((this.groups_sent + this.chats_sent) >= 3000) this.messages3 = true;

      if ((this.groups_rcvd + this.chats_rcvd) >= 200) this.recieve0 = true;
      if ((this.groups_rcvd + this.chats_rcvd) >= 1000) this.recieve1 = true;

      if (this.messages1 && !this.messages3) this.overall_level++;
      if (this.messages3) this.overall_level += 2;
      if (this.recieve1) this.overall_level++;
      this.updateScaleLevel();

      this.awService.chatsStats().then((chats_res) => {
        this.loading_chats = false;
        this.chats = chats_res;
        if (this.chats >= 5) this.chats0 = true;
        if (this.chats >= 15) this.chats1 = true;

        let chats_progress = document.getElementById("chats_progress");
        chats_progress.style.width = this.getLevel(this.chats, 2).percent + "%";
        this.chats_level = Math.floor((this.getLevel(this.chats, 2).level + this.getLevel(this.chats_sent).level + this.getLevel(this.chats_rcvd).level) / 3);
        this.overall_level += this.chats_level;

        this.updateScaleLevel();
      });

      this.awService.groupsStats().then((groups_res) => {
        this.loading_groups = false;
        this.groups = groups_res;
        if (this.groups >= 5) this.groups0 = true;
        if (this.groups >= 15) this.groups1 = true;
        let groups_progress = document.getElementById("groups_progress");
        if (groups_progress) groups_progress.style.width = this.getLevel(this.groups, 2).percent + "%";
        
        this.groups_level = Math.floor((this.getLevel(this.groups, 2).level + this.getLevel(this.groups_sent).level + this.getLevel(this.groups_rcvd).level) / 3);
        this.overall_level += this.groups_level;
        this.updateScaleLevel();
      });
    })

    this.awService.accessessStats().then((accesses: any) => {
      accesses.forEach(access => {
        this.chartLabels.push(access.id);
        this.chartData.push(access.accesses);
      });
      for (var i = 0; i < this.chartLabels.length; i++) {
        if (i + 1 < this.chartLabels.length) {
          var date1 = moment(this.chartLabels[i], "YYYY-MM-DD");
          var date2 = moment(this.chartLabels[i + 1], "YYYY-MM-DD");
          //if the current date +1 is not the same as it's next neighbor we have to add in a new one
          if (!date1.add(1, "days").isSame(date2)) {
            //add the label
            this.chartLabels.splice(i + 1, 0, date1.format("YYYY-MM-DD"));
            //add the data
            this.chartData.splice(i + 1, 0, 0);
          }
        }
      }

      this.loadChart(7);
    })

    this.awService.hasDevice().then((devices) => {
      this.devices = devices;
      if (this.devices) {
        this.overall_level++;
        this.updateScaleLevel();
      }
    });
    this.awService.hasUnavailableRules().then((ur) => {
      this.unavailableRules = ur;
    });
    this.department = this.userService.getUser().profile.department ? true : false;
    this.position = this.userService.getUser().profile.position ? true : false;
    this.photo = this.userService.getUser().profile.picture ? true : false;
    this.pin = (this.userService.getUser().validation.pin && this.userService.getUser().validation.pin.pin) ? true : false;
    this.privacy = this.userService.getUser().validation.privacy ? true : false;

    if (this.photo && (this.department || this.position) && this.pin && this.privacy) {
      this.overall_level++;
      this.updateScaleLevel();
    }

    this.awService.groupCreator().then((gc) => { this.groupCreator = gc; });
  }

  loadChart(filter) {
    let options = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        y: {
          ticks: {
            stepSize: 1,
            beginAtZero: true
          }
        }
      }
    }
    let labels = [];
    let data = [];
    this.chartFilter = filter;
    if (filter === 'all') {
      labels = this.chartLabels;
      data = this.chartData;

    } else {
      labels = this.chartLabels.slice(this.chartLabels.length - filter, this.chartLabels.length);
      data = this.chartData.slice(this.chartData.length - filter, this.chartData.length)
    }

    if (this.chart) this.chart.destroy();
    this.chart = new Chart(this.canvas.nativeElement, {
      type: 'bar',
      data: {
        labels,
        datasets: [{
          label: this.tr.instant('awards.access_day'),
          data,
          backgroundColor: 'rgba(0, 128, 0, 0.3)',
          borderColor: 'rgba(0, 128, 0, 1)',
          borderWidth: 1
        }]
      },
      options
    });
  }

  getLevel(num, diff = 1) {
    let percent = 0;
    let LEVEL1 = 100;
    let LEVEL2 = 500;
    let LEVEL3 = 2000;
    let LEVEL4 = 3500;
    let LEVEL5 = 6000;

    if (diff === 2) {
      LEVEL1 = 5;
      LEVEL2 = 10;
      LEVEL3 = 20;
      LEVEL4 = 35;
      LEVEL5 = 50;
    } if (diff === 3) {
      LEVEL1 = 2;
      LEVEL2 = 5;
      LEVEL3 = 10;
      LEVEL4 = 15;
      LEVEL5 = 20;
    }

    if (num < LEVEL1) {
      percent = num * 100 / LEVEL1;
      return { level: 1, next: LEVEL1, tag: 'awards.begginer', percent, color: this.BEGGINER_COL };
    } else if (num >= LEVEL1 && num < LEVEL2) {
      percent = num * 100 / LEVEL2;
      return { level: 2, next: LEVEL2, tag: 'awards.learner', percent, color: this.LEARNER_COL };
    } else if (num >= LEVEL2 && num < LEVEL3) {
      percent = num * 100 / LEVEL3;
      return { level: 3, next: LEVEL3, tag: 'awards.advanced', percent, color: this.ADVANCED_COL };
    } else if (num >= LEVEL3 && num < LEVEL4) {
      percent = num * 100 / LEVEL4;
      return { level: 4, next: LEVEL4, tag: 'awards.professional', percent, color: this.PROFESSIONAL_COL };
    } else if (num >= LEVEL4 && num < LEVEL5) {
      percent = num * 100 / LEVEL5;
      return { level: 5, next: LEVEL5, tag: 'awards.master', percent, color: this.MASTER_COL };
    } else if (num >= LEVEL5) {
      percent = 100;
      return { level: 6, next: '∞', tag: 'awards.wise', percent, color: this.WISE_COL };
    }else{
      percent = num * 100 / LEVEL1;
      return { level: 1, next: LEVEL1, tag: 'awards.begginer', percent, color: this.BEGGINER_COL };
    }
  }

  updateScaleLevel() {
    let LEVEL1 = 3;
    let LEVEL2 = 6;
    let LEVEL3 = 9;
    let LEVEL4 = 12;
    let LEVEL5 = 15;
    let LEVEL6 = 18;

    let percent = 0;
    percent = this.overall_level / LEVEL6 * 100 - 2.5;
    if (this.overall_level > LEVEL6) percent = 95;
    this.scale_position = percent + "%";

    if (this.overall_level <= LEVEL1) {
      this.scale_level = "awards.begginer";
    } else if (this.overall_level > LEVEL1 && this.overall_level <= LEVEL2) {
      this.scale_level = "awards.learner";
    } else if (this.overall_level > LEVEL2 && this.overall_level <= LEVEL3) {
      this.scale_level = "awards.advanced";
    } else if (this.overall_level > LEVEL3 && this.overall_level <= LEVEL4) {
      this.scale_level = "awards.professional";
    } else if (this.overall_level > LEVEL4 && this.overall_level <= LEVEL5) {
      this.scale_level = "awards.master";
    } else if (this.overall_level > LEVEL5) {
      this.scale_level = "awards.wise";
    }
  }

  segmentChanged(ev: any) {
    if (ev.detail.value === 'chats') {
      this.chatsSegment = true;
      this.groupsSegment = false;
    } else if (ev.detail.value === 'groups') {
      this.chatsSegment = false;
      this.groupsSegment = true;
    }
  }

  getMedalsNumber() {
    let total = 20;
    let count = 0;

    if (this.photo) count++;
    if (this.department) count++;
    if (this.position) count++;
    if (this.devices) count++;
    if (this.pin) count++;
    if (this.unavailableRules) count++;
    if (this.privacy) count++;
    if (this.groupCreator) count++;
    if (this.maxInARow >= 3) count++;
    if (this.maxInARow >= 5) count++;
    if (this.chats0) count++;
    if (this.chats1) count++;
    if (this.groups0) count++;
    if (this.groups1) count++;
    if (this.messages0) count++;
    if (this.messages1) count++;
    if (this.messages2) count++;
    if (this.messages3) count++;
    if (this.recieve0) count++;
    if (this.recieve1) count++;

    return count + '/' + total;
    // public devices = false;
    // public photo = false;
    // public department = false;
    // public position = false;
    // public pin = false;
    // public unavailableRules = false;
    // public privacy = false;
    // public groupCreator = false;
    // public chats0 = false;
    // public chats1 = false;
    // public groups0 = false;
    // public groups1 = false;
    // public messages0 = false;
    // public messages1 = false;
    // public messages2 = false;
    // public messages3 = false;
  }


  async openAwardsInfo() {
    let modal = await this.modalCtrl.create({
      component: AwardsInfoComponent,
      swipeToClose: true,
      backdropDismiss: true,
      cssClass: 'awards-modal'
    });
    await modal.present();
  }
}

