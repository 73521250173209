import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { distinctUntilChanged, filter, map, switchMap, takeUntil, pluck, flatMap, first, take, tap, scan } from 'rxjs/operators';
import { BehaviorSubject, Observable, ReplaySubject, combineLatest, Observer } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { AlertController, ModalController, IonSearchbar } from '@ionic/angular';
import { LoadingService } from './loading.service';
import { TimeService } from './time.service';
import { FileService } from './file.service';
import { Utils } from 'src/utils/utils';
import { serverTimestamp, deleteField, QueryDocumentSnapshot } from 'firebase/firestore';
import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, orderBy, query, setDoc, updateDoc, where, writeBatch } from 'firebase/firestore';
import { collection as collectionSnapshots, doc as docSnapshots, docData } from 'rxfire/firestore';
import { FirebaseService } from './firebase.service';
import { WindowService } from './window.service';
import { AlertService } from './alert.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  public organizations$: BehaviorSubject<any> = new BehaviorSubject(null);
  public org$: BehaviorSubject<any> = new BehaviorSubject(null);

  public user$: ReplaySubject<any> = new ReplaySubject(1);
  public userBehaviour$: BehaviorSubject<any> = new BehaviorSubject(null);
  public orgUserInfo$: BehaviorSubject<any> = new BehaviorSubject(null);
  public userUnavailableRules$: BehaviorSubject<any> = new BehaviorSubject(null);
  public userPrivacy$: BehaviorSubject<any> = new BehaviorSubject(null);
  public userChatInfo$: BehaviorSubject<any> = new BehaviorSubject(null);
  public userGroupInfo$: BehaviorSubject<any> = new BehaviorSubject(null);
  public userAlerts$: BehaviorSubject<any> = new BehaviorSubject(null);
  public newReactions$: BehaviorSubject<any> = new BehaviorSubject(null);

  //search users
  public doingQuery$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public lastSearch = '';
  public users: { [k: string]: any } = [];
  public searchUsers = [];

  public usersProfile = {};

  public isExternal = false;
  public userOrg$: Observable<any>;

  constructor(
    public fs: FirebaseService,
    public authService: AuthService,
    public translate: TranslateService,
    public alertController: AlertController,
    public loadingService: LoadingService,
    public timeService: TimeService,
    public windowService: WindowService,
    public alertService: AlertService,
    public fileService: FileService) {

    this.authService.getAuthenticationState().pipe(
      distinctUntilChanged(),
      filter(isAuth => isAuth)
    ).subscribe(() => this.init());
  }

  init() {
    // this.user$ = new ReplaySubject(1);
    // this.userBehaviour$ = new BehaviorSubject(null);
    // this.org$ = new BehaviorSubject(null);
    // this.organizations$ = new BehaviorSubject(null);
    // this.orgUserInfo$ = new BehaviorSubject(null);
    this.user$.next(null);
    this.org$.next(null);
    this.userBehaviour$.next(null);
    this.userBehaviour$.next(null);
    this.organizations$.next(null);
    this.orgUserInfo$.next(null);
    this.userChatInfo$.next(null);
    this.userGroupInfo$.next(null);
    this.userAlerts$.next(null);


    this.loadUser();
  }

  public loadUser() {
    // Subscribe to user
    this.authService.getAuthenticationState().pipe(
      distinctUntilChanged(),
      filter(isAuth => isAuth),
      switchMap(() => this.authService.getUser()),
      filter((user: any) => user),
      switchMap((user) => docSnapshots(doc(this.fs.firestore, `users/${user.uid}`))
      ),
      map((snap) => ({ id: snap.id, ...snap.data() })),
      takeUntil(this.authService.onSignOut$)
    ).subscribe((user: any) => {
      this.userBehaviour$.next(user);
      this.user$.next(user);
      this.translate.use((user.profile && user.profile.lang) ? user.profile.lang : 'es');
      if (user && user.validation && user.validation.disabled === true) {
        this.authService.logout();
      }

    });

    // Subscribe to orgs
    this.user$.pipe(
      filter(user => user),
      distinctUntilChanged((a, b) => a && b && a.id === b.id),
      switchMap((user: any) =>
        combineLatest(
          Object.keys(user.organizations || {}).slice().reverse().map(orgKey => {
            return docSnapshots(doc(this.fs.firestore, `orgs/${orgKey}`)).pipe(
              map((snap) => ({ id: snap.id, ...snap.data() })),
              distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
            );
          })
        )),
      takeUntil(this.authService.onSignOut$)
    ).subscribe(orgs => {
      if (orgs && orgs[0]) {
        orgs.sort((a: any, b: any) => a.name.localeCompare(b.name));
        this.org$.next(orgs[0]);
        this.organizations$.next(orgs);
      }
    });

    // userOrg$ id's helper observable
    this.userOrg$ = this.user$.pipe(
      distinctUntilChanged((a, b) => a && b && a.id === b.id),
      switchMap((user) => this.org$.pipe(
        distinctUntilChanged((a, b) => a && b && a.id === b.id),
        map(org => ({ user, org }))
      )
      )
    );

    // Subscribe to user to detect disabled
    this.user$.pipe(
      filter(user => user),
      distinctUntilChanged((a, b) => a && b && a.id === b.id),
      takeUntil(this.authService.onSignOut$)
    ).subscribe(user => {
      if (user && user.validation && user.validation.disabled === true) this.authService.logout();
      if (this.windowService.orgKey && this.windowService.userId) {
        if (user && (!user.validation || !user.validation.minervaID || !user.validation.minervaID[this.windowService.orgKey] || user.validation.minervaID[this.windowService.orgKey] != this.windowService.userId)) {
          this.authService.logout();
          this.alertService.showError('errors.wrongExternalUser', false, { user1: user.validation.minervaID[this.windowService.orgKey], user2: this.windowService.userId });
        }
      }

    });

    // Subscribe to userOrgInfo
    this.userOrg$
      .pipe(
        filter(({ user, org }) => user && org),
        switchMap(({ org, user }) => this.getUserOrganizationInfo(user, org)),
        takeUntil(this.authService.onSignOut$))
      .subscribe((params) => {
        this.orgUserInfo$.next(params);
      });

    // Subscribe to userUnavailableRules
    this.user$.pipe(
      filter(user => user),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
      switchMap((user) => this.getUserUnavailableRules(user)),
      takeUntil(this.authService.onSignOut$)
    ).subscribe(rules => {
      this.userUnavailableRules$.next(rules);
    });


    // Subscribe to userChatInfo
    this.user$.pipe(
      filter(user => user),
      distinctUntilChanged((a, b) => a && b && a.id === b.id),
      switchMap((user) => collectionSnapshots(collection(this.fs.firestore, `users/${user.id}/chatsInfo`))),
      map((changes) => changes.map((change) => ({ ...change.data(), id: change.id }))),
      scan((acc, info) => ({ info, index: acc.index + 1 }), { index: -1, info: null }),
      takeUntil(this.authService.onSignOut$)
    ).subscribe((x) => {
      this.userChatInfo$.next(x);
    });


    // Subscribe to userGroupInfo
    this.user$.pipe(
      filter(user => user),
      distinctUntilChanged((a, b) => a && b && a.id === b.id),
      switchMap((user) => collectionSnapshots(collection(this.fs.firestore, `users/${user.id}/groupsInfo`))),
      map((changes) => changes.map((change) => ({ ...change.data(), id: change.id }))),
      scan((acc, info) => ({ info, index: acc.index + 1 }), { index: -1, info: null }),
      takeUntil(this.authService.onSignOut$)
    ).subscribe((x) => {
      this.userGroupInfo$.next(x)
    });


    // Subscribe to Alerts
    this.user$.pipe(
      filter(user => user),
      distinctUntilChanged((a, b) => a && b && a.id === b.id),
      switchMap((user) => collectionSnapshots(collection(this.fs.firestore, `users/${user.id}/alerts`))),
      map(changes => changes.reduce((acc: any, change: QueryDocumentSnapshot<any>) => {
        const data = change.data();
        const id: any = change.id;
        const moduleId = data.module_id;
        const previousAlert = acc[moduleId] || { alerts: [] };
        previousAlert.alerts.push({ id, ...data });
        return { ...acc, [moduleId]: previousAlert };
      }, {})),
      map(moduleAlerts => Object.values(moduleAlerts).map((alert: any, i) => ({ ...alert, id: Object.keys(moduleAlerts)[i] }))),
      takeUntil(this.authService.onSignOut$)
    ).subscribe((x) => {
      this.userAlerts$.next(x);
    });


    
    //Subscribe to new reactions //TODO do not execute when first subscribe
    this.user$.pipe(
      filter(user => user),
      distinctUntilChanged((a, b) => a && b && a.id === b.id),
      switchMap((user) => collectionSnapshots(collection(this.fs.firestore, `users/${user.id}/reactions`))),
      takeUntil(this.authService.onSignOut$)
    ).subscribe((x) => {
      // console.log("ENTER USER PIPE NEW REACTIOn", x.length)
      let res = x.map(a => {return {id:a.id, ...a.data()}})
      // console.log("RES", res)
      this.newReactions$.next(res);
    });

    this.org$.pipe(
      filter(org => org),
      distinctUntilChanged((a, b) => a && b && a.id === b.id),
      takeUntil(this.authService.onSignOut$))
      .subscribe((org) => {
        this.isExternal = false;
        if (this.getUser() && this.getUser().profile && this.getUser().profile.external) {
          if (this.getUser().profile.external[org.id]) this.isExternal = this.getUser().profile.external[org.id];
        }
      })

  }


  getOrganization() {
    return this.org$.getValue();
  }

  getOrgSnap(orgKey) {

  }

  public getUser() {
    return this.userBehaviour$.getValue();
  }

  public getUserById(uid): Observable<any> {
    return docData(doc(this.fs.firestore,
      'users',
      uid
    )).pipe(takeUntil(this.authService.onSignOut$));
  }

  public getUserSnapById(uid): Promise<any> {
    return getDoc(doc(this.fs.firestore, `users/${uid}`));
  }

  public async getUserSnapByIdLocalCheck(uid): Promise<any> {
    if (this.usersProfile[uid]) return this.usersProfile[uid];

    let snap = await getDoc(doc(this.fs.firestore, `users/${uid}`));
    this.usersProfile[snap.id] = { id: snap.id, ...snap.data()}
    return this.usersProfile[snap.id];
  }

  public getConnectionStatus(user) {
    if (user && !user.validation && user.status) {
      //estat quan carrega de org/orgKey/userInfo (carrega inicial)

      if (user.status && user.status.unavailable) {
        //if (this.getUserUnavailable(user)) {
        return "UNAVAILABE";
      }

      if (!user.status.visibility) {
        return "OFFLINE";
      }

      if (user.status.visibility) {
        return user.status.visibility;
      }
    } else {

      if (!user || (!user.validation && !user.status)) {
        return "OFFLINE";;
      }

      if (!user.validation || !user.validation.verified) {
        return "UNVERIFIED";
      }

      //TODO: user.visibility
      if (user.status && user.status.unavailable) {
        //if (this.getUserUnavailable(user)) {
        return "UNAVAILABE";
      }

      if (user.validation && user.validation.disabled) {
        return "DISABLED";
      }

      if (!user.status || (!user.status.lastConn && !user.status.visibility)) {
        return "OFFLINE";
      }

      if (user.status.visibility) {
        return user.status.visibility;
      }

    }
  }

  deleteUserProperty(value) {
    return updateDoc(doc(this.fs.firestore, `users/${this.getUser().id}`), { [value]: deleteField() });
  }
  updateUserProperty(value) {
    return setDoc(doc(this.fs.firestore, `users/${this.getUser().id}`), { ...value }, { merge: true });
  }

  updateValidationProperty(key: string, value: any): Promise<any> {
    return updateDoc(doc(this.fs.firestore, `users/${this.getUser().id}`), { [`validation.${key}`]: value });
  }

  getUserOrganizationInfo(user, org) {
    return docSnapshots(doc(this.fs.firestore,
      'users',
      user.id,
      'organizationInfo',
      org.id
    )).pipe(
      map((params) => ({ ...params.data() }))
    );
  }

  async getUserOrganizationInfoPromise(user, org) {
    let res = await getDoc(doc(this.fs.firestore,
      'users',
      user.id,
      'organizationInfo',
      org.id
    ))
    return { ...res.data(), id: res.id };
  }

  updateProfileProperty(key, value) {
    return updateDoc(
      doc(this.fs.firestore, `users/${this.getUser().id}`),
      { [`profile.${key}`]: value }
    );
  }

  async updateStatusProperty(key, value, warning = false) {
    if (warning) {
      let translations = [];
      await this.translate.get(['settings.confirmAvailable', 'general.cancel', 'unavailable.remove'])
        .forEach(tr => {
          translations = tr;
        });
      const confirm = await this.alertController.create({
        header: translations['settings.confirmAvailable']['title'],
        message: translations['settings.confirmAvailable']['message'],
        buttons: [
          {
            text: translations['general.cancel']
          },
          {
            text: translations['unavailable.remove'],
            handler: async () => {
              await this.loadingService.presentLoading();
              let batch = writeBatch(this.fs.firestore);
              let ref = doc(this.fs.firestore, `users/${this.getUser().id}`);
              batch.set(ref, { status: { [key]: value, 'unavailableMode': 'manual' } }, { merge: true });

              let orgs = await this.organizations$.getValue();
              orgs.forEach(org => {
                ref = doc(this.fs.firestore, `orgs/${org.id}/userStatus/${this.getUser().id}`);
                batch.set(ref, { status: { unavailable: value } }, { merge: true })
              });
              await batch.commit();
              return this.loadingService.dismissLoading();
            }
          }
        ]
      });
      await confirm.present();
    } else {
      let batch = writeBatch(this.fs.firestore);
      let ref = doc(this.fs.firestore, `users/${this.getUser().id}`);
      batch.set(ref, { status: { [key]: value } }, { merge: true });
      let orgs = await this.organizations$.getValue();
      orgs.forEach(org => {
        ref = doc(this.fs.firestore, `orgs/${org.id}/userStatus/${this.getUser().id}`);
        batch.set(ref, { status: { [key]: value } }, { merge: true });
      });
      return batch.commit();
    }
  }


  getUserUnavailableRules(user) {
    const ref = collection(
      this.fs.firestore,
      `users/${user.id}/unavailableRules`
    );
    return collectionSnapshots(ref).pipe(
      takeUntil(this.authService.onSignOut$),
      map((snaps) => snaps.map((snap) => ({ id: snap.id, ...snap.data() }))),
    );
  }

  addUnavailableRule(data) {
    const ref = collection(this.fs.firestore, `users/${this.getUser().id}/unavailableRules`);
    addDoc(ref, { ...data });
  }


  removeUnavailableRule(ruleKey: string): Promise<any> {
    const path = `users/${this.getUser().id}/unavailableRules/${ruleKey}`;
    return deleteDoc(doc(this.fs.firestore, path));
  }

  // async changePassword(_current, _new) {
  //   try {
  //     await this.authService.changePassword(_current, _new);
  //     await this.updateValidationProperty('lastPasswordChange', serverTimestamp());
  //   } catch (e) {
  //     throw e;
  //   }
  // }

  async selectOrganization(organization: string) {
    const orgs: any[] = this.organizations$.getValue();
    if (orgs) {
      const org = orgs.find((x) => x.id === organization);
      if (org) {
        this.org$.next(org);
        this.lastSearch = "";
      }
    }
  }

  async acceptPrivacy(global, userOrgs) {
    let batch = writeBatch(this.fs.firestore);
    await this.loadingService.presentLoading();
    const privacyId = doc(collection(this.fs.firestore, '_')).id;
    let ref1 = doc(this.fs.firestore, `users/${this.getUser().id}/privacy/${privacyId}`);
    let now = serverTimestamp();
    batch.set(ref1, {
      date: now,
      org: "medxat-privacy",
      orgKey: "medxat",
      url: global
    });

    userOrgs.forEach(org => {
      let privacyId = doc(collection(this.fs.firestore, '_')).id;
      let ref2 = doc(this.fs.firestore, `users/${this.getUser().id}/privacy/${privacyId}`);
      batch.set(ref2, {
        date: now,
        org: org.name,
        orgKey: org.id,
        url: this.getUser().profile.patient ? (org.privacy_patient ? org.privacy_patient : org.privacy) : (this.getUser().profile.external ? (org.privacy_external ? org.privacy_external : org.privacy) : org.privacy),
      });
    });
    let ref3 = doc(this.fs.firestore, `users/${this.getUser().id}`);
    batch.update(ref3, { 'validation.privacy': now })
    try {
      await batch.commit();
    } catch (e) {
      console.log("ERROR", e);
    }
    await this.loadingService.dismissLoading();
  }

  async changeProfilePicture(file) {
    if (file === null || !file) {
      await this.removeProfilePicture();
      return;
    }
    await this.fileService.upload(`/profilePictures/${this.getOrganization().id}/${this.getUser().id}`, file.name, file);
  }


  async removeProfilePicture(url = null) {

    const chatsRef = query(
      collection(this.fs.firestore, `orgs/${this.getOrganization().id}/chats`),
      where(`users.${this.getUser().id}`, '==', true)
    );
    let chatsSnap = await getDocs(chatsRef);

    if (chatsSnap) {
      let ref;
      let batch = writeBatch(this.fs.firestore);
      let count = 0;
      await chatsSnap.docs.map(async (chatSnap) => {
        count++;
        ref = doc(this.fs.firestore, `orgs/${this.getOrganization().id}/chats/${chatSnap.id}`);
        batch.set(ref, {
          userParams:
          {
            [this.getUser().id]: {
              profile: {
                picture: url,
                smallPicture: url
              }
            }
          }
        }, { merge: true });
        if (count === 488) {
          await batch.commit();
          batch = writeBatch(this.fs.firestore);
        }
      });

      ref = doc(this.fs.firestore, `users/${this.getUser().id}`);

      batch.set(ref, {
        profile: {
          picture: url,
          smallPicture: url
        }
      }, { merge: true });
      await batch.commit();
      return;
    }
    return;
  }
  async updateUser(path: string = '', update: any = {}) {
    if (path && path.length > 0 && path[0] === '/') { path = path.substring(1); }
    const id = await this.user$.pipe(
      distinctUntilChanged((a, b) => a && b && a.id === b.id),
      filter(x => x),
      first(),
      pluck('id')
    ).toPromise();

    return await updateDoc(doc(this.fs.firestore, `users/${id}/${path}`), update);
  }

  getPositionAndDepartment(user?): string {
    return user ? [user.profile.position, user.profile.department].filter(p => p && (p.length > 0)).join(', ') : '';
  }

  getFilteredSearchbarUsers(searchBar: any, exclude: { [k: string]: boolean } = {}, mode = 'professionals', searchAll = false, includeDisabled = false, patientMode = false): Observable<any> {
    if (mode === 'patients') patientMode = true;

    const searchValue$$ = new BehaviorSubject<string>('');
    if (mode != 'locations') {
      searchBar.ionInput
        .pipe(
          map((event: Event) => {
            const value = (<HTMLInputElement>event.target).value;
            return (typeof value === 'string') ? value : '';
          }))
        .subscribe(searchValue => {
          if (searchValue.length < 3) {
            return '';
          }
          if (searchValue.trim() === '') {
            return '';
          }
          searchValue$$.next(searchValue.toLowerCase());
        });
    }


    return combineLatest<any>(searchValue$$)
      .pipe(
        switchMap(async (search) => {

          let searchValue = search[0];
          if (!searchValue || searchValue === '') {
            return
          }
          let orgKey = this.getOrganization().id;
          let splitSearch = searchValue.split(' ');
          let newSearch = '';

          let wheres = [where('organizations.' + orgKey, '==', true)];

          if (patientMode && !searchAll) {
            wheres.push(where('profile.patient', '==', true));
          }

          for (let i = 0; i < splitSearch.length; i++) {
            if (splitSearch[i] && splitSearch[i] != '') {
              if (splitSearch[i].length >= 3) {
                splitSearch[i] = Utils.removeAccents(splitSearch[i].substr(0, splitSearch[i].length === 3 ? 3 : 4));
                wheres.push(where('search.' + splitSearch[i], '==', true));
                newSearch += (i === 0 ? splitSearch[i] : '_' + splitSearch[i]);
              }
            }
          }
          let lastLength = this.lastSearch.split('_');
          let newLength = newSearch.split('_');

          if ((lastLength.length === newLength.length && this.lastSearch != newSearch) || (lastLength.length > newLength.length) || (lastLength.length < newLength.length && this.lastSearch.toString() != newLength.slice(0, lastLength.length).toString())) {
            this.doingQuery$.next(true);
            let userQuery = query(collection(this.fs.firestore, `users`), ...wheres);
            let usrsSnap = await getDocs(userQuery);
            this.lastSearch = newSearch;

            let users = [];
            for (let j = 0; j < usrsSnap.docs.length; j++) {
              let usr = usrsSnap.docs[j];
              if (usr) {
                let user: any = {
                  id: usr.id,
                  ...usr.data()
                };

                if (includeDisabled) {
                  if (searchAll) await users.push(user); //grups pacients/profesionals
                  else if (!patientMode && !user.profile.patient) await users.push(user); //búsqueda de profesionals
                  else if (patientMode && user.profile.patient) await users.push(user); //búsqueda de pacients
                } else if (user && user.validation && !user.validation.disabled) {
                  if (searchAll) await users.push(user); //grups pacients/profesionals
                  else if (!patientMode && !user.profile.patient) await users.push(user); //búsqueda de profesionals
                  else if (patientMode && user.profile.patient) await users.push(user); //búsqueda de pacients
                }
              }
            }
            this.searchUsers = users;
          }

          if (!this.searchUsers || this.searchUsers.length === 0) {
            return;
          }
          return this.searchUsers.filter(user =>
            !exclude[user.id] &&
            ((searchValue.length == 0) ||
              (user && user.profile.name && Utils.removeAccents(user.profile.name).toLowerCase().indexOf(Utils.removeAccents(searchValue).toLowerCase())) === 0 ||
              (user && user.profile.name && Utils.removeAccents(user.profile.name).toLowerCase().indexOf(' ' + Utils.removeAccents(searchValue).toLowerCase())) > 0 ||
              (user && user.profile.email && Utils.removeAccents(user.profile.email).toLowerCase().indexOf(Utils.removeAccents(searchValue).toLowerCase())) === 0
            )
          ).sort((a, b) => {
            if (a.profile.name.toLowerCase() > b.profile.name.toLowerCase()) {
              return 1;
            }
            if (a.profile.name.toLowerCase() < b.profile.name.toLowerCase()) {
              return -1;
            }
            return 0;
          }).sort((a, b) => {
            let item1 = (a && a.profile.name) ? a.profile.name.toLowerCase().indexOf(searchValue.toLowerCase()) : 0;
            let item2 = (b && b.profile.name) ? b.profile.name.toLowerCase().indexOf(searchValue.toLowerCase()) : 0;
            return (item1 - item2)
          });
        })
      )
  }

  getOrgDocuments() {
    return getDocs(query(
      collection(this.fs.firestore, `orgs/${this.getOrganization().id}/documents`),
      orderBy('order', 'asc')
    ));
  }

  getDocument(id) {
    return getDoc(doc(this.fs.firestore, `documents/${id}`));
  }

  getPatientsDashboardPermission(chat) {
    if (!chat.tag || !chat.tag.toLowerCase().includes("nhc")) return false;
    let user = this.getUser();
    let org = this.getOrganization();
    let orgmodules = org && org.modules && org.modules.patientsDashboard ? org.modules.patientsDashboard : false;
    let usermodules = user && user.modules && user.modules.patientsDashboard ? user.modules.patientsDashboard : false;
    let orgmoduleall = org && org.params && org.params.patientsDashboard ? org.params.patientsDashboard.visibility === 'all' : false;
    if (!orgmodules) return false;
    if (orgmodules && usermodules || orgmodules && orgmoduleall) return true;
    return false;

  }
}
