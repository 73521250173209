import { Injectable } from '@angular/core';
import { ModalController, AlertController, Platform } from '@ionic/angular';
import { UserService } from './user.service';
import { AuthService } from './auth.service';
import { takeUntil, map } from 'rxjs/operators';
import { doc, getDoc, getDocs, query, writeBatch } from 'firebase/firestore';
import { collection as collectionSnapshots, doc as docSnapshots } from 'rxfire/firestore';

import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { ChatService } from './chat.service';
import { VideoService } from './video.service';
import { AudioService } from './audio.service';
import { FileService } from './file.service';

import { DeviceService } from './device.service';
import { combineLatest } from 'rxjs';
import { VideoModalService } from './video-modal.service';
import { Browser } from '@capacitor/browser';
import { addDoc, collection, deleteDoc, deleteField, orderBy, serverTimestamp, setDoc, where } from "firebase/firestore";
import { getDownloadURL, UploadResult } from 'firebase/storage';
import { FirebaseService } from './firebase.service';

export enum VisitStatus {
  SCHEDULED = 'scheduled',
  PD_PATIENT = 'pd_patient',
  WAITING_ROOM = 'waiting_room',
  IN_PROGRESS = 'in_progress',
  PD_FINISHED = 'pd_finished',
  FINISHED = 'finished',
  CANCELLED = 'cancelled'
}

@Injectable({
  providedIn: 'root'
})
export class TelemedicineService {
  public existingCallModal;
  visitW8Threshold = 900;

  constructor(
    public modalCtrl: ModalController,
    public fs: FirebaseService,
    public userService: UserService,
    public authService: AuthService,
    public alertCtrl: AlertController,
    public tr: TranslateService,
    public chatService: ChatService,
    public videoService: VideoService,
    public audioService: AudioService,
    public platform: Platform,
    public fileService: FileService,
    public deviceService: DeviceService,
    public videoModal: VideoModalService
  ) {

    this.videoService.calling$
      .subscribe(async (snap) => {
        let params: any = {
          'join': true,
          'room': { call: { ...snap } },
          'callMode': snap.callMode ? true : false,
          'origin': snap.origin
        }
        if (snap.visitId) {
          params.visitId = snap.visitId;
        }
        if (this.videoService.disabledVideoWebView)
          await this.videoService.openExternal(params);
        else await this.videoModal.openVideo(params);
      })
  }


  getPendingVisits$(org, day, service?, user?) {
    let previousVisists$ = null;
    let pendingVisits$ = null;

    if (service) {
      let queryprevious = query(collection(this.fs.firestore, `orgs/${org}/visits`),
        where('service', '==', service),
        where('date.day', '<', day),
        where('status', '==', VisitStatus.IN_PROGRESS),
        orderBy('date.day', 'asc'),
        orderBy('date.time', 'asc')
      )
      previousVisists$ = collectionSnapshots(queryprevious)
        .pipe(
          takeUntil(this.authService.onSignOut$),
          map((docs: any) => docs.map((snap: any) => ({ id: snap.id, ...snap.data() })))
        );
      let querypending = query(collection(this.fs.firestore, `orgs/${org}/visits`),
        where('service', '==', service),
        where('status', '==', VisitStatus.PD_FINISHED),
        orderBy('date.day', 'asc'),
        orderBy('date.time', 'asc')
      )
      pendingVisits$ = collectionSnapshots(querypending).pipe(
        takeUntil(this.authService.onSignOut$),
        map((docs: any) => docs.map((snap: any) => ({ id: snap.id, ...snap.data() })))
      );
      return combineLatest([previousVisists$, pendingVisits$]).pipe(
        map(([list1, list2]: any) => [...list1, ...list2])
      );
    }
    let querypreviousv = query(collection(this.fs.firestore, `orgs/${org}/visits`),
      where('doctor.id', '==', user),
      where('date.day', '<', day),
      where('status', '==', VisitStatus.IN_PROGRESS),
      orderBy('date.day', 'asc'),
      orderBy('date.time', 'asc')
    )
    previousVisists$ = collectionSnapshots(querypreviousv).pipe(
      takeUntil(this.authService.onSignOut$),
      map((docs: any) => docs.map((snap: any) => ({ id: snap.id, ...snap.data() })))
    );

    let queryprendingv = query(collection(this.fs.firestore, `orgs/${org}/visits`),
      where('doctor.id', '==', user),
      where('status', '==', VisitStatus.PD_FINISHED),
      orderBy('date.day', 'asc'),
      orderBy('date.time', 'asc')
    )

    pendingVisits$ = collectionSnapshots(queryprendingv).pipe(
      takeUntil(this.authService.onSignOut$),
      map((docs: any) => docs.map((snap: any) => ({ id: snap.id, ...snap.data() })))
    );

    return combineLatest([previousVisists$, pendingVisits$]).pipe(
      map(([list1, list2]: any) => [...list1, ...list2])
    );
  }

  getFinishedVisits$(org, day, service?, user?) {
    if (service) {
      return collectionSnapshots(query(collection(this.fs.firestore, `orgs/${org}/visits`),
        where('service', '==', service),
        where('completion_date.day', '==', day),
        where('status', '==', VisitStatus.FINISHED),
        orderBy('completion_date.time', 'asc')
      )).pipe(
        takeUntil(this.authService.onSignOut$),
        map((docs: any) => docs.map((snap: any) => ({ id: snap.id, ...snap.data() })))
      );
    } else {
      return collectionSnapshots(query(collection(this.fs.firestore, `orgs/${org}/visits`),
        where('doctor.id', '==', user),
        where('completion_date.day', '==', day),
        where('status', '==', VisitStatus.FINISHED),
        orderBy('completion_date.time', 'asc')
      )).pipe(
        takeUntil(this.authService.onSignOut$),
        map((docs: any) => docs.map((snap: any) => ({ id: snap.id, ...snap.data() })))
      );
    }
  }

  getAttentionVisits(orgKey, uid) {
    const day = moment().format('YYYY/MM/DD');
    const attentionVisits$ = collectionSnapshots(query(collection(this.fs.firestore, `orgs/${orgKey}/visits`),
      where('doctor.id', '==', uid),
      where('date.day', '==', day))).pipe(
        takeUntil(this.authService.onSignOut$),
        map(
          (docs: any) => docs
            .map((snap: any) => ({ id: snap.id, ...snap.data() }))
            .filter(x => x.status !== VisitStatus.FINISHED && x.status !== VisitStatus.CANCELLED)
        )
      );
    return combineLatest([attentionVisits$, this.getPendingVisits$(orgKey, day, null, uid)]).pipe(
      map(([list1, list2]: any) => [...list1, ...list2])
    );
  }

  getUserVisits$(user, day, service?) {
    if (service) {
      return collectionSnapshots(query(collection(this.fs.firestore, `orgs/${this.userService.getOrganization().id}/visits`),
        where('service', '==', service),
        where('date.day', '==', day),
        orderBy('date.time', 'asc'))).pipe(
          takeUntil(this.authService.onSignOut$),
          map((docs: any) => docs.map((snap: any) => ({ id: snap.id, ...snap.data() })))
        );
    } else if (user && user.profile && !user.profile.patient) {
      return collectionSnapshots(query(collection(this.fs.firestore, `orgs/${this.userService.getOrganization().id}/visits`),
        where('doctor.id', '==', this.userService.getUser().id),
        where('date.day', '==', day),
        orderBy('date.time', 'asc'))).pipe(
          takeUntil(this.authService.onSignOut$),
          map((docs: any) => docs.map((snap: any) => ({ id: snap.id, ...snap.data() })))
        )
    } else if (user && user.profile && user.profile.patient) {
      if (!user.profile.his) return;
      let patientMinervaID = user.profile.his;
      return collectionSnapshots(query(collection(this.fs.firestore, `orgs/${this.userService.getOrganization().id}/visits`),
        where('patient.minervaID', '==', patientMinervaID),
        orderBy('date.time', 'asc'))).pipe(
          takeUntil(this.authService.onSignOut$),
          map((docs: any) => docs.map((snap: any) => ({ id: snap.id, ...snap.data() })))
        )
    }
  }

  getVisit$(id) {
    return docSnapshots(doc(this.fs.firestore, `orgs/${this.userService.getOrganization().id}/visits/${id}`))
      .pipe(
        takeUntil(this.authService.onSignOut$),
        map((snap: any) => ({ id: snap.id, ...snap.data() }))
      );
  }

  getVisit(id) {
    return getDoc(doc(this.fs.firestore, 'orgs', this.userService.getOrganization().id, 'visits', id));
  }

  async getPatientVisits(patientId) {
    let res = await getDocs(query(collection(this.fs.firestore, 'orgs', this.userService.getOrganization().id, 'visits'),
      where('patient.id', '==', patientId),
      orderBy('date.day', 'desc')
    ))
    return Promise.resolve(res.docs.map((doc: any) => { return { id: doc.id, ...doc.data() } }))
  }

  modifyVisitDate(visit, opt, value) {
    return setDoc(doc(this.fs.firestore, 'orgs', this.userService.getOrganization().id, 'visits', visit.id),
      { date: { [opt]: value }, patient: { visit_seen: false } }, { merge: true })
  }

  async presentModifyAlertService(visit, opt, value) {
    let translations = {};
    await this.tr.get(['tm.modify', 'general']).forEach(x => translations = x);
    let alert = await this.alertCtrl.create({
      header: translations['tm.modify'].title,
      subHeader: value,
      message: translations['tm.modify'][opt],
      buttons: [
        {
          text: translations['general'].yes,
          handler: async () => {
            await this.modifyVisitDate(visit, opt, value);
          }
        },
        {
          text: translations['general'].no,
          role: 'cancel',
        }
      ]
    });
    return alert.present();
  }

  modifyVisit(visit, field, value) {
    return setDoc(doc(this.fs.firestore, 'orgs', this.userService.getOrganization().id, 'visits', visit.id),
      { [field]: value }, { merge: true });
  }

  async markVisitsAsSeen(visits) {
    if (!visits) return;
    let batch = writeBatch(this.fs.firestore);

    visits.forEach(visit => {
      if (!visit || !visit.id) return;
      if (!visit.patient || (visit.patient && !visit.patient.visit_seen)) {
        let ref = doc(this.fs.firestore, 'orgs', this.userService.getOrganization().id, 'visits', visit.id);
        batch.set(ref, { patient: { visit_seen: true } }, { merge: true })
      }
    })
    return batch.commit();
  }

  updateVisit(visit, update, merge = true) {
    return setDoc(doc(this.fs.firestore, 'orgs', this.userService.getOrganization().id, 'visits', visit.id),
      update, { merge: true })
  }

  async startVisit(visit) {
    await this.updateVisit(visit, { status: VisitStatus.IN_PROGRESS, start: serverTimestamp() });
  }

  async pdFinishVisit(visit) {
    await this.updateVisit(visit, { status: VisitStatus.PD_FINISHED });
  }

  async finishVisit(visit) {
    await this.updateVisit(visit, { status: VisitStatus.FINISHED, end: serverTimestamp() });
  }

  async forwardVisit(visit) {
    await this.updateVisit(visit, { status: VisitStatus.PD_PATIENT });
  }

  updateVisitChat(visit, id) {
    return setDoc(doc(this.fs.firestore, 'orgs', this.userService.getOrganization().id, 'visits', visit.id),
      { chat: { id } }, { merge: true });
  }

  async dowloadFile(file) {
    if (!this.platform.is('capacitor')) {
      await Browser.open({ url: file.url });
    } else {
      await this.fileService.showDownloadWarning(file.url, file.name, file.type);
    }
  }

  async getVisitFiles(visitId) {
    let res = await getDocs(query(collection(this.fs.firestore, 'orgs', this.userService.getOrganization().id, 'visits', visitId, 'files')))

    return Promise.resolve(res.docs.map((doc: any) => { return { id: doc.id, ...doc.data() } }))
  }

  getVisitFiles$(visitId) {
    return collectionSnapshots(query(collection(this.fs.firestore, 'orgs', this.userService.getOrganization().id, 'visits', visitId, 'files')))
      .pipe(
        takeUntil(this.authService.onSignOut$),
        map((docs: any) => docs.map((snap: any) => ({ id: snap.id, ...snap.data() })))
      )
  }

  async uploadFile(visit, file, senderName) {
    const path = `/vists/${this.userService.getOrganization().id}/${visit.id}/${this.userService.getUser().id}`;
    let ids = [visit.doctor.id, visit.patient.id];
    try {
      const resp: UploadResult = await this.fileService.upload(path, file.name, file, null, null);
      const url = await getDownloadURL(resp.ref);
      return addDoc(collection(this.fs.firestore, 'orgs', this.userService.getOrganization().id, 'visits', visit.id, 'files'),
        { name: file.name, type: file.type, url, senderName, time: new Date() });
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async uploadUnreadFile(visit, type, read = true) {
    let batch = writeBatch(this.fs.firestore);

    let ref = doc(this.fs.firestore, 'orgs', this.userService.getOrganization().id, 'visits', visit.id);

    if (type === 'patient') {
      batch.set(ref, { patientUnreadFile: read ? read : deleteField() }, { merge: true })
    } else {
      batch.set(ref, { doctorUnreadFile: read ? read : deleteField() }, { merge: true })
    }
    return batch.commit();
  }

  deleteFile(visit, file) {
    return deleteDoc(doc(this.fs.firestore, 'orgs', this.userService.getOrganization().id, 'visits', visit.id, 'files', file.id));
  }
  async getChat(visit) {
    if (visit.chat && visit.chat.id) {
      let res = await getDoc(doc(this.fs.firestore, 'orgs', this.userService.getOrganization().id, 'chats', visit.chat.id));

      return Promise.resolve({ id: res.id, ...res.data() })
    } else {

      let other;
      let snap;
      //create tm chat
      if (this.userService.getUser() && this.userService.getUser().profile && this.userService.getUser().profile.patient)
        snap = await this.userService.getUserSnapById(visit.doctor.id);
      else snap = await this.userService.getUserSnapById(visit.patient.id);
      other = { id: snap.id, ...snap.data() };

      let id = await this.chatService.createChat(other, true);
      let res = await getDoc(doc(this.fs.firestore, 'orgs', this.userService.getOrganization().id, 'chats', id));
      return Promise.resolve({ id: res.id, ...res.data() })
    }
  }


  async startPhoneCall(id, visit) {
    if (this.platform.is('capacitor') || this.platform.is('mobileweb')) {
      return this.videoModal.startLocalPhoneCall(id);
    }
    let translations = {};
    await this.tr.get(['tm', 'general']).forEach(x => translations = x);
    let alert = await this.alertCtrl.create({
      header: translations['tm'].vc_start,
      message: translations['tm'].vc_start_msg,
      backdropDismiss: false,
      buttons: [
        {
          text: translations['tm'].start_local,
          handler: async () => {
            this.videoModal.startLocalPhoneCall(id);
          }
        },
        {
          text: translations['tm'].start_external,
          handler: async () => {
            this.startRemotePhoneCall(visit);
          }
        },
        {
          text: translations['general'].cancel,
          role: 'cancel',
        }
      ]
    });
    await alert.present();

  }

  async startRemotePhoneCall(visit) {
    await this.userService.deleteUserProperty('externalCall');
    let randId = Math.random() * (999999 - 0) + 0;
    await this.userService.updateUserProperty({ externalCall: { visit: visit, randId, type: 'call', source: this.deviceService.info.uuid } });
  }



  async startPatientVideo(visit) {
    let params = {
      'contactName': visit.doctor.name,
      'callMode': false,
      'testMode': true,
      'setting': true,
      'visit': visit
    }
    if (this.videoService.disabledVideoWebView) await this.videoService.openExternal(params);
    else await this.videoModal.openVideo(params);
  }

  async assignVisit(visit) {
    let alert = await this.alertCtrl.create({
      header: this.tr.instant('tm.assign_title'),
      message: this.tr.instant('tm.assign_message'),
      backdropDismiss: false,
      buttons: [
        {
          text: this.tr.instant('general.yes'),
          handler: async () => {
            let ref = doc(this.fs.firestore, 'orgs', this.userService.getOrganization().id, 'visits', visit.id)

            let user = this.userService.getUser();
            let batch = writeBatch(this.fs.firestore)
            batch.update(ref, {
              doctor: {
                id: user.id,
                email: user.profile.email,
                name: user.profile.name_public ? user.profile.name_public : user.profile.name,
                at: new Date(),
                original_doctor: { ...visit.doctor }
              }
            })
            return batch.commit();
          }
        },
        {
          text: this.tr.instant('general.no'),
          role: 'cancel',
        }
      ]
    });
    return alert.present();
  }

  translateStatus(status) {
    return this.tr.instant(`tm.status.${status}`);
  }
  statusColor(status) {
    let color = "primary";
    switch (status) {
      case VisitStatus.SCHEDULED:
        color = 'primary';
        break;
      case VisitStatus.PD_PATIENT:
        color = 'gray';
        break;
      case VisitStatus.WAITING_ROOM:
        color = 'success';
        break;
      case VisitStatus.IN_PROGRESS:
        color = 'green';
        break;
      case VisitStatus.PD_FINISHED:
        color = 'pink';
        break;
      case VisitStatus.FINISHED:
        color = 'danger';
        break;
    }
    return color;
  }

  dbDateFormat(date) {
    return moment(date, 'DD/MM/YYYY').format('YYYY/MM/DD')
  }

  dbToViewDateFormat(date) {
    return moment(date, 'YYYY/MM/DD').format('DD/MM/YYYY')
  }

  dbToPickerDateFormat(date) {
    return moment(date, 'YYYY/MM/DD').format('YYYY-MM-DD')
  }
  dbPickerToDateFormat(date) {
    return moment(date, 'YYYY-MM-DD').format('YYYY/MM/DD')
  }


  async checkVisitTreshold(visit, time) {
    var t1 = moment(time, "HH:mm");
    var t2 = moment(visit.date.time, "HH:mm");
    var diff = moment(t2.diff(t1)).format('X'); //in seconds
    let w8Threshold = this.visitW8Threshold;
    let selectedOrg = this.userService.getOrganization();
    if (selectedOrg && selectedOrg.params && selectedOrg.params.visits && selectedOrg.params.visits.visit_w8_threshold) {
      w8Threshold = selectedOrg.params.visits.visit_w8_threshold;
    }
    if (parseInt(diff) <= parseInt("" + w8Threshold)) {
      if (visit.status === VisitStatus.SCHEDULED && visit.status != VisitStatus.WAITING_ROOM && visit.status != VisitStatus.IN_PROGRESS)
        await this.updateVisit(visit, { status: VisitStatus.PD_PATIENT });
      return true;
    }
    return false;
  }

  checkRescheduled(visit) {
    if (!visit || !visit.date || !visit.original_date) return false;
    if (visit.date.day != visit.original_date.day || visit.date.time != visit.original_date.time) return true;
    return false;
  }


  thresholdToText() {
    return "" + this.visitW8Threshold / 60;
  }

  updateVisitNetwork(visit, downloadSlow, uploadSlow, info) {
    let ref = doc(this.fs.firestore, 'orgs', this.userService.getOrganization().id, 'visits', visit.id);

    let batch = writeBatch(this.fs.firestore);
    batch.set(ref, {
      call: {
        patient: {
          network: {
            downloadSlow,
            uploadSlow,
            ...info
          }
        }
      }
    }, { merge: true })
    return batch.commit();
  }

}