import { Component, OnInit, OnDestroy, Input, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { AuthService } from 'src/services/auth.service';
import { Platform, AlertController } from '@ionic/angular';
import { CommunicationService } from 'src/services/communication.service';
import { DeviceService } from 'src/services/device.service';
import { LoadingService } from 'src/services/loading.service';
import { takeUntil, distinctUntilChanged } from 'rxjs/operators';
import { FileService } from 'src/services/file.service';
import { GeolocationService } from 'src/services/geolocation.service';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from 'src/services/alert.service';
import NFCPlugin from 'src/capacitor_plugins/nfc-plugin';
import { Router } from '@angular/router';

@Component({
  selector: 'form-question',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
})
export class FormComponent implements OnInit, OnDestroy {
  form: UntypedFormGroup;

  @Input() moduleId: any;
  @Input() question: any;
  @Input() path: any;

  @Input() set formObj(form) {
    this.form = form;
    if ((this.element && this.element.auto_display) || (this.question && this.question.auto_display)) {
      if (this.NFCType && !this.question.value) this.readNFC();
      if (this.locationType && !this.question.value) this.getGeolocation();
    }
  };
  @Input() style: any;
  @Input() element: any;
  @Input() set formsReportMode(val) {
    this.formsReport = val;

  }
  @Input() conditional_format: any;
  @Output() onAction: EventEmitter<any> = new EventEmitter();
  @Input() autoDisplay: EventEmitter<any>;
  @Output() valueChanges: EventEmitter<any> = new EventEmitter();

  public fileList = [];
  public fileURL = [];
  public selectedFiles: any = '';
  public _loader: any;
  public formsReport: boolean = false;
  public style$ = new Subject<any>();
  public onDestroy$ = new Subject<void>();
  public loaded = false;
  public checkBoxTextValue = '';

  public geoLatitude: number;
  public geoLongitude: number;
  public geoAccuracy: number;
  public geoAddress: string;
  public gettingLocation: boolean = false;

  public isWatching: boolean = false;
  public watchLocationUpdates: any;

  public isValid = true;

  constructor(
    public authService: AuthService,
    public platform: Platform,
    public commService: CommunicationService,
    public loadingService: LoadingService,
    public deviceService: DeviceService,
    public fileService: FileService,
    public geolocationService: GeolocationService,
    public alertCtrl: AlertController,
    public as: AlertService,
    public router: Router,
    public tr: TranslateService
  ) {
  }

  ngOnInit() {
    this.form.valueChanges
      .pipe(takeUntil(this.onDestroy$),
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)))
      .subscribe((value) => {
        if (!this.conditional_format && this.question && this.question.conditional_format) {
          this.conditional_format = this.question.conditional_format
        }
        if (this.conditional_format) {
          let new_format = this.checkConditionalFormat(this.conditional_format, value);
          if (value && new_format) {
            this.style = new_format;
          }
        }
        this.valueChanges.emit(true);
        this.isValid = this.checkIsValid();
      })
    this.loaded = true;

    if ((this.element && this.element.auto_display) || (this.question && this.question.auto_display)) {
      if (this.NFCType && !this.question.value) this.readNFC();
      if (this.locationType && !this.question.value) this.getGeolocation();
    }
    if (this.conditional_format) {
      if (this.form && this.form.controls && this.form.controls) {
        let temp_form = [];
        let keys = Object.keys(this.form.controls);
        keys.forEach(key => {
          temp_form[key] = this.form.controls[key].value;
        });

        let format = this.checkConditionalFormat(this.conditional_format, temp_form)
        if (format) this.style = format;
      }

    }

    this.autoDisplay.subscribe((val) => {
      if (val && val === this.question.tag) {
        this.readNFC();
      }
    })
  }

  checkIsValid() {
  
    if (!this.question) return false;
    if(this.question.readonly || this.question.disabled) return true;

    if (this.question && this.question['regex_constraints']) {
      this.form.controls[this.question.tag].setValidators(Validators.pattern(this.question['regex_constraints']));
      this.form.controls[this.question.tag].updateValueAndValidity();
    }

    if (this.form && this.form.controls && this.question && this.form.controls[this.question.tag] && this.form.controls[this.question.tag].value != '' && (this.question['min'] || this.question['max'])) {
      if (this.question['min'] && (parseInt(this.form.controls[this.question.tag].value) < this.question['min'])) {
        return false;
      } else if (this.question['max'] && (parseInt(this.form.controls[this.question.tag].value) > this.question['max'])) {
        return false;
      }
    }

    if (this.dropdownType) {
      if (this.question.value === '' || !this.question.value) {
        return true;
      }
    }
    this.form.updateValueAndValidity();
    try {
      if (this.form && this.form.controls && this.form.controls[this.question.tag]) {
        return this.form.controls[this.question.tag].valid;
      }
    } catch (e) {
      //  this.as.showError();
    }

    return false;
  }

  checkConditionalFormat(conditional_format, value) {
    let new_format;
    if (!value) return;
    conditional_format.forEach(condition => {
      if (!condition.value) {
        return;
      }
      let conditional_value = null;
      if (!condition.tag) {
        conditional_value = value[this.question.tag];
      } else {
        let condition_tag = condition.tag.split('.')[1];
        conditional_value = value[condition_tag];
      }
      if (conditional_value && (condition.value.toString().toLowerCase() === conditional_value.toString().toLowerCase())) {
        new_format = condition.format;
      } else if (condition.value === 'custom_value_exists' && conditional_value) {
        new_format = condition.format;
      } else if (condition.value === 'custom_value_no_exists' && (!conditional_value || conditional_value === "")) {
        new_format = condition.format;
      }
    });
    return new_format;
  }
  get boxType() {
    if (this.question && this.question.type) {
      return this.question.type != 'button'
        && !this.dropdownType
        && !this.textareaType
        && !this.rangeType
        && !this.fileType
        && !this.urlImage
        && !this.radioType
        && !this.radioButtonType
        && !this.checkboxType
        && !this.locationType
        && !this.htmlType
        && !this.NFCType;
    }
    return false;

  }

  get dropdownType() {
    if (this.question && this.question.type) {
      return this.question.type === 'dropdown';
    }
    return false;

  }

  get autofocus() {
    return !this.question.value && this.formsReport && this.question.value != 0;
  }

  get textareaType() {
    if (this.question && this.question.type) {
      return this.question.type === 'textarea';
    }
    return false;
  }

  get rangeType() {
    if (this.question && this.question.type) {
      return this.question.type === 'range';
    }
    return false;
  }

  get fileType() {
    if (this.question && this.question.type) {
      if (this.question.value) {
        this.fileList = this.question.value;
      }
      return this.question.type === 'file';
    }
    return false;
  }

  get urlImage() {
    if (this.question && this.question.type) {
      return this.question.type === 'url_image';
    }
    return false;
  }

  get radioType() {
    if (this.question && this.question.type) {
      return this.question.type === 'radio';
    }
    return false;
  }

  get radioButtonType() {
    if (this.question && this.question.type) {
      return this.question.type === 'radio-button';
    }
    return false;
  }

  get checkboxType() {
    if (this.question && this.question.type) {
      return this.question.type === 'checkbox';
    }
    return false;
  }

  get locationType() {
    if (this.question && this.question.type) {
      return this.question.type === 'location';
    }
    return false;
  }

  get htmlType() {
    if (this.question && this.question.type) {
      return this.question.type === 'html';
    }
    return false;
  }

  get NFCType() {
    if (this.question && this.question.type) {
      return this.question.type === 'nfc';
    }
    return false;
  }

  async fileChange($event, type?) {
    if ($event.target && $event.target.files[0]) {
      let files = $event.target.files;
      await this.loadingService.presentLoading();

      for (var i = 0; i < files.length; i++) {
        try {
          let res = await this.commService.postFile('/file', { 'module_id': this.moduleId, filename: files[i].name }, files[i])
          this.fileList.push(res);
          let helper = {};
          helper[this.question.tag] = this.fileList;
          this.form.patchValue(helper);
        } catch (error) {
          await this.loadingService.dismissLoading();
          await this.as.showError();
        }
      }
      await this.loadingService.dismissLoading();
    }
  }

  getFileURL(url) {
    if (url.charAt(0) != '/') url = '/' + url;
    return this.commService.baseURL + url;
  }

  removeFile(file) {
    if (this.fileList.indexOf(file) != -1) {
      let index = this.fileList.indexOf(file);
      this.fileList.splice(index, 1);
      let helper = {};
      helper[this.question.tag] = this.fileList;
      this.form.patchValue(helper);
    }
  }

  async getGeolocation() {
    this.gettingLocation = true;
    try {
      if ((this.element && this.element.coordinates) || this.question.coordinates) {
        this.geolocationService.getAddress(true)
          .pipe(takeUntil(this.onDestroy$))
          .subscribe((position) => {
            if(!position) return;
            if(!position.coords) return;
            let tmp = {
              timestamp: position.timestamp,
              coords: {
                accuracy: position.coords.accuracy,
                altitude: position.coords.altitude,
                altitudeAccuracy: position.coords.altitudeAccuracy,
                heading: position.coords.heading,
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
                speed: position.coords.speed,
              }
            };
            this.question.value = JSON.stringify(tmp);
            this.gettingLocation = false;
            this.form.controls[this.question.tag].setValue(this.question.value);
          })
      } else {
        this.geolocationService.getAddress(false)
          .pipe(takeUntil(this.onDestroy$))
          .subscribe((val: any) => {
            if (!val) return;
            this.question.value =
              val.street +
              (val.street ? (', ' + val.City) : val.City) +
              ((val.street || val.City) ? (', ' + val.State) : val.State) +
              ((val.street || val.City || val.State) ? (', ' + val.Country) : val.Country);
            this.gettingLocation = false;
            this.form.controls[this.question.tag].setValue(this.question.value);
          })
      }
    } catch (e) {
      if (this.element.hide_value || this.question.hide_value) this.question.value = null;
      else this.question.value = "GPS Error";
    }
  }

  //Stop location update watch
  stopLocationWatch() {
    this.isWatching = false;
    this.gettingLocation = false;
    if (this.watchLocationUpdates)
      this.watchLocationUpdates.unsubscribe();
  }

  async readNFC() {
    if (!this.platform.is('capacitor')) return;
    let res;
    const params: any = {};
    await this.tr.get('forms.nfc.read_title').forEach(x => params.title = x);
    await this.tr.get('forms.nfc.read_subtitle').forEach(x => params.subtitle = x);

    if (this.question.nfc_message) params.subtitle = this.question.nfc_message;
    if (this.element.nfc_message) params.subtitle = this.element.nfc_message;

    console.log("PARAMS", params);

    if (this.platform.is('ios')) {
      res = await NFCPlugin.isPluginAvailable({ title: params.title, subtitle: params.subtitle });
    } else if (this.platform.is('android')) {
      res = await NFCPlugin.read(params);
    }

    if (res && (res.payload || res.uid)) {
      if (res.uid) this.question.value = JSON.stringify(res);
      else this.question.value = res.payload;
    }
    else {
      if (this.element.hide_value || this.question.hide_value) this.question.value = null;
      else this.question.value = "ERROR Reading NFC";
    }

    this.form.controls[this.question.tag].setValue(this.question.value);

    if (this.element && this.element.actions) {
      this.onAction.emit(this.element.actions);
    }


  }

  async deleteRegistry(element) {

    let translations = {};
    await this.tr.get(['general', 'forms.alert.delete_registry']).forEach(x => translations = x);

    let alert = await this.alertCtrl.create({
      message: translations['forms.alert.delete_registry'],
      buttons: [
        {
          text: translations['general'].no,
          role: 'cancel',
        },
        {
          text: translations['general'].yes,
          handler: async () => {
            this.question.value = element.value;
            this.form.controls[this.question.tag].setValue(this.question.value);
            this.onAction.emit([element]);
          }
        }

      ]
    });
    await alert.present();
  }

  get isiOS() {
    return this.platform.is('ios');
  }

  get isCordova() {
    return this.platform.is('cordova');
  }

  isImage(type) {
    //TODO: no detecta imatges ara mateix, tot FILE i la url no funciona s'ha de fer public al backend
    return this.fileService.isImage(type);
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }

  // checkboxChecked(opt) {
  //     if (!this.question.value) {
  //         this.question.value = [];
  //         return false;
  //     }
  //     if (this.question.value.indexOf(opt.value) === -1) {
  //         return false;
  //     }
  //     return true;
  // }

  // checkboxClicked(opt, $event) {
  //     console.log("CLICKED", $event);
  //     if (!this.question.value || this.question.value.length === 0) {
  //         this.question.value = [];
  //         return;
  //     }
  //     if (this.question.value && this.question.value.indexOf(opt.value) === -1) {
  //         this.question.value.push(opt.value);
  //     } else {
  //         let index = this.question.value.indexOf(opt.value);
  //         this.question.value.splice(index, 1);
  //     }
  //     this._changeDetectorRef.markForCheck();
  // }


  changeRadioButton(event, force = false) {
    if (!event) return;
    if (event && event.detail) this.question.value = event.detail.value;
  }


  changeRange(event) {
    if (!event) return;
    this.question.value = event.target.value;
  }

  clickCheckBox() {
    this.question.value = this.question.value ? !this.question.value : true;
  }

  navigateToPath(){
    if(!this.path || !this.path.url) return;
    let url = this.path.url.replace("{field}", this.question.value);
    this.router.navigate([url]);
  }

  //TODO
  // fieldRequired(question){
  //   let hidden = false;


  //   if(question && question.format && question.format['display'] === 'none') hidden = true;
  //   if(question && question.format && question.format['hidden'] === 'none') hidden = true;

  //   if(this.style && this.style['display'] === 'none') hidden = true;
  //   if(this.style && this.style['visibility'] === 'hidden') hidden = true;

  //   let required = question.required || false;
  //   if(hidden) this.form.controls[this.question.tag].setValue(" ");
  //   return required;
  // }

}

