import { Injectable } from '@angular/core';
import { UserService } from './user.service';
import { AuthService } from './auth.service';
import { Platform, ToastController } from '@ionic/angular';
import { distinctUntilChanged, filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { DeviceService } from './device.service';


import { doc, getDoc } from 'firebase/firestore';
import { EncryptionService } from './encryption.service';
import { Utils } from '../utils/utils';
import { NavigationService } from './navigation.service';
import { ChatService } from './chat.service';
import { TranslateService } from '@ngx-translate/core';


import { PushNotifications, PushNotificationSchema, ActionPerformed } from '@capacitor/push-notifications';
import { LocalNotifications } from '@capacitor/local-notifications'
import { FirebaseService } from './firebase.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  public LNEnabled = false;
  public token;
  private chatNewMessages: {};
  private groupNewMessages: {};
  private browserNotifications: boolean = false;
  public counter = 0;
  public counter2 = 0;
  public registered = false;
  public notifiedAlerts = null;
  public notifiedReactions = null;

  public regListener: any;
  public regEListener: any;
  public pushNListener: any;
  public pushAPListener: any;

  constructor(
    public userService: UserService,
    public authService: AuthService,
    public chatService: ChatService,
    public deviceService: DeviceService,
    public fs: FirebaseService,
    public navigationService: NavigationService,
    public platform: Platform,
    public toast: ToastController,
    public tr: TranslateService,
    public es: EncryptionService
  ) {

    this.checkPermissions();

    // userOrg$ id's helper observable
    const userOrg$ =
      this.userService.user$.pipe(
        distinctUntilChanged((a, b) => a && b && a.id === b.id),
        switchMap((user) => this.userService.org$.pipe(
          distinctUntilChanged((a, b) => a && b && a.id === b.id),
          map(org => ({ user, org }))
        ))
      );

    if (this.platform.is('capacitor')) PushNotifications.removeAllDeliveredNotifications();

    this.deviceService.appState$.subscribe(async (state) => {
      if (state && state.isActive) {
        if (this.platform.is('capacitor')) await PushNotifications.removeAllDeliveredNotifications();
      }
    });


    this.authService.getAuthenticationState().pipe(
      distinctUntilChanged(),
      switchMap((isAuth) => userOrg$.pipe(map((x) => ({ ...x, isAuth })))),
    ).subscribe(({ isAuth, user, org }) => {
      if (isAuth && user && org && !this.registered) {
        this.init();
      } else if (!isAuth) {
        if (this.regListener) this.regListener.remove();
        if (this.regEListener) this.regEListener.remove();
        if (this.pushNListener) this.pushNListener.remove();
        if (this.pushAPListener) this.pushAPListener.remove();
        this.registered = false;
        this.token = null;
      }
    });
    // this.authService.displayToast$.subscribe((type) => {
    //   let msg = this.tr.instant(type);
    //   this.showToast(msg, 3500);
    // })
  }

  async init() {
    this.checkPermissions();
    this.notifiedAlerts = null;
    await this.registerNotifications();
    if (!this.platform.is('capacitor')) this.subscribeUserInfo();
    this.userService.userAlerts$.pipe(
      takeUntil(this.authService.onSignOut$),
      distinctUntilChanged()).subscribe(alerts => {
        if (alerts && !this.notifiedAlerts) {
          this.notifiedAlerts = {};
          alerts.forEach(alert => {
            this.notifiedAlerts[alert.id] = alert.alerts.length;
          });
        } else if (alerts && this.notifiedAlerts) {
          alerts.forEach(alert => {
            if (this.notifiedAlerts[alert.id] && this.notifiedAlerts[alert.id] < alert.alerts.length) {
              if (alert && alert.alerts[alert.alerts.length - 1] && alert.alerts[alert.alerts.length - 1].report) {
                let notification = alert.alerts[alert.alerts.length - 1].report.notification;
                this.newNotification(notification.title, notification.body, this.userService.getOrganization().id, notification.image);
              }
            } else if (!this.notifiedAlerts[alert.id] && alert.alerts.length > 0) {
              if (alert && alert.alerts[alert.alerts.length - 1] && alert.alerts[alert.alerts.length - 1].report) {
                let notification = alert.alerts[alert.alerts.length - 1].report.notification;
                this.newNotification(notification.title, notification.body, this.userService.getOrganization().id, notification.image);
              }
            }
            this.notifiedAlerts[alert.id] = alert.alerts.length;
          });
        }
      })


    this.notifiedReactions = null;
    this.userService.newReactions$.pipe(
      takeUntil(this.authService.onSignOut$),
      distinctUntilChanged()
    ).subscribe(reactions => {
      if (reactions && !this.notifiedReactions) {
        this.notifiedReactions = {};
        reactions.forEach(reaction => {
          this.notifiedReactions[reaction.id] = reaction;
        });
      } else if (reactions && this.notifiedReactions) {
        reactions.forEach(reaction => {
          if (!this.notifiedReactions[reaction.id]) {
            try {
              reaction.title = this.es.AESdecrypt(reaction.title, reaction.chatKey);
              reaction.msg = this.es.AESdecrypt(reaction.msg, reaction.chatKey);

              this.newNotification(reaction.title, reaction.msg, this.userService.getOrganization().id, null);
              this.notifiedReactions[reaction.id] = reaction;
            } catch (error) {
              console.log("SUB NEW REACTION CATCH =>", error);
            }
          }
        });
      }
    });


  }

  public notificationTest() {
    LocalNotifications.schedule({
      notifications: [
        {
          title: 'Notification title',
          body: 'notification body',
          id: 1,
          schedule: { at: new Date(Date.now() + 200) },
          smallIcon: 'assets://icon/favicon.ico'
        }
      ]
    });
  }

  private async checkPermissions() {
    if (this.platform.is('capacitor')) {
      const pushStatus = await PushNotifications.checkPermissions();
      console.log("NOTIS: Permissions state", pushStatus);
      if (pushStatus.receive !== 'granted') await PushNotifications.requestPermissions();
    }
    const localStatus = await LocalNotifications.checkPermissions();
    if (localStatus.display !== 'granted') await LocalNotifications.requestPermissions();
  }

  private async registerNotifications() {

    if (!this.platform.is('capacitor')) {
      if (!("Notification" in window)) this.browserNotifications = false;
      if (Notification.permission === "granted") this.browserNotifications = true;
      else if (Notification.permission !== 'denied') {
        let permission = await Notification.requestPermission();
        this.browserNotifications = permission === "granted";
      }
      return;
    } else {

      // On success, we should be able to receive notifications
      if (this.regListener) this.regListener.remove();

      console.log("NOTIS: before register");
      this.regListener = PushNotifications.addListener('registration',
        async (token) => {
          console.log("NOTIS: token", token.value);
          this.token = token;
          if (this.token && this.token.value) {
            await this.deviceService.saveDeviceToken(this.token.value);
            this.LNEnabled = true;
          }
        }
      );

      // Some issue with our setup and push will not work
      if (this.regEListener) this.regEListener.remove();
      this.regEListener = PushNotifications.addListener('registrationError',
        (error: any) => {
          console.log("Notis: Error Register Notifications", error);
          this.LNEnabled = false;
        }
      );
      // Register notifications
      await PushNotifications.register();

      /**
       * En app oberta la notificació es genera al LocalNotification
       * Si no ho volem així s'ha de canviar el capacitor.config.ts i afegir 'alert' a: presentationOptions: ['sound', 'alert'],
       * Amb el canvi anterior s'ha detectat un bug que al clicar la notificació si app oberta NO fa res
       */
      if (this.pushNListener) this.pushNListener.remove();
      this.pushNListener = PushNotifications.addListener('pushNotificationReceived',
        (notification: PushNotificationSchema) => {
          console.log("NOTIS: rebuda", notification);
          this.counter2++;
          const chat_id = notification.data['medxat.chat_id'];
          const appState = this.deviceService.appState$.getValue();
          let message_key = notification.data['message.msg_key'];
          if ((appState && !appState.isActive) || (chat_id !== this.chatService.chatSelected)) {
            LocalNotifications.schedule({
              notifications: [
                {
                  id: Utils.hashCode(message_key),
                  body: notification.body,
                  title: notification.title
                }
              ]
            })
          }
        }
      );

      // Method called when tapping on a notification
      if (this.pushAPListener) this.pushAPListener.remove();
      this.pushAPListener = PushNotifications.addListener('pushNotificationActionPerformed',
        (notification: ActionPerformed) => {
          console.log("action noti", notification);
        }
      );
    }
    this.registered = true;

  }


  private subscribeUserInfo() {
    this.chatNewMessages = {};
    this.groupNewMessages = {};
    this.userService.userChatInfo$.pipe(
      filter(x => x)
    ).subscribe((info) => this.processInfo(info));
    this.userService.userGroupInfo$.pipe(
      filter(x => x)
    ).subscribe((info) => this.processInfo(info, 'groups'));
  }

  private async processInfo(info, origin = 'chats') {
    for (const key of Object.keys(info.info)) {
      const chatInfo = info.info[key];
      const id = chatInfo.id;
      const previousInfo = this.chatNewMessages[id];
      const previousUnreadMessages = (previousInfo || {}).unreadCount || [];
      const unreadMessages = (chatInfo || {}).unreadCount || [];
      const newMessages = unreadMessages.filter(x => !previousUnreadMessages.includes(x));
      this.chatNewMessages[id] = chatInfo;
      if (info.index > 0) {
        for (const messageId of newMessages) {
          if (!this.deviceService.isActive$.getValue() && this.browserNotifications)
            this.newMessageNotification(id, messageId, chatInfo.orgKey, origin)
        }

      }
    }
  }


  private async newMessageNotification(chatId, messageId: string, orgId: string, origin = 'chats') {

    const message = await getDoc(doc(this.fs.firestore, `orgs/${orgId}/${origin}/${chatId}/messages/${messageId}`));
    // const message = await this.fs.firestore
    //   .doc(`orgs/${orgId}/${origin}/${chatId}/messages/${messageId}`)
    //   .get()
    //   .pipe(first())
    //   .toPromise();

    let group;
    if (origin === 'groups') {
      group = await getDoc(doc(this.fs.firestore, `orgs/${orgId}/${origin}/${chatId}`));
      if (group.exists) group = group.data();
    }
    if (message.exists) {
      const messageData: any = message.data();
      console.log("NEW MESSAGE NOTIFICATION", messageData.content)
      const content = Utils.processHTML(this.es.AESdecrypt(messageData.content, chatId));
      console.log("NEW MESSAGE NOTIFICATION content", content)

      if (messageData.info) return;
      let body = content;
      if (messageData.file) {
        body = "📄 " + (content ? content : messageData.file.name);
      }
      const user = await getDoc(doc(this.fs.firestore, `users/${messageData.from}`));
      // await this.fs.firestore.doc(`users/${messageData.from}`).get().pipe(first()).toPromise();


      let title = (user.data() as any).profile.name;
      if (origin === 'groups' && group) title += '@' + group.name;
      let notification = new Notification(title, {
        body,
        icon: (user.data() as any).profile.smallPicture,
        tag: orgId, //cada org genera un bloc de notificació, si es de la mateixa org es reescriu
        silent: false,
        requireInteraction: true,
      });
      notification.onclick = function (event) {
        notification.close();
        if (window.document.hasFocus() === false) window.focus();
      }
    }
  }

  newNotification(title, body, orgId, icon) {
    let notification = new Notification(title, {
      body,
      icon,
      tag: orgId, //cada org genera un bloc de notificació, si es de la mateixa org es reescriu
      silent: false,
      requireInteraction: true,
    });
    notification.onclick = function (event) {
      notification.close();
      if (window.document.hasFocus() === false) window.focus();
    }
  }

  notificationsAvailable() {
    return (this.platform.is('capacitor') && this.LNEnabled) || (!this.platform.is('capacitor') && this.browserNotifications)
  }

  // async showToast(message: string = '', duration = 3000) {
  //   const done = await this.toast.create({
  //     message,
  //     duration,
  //     position: 'bottom',

  //   });
  //   await done.present();
  // }
}
