import { Injectable } from '@angular/core';
import { UserService } from './user.service';
import { AuthService } from './auth.service';
import { distinctUntilChanged, takeUntil, sampleTime, filter, flatMap, first, pluck, tap, timeout, map, switchMap, combineAll } from 'rxjs/operators';
import { Platform } from '@ionic/angular';
import { merge, fromEvent, BehaviorSubject, Observable, pipe } from 'rxjs';
import { NetworkService } from './network.service';
import { WindowService } from './window.service';

import { Device } from '@capacitor/device';
import { App, AppState } from '@capacitor/app';
import { GlobalAppService } from './global-app.service';
import { TextZoom } from '@capacitor/text-zoom';
import { Preferences } from '@capacitor/preferences';
import { getDatabase, ref, onDisconnect, DatabaseReference, remove, push, set } from "firebase/database";
import { DocumentSnapshot, serverTimestamp } from 'firebase/firestore';
import { doc as docSnapshots } from 'rxfire/firestore';
import { collection, doc, setDoc, writeBatch } from 'firebase/firestore'
import { FirebaseService } from './firebase.service';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {
  public info;
  public presenceRef: DatabaseReference;
  public connectionRef;

  public isActive: boolean = false;
  public isActive$ = new BehaviorSubject<boolean>(document.hasFocus());
  public hasFocus: boolean = false;
  public activityTimeout: any | null = null;
  public inactiveOfflineTimeout: any | null = null;
  public activeChangeTimeout: any | null = null;
  public isFirefox: any = null;
  public newVersionAvailable: boolean = false;
  public newVersionAvailable$ = new BehaviorSubject<boolean>(false);

  /**
 * Tmeout, in seconds, to automatically set the app as inactive
 * @type {number}
 */
  static ACTIVITY_TIMEOUT = 1 * 60 * 60;

  /**
   * Delay, in seconds, to switch from active to inactive
   * @type {number}
   */
  static INACTIVITY_DELAY = 0;

  /**
   * Delay, in seconds, to switch from inactive to appearing offline
   * @type {number}
   */
  static OFFLINE_DELAY = 4 * 60 * 60; //--> 1h // 4 * 60 * 60; --> 4h


  static LAST_ACTIVITY_TIME = 30 * 1000;
  public info$: BehaviorSubject<any> = new BehaviorSubject(null);
  public appState$: BehaviorSubject<AppState> = new BehaviorSubject(null);
  public deviceRefId;
  public zoom = 1.0;
  public zoomMin = 0.8;
  public zoomMax = 1.5;

  constructor(
    public fs: FirebaseService,
    public userService: UserService,
    public authService: AuthService,
    public platform: Platform,
    public windowService: WindowService,
    public globalAppService: GlobalAppService,
    public networkService: NetworkService) {
    try {
      this.setZoom();
    } catch (e) { }

    this.authService.isAuthenticated$
      .pipe(
        distinctUntilChanged((a, b) => a === b)
      ).subscribe((auth) => {
        if (auth) {
          this.userService.user$.pipe(
            filter(user => user),
            distinctUntilChanged((a, b) => a.id === b.id),
            switchMap((user) => this.userService.org$
              .pipe(
                filter(org => org),
                distinctUntilChanged((a, b) => a.id === b.id)
              ))).subscribe(() => {
                this.init();
              });
        }
      });
  }

  async init() {
    // Device app state
    App.addListener('appStateChange', async (state: AppState) => {
      this.appState$.next(state);
    });

    //Device info
    this.info = {};
    this.info = await Device.getInfo();
    let appInfo = null;
    try {
      appInfo = await App.getInfo();
    } catch (e) {
    }
    const uuid = await Device.getId();
    if (appInfo) {
      this.info.appVersion = appInfo.version;
      this.info.appBuild = appInfo.build;
      this.info.appId = appInfo.id;
      this.info.appName = appInfo.name;
    }
    if (uuid && uuid.identifier) this.info.uuid = uuid.identifier;
    if (this.info && !this.info.uuid) this.info.uuid = doc(collection(this.fs.firestore, '_')).id;
    Object.keys(this.info).forEach(key => this.info[key] === undefined && delete this.info[key]); // Fet per Firefox batteryLevel undefined i falla el batch.set

    this.deviceRefId = this.platform.is('capacitor') ? this.info.uuid : doc(collection(this.fs.firestore, '_')).id; //si es app, agafem device.uuid, sinó random ja que poden tenir multiples pestanyes

    this.info$.next(this.info);
    this.authService.onSignOut$.subscribe(val => {
      if (this.presenceRef) {
        remove(this.presenceRef);
      }
    });

    //Start Presence When ONLINE
    this.networkService.firebaseConnection$.pipe(
      distinctUntilChanged((a, b) => a === b),
      filter(conn => conn),
      takeUntil(this.authService.onSignOut$)
    ).subscribe(() => this.startPresence())


    // if (this.platform.is('capacitor')){
    await this.saveDeviceInfo();

    if (window && window.document) {
      merge(
        fromEvent(window.document, 'click'),
        fromEvent(window.document, 'touchend'),
        fromEvent(window.document, 'touchcancel'),

        fromEvent(window.document, 'scrollend')
      ).pipe(
        sampleTime(1000),
        filter(() => this.hasFocus) //|| (Date.now() - this._lastBlur > 1000)
      ).subscribe(() => {
        this.resetActivityTimeout();
      });
      if (!this.windowService.minerva) {
        merge(this.platform.resume, fromEvent(window, 'focus'))
          .subscribe(() => {
            this.hasFocus = true;
            this.resetActivityTimeout();
            if (!this.isActive)
              this.changeActiveState(true);
          });

        merge(this.platform.pause, fromEvent(window, 'blur'))
          .subscribe(() => {
            this.hasFocus = false;
            if (this.isActive) {
              this.cancelActivityTimeout();
              this.changeActiveState(false);
            }
          });
      } else {
        merge(fromEvent(window, 'focus')).pipe(sampleTime(1000))
          .subscribe(() => {
            this.hasFocus = true;
            this.windowService.sendMessageToParentWindow({ type: 'medxat-focus' });
            this.changeActiveState(true);
          });
        merge(fromEvent(window, 'blur')).pipe(sampleTime(1000))
          .subscribe(() => {
            this.hasFocus = false;
            this.windowService.sendMessageToParentWindow({ type: 'medxat-blur' });
            this.changeActiveState(false);
          });
      }

      if (!this.platform.is('capacitor')) {
        this.isFirefox = window.navigator.userAgent.match(/Firefox\/([0-9]+)\./);
      }
    }

    //STATUS CHANGE FROM MINERVA
    this.windowService.windowState$
      .pipe(
        distinctUntilChanged((a, b) => a === b),
        takeUntil(this.authService.onSignOut$)
      ).subscribe((val) => {
        this.changeActiveState(val);
      })
    // Inform any parent window that we are initialized
    this.windowService.sendMessageToParentWindow({ type: 'initialized' });


    //DETECT NEW VERSION
    if (this.platform.is('capacitor')) {
      this.globalAppService.globalParams$
        .pipe(
          distinctUntilChanged((a, b) => a === b)
        ).subscribe((appParams: any) => {
          if (this.info && this.info.appVersion
            && appParams && appParams.versions && appParams.versions.guttmann) {
            if (this.platform.is('ios')) {
              if (appParams.versions.guttmann.prodIos > this.info.appVersion) this.newVersionAvailable = true;
              else this.newVersionAvailable = false;
            } else {
              if (appParams.versions.guttmann.prodAndroid > this.info.appVersion) this.newVersionAvailable = true;
              else this.newVersionAvailable = false;
            }
          }
          if (this.newVersionAvailable) this.newVersionAvailable$.next(true);
          else this.newVersionAvailable$.next(false);
        });
    }
  }

  public async saveDeviceInfo(): Promise<any> {
    const info = await this.info$.pipe(
      filter(x => x),
      first(),
      timeout(5000)
    ).toPromise();

    let batch = writeBatch(this.fs.firestore);

    let ref = doc(this.fs.firestore, `users/${this.userService.getUser().id}/devices/${this.deviceRefId}`);

    //Prepare before logout
    this.authService.batchBeforeSignOut.set(ref, {
      signedIn: false
    }, { merge: true });

    batch.set(ref, {
      signedIn: true,
      connected: serverTimestamp(),
      ...this.info
    }, { merge: true });
    return await batch.commit();
  }

  public async saveFingerprint(data): Promise<any> {
    const info = await this.info$.pipe(
      filter(x => x),
      first(),
      timeout(5000)
    ).toPromise();

    const ref = doc(this.fs.firestore, `users/${this.userService.getUser().id}/devices/${info.uuid}`);
    return await setDoc(ref, { 'fingerprint': data }, { merge: true })
  }

  public async saveDeviceToken(token: string): Promise<any> {
    return await this.userService.user$.pipe(
      filter(x => x && x.id),
      first(),
      pluck('id'),
      flatMap(uid => this.info$.pipe(filter(x => x), first(), map((info) => ({ uid, info })))),
      flatMap(({ uid, info }) => setDoc(
        doc(this.fs.firestore, `users/${uid}/devices/${info.uuid}`),
        { token },
        { merge: true}
      )),
    ).toPromise();
  }

  public async startPresence() {
    if (this.presenceRef) {
      const oldRef = this.presenceRef;
      await remove(oldRef);
      await onDisconnect(oldRef).cancel();
    }

    this.presenceRef = push(ref(getDatabase(), `/status/${this.userService.getUser().id}`));

    const presenceInfo: any = {
      connected: serverTimestamp(),
      active: this.platform.is('capacitor') ? true : this.isActive,
      type: this.platform.is('capacitor') ? 'app' : 'web',
      ...this.info
    };
    if ((this.platform.getQueryParam('minerva'))) {
      presenceInfo.type = "minerva";
    }

    onDisconnect(this.presenceRef).remove()
    if (this.connectionRef) {
      const oldConnRef = this.connectionRef;
      let deleteBatch = writeBatch(this.fs.firestore);
      await deleteBatch.delete(oldConnRef);
    }


    this.connectionRef = await doc(
      this.fs.firestore,
      'users',
      this.userService.getUser().id,
      'devices',
      this.deviceRefId
    );

    await set(this.presenceRef, { 
      state: 'ONLINE', 
      lastChanged: new Date(), 
      orgKey: this.userService.getOrganization().id, 
      ref: this.connectionRef.id, 
      type: this.platform.is('capacitor') ? 'app' : 'web' 
    });

    this.isActive$.pipe(
      distinctUntilChanged((a, b) => a === b),
      takeUntil(this.authService.onSignOut$),
      takeUntil(this.networkService.firebaseDisconnected$)
    ).subscribe(async (val) => {

      this.isActive = val;
      presenceInfo.active = val;

      let batch = writeBatch(this.fs.firestore);
      let now = serverTimestamp();
      if (presenceInfo.connected) delete presenceInfo.connected
      if (this.isActive) {
        presenceInfo.firstActivity = now;
        presenceInfo.lastActivity = null;
      } else {
        presenceInfo.lastActivity = now;
        delete presenceInfo.firstActivity;
      }

      batch.set(this.connectionRef, presenceInfo, { merge: true });

      let userRef = doc(this.fs.firestore, 'users', this.userService.getUser().id);

      let statusUpdate: any = {
        status: {
          visibility: this.isActive ? 'ONLINE' : 'AWAY',
        }
      };
      if (!this.isActive) statusUpdate.status.lastConn = now;
      //REMEMBER: Si isActive no actualitzem lastConn perquè a vegades en 2n plà fa refresh i focus i marcaria online i no es veritat

      batch.set(userRef, statusUpdate, { merge: true });

      let statusRef = doc( this.fs.firestore, 
        'orgs',
        this.userService.getOrganization().id,
        'userStatus',
        this.userService.getUser().id
      );
      batch.set(statusRef, {
        status: {
          visibility: this.isActive ? 'ONLINE' : 'AWAY'
        }
      }, { merge: true });

      await batch.commit();
    });
  }

  public getDeviceInfo(): Observable<any> {
    return docSnapshots(doc( this.fs.firestore,
      'users',
      this.userService.getUser().id,
      'devices',
      this.info.uuid
    )).pipe(
        takeUntil(this.authService.onSignOut$),
        map((snap: DocumentSnapshot) => ({ id: snap.id, ...snap.data() }))
      );
  }

  public changeActiveState(active: boolean, forced = false) {
    this.cancelActivityTimeout();
    this.cancelActiveChangeTimeout();
    this.cancelInactiveOfflineTimeout();
    this.isActive$.next(active);
  }

  public resetActivityTimeout() {
    this.cancelActivityTimeout();
    this.cancelActiveChangeTimeout();
    this.cancelInactiveOfflineTimeout();

    this.changeActiveState(true);

    this.activityTimeout = setTimeout(() => {
      this.changeActiveState(false, true);
    }, DeviceService.ACTIVITY_TIMEOUT * 1000);
  }

  public cancelActivityTimeout() {
    if (this.activityTimeout) {
      clearTimeout(this.activityTimeout);
      this.activityTimeout = null;
    }
  }

  public cancelActiveChangeTimeout() {
    if (this.activeChangeTimeout) {
      clearTimeout(this.activeChangeTimeout);
      this.activeChangeTimeout = null;
    }
  }

  public cancelInactiveOfflineTimeout() {
    if (this.inactiveOfflineTimeout) {
      clearTimeout(this.inactiveOfflineTimeout);
      this.inactiveOfflineTimeout = null;
    }
  }

  public zoomIn() {
    if (this.zoom < this.zoomMax) {
      this.zoom += 0.1;
      if (this.platform.is('capacitor')) {
        TextZoom.set({ value: this.zoom });
      } else {
        //document.body.style.zoom = this.zoom * 100 + "%";
      }
    }
    this.saveZoom();
  }

  public zoomOut() {
    if (this.zoom >= this.zoomMin) {
      this.zoom -= 0.1;
      if (this.platform.is('capacitor')) {
        TextZoom.set({ value: this.zoom });
      } else {
        //document.body.style.zoom = this.zoom * 100 + "%";
      }
    }
    this.saveZoom();
  }

  saveZoom() {
    return Preferences.set({ key: 'zoom', value: this.zoom.toString() });
  }

  async setZoom() {
    if (this.platform.is('capacitor')) this.zoom = (await TextZoom.getPreferred()).value;
    let sg = await Preferences.get({ key: 'zoom' });
    if (sg.value) this.zoom = parseFloat(sg.value);
    if (this.zoom > this.zoomMax) this.zoom = this.zoomMax;
    if (this.zoom < this.zoomMin) this.zoom = this.zoomMin;
    if (this.platform.is('capacitor')) TextZoom.set({ value: this.zoom });
    //else document.body.style.zoom = this.zoom * 100 + "%";
  }


  isMobile() {
    return this.platform.is('mobile') || this.platform.is('tablet') || this.platform.is('phablet') || this.platform.is('mobileweb');
  }
}
