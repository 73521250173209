import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PinComponent } from '../shared/pin/pin.component';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { EncryptionService } from 'src/services/encryption.service';
import { Platform, NavParams, ModalController } from '@ionic/angular';
import { UserService } from 'src/services/user.service';
import { NetworkService } from 'src/services/network.service';
import { takeUntil, distinctUntilChanged, map } from 'rxjs/operators';
import { AlertService } from 'src/services/alert.service';
import { AuthService } from 'src/services/auth.service';
import FingerprintPlugin from 'src/capacitor_plugins/fingerprint-plugin';

@Component({
  selector: 'app-pin',
  templateUrl: './pin.page.html',
  styleUrls: ['./pin.page.scss'],
})
export class PinPage implements OnInit, OnDestroy {

  public mode: string;
  public previousPin: string;
  public failedAttempts = 0;
  public pinCode: PinComponent;
  public firebasePinMd5: string;
  public showFingerprint = false;
  public user_fingerprint: boolean;
  public onDestroy$ = new Subject<void>();

  public max_attempts: number;
  public title = '';
  public fingerprint_dialog_title: string;
  public enter_pin: string;
  public confirm_pin: string;
  public set_pin: string;
  public enter_change_pin: string;
  public error_confirmation = '';
  public error_wrong_pin = '';
  public use_fingerprint_dialog: string;
  public show_help_text = false;
  public help_text_mode = '';
  public offlineMode: boolean = true;
  public offline_mode_title: string;
  public previous_title: string;
  fallback: any;
  cancel: any;


  @ViewChild(PinComponent, { static: false }) set pinComponent(v: PinComponent) {
    this.pinCode = v;
  }


  constructor(
    public translateService: TranslateService,
    public plt: Platform,
    public userService: UserService,
    public networkService: NetworkService,
    public modalController: ModalController,
    public navParams: NavParams,
    public alertService: AlertService,
    public auth: AuthService
  ) {

  }

  ngOnInit() {
    this.networkService.networkStatus$.pipe(
      takeUntil(this.onDestroy$),
      distinctUntilChanged(),
    ).subscribe((status) => {
      if (!status || !status.connected) {
        this.previous_title = this.title;
        this.title = this.offline_mode_title;
        this.offlineMode = true;
      } else {
        if (this.offlineMode) { this.title = (this.title === this.offline_mode_title) ? this.previous_title : this.title; }
        this.offlineMode = false;
      }
    });

    this.mode = this.navParams.get('mode');
    this.firebasePinMd5 = this.navParams.get('firebase_pin');
    this.user_fingerprint = this.navParams.get('user_fingerprint');
    this.max_attempts = this.navParams.get('max_attempts') || 5;

    this.translateService.get('pin')
      .pipe(
        takeUntil(this.onDestroy$)
      )
      .subscribe((pin_translations) => {
        this.error_confirmation = pin_translations.error_confirmation;
        this.error_wrong_pin = pin_translations.error_wrong_pin;
        this.enter_pin = pin_translations.enter_pin;
        this.confirm_pin = pin_translations.confirm_pin;
        this.set_pin = pin_translations.set_pin;
        this.enter_change_pin = pin_translations.enter_change_pin;
        this.fingerprint_dialog_title = pin_translations.fingerprint_dialog_title;
        this.use_fingerprint_dialog = pin_translations.use_fingerprint_dialog;
        this.offline_mode_title = pin_translations.offline_mode_title;
        this.fallback = pin_translations.fallback;
        this.cancel = pin_translations.cancel;
        this.setMode(this.mode);

      });
    setTimeout(() => {
      if (this.showFingerprint) { this.presentFingerprint(); }
    }, 500);

    this.userService.user$.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe((user) => {
      if (user && user.validation && user.validation.pin) {
        this.failedAttempts = user.validation.pin.failedAttempts || 0;
      }
    });

    this.setMode(this.mode);

    this.auth.isAuthenticated$.pipe(
      takeUntil(this.onDestroy$),
      distinctUntilChanged()
    ).subscribe((val) => {
      if (!val) this.modalController.dismiss();
    })
  }

  onPinComplete(pin) {
    switch (this.mode) {
      case 'set':
        this.previousPin = pin;
        this.setMode('confirm');
        break;
      case 'confirm':
        if (this.previousPin === pin) {
          this.modalController.dismiss({ pin });
        } else {
          this.showToast(this.error_confirmation);
          this.pinCode.errorShake();
          this.setMode('set');
        }
        break;
      case 'enter':
        if (this.correctPin(pin)) {
          this.success();
          this.modalController.dismiss({ success: true });
        } else {
          this.fail();

        }
        break;

      case 'change':
        if (this.correctPin(pin)) {
          this.success().then(() => this.setMode('set'));
        } else {
          this.fail();
        }
    }
  }

  public setMode(mode: string) {
    this.mode = mode;
    switch (this.mode) {
      case 'set':
        this.title = this.set_pin;
        this.showFingerprint = false;
        break;
      case 'confirm':
        this.title = this.confirm_pin;
        this.showFingerprint = false;
        break;
      case 'enter':
        this.title = this.enter_pin;
        this.showFingerprint = this.user_fingerprint;
        break;
      case 'change':
        this.title = this.enter_change_pin;
        this.showFingerprint = false;
        break;
      case 'fingerprint':
        this.title = this.use_fingerprint_dialog;
    }

    setTimeout(() => {
      if (this.pinCode) { this.pinCode.clear(); }

    }, 200);

  }

  public showToast(error_msg: string) {
    // let toast = this.toastController
    //   .create({
    //     message: error_msg + ' (' + (this.failedAttempts) + '/' + this.max_attempts + ')',
    //     duration: 3000,
    //     position: 'bottom'
    //   });
    // toast.present();
  }

  public async presentFingerprint() {
    console.log("FINGERPRINT_ DATA 1")
    try {
      const data = await FingerprintPlugin.authenticate({
        title: this.fingerprint_dialog_title,
        cancel: this.cancel,
        fallback: this.fallback
      });
      console.log("FINGERPRINT_ DATA", data)
      if (data.authentication === 'success') {
        await this.modalController.dismiss({ success: true });
      }
    } catch (e) {
      console.log("FINGERPIRNT_ ERROR", e)
    }
    // this.faio.show({
    //   clientId: '1234', //Android: Used for encryption. iOS: used for dialogue if no `localizedReason` is given.
    //   clientSecret: 'o7aoOMYUbyxaD23oFAnJ', //Necessary for Android encrpytion of keys. Use random secret key.
    //   disableBackup: true, //Only for Android(optional),
    //   localizedReason: this.fingerprint_dialog_title
    // })
    //   .then((result: any) => {
    //     this.viewController.dismiss({ success: true });
    //   })
    //   .catch((error: any) => {
    //   });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }


  async saveFingerprint(use: boolean) {
    if (use) {
      const data = await FingerprintPlugin.authenticate({
        title: this.fingerprint_dialog_title,
        cancel: this.cancel,
        fallback: this.fallback
      });
      if (data.authentication === 'success') {
        await this.modalController.dismiss({ use_fingerprint: true });
      }
    }
    else
      this.modalController.dismiss({ use_fingerprint: false });

  }

  public correctPin(pin: string) {
    return this.firebasePinMd5 === EncryptionService.SHA512Encrypt(pin);
  }

  showHelpText(mode) {
    this.help_text_mode = mode;
    this.show_help_text = true;
  }

  closeHelpText() {
    this.show_help_text = false;
  }

  public fail() {
    ++this.failedAttempts;
    this.userService.updateValidationProperty('pin.failedAttempts', this.failedAttempts).then(() => {
      this.pinCode.errorShake();
      this.showToast(this.error_wrong_pin);
      this.setMode(this.mode);
      if (this.failedAttempts >= this.max_attempts) { this.modalController.dismiss({ error: true }); }

    }, () => {
      this.pinCode.errorShake();
      this.showToast(this.error_wrong_pin);
      this.setMode(this.mode);
    });
  }

  public success() {
    this.failedAttempts = 0;
    return this.userService.updateValidationProperty('pin.failedAttempts', this.failedAttempts);
  }

  getHelpText() {
    if (this.offlineMode) { return 'pin.offline_mode_subtitle'; }
    return (this.mode !== 'change' && this.mode !== 'enter') ? 'pin.help_text' : 'pin.forgot_password';
  }

}
