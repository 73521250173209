import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { fromEvent } from 'rxjs';

@Component({
  selector: 'search-patient-modal',
  templateUrl: './search-patient-modal.component.html',
  styleUrls: ['./search-patient-modal.component.scss'],
})
export class SearchPatientModalComponent implements OnInit, OnDestroy{
  searchData={
    nhc: null,
  };

  loading;
  keyboard$;

  constructor(
    public modalController: ModalController,
    public zone: NgZone
  ) { }

  ngOnInit() {
    this.keyboard$ = fromEvent(document, 'keypress')
      .subscribe((event: any) => {
        if (event && event.keyCode === 13) {
          this.zone.run(async () => {
            if(!this.disable())
            {
              this.keyboard$.unsubscribe();
              this.dismiss();
            }
          });
        }
      });
  }

  ngOnDestroy(): void {
    if (this.keyboard$) this.keyboard$.unsubscribe();
  }

  dismiss(search = true){
    this.modalController.dismiss(search ? this.searchData:null);
  }

  disable() {
    return this.loading || !this.searchData?.nhc || isNaN(this.searchData?.nhc);
  }

}
