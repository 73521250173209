import { Component, OnInit, Input } from '@angular/core';

export enum ReceiptStatus {
  PendingSend,
  ReceivedByBackend,
  DeliveredToRecipient,
  SeenByRecipient,
}

@Component({
  selector: 'receipt-status',
  templateUrl: './receipt-status.component.html',
  styleUrls: ['./receipt-status.component.scss'],
})
export class ReceiptStatusComponent implements OnInit {
  @Input() status;
  constructor() { }

  ngOnInit() { }

  get isPending(): boolean {
    return this.status === ReceiptStatus.PendingSend;
  }

  get isReceived(): boolean {
    return this.status === ReceiptStatus.ReceivedByBackend;
  }

  get isDelivered(): boolean {
    return this.status === ReceiptStatus.DeliveredToRecipient;
  }

  get isSeen(): boolean {
    return this.status === ReceiptStatus.SeenByRecipient;
  }

}

export class ReceiptStatusUtil {

  static getMessageStatus(message): ReceiptStatus {
    if (message.seen) {
      return ReceiptStatus.SeenByRecipient;
    }

    if (message.delivered) {
      return ReceiptStatus.DeliveredToRecipient;
    }

    if (message.rcvd) {
      return ReceiptStatus.ReceivedByBackend;
    }

    return ReceiptStatus.PendingSend;
  }

}
