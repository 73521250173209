import { Component, Input, ElementRef } from '@angular/core';

@Component({
  selector: 'info-message',
  templateUrl: './info-message.component.html',
  styleUrls: ['./info-message.component.scss'],
})
export class InfoMessageComponent {

   // The width for the component, or 'auto' if not supplied
   @Input() width: string = 'auto';

   // What type of info message this is
   @Input() type: string;
 
   // Any extra data to store on this component
   @Input() data: any;
 
   @Input() content: any;

   public _colorClass: string = 'fab-md-lightGray';
 
   @Input()
   set color(val: string) {
     this._colorClass = `fab-md-${val}`;
   }
 
   constructor(public elementRef: ElementRef) {
 
   }
 
   get nativeElement() {
     return this.elementRef.nativeElement;
   }
}
