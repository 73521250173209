import { Component, OnInit } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';

@Component({
  selector: 'app-awards-info',
  templateUrl: './awards-info.component.html',
  styleUrls: ['./awards-info.component.scss'],
})
export class AwardsInfoComponent implements OnInit {

  constructor(public modalCtrl: ModalController,
    public platform: Platform) { }

  ngOnInit() {}


  dismiss() {
    this.modalCtrl.dismiss();
  }
}
