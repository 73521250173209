export class Utils {

    static removeAccents(str) {
        let accents = 'ÀÁÂÃÄÅàáâãäåßÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž';
        let accentsOut = "AAAAAAaaaaaaBOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz";
        str = str.split('');
        str.forEach((letter, index) => {
            let i = accents.indexOf(letter);
            if (i != -1) {
                str[index] = accentsOut[i];
            }
        });
        return str.join('');
    }

    static encodeName(name: string, uid: string) {
        let lgth = uid.length - 3;
        return uid.substr(0, 2) + "" + uid.substr(lgth, 3);
    }

    static processHTML(escapedMessage: any): string {
        if (!escapedMessage) {
            return '';
        }
        return escapedMessage.replace(/&gt;/g, '>').replace(/&lt;/g, '<');
    }

    static hashCode(s: String){
        let hash = 0;
        if(s.length == 0) return hash;
        for(let i = 0; i < s.length; ++i){
            let char = s.charCodeAt(i);
            hash = ((hash << 5) - hash) + char;
            hash = hash & hash;
        }
        return hash;
    }


}
