// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text-loader {
  color: white;
}
.text-loader div {
  height: 40px;
  width: 40px;
  background-color: var(--ion-color-primary);
  border-radius: 50%;
  text-align: center;
  padding-top: 2px;
}
.text-loader div.nosubtitle {
  display: -webkit-flex; /* Safari */
  -webkit-align-items: center; /* Safari 7.0+ */
  display: flex;
  align-items: center;
  vertical-align: center;
  text-align: center;
}
.text-loader .title {
  font-weight: bold;
  font-size: 1rem;
  margin-top: 2px;
  margin-bottom: 0px;
}
.text-loader .title.nosubtitle { /* Safari 6.1+ */
  flex: 1;
  margin: 0px;
  padding: 0px;
}
.text-loader .title.small {
  font-size: 0.8rem;
}
.text-loader hr {
  margin: 0px;
  padding: 0px;
}
.text-loader .subtitle {
  font-weight: bold;
  font-size: 0.6rem;
  margin-top: 0.08rem;
  margin-bottom: 0px;
}
.text-loader .subtitle.ios {
  margin-top: -0.2rem !important;
}
.text-loader .square-style {
  border-radius: 0;
  height: 100%;
  padding-bottom: 4px;
}
.text-loader .square-style .title {
  margin-top: 1.2px !important;
}
.text-loader .square-style .subtitle {
  margin-top: 0px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/text-loader/text-loader.component.scss"],"names":[],"mappings":"AAAA;EAEE,YAAA;AAAF;AACE;EACE,YAAA;EACA,WAAA;EACA,0CAAA;EACA,kBAAA;EACA,kBAAA;EACA,gBAAA;AACJ;AACI;EACE,qBAAA,EAAA,WAAA;EACA,2BAAA,EAAA,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,sBAAA;EACA,kBAAA;AACN;AAGE;EACE,iBAAA;EACA,eAAA;EACA,eAAA;EACA,kBAAA;AADJ;AAGI,iCACE,gBAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;AADN;AAII;EACE,iBAAA;AAFN;AAME;EACE,WAAA;EACA,YAAA;AAJJ;AAME;EACE,iBAAA;EACA,iBAAA;EACA,mBAAA;EACA,kBAAA;AAJJ;AAKI;EACE,8BAAA;AAHN;AAOE;EACE,gBAAA;EACA,YAAA;EACA,mBAAA;AALJ;AAOI;EACE,4BAAA;AALN;AAQI;EACE,0BAAA;AANN","sourcesContent":[".text-loader {\n  $text-image-size: 4.3rem !default;\n  color: white;\n  div {\n    height: 40px;\n    width: 40px;\n    background-color: var(--ion-color-primary);\n    border-radius: 50%;\n    text-align: center;\n    padding-top: 2px;\n\n    &.nosubtitle {\n      display: -webkit-flex; /* Safari */\n      -webkit-align-items: center; /* Safari 7.0+ */\n      display: flex;\n      align-items: center;\n      vertical-align: center;\n      text-align: center;\n    }\n  }\n\n  .title {\n    font-weight: bold;\n    font-size: 1rem;\n    margin-top: 2px;\n    margin-bottom: 0px;\n\n    &.nosubtitle {\n      -webkit-flex: 1; /* Safari 6.1+ */\n      flex: 1;\n      margin: 0px;\n      padding: 0px;\n    }\n\n    &.small {\n      font-size: 0.8rem;\n    }\n  }\n\n  hr {\n    margin: 0px;\n    padding: 0px;\n  }\n  .subtitle {\n    font-weight: bold;\n    font-size: 0.6rem;\n    margin-top: 0.08rem;\n    margin-bottom: 0px;\n    &.ios{\n      margin-top: -0.2rem !important;\n    }\n  }\n\n  .square-style {\n    border-radius: 0;\n    height: 100%;\n    padding-bottom: 4px;\n\n    .title {\n      margin-top: 1.2px !important;\n    }\n\n    .subtitle {\n      margin-top: 0px !important;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
