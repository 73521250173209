// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ion-color-lightGray {
  --ion-color-base: var(--ion-color-lightGray) !important;
  --ion-color-base-rgb: var(--ion-color-lightGray-rgb) !important;
  --ion-color-contrast: var(--ion-color-lightGray-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-lightGray-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-lightGray-shade) !important;
  --ion-color-tint: var(--ion-color-lightGray-tint) !important;
}

h1 {
  text-align: center;
}

.fingerprint {
  text-align: center;
}
.fingerprint ion-icon {
  font-size: 9rem;
  margin-top: 2rem;
}

.help-warning img {
  margin-top: 2rem;
  max-width: 80px;
  display: block;
  margin: auto;
}`, "",{"version":3,"sources":["webpack://./src/app/pin/pin.page.scss"],"names":[],"mappings":"AAAA;EACE,uDAAA;EACA,+DAAA;EACA,oEAAA;EACA,4EAAA;EACA,8DAAA;EACA,4DAAA;AACF;;AAGA;EACE,kBAAA;AAAF;;AAEA;EACE,kBAAA;AACF;AAAE;EACE,eAAA;EACA,gBAAA;AAEJ;;AAEE;EACE,gBAAA;EACA,eAAA;EACA,cAAA;EACA,YAAA;AACJ","sourcesContent":[".ion-color-lightGray {\n  --ion-color-base: var(--ion-color-lightGray) !important;\n  --ion-color-base-rgb: var(--ion-color-lightGray-rgb) !important;\n  --ion-color-contrast: var(--ion-color-lightGray-contrast) !important;\n  --ion-color-contrast-rgb: var(--ion-color-lightGray-contrast-rgb) !important;\n  --ion-color-shade: var(--ion-color-lightGray-shade) !important;\n  --ion-color-tint: var(--ion-color-lightGray-tint) !important;\n}\n\n\nh1{\n  text-align: center;\n}\n.fingerprint{\n  text-align: center;\n  ion-icon{\n    font-size: 9rem;\n    margin-top: 2rem;\n  }\n}\n.help-warning{\n  img{\n    margin-top: 2rem;\n    max-width: 80px;\n    display:block;\n    margin:auto;\n  }\n}\n\n.pin-code-container{\n  // height: 100%;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
