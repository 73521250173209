import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customCapitalize'
})
export class CustomCapitalizePipe implements PipeTransform {

  transform(value) {    
    let res = "";
    let strings = value.split(" ");
    for(let str of strings){
      if(str && str[0]) res += str[0].toUpperCase();
    }
    return res;
  }

}
