// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-item {
  padding-left: 10px !important;
}
ion-item .item-inner {
  margin-left: 0.7rem !important;
}
ion-item ion-icon {
  margin-right: 0.7rem;
}

ion-spinner {
  margin-right: 0.7rem;
}

.label-wrap {
  white-space: pre-wrap;
}
.label-wrap.title {
  font-weight: bold;
  font-size: 0.9rem;
  word-wrap: break-word !important;
}
.label-wrap .body {
  font-size: 0.5rem;
  white-space: normal;
  word-wrap: break-word !important;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/header-alert/header-alert.component.scss"],"names":[],"mappings":"AAGA;EACI,6BAAA;AAFJ;AAII;EACA,8BAAA;AAFJ;AAKI;EACI,oBAAA;AAHR;;AAOA;EACI,oBAAA;AAJJ;;AAOA;EACI,qBAAA;AAJJ;AAKI;EACI,iBAAA;EACA,iBAAA;EACA,gCAAA;AAHR;AAMI;EACI,iBAAA;EACA,mBAAA;EACA,gCAAA;AAJR","sourcesContent":["\n  \n\nion-item {\n    padding-left: 10px !important;\n\n    .item-inner {\n    margin-left: 0.7rem !important;\n    }\n\n    ion-icon{\n        margin-right: 0.7rem;\n    }\n}\n\nion-spinner{\n    margin-right: 0.7rem;\n}\n\n.label-wrap{\n    white-space: pre-wrap;\n    &.title {\n        font-weight: bold;\n        font-size: 0.9rem;\n        word-wrap: break-word !important;\n    }\n    \n    .body {\n        font-size: 0.5rem;\n        white-space: normal;\n        word-wrap: break-word !important;\n    }\n}\n\n  \n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
