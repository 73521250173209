import { Injectable, ElementRef } from '@angular/core';
import { AuthService } from './auth.service';
import {
  distinctUntilChanged,
  first,
  map,
  switchMap,
  takeUntil,
  catchError, scan, tap, startWith
} from 'rxjs/operators';
import { UserService } from './user.service';
import { BehaviorSubject, combineLatest, from, Observable, of, Subject } from 'rxjs';
import { ColorGenerator } from 'src/app/shared/text-img/color-generator';
import { FileService } from './file.service';
import { ModalController, Platform, AlertController, NavController, ToastController } from '@ionic/angular';
import { GalleryComponent } from 'src/app/shared/gallery/gallery.component';
import { LoadingService } from './loading.service';
import { EncryptionService } from './encryption.service';
import { AlertService } from './alert.service';
import { TranslateService } from '@ngx-translate/core';
import Autolinker from 'autolinker';
import { ReceiptStatus, ReceiptStatusUtil } from 'src/app/shared/receipt-status/receipt-status.component';
import { TimeService } from './time.service';
import { ContactSelectorComponent } from 'src/app/shared/contact-selector/contact-selector.component';
import { NavigationService } from './navigation.service';
import { Utils } from '../utils/utils';
import { CommunicationService } from './communication.service';
import { AudioService } from './audio.service';
import { DatePipe } from '@angular/common';

import { Browser } from '@capacitor/browser';
import { Camera, CameraResultType, ImageOptions, CameraSource } from '@capacitor/camera';
import { Keyboard, KeyboardInfo, KeyboardResize } from '@capacitor/keyboard';
import { arrayUnion, deleteField, DocumentChange, DocumentReference, increment, serverTimestamp } from 'firebase/firestore';
import { addDoc, collection, doc, getDoc, getDocs, limit, orderBy, query, setDoc, updateDoc, where, writeBatch } from 'firebase/firestore';
import { getDownloadURL, UploadResult } from 'firebase/storage';
import { FirebaseService } from './firebase.service';
import { collectionChanges, collection as collectionSnapshots, doc as docSnapshots, docData } from 'rxfire/firestore';
import { GuttmannService } from './guttmann.service';
import { PopoverService } from '@atheneasolutions/ion-popover-component';


export const NUM_MESSAGES_PER_BATCH = 30;
export const NUM_MESSAGES_PER_LOAD = 20;
export const GROUP_NAME_MAX_LENGTH = 25;

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  /**
   * Chats$ is behaviour subjects that emits a list of behaviour subject chats when the order of chats is altered
   */
  public chats$: BehaviorSubject<BehaviorSubject<any>[]> = new BehaviorSubject([]);
  public patientChats$: BehaviorSubject<BehaviorSubject<any>[]> = new BehaviorSubject([]);

  /**
   * chats$$ is an object where chats$$[chat_id] contains a behaviour subject that emits when a chat changes
   */
  public chats$$: any = {};
  public patientChats$$: any = {};
  public tmChats$$: any = {};

  /**
   * Analogous of chats$ and chats$$ but with groups
   */
  public groups$: BehaviorSubject<BehaviorSubject<any>[]> = new BehaviorSubject([]);
  public patientGroups$: BehaviorSubject<BehaviorSubject<any>[]> = new BehaviorSubject([]);
  public groups$$: any = {};
  public patientGroups$$: any = {};


  public replyMsg;
  public replyMsg$ = new Subject<boolean>();
  public forwardMsg;
  public scrolledToBottom = true;
  public scrolledToBottomFirst = false;
  public scrollToBottomScrollHeight = 0;
  public userScrolled = false;
  public firstBottomDetected = false;

  public lastScrollPosition;
  public doScrollToBottom$ = new Subject<any>();
  public imageModal;
  public contactSelectModal;
  public timedOut: { [messageKey: string]: boolean } = {};
  public inputValue: { [chatKey: string]: any } = {};
  public chatSelected;
  public loadingMoreMessages = false;
  public searchFilter$: BehaviorSubject<string> = new BehaviorSubject('');
  public messagesProcessed = {};
  /**
   * Whenever a change is produced in chatsInfo, GroupsInfo or userStat but its corresponding chat doesn't exist yet
   * we store them here to apply them when the chat is created. A set is used to avoid applying changes twice.
   */
  public notAppliedUserInfoChanges: Set<any>;
  public notAppliedUserStatChanges: Set<any>;
  public notAppliedGroupUserInfoChanges: Set<any>;


  /**
   * They emit when chats or groups are loaded for the first time. Then they complete.
   */
  public chatsLoaded$: Subject<any>;
  public groupsLoaded$: Subject<any>;
  public messageDeleted$: Subject<any> = new Subject<void>();

  public contactDetailUser;
  userIsPatient = false;
  userIsExternal = false;

  public textarea;

  public sendingMessage = false;
  public scrolledToUnread = true;


  public shareObject = null;
  public keyboardActive = false;
  static processHTML(escapedMessage: any): string {
    if (!escapedMessage) {
      return '';
    }
    return escapedMessage.replace(/&gt;/g, '>').replace(/&lt;/g, '<');
  }

  constructor(
    public fs: FirebaseService,
    public userService: UserService,
    public authService: AuthService,
    public fileService: FileService,
    public modalCtrl: ModalController,
    // public asService: ActionSheetService,
    public platform: Platform,
    public tr: TranslateService,
    public timeService: TimeService,
    public alertCtrl: AlertController,
    public navController: NavController,
    public loadingService: LoadingService,
    public navigationService: NavigationService,
    public comm: CommunicationService,
    public alertService: AlertService,
    public audioService: AudioService,
    public toastCtrl: ToastController,
    public es: EncryptionService,
    public gs: GuttmannService,
    public datePipe: DatePipe,
    public popoverService: PopoverService) {

    // userOrg$ id's helper observable
    const userOrg$ =
      this.userService.user$.pipe(
        distinctUntilChanged((a, b) => a && b && a.id === b.id),
        switchMap((user) => this.userService.org$.pipe(
          distinctUntilChanged((a, b) => a && b && a.id === b.id),
          map(org => ({ user, org }))
        )
        )
      );


    this.authService.getAuthenticationState().pipe(
      distinctUntilChanged(),
      switchMap((isAuth) => userOrg$.pipe(map((x) => ({ ...x, isAuth })))),
    ).subscribe(({ isAuth, user, org }) => {
      if (isAuth && user && org) {
        this.init();
      }
    });

  }

  async init() {
    const user = this.userService.getUser();
    this.userIsPatient = (user.profile || {}).patient;

    this.userIsExternal = (user.profile && user.profile.external && user.profile.external[this.userService.getOrganization().id] || {});

    await this.loadingService.presentLoading();

    this.notAppliedUserInfoChanges = new Set();
    this.notAppliedUserStatChanges = new Set();
    this.notAppliedGroupUserInfoChanges = new Set();

    this.chatsLoaded$ = new Subject();
    this.groupsLoaded$ = new Subject();
    // When chats and groups complete dismiss the loader
    combineLatest([this.chatsLoaded$, this.groupsLoaded$]).subscribe(() => {
      this.loadingService.dismissLoading();
    });

    await this.loadChats();
    this.loadUserChatInfo();
    this.loadUserStatus();

    this.loadGroups();
    this.loadUserGroupInfo();

    if (this.platform.is('capacitor')) {
      // Keyboard.setResizeMode({ mode: KeyboardResize.Native })
      Keyboard.addListener('keyboardDidShow', (info: KeyboardInfo) => {
        console.log("KEYBOARD_ DID SHOW1")
        this.keyboardActive = true;
        if (this.scrolledToBottom) this.doScrollToBottom$.next(info.keyboardHeight);
      });
      Keyboard.addListener('keyboardWillShow', ()=>{
        this.keyboardActive = true;
      })
      Keyboard.addListener('keyboardDidHide', ()=>{
        this.keyboardActive = false;
      })
      Keyboard.addListener('keyboardWillHide', ()=>{
        this.keyboardActive = false;
      })
    }
  }

  async loadChats() {
    this.chats$.next([]);
    this.patientChats$.next([]);
    this.chats$$ = {};
    this.patientChats$$ = {};
    this.tmChats$$ = {};
    const user = this.userService.getUser();
    const org = this.userService.getOrganization();
    const chatsQuery = query(
      collection(this.fs.firestore, `orgs/${org.id}/chats`),
      where(`users.${user.id}`, '==', true)
    );
    const initialChats = await getDocs(chatsQuery);
    let chats$ = collectionChanges(chatsQuery).pipe(
      catchError(() => of([])),
      takeUntil(this.authService.onSignOut$)
    );
    if (initialChats.docs.length === 0) chats$ = chats$.pipe(
      startWith([])
    );
    // Scan counts the number of times chats$ has emitted
    chats$.pipe(
      scan((acc: any, changes) => ({ times: acc.times + 1, changes }), { times: 0 })
    ).subscribe(({ times, changes }) => {
      if (times === 1) {
        this.chatsLoaded$.next(true);
        this.chatsLoaded$.complete();
      }
      let documentsAdded = false;
      let needsSorting = false;
      let patientsNeedsSorting = false;


      // Apply chat changes
      changes.forEach((change: DocumentChange) => {
        if (change.type === 'added') {
          const id: any = change.doc.id;
          let chat: any = {
            ...change.doc.data(),
            id,
            orgKey: org.id,
            exists: true
          };
          //TODO: descomentar i validar per evitar que es mostri en les llistes de multiorg
          //if (chat.orgKey && chat.orgKey != this.userService.getOrganization().id) return;

          chat = this.transformChat(chat, user);

          if (chat.telemedicine) this.tmChats$$[id] = new BehaviorSubject(chat);
          else if (!chat.patient || this.userIsPatient) this.chats$$[id] = new BehaviorSubject(chat);
          else if (chat.patient) this.patientChats$$[id] = new BehaviorSubject(chat);

          if (!chat.patient || this.userIsPatient) needsSorting = true;
          else patientsNeedsSorting = true;

          documentsAdded = true;
        }


        if (change.type === 'modified') {
          const id: any = change.doc.id;
          const data = change.doc.data();
          if (!this.chats$$[id] && !this.patientChats$$[id] && !this.tmChats$$[id]) return;

          let previousChat;
          if (data.telemedicine) previousChat = this.tmChats$$[id].getValue()
          else previousChat = (!data.patient || this.userIsPatient) ? this.chats$$[id].getValue() : this.patientChats$$[id].getValue();
          const sortingChanged = JSON.stringify(data.lastMessage) !== JSON.stringify(previousChat.lastMessage);
          let chat = {
            ...previousChat,
            ...change.doc.data(),
            id,
            exists: true
          };
          chat = this.transformChat(chat, user);


          if (chat.telemedicine) this.tmChats$$[id].next(chat)
          else if (!chat.patient || this.userIsPatient) this.chats$$[id].next(chat);
          else if (chat.patient) this.patientChats$$[id].next(chat);

          if (sortingChanged) {
            if (!chat.patient || this.userIsPatient) needsSorting = true;
            else patientsNeedsSorting = true;
          }
        }
      });

      if (needsSorting) this.sortChats();
      if (patientsNeedsSorting) this.sortChats('chat', true);
      if (documentsAdded) {
        if (this.notAppliedUserInfoChanges) this.applyPendingUserInfoChanges();
        if (this.notAppliedUserStatChanges) this.applyPendingUserStatChanges(user);
      }
    });
  }


  async loadGroups() {

    this.groups$.next([]);
    this.patientGroups$.next([]);
    this.patientGroups$$ = {};
    this.patientChats$$ = {};
    this.groups$$ = {};


    const user = this.userService.getUser();
    const org = this.userService.getOrganization();

    const groupsQuery = query(
      collection(this.fs.firestore, `orgs/${org.id}/groups`),
      where(`users.${user.id}`, '>=', false)
    );
    let groups$ = collectionChanges(groupsQuery).pipe(
      catchError(() => of([])),
      takeUntil(this.authService.onSignOut$)
    );
    const initialGroups = await getDocs(groupsQuery);

    if (initialGroups.docs.length === 0) groups$ = groups$.pipe(
      startWith([])
    );

    groups$.pipe(
      scan((acc: any, changes: any) => ({ times: acc.times + 1, changes }), { times: 0 })
    ).subscribe(({ changes, times }) => {
      if (times === 1) {
        this.groupsLoaded$.next(true);
        this.groupsLoaded$.complete();
      }
      let documentsAdded = false;
      let needsSorting = false;
      let patientsNeedsSorting = false;

      // Apply chat changes
      changes.forEach((change: DocumentChange) => {
        if (change.type === 'added') {
          const id: any = change.doc.id;
          let group: any = {
            ...change.doc.data(),
            id,
            orgKey: org.id,
            exists: true
          };
          //TODO: descomentar i validar per evitar que es mostri en les llistes de multiorg
          //if (group.orgKey && group.orgKey != this.userService.getOrganization().id) return;

          group = this.transformChat(group, user, 'group');

          if (!group.patient || this.userIsPatient) this.groups$$[id] = new BehaviorSubject(group);
          else this.patientGroups$$[id] = new BehaviorSubject(group);

          if (!group.patient || this.userIsPatient) needsSorting = true;
          else patientsNeedsSorting = true;
          documentsAdded = true;
        }


        if (change.type === 'modified') {

          const id: any = change.doc.id;
          const data = change.doc.data();
          const previousGroup = (!data.patient || this.userIsPatient) ? this.groups$$[id].getValue() : this.patientGroups$$[id].getValue();
          const sortingChanged = JSON.stringify(data.lastMessage) !== JSON.stringify(previousGroup.lastMessage);
          let group = {
            ...previousGroup,
            ...change.doc.data(),
            id,
            exists: true
          };
          this.transformChat(group, user, 'group');

          if (!group.patient || this.userIsPatient) this.groups$$[id].next(group);
          else this.patientGroups$$[id].next(group);
          if (sortingChanged && !group.userDeleted) {
            if (!group.patient || this.userIsPatient) needsSorting = true;
            else patientsNeedsSorting = true;
          }

        }
      });

      if (needsSorting) this.sortChats('group');
      if (patientsNeedsSorting) this.sortChats('group', true);

      if (documentsAdded) {
        if (this.notAppliedGroupUserInfoChanges) this.applyPendingUserInfoChanges('group');
      }
    })
  }

  sortChats(origin = 'chat', isPatient = false) {
    let chats: any[] = origin === 'chat'
      ? (isPatient ? Object.values(this.patientChats$$) : Object.values(this.chats$$))
      : (isPatient ? Object.values(this.patientGroups$$) : Object.values(this.groups$$));

    chats.sort((a: BehaviorSubject<any>, b: BehaviorSubject<any>) => {
      const aValue = a.getValue();
      const bValue = b.getValue();
      const x = (bValue && bValue.lastMessage && bValue.lastMessage.sent) ? bValue.lastMessage.sent.toDate() : 0;
      const y = ((aValue && aValue.lastMessage && aValue.lastMessage.sent) ? aValue.lastMessage.sent.toDate() : 0);
      return x - y;
    });
    if (origin === 'chat' && isPatient) this.patientChats$.next(chats);
    else if (origin === 'chat') this.chats$.next(chats);
    else if (origin === 'group' && isPatient) this.patientGroups$.next(chats);
    else this.groups$.next(chats);
  }



  changeChatStatus(origin, chat, status) {
    let batch = writeBatch(this.fs.firestore);
    let ref;
    let uid = this.userService.getUser().id;
    const col = (origin === 'chat') ? 'chatsInfo' : ((origin === 'group') ? 'groupsInfo' : null);
    if (col === null) return;
    ref = doc(this.fs.firestore, `users/${uid}/${col}/${chat.id}`);
    batch.set(ref, { status: status }, { merge: true });
    return batch.commit();
  }

  setChatSelected(key, value = true) {
    if (!key) { this.navigationService.inChat = false; return this.chatSelected = null; };
    if (value) {
      this.chatSelected = key;
      this.navigationService.inChat = true
    } else {
      this.navigationService.inChat = false
      if (this.chatSelected != key) return;
      else this.chatSelected = null;
    }
  }



  public getFirstMessages(origin, chat, nextLoad, unreadCount?) {
    let key = chat.id;

    if (nextLoad < 30 && unreadCount < 30) nextLoad = 30;
    else if (nextLoad < 200 && unreadCount > 200) nextLoad = 200;

    if (origin === 'chat') {
      let messagesQuery = query(
        collection(this.fs.firestore, `orgs/${this.userService.getOrganization().id}/chats/${key}/messages`),
        orderBy('sent', 'desc'),
        limit(nextLoad)
      )
      return collectionSnapshots(messagesQuery).pipe(
        map((docs) => docs.map((snap) => ({ id: snap.id, ...snap.data() }))));
    } else if (origin === 'group') {
      let messagesQuery = query(
        collection(this.fs.firestore, `orgs/${this.userService.getOrganization().id}/groups/${key}/messages`),
        where('sent', '>=', chat.timeAdded ? chat.timeAdded : chat.timeCreated),
        orderBy('sent', 'desc'),
        limit(nextLoad)
      )
      return collectionSnapshots(messagesQuery).pipe(
        map((docs) => docs.map((snap) => ({ id: snap.id, ...snap.data() }))));
    }
  }


  public filterChats(text) {
    this.searchFilter$.next(text);
  }

  public async presentContactSelectModal(mode, chat?, patientMode?) {
    if (this.contactSelectModal) {
      await this.contactSelectModal.dismiss();
    }
    this.contactSelectModal = await this.modalCtrl.create({
      component: ContactSelectorComponent,
      componentProps: {
        'mode': mode,
        'chat': chat,
        'patientMode': patientMode
      },
    });
    await this.contactSelectModal.present();
    let result = await this.contactSelectModal.onDidDismiss();
    if (result && result.data && (result.data.user || result.data.groupName)) {
      let contact = result.data.user;
      let uid = this.userService.getUser().id;
      if (mode === 'new_chat') {
        //check chat exists
        let querySnap
        if (!patientMode) {
          let chatsQuery = query(
            collection(this.fs.firestore, `orgs/${this.userService.getOrganization().id}/chats`),
            where(`users.${uid}`, '==', true),
            where(`users.${contact.id}`, '==', true)
          );
          querySnap = await getDocs(chatsQuery);
        } else {
          let chatsQuery = query(
            collection(this.fs.firestore, `orgs/${this.userService.getOrganization().id}/chats`),
            where(`users.${uid}`, '==', true),
            where(`users.${contact.id}`, '==', true),
            where('patient', '==', true)
          );
          querySnap = await getDocs(chatsQuery);
        }
        if (querySnap && querySnap.docs.length === 0) {
          //NEW CHAT
          let chat_id = await this.createChat(contact);

          if (!patientMode) await this.navigationService.loadDetail(
            'chat_module/list',
            `chat_module/chats/${chat_id}`,
            `chat_module/list/chats/${chat_id}`
          );
          else await this.navigationService.loadDetail(
            'patients/list',
            `patients/chats/${chat_id}`,
            `patients/list/chats/${chat_id}`
          );

        } else if (querySnap && querySnap.docs && querySnap.docs.length === 1) {
          //OPEN CHAT
          let chat: any = {
            id: querySnap.docs[0].id,
            ...querySnap.docs[0].data()
          };
          if (!patientMode) await this.navigationService.loadDetail(
            'chat_module/list',
            `chat_module/chats/${chat.id}`,
            `chat_module/list/chats/${chat.id}`
          );
          else await this.navigationService.loadDetail(
            'patients/list',
            `patients/chats/${chat.id}`,
            `patients/list/chats/${chat.id}`
          );
        } else {
          this.alertService.showError();
        }
      } else if (mode === 'group_add_member') { //add member to group
        await this.loadingService.presentLoading();
        await this.addMemberToGroup(chat, contact)
        await this.loadingService.dismissLoading();
      } else if (mode === 'new_group') {
        await this.loadingService.presentLoading();
        let groupName = result.data.groupName;
        let usersToAdd = result.data.usersToAdd;
        let groupId = await this.createGroup(groupName, usersToAdd, patientMode);
        await this.loadingService.dismissLoading();

        if (!patientMode) await this.navigationService.loadDetail(
          'chat_module/list',
          `chat_module/groups/${groupId}`,
          `chat_module/list/groups/${groupId}`
        );
        else await this.navigationService.loadDetail(
          'patients/list',
          `patients/groups/${groupId}`,
          `patients/list/groups/${groupId}`
        );
      }
    }
  }


  private addMemberToGroup(group, user) {
    const sender = this.userService.getUser();
    const orgKey = this.userService.getOrganization().id;

    let batch = writeBatch(this.fs.firestore);
    let now = serverTimestamp();
    let ref = doc(this.fs.firestore, `users/${user.id}/groupsInfo/${group.id}`);

    let groupObject: any = { unreadCount: 0, inGroup: false, timeAdded: now, orgKey };
    if (user.patient || group.patient) {
      groupObject.patient = true;
    }
    batch.set(ref, groupObject);

    ref = doc(this.fs.firestore, `orgs/${orgKey}/groups/${group.id}`);
    batch.set(ref, { users: { [user.id]: true } }, { merge: true });

    let destUsers = 0; //destUsers els que siguin users[uid] = true, els false (eliminats del grup) NO.
    Object.keys(group.users).forEach(key => {
      if (group.users[key] === true) destUsers++;
    });

    let message: any = {
      type: GroupMessageTypes.AddUser,
      content: user.id,
      from: sender.id,
      destUsers: destUsers + 1,
      seenUsers: 0,
      fromName: (group.patient && sender.profile.name_public) ? sender.profile.name_public : sender.profile.name,
      addName: (group.patient) ? (user.profile.patient ? Utils.encodeName(user.profile.name, user.id) : (user.profile.name_public ? user.profile.name_public : user.profile.name)) : user.profile.name,
      sent: now,
      info: true
    }

    if (user.profile.patient) message.patient = true

    ref = doc(collection(this.fs.firestore, `orgs/${orgKey}/groups/${group.id}/messages`))
    batch.set(ref, message)
    return batch.commit();
  }

  async createGroup(name, usersToAdd, patientMode) {
    const uid = this.userService.getUser().id;
    const orgKey = this.userService.getOrganization().id;
    let batch = writeBatch(this.fs.firestore);
    let now = serverTimestamp();
    let users = {};
    users[uid] = true;
    usersToAdd.forEach(user => {
      users[user] = true;
    });

    let group: any = {
      name: name,
      users,
      createdBy: uid,
      timeCreated: now
    }
    if (patientMode) group.patient = true;

    let ref = doc(collection(this.fs.firestore, `orgs/${orgKey}/groups`));
    batch.set(ref, group);
    await batch.commit();
    return Promise.resolve(ref.id);
  }



  public async presentChatOptionsActionSheet(e: Event, origin, chat, extra = false) {
    // await this.asService.presentChatOptionsActionSheet(origin, chat, extra);
    // let res = await this.asService.actionSheet.onDidDismiss();
    // if (!res && !res.data) return;
    const role = await this.chatOptionsPopover(e, chat, origin, extra);
    switch (role) {
      case 'show_detail':
        if (origin === 'chat') this.navController.navigateForward('contact-details');
        else this.navController.navigateForward('group-details');
        this.chatSelected = chat.id;
        break;
      case 'highlight_chat':
        await this.loadingService.presentLoading();
        await this.changeChatStatus(origin, chat, 'important');
        chat.status = 'important';
        await this.loadingService.dismissLoading();
        break;
      case 'unhighlight_chat':
        await this.loadingService.presentLoading();
        await this.changeChatStatus(origin, chat, null);
        chat.status = null;
        await this.loadingService.dismissLoading();
        break;
      case 'hide_chat':
        await this.loadingService.presentLoading();
        await this.changeChatStatus(origin, chat, 'hidden');
        chat.status = 'hidden';
        await this.loadingService.dismissLoading();
        break;
      case 'show_chat':
        await this.loadingService.presentLoading();
        await this.changeChatStatus(origin, chat, 'visible');
        chat.status = 'visible';
        await this.loadingService.dismissLoading();
        break;
      case 'patient_chat_settings':
        if (origin === 'chat') this.navController.navigateForward('patient-options');
        this.chatSelected = chat.id;
        break;
      case 'export_messages':
        let translations = [];
        await this.tr.get(['general.yes', 'general.cancel', 'export.title', 'export.subtitle', 'export.all', 'export.loaded'], this.userService.getUser().profile).forEach((tr) => translations = tr);
        let prompt = await this.alertCtrl.create({
          header: translations['export.title'],
          message: translations['export.subtitle'],
          inputs: [
            {
              name: "from",
              placeholder: "dd/mm/aaaa",
              type: "date"
            }, {
              name: "to",
              placeholder: "dd/mm/aaaa",
              type: "date"
            }],
          buttons: [
            {
              text: translations['export.loaded'],
              handler: async (data: any) => {
                this.exportAsPDF(origin, chat, 1, data.from, data.to);
              },
            },
            {
              text: translations['export.all'],
              handler: async (data: any) => {
                this.exportAsPDF(origin, chat, 0);
              },
            },
            {
              text: translations['general.cancel'],
              role: 'cancel'
            },
          ],
        });
        await prompt.present();
        break;
      case 'show_patient_dashboard_detail':
        this.navigateToPatientDashboard(chat);
        break;
      default: break;
    }
    return;
  }

  private async chatOptionsPopover(e: Event, chat, origin, extra = false) {
    let isChat: boolean = origin === 'chat';
    let isPatient: boolean = this.userService.getUser().profile.patient;

    //Botons generals
    let messageOptionsItems = [
      {
        text: this.tr.instant(isChat ? 'contact.viewDetails' : 'group.viewDetails'),
        icon: isChat ? 'person' : 'people',
        role: 'show_detail'
      }
    ]

    //Botó per grups
    if (!isChat && this.userService.getPatientsDashboardPermission(chat)) messageOptionsItems.unshift({
      text: this.tr.instant('groupDetails.patient_detail'),
      icon: 'person-circle-outline',
      role: 'show_patient_dashboard_detail'
    });

    //Botó si chat amb pacient
    if (!isPatient && isChat && chat.patient) messageOptionsItems.push({
      text: this.tr.instant('chat.patient_settings'),
      icon: 'link',
      role: 'patient_chat_settings'
    });
    
    //Destacar / desmarcar  chat
    //TODO ADD CLASS OR COLOR .starIcon
    if (!chat.status || chat.status === 'visible') messageOptionsItems.push({
      text: this.tr.instant('general.highlight'),
      icon: 'star',
      role: 'highlight_chat'
    }); else if (chat.status === 'important') messageOptionsItems.push({
      text: this.tr.instant('general.unhighlight'),
      icon: 'star-outline',
      role: 'unhighlight_chat'
    });

    //Botó per amagar el xat
    if (!isPatient && isChat && (!chat.status || chat.status != 'hidden')) messageOptionsItems.push({
      text: this.tr.instant('general.hide'),
      icon: 'eye-off',
      role: 'hide_chat'
    });

    //Botó per mostrar el xat
    if (!isPatient && chat.status && chat.status === 'hidden') messageOptionsItems.push({
      text: this.tr.instant('general.show'),
      icon: 'eye',
      role: 'show_chat'
    });

    //Botó per exportar el xat
    if (extra && !isPatient && !chat.userDeleted) messageOptionsItems.push({
      text: this.tr.instant('export.options_title'),
      icon: 'archive',
      role: 'export_messages'
    });

    const role = await this.popoverService.presentPopover(e, messageOptionsItems, 'round');
    return role;
  }

  navigateToPatientDashboard(group) {
    let nhc = group.tag && group.tag.toLowerCase().includes("nhc_") ? group.tag.toLowerCase().split("nhc_")[1] : "";
    if (!nhc) return;
    this.gs.selectedPatient = { id: nhc, from: 'group' };
    this.navController.navigateForward('patients-dashboard/patient');
  }

  public processNewMessages() {

  }

  public async createChat(contact, telemedicine = false) {
    let orgKey = this.userService.getOrganization().id;
    const batch = writeBatch(this.fs.firestore);
    if (!orgKey) return null;
    let chatRef = doc(collection(this.fs.firestore, `orgs/${orgKey}/chats`));
    let chatId = chatRef.id;
    let chat: any = {
      createdBy: this.userService.getUser().id,
      timeCreated: serverTimestamp(),
      users: {
        [this.userService.getUser().id]: true,
        [contact.id]: true
      },
      userParams: {
        [this.userService.getUser().id]: { profile: this.userService.getUser().profile },
        [contact.id]: { profile: contact.profile },
      }
    };

    if (contact.profile.patient && !telemedicine) {
      chat.patient = true;
    } else {
      chat.new = true;
    }
    if (telemedicine) {
      chat.telemedicine = true;
    }
    batch.set(chatRef, { ...chat });

    if (chat.users) {
      Object.keys(chat.users).forEach(usId => {
        let ref = doc(this.fs.firestore, `users/${usId}/chatsInfo/${chatId}`);
        const chatObj: any = { unreadCount: 0, orgKey };

        if (telemedicine) {
          chatObj.telemedicine = true;
        } else if (chat.patient) {
          chatObj.patient = true;
        }

        //amager per defecte el chat
        chatObj.status = 'hidden';

        batch.set(ref, chatObj);
        ref = doc(this.fs.firestore, `orgs/${orgKey}/userStatus/${usId}`);
        if (usId === this.userService.getUser().id) {
          batch.set(ref, { userChat: { [contact.id]: true } }, { merge: true })
        } else {
          batch.set(ref, { userChat: { [this.userService.getUser().id]: true } }, { merge: true });
          if (chat.patient && !this.userService.getUser().profile.patient) {
            //Si el professional crea el chat amb  el pacient, activar relació professional-pacient i donar permissos d'escriptura al pacient
            let ref = doc(this.fs.firestore, `users/${usId}`);
            batch.set(ref, { users: { [this.userService.getUser().id]: true } }, { merge: true });

            ref = doc(this.fs.firestore, `users/${usId}/chatsInfo/${chatId}`);
            batch.set(ref, { patient: true, canWrite: true }, { merge: true });
          }
        }
      });
    } else {
      return null;
    }
    try {
      await batch.commit();
    } catch (e) {
      console.log("ERROR", e);
    }
    return chatId;
  }

  async exportAsPDF(origin, chat, type = 0, from = null, to = null) {
    await this.loadingService.presentLoading();
    let batch = writeBatch(this.fs.firestore);
    let ref = doc(collection(this.fs.firestore, `users/${this.userService.getUser().id}/exportChatRequests`));
    let date_range: any = {};
    if (type === 1) {
      date_range.from = from;
      date_range.to = to;
    }

    batch.set(ref, {
      date: serverTimestamp(),
      origin,
      orgKey: this.userService.getOrganization().id,
      chat: {
        id: chat.id,
        users: chat.users,
        timeCreated: chat.timeCreated,
        other: (origin === 'chat' && chat.other) ? { profile: chat.other.profile } : chat.name,
        user: this.userService.getUser()
      },
      type,
      ...date_range
    });
    await batch.commit();
    await this.loadingService.dismissLoading();


  }


  public loadUserChatInfo() {

    const user = this.userService.getUser();
    const org = this.userService.getOrganization();

    const chatInfoQuery = query(
      collection(this.fs.firestore, `users/${user.id}/chatsInfo`),
      where('orgKey', '==', org.id)
    );

    const userInfo$ = collectionChanges(chatInfoQuery).pipe(
      takeUntil(this.authService.onSignOut$)
    );

    userInfo$.subscribe((changes) => {
      changes.forEach((change: DocumentChange<any>) => this.applyUserInfoChange(change));
    });

  }

  public loadUserGroupInfo() {

    const user = this.userService.getUser();
    const org = this.userService.getOrganization();


    const groupInfoQuery = query(
      collection(this.fs.firestore, `users/${user.id}/groupsInfo`),
      where('orgKey', '==', org.id)
    );
    const userInfo$ = collectionChanges(groupInfoQuery).pipe(
      takeUntil(this.authService.onSignOut$)
    );

    userInfo$.subscribe((changes) => {
      changes.forEach((change: DocumentChange) => this.applyUserInfoChange(change, 'group'));
    });

  }

  public applyPendingUserInfoChanges(origin = 'chat') {

    if (origin === 'chat') {
      const changes = Array.from(this.notAppliedUserInfoChanges);
      // Apply userInfo changes
      for (const change of changes) {
        this.notAppliedUserInfoChanges.delete(change);
        this.applyUserInfoChange(change);
      }
    }

    else {
      const changes = Array.from(this.notAppliedGroupUserInfoChanges);
      // Apply userInfo changes
      for (const change of changes) {
        this.notAppliedGroupUserInfoChanges.delete(change);
        this.applyUserInfoChange(change, 'group');
      }
    }

  }

  public applyUserInfoChange(change: DocumentChange, origin = 'chat') {
    if (change.type === 'added' || change.type === 'modified') {
      const id: any = change.doc.id;
      const data = change.doc.data();
      const orgKey = data.orgKey;
      if (origin === 'chat') {
        const patient = this.patientChats$$[id] ? 'patient' : (this.chats$$[id] ? 'internal' : (this.tmChats$$[id] ? 'telemedicine' : 'undefined'));
        if (patient === 'patient' || patient === 'internal' || patient === 'telemedicine') {
          const previousChat = patient === 'internal' ? this.chats$$[id].getValue() : (patient === 'telemedicine' ? this.tmChats$$[id].getValue() : this.patientChats$$[id].getValue());
          const obj = {
            ...previousChat,
            id,
            unreadCount: data.unreadCount,
            status: data.status
          };
          if (patient === 'telemedicine') this.tmChats$$[id].next(obj);
          else if (patient === 'internal') this.chats$$[id].next(obj);
          else this.patientChats$$[id].next(obj);
        }

        // Group object hasn't been created yet
        else this.notAppliedUserInfoChanges.add(change);
      }

      else {
        const patient = this.patientGroups$$[id] ? 'patient' : (this.groups$$[id] ? 'internal' : 'undefined');
        if (patient === 'patient' || patient === 'internal') {
          const previousGroup = patient === 'internal' ? this.groups$$[id].getValue() : this.patientGroups$$[id].getValue();
          // Add status and unread count to group object

          const group = {
            ...previousGroup,
            id,
            unreadCount: data.unreadCount,
            status: data.status,
            timeAdded: data.timeAdded,
            timeRemoved: data.timeRemoved,
            userBroadcast: data.broadcast
          };
          if (data.timeRemoved && previousGroup.lastMessage) {
            group.lastMessage.sent = data.timeRemoved;
            group.lastMessage.rcvd = data.timeRemoved;
            group.lastMessage.delivered = data.timeRemoved;
            this.sortChats('group');
          }
          if (patient === 'internal') this.groups$$[id].next(group);
          else this.patientGroups$$[id].next(group);


        }
        else {
          // Group object hasn't been created yet
          this.notAppliedGroupUserInfoChanges.add(change);
        }
      }

      const chatRef = doc(this.fs.firestore, `orgs/${orgKey}/${origin === 'chat' ? 'chats' : 'groups'}/${id}`);
      const unreadMessages = data.unreadCount || [];
      const status = data.status;

      if (unreadMessages.length > 0 && status === 'hidden') updateDoc(change.doc.ref, 'status', null);

      const messagesToMark = [];
      for (const msg of unreadMessages) {
        const messageRef = doc(this.fs.firestore, `${chatRef.path}/messages/${msg}`);

        messagesToMark.push(messageRef);
      }
      if (messagesToMark.length > 0) this.markMessageAsDelivered(chatRef, messagesToMark, origin);


    }
  }

  public isFromMe(message): boolean {
    return message && (message.from === this.userService.getUser().id);
  }

  public isInfo(message): boolean {
    return !!message.info;
  }

  public messageGetInnerHTML(content) {
    var div = document.createElement("div");
    div.innerHTML = content;
    return div.innerText;
  }

  public isFailedMessage(message): boolean {
    //TODO: fer timeout messages
    return !message.rcvd && this.timedOut[message.id];
  }

  public receiptStatus(message): ReceiptStatus {
    return message && ReceiptStatusUtil.getMessageStatus(message);
  }

  public replyMessage(message) {
    this.replyMsg = message;
    if (this.replyMsg.content && this.replyMsg.content.length > 25) {
      this.replyMsg.content = this.replyMsg.content.substring(0, 24) + '...';
    } else if (!this.replyMsg.content && this.replyMsg.file && this.replyMsg.file.name) {
      this.replyMsg.content = this.replyMsg.file.name.substring(0, 24) + '...';
    }
    this.replyMsg$.next(true); //FOCUS textArea
    // this.content.resize();
    // this.forceScrollToBottom();
  }

  public async sendMessage(origin, chat, options: any = {}) {
    this.sendingMessage = true;
    let chat_id = chat.id;

    if (
      !options.file
      && !options.forward
      && (!this.inputValue[chat_id] || this.inputValue[chat_id] === '' || this.inputValue[chat_id].trim() === '')
    ) {
      this.sendingMessage = false;
      return;
    }
    let content = this.inputValue[chat_id] || '';
    this.inputValue[chat_id] = '';

    if (options.forward && this.forwardMsg) {
      content = this.forwardMsg.content;
      if (this.forwardMsg.file) options.file = this.forwardMsg.file;
    }

    if (content) content = content.trim();
    content = Autolinker.link(content, {
      newWindow: true,
      urls: {
        schemeMatches: true,
        wwwMatches: true,
        tldMatches: true
      },
      email: false,
      phone: false,
      stripPrefix: false,
    });
    content = EncryptionService.AESencrypt(content, chat_id);
    const now = serverTimestamp();

    const message: any = {
      content,
      from: this.userService.getUser().id,
      sent: now,
      rcvd: now,
      delivered: false,
      seen: false,
    };

    if (options && options.info) {
      message.info = options.info;
    }

    if (options && options.forward) {
      message.forward = true;
    }

    if (this.replyMsg && this.replyMsg.content != '') {
      const _replyMsg = Object.assign({}, this.replyMsg);
      _replyMsg.content = EncryptionService.AESencrypt(_replyMsg.content, chat_id);
      message.reply = _replyMsg;
    }

    if (options && options.file) {
      message.file = options.file;
    }

    const orgKey = this.userService.getOrganization().id;
    const batch = writeBatch(this.fs.firestore);

    if (origin === 'chat') {
      const mgsRef = doc(collection(this.fs.firestore, `orgs/${orgKey}/chats/${chat_id}/messages`));

      batch.set(mgsRef, message);
      let ref = doc(this.fs.firestore, `orgs/${orgKey}/chats/${chat_id}`);

      delete message.sent;
      if (!message.file) {
        message.file = null;
      }
      if (!message.info) {
        delete message.info;
      }

      batch.set(ref, { lastMessage: { id: mgsRef.id, ...message } }, { merge: true });
      if (chat.status === 'hidden') {
        ref = doc(this.fs.firestore, 'users', this.userService.getUser().id, 'chatsInfo', chat_id);
        batch.set(ref, { status: null }, { merge: true });
      }
    } else if (origin === 'group') {
      if (chat.patient && this.userService.getUser().profile.patient) {
        message.fromName = Utils.encodeName(this.userService.getUser().profile.name, this.userService.getUser().id);
        message.fromRealName = this.userService.getUser().profile.name;
        message.fromPatient = true;
      } else if (chat.patient && !this.userService.getUser().profile.patient) {
        message.fromName = (this.userService.getUser().profile.name_public) ? this.userService.getUser().profile.name_public : this.userService.getUser().profile.name;
      } else {
        message.fromName = this.userService.getUser().profile.name;
      }
      if (!message.info) message.type = GroupMessageTypes.Message; //TODO: es podrà borrar. Ara per ara està per compatibilitat v2.0.8

      let destUsers = 0; //destUsers els que siguin users[uid] = true, els false (eliminats del grup) NO.
      Object.keys(chat.users).forEach(key => {
        if (chat.users[key] === true) destUsers++;
      })
      message.destUsers = destUsers;
      message.seenUsers = 0;

      let mgsRef = doc(collection(this.fs.firestore, `orgs/${orgKey}/groups/${chat_id}/messages`));
      batch.set(mgsRef, message);
      // let ref = this.fs.firestore
      //   .collection('orgs')
      //   .doc(orgKey)
      //   .collection('groups')
      //   .doc(chat.id).ref;

      // message.seen = false;
      // message.delivered = false;
      // delete message.sent;

      // batch.set(ref, { lastMessage: { id: mgsRef.id, ...message } }, { merge: true });
    }

    await batch.commit();
    await this.cancelReplyMsg();
    this.shareObject = null;
    if (this.textarea && this.textarea.style) {
      this.textarea.style.height = 'auto';
    }
    this.forwardMsg = null;
    this.sendingMessage = false;
    //this.doScrollToBottom$.next();
    //REMEMBER: ios a vegades no fa scroll en enviar
    // setTimeout(() => {
    //   this.doScrollToBottom$.next();
    // }, 150);
    return;
  }

  cancelReplyMsg() {
    this.replyMsg = null;
  }

  async markMessagesAsSeen(origin, chat, messages) {
    const orgKey = this.userService.getOrganization().id;
    const chatKey = chat.id;
    let now = serverTimestamp();
    let batch = writeBatch(this.fs.firestore);
    let count = 0;
    if (origin === 'chat') {
      for (let i = 0; i < messages.length; i++) {
        let message = messages[i];
        let ref = doc(this.fs.firestore, 'orgs', orgKey, 'chats', chatKey, 'messages', message);
        // let snap = await ref.get();
        // if (!snap.exists) continue;
        batch.set(ref, { seen: now }, { merge: true });
        count++;
        if (chat.lastMessage && chat.lastMessage.id === message) {
          let ref = doc(this.fs.firestore, 'orgs', orgKey, 'chats', chatKey);
          batch.set(ref, {
            [`lastMessage.seen`]: now,
          }, { merge: true });
          count++;
        }
        count++;
        if (count === 300) {
          await batch.commit();
          batch = writeBatch(this.fs.firestore);
          count = 0;
        }
      }
    } else if (origin === 'group') {
      for (let i = 0; i < messages.length; i++) {
        let message = messages[i];
        let ref = doc(this.fs.firestore, 'orgs', orgKey, 'groups', chatKey, 'messages', message, 'userInfo', this.userService.getUser().id);
        batch.set(ref, { seen: now }, { merge: true });
        count++;
        if (count === 300) {
          await batch.commit();
          batch = writeBatch(this.fs.firestore);
          count = 0;
        }
      }
    }
    return batch.commit();
  }

  async checkGroupUnread(origin, chat) {
    if (origin === 'chat') {
      let unreadCount = chat.unreadCount;
      for (let i = 0; i < chat.unreadCount.length; i++) {
        let msg = chat.unreadCount[i];
        let msgSnap = await getDoc(doc(this.fs.firestore,
          'orgs',
          this.userService.getOrganization().id,
          'chats',
          chat.id,
          'messages',
          msg
        ));
        if (msgSnap && msgSnap.data() && msgSnap.data().seen) {
          unreadCount.splice(i, 1);
        }
      }
      if (unreadCount.length === 0) {
        return await this.resetChatUnreadCount('chat', chat);
      } else if (unreadCount != chat.unreadCount) {
        let batch = writeBatch(this.fs.firestore);
        let ref = doc(this.fs.firestore, 'users', this.userService.getUser().id, 'chatsInfo', chat.id);
        batch.update(ref, { unreadCount });
        return await batch.commit();
      }

      return Promise.resolve();
    } else if (origin === 'group') {
      let group = chat;
      let unreadCount = group.unreadCount;
      for (let i = 0; i < group.unreadCount.length; i++) {
        let msg = group.unreadCount[i];
        let msgSnap = await getDoc(doc(this.fs.firestore,
          'orgs',
          this.userService.getOrganization().id,
          'groups',
          group.id,
          'messages',
          msg,
          'userInfo',
          this.userService.getUser().id
        ));
        if (msgSnap && msgSnap.data() && msgSnap.data().seen) {
          unreadCount.splice(i, 1);
        }
      }
      if (unreadCount.length === 0) {
        return await this.resetChatUnreadCount('group', group);
      } else if (unreadCount != group.unreadCount) {
        let batch = writeBatch(this.fs.firestore);
        let ref = doc(this.fs.firestore, 'users', this.userService.getUser().id, 'groupsInfo', group.id);
        batch.update(ref, { unreadCount });
        return await batch.commit();
      }

      return Promise.resolve();
    }
  }
  resetChatUnreadCount(origin, chat) {
    const uid = this.userService.getUser().id;
    const chatKey = chat.id;
    let now = serverTimestamp();
    let batch = writeBatch(this.fs.firestore);
    if (origin === 'chat') {
      let ref = doc(this.fs.firestore, 'users', uid, 'chatsInfo', chatKey);
      batch.update(ref, { unreadCount: 0 });
    } else if (origin === 'group') {
      let ref = doc(this.fs.firestore, 'users', uid, 'groupsInfo', chatKey);
      batch.set(ref, { unreadCount: 0 }, { merge: true });
    }
    return batch.commit();
  }

  getColor(message) {
    if (message)
      return ColorGenerator.getTextColor(message.from);

    return '#000000'
  }

  chatToolbarDisabled(origin, contact = null) {
    if (this.audioService.recording) return true;

    if (this.userService.getUser().status && this.userService.getUser().status.unavailable) {
      return true;
    }
    if (origin === 'chat') {
      if (contact && contact.validation && (!contact.validation.verified || contact.validation.disabled)) {
        return true;
      }
    } else {
      let group = contact;
      if (group.broadcast)
        return !group.userBroadcast;
      else {
        if (!group.userDeleted) return false;
        return true;
      }
    }
  }

  chatToolbarPlaceholder(origin, chat) {
    let translations = [];

    if (this.audioService.recording) {
      return this.tr.instant('chat.recording') + " " + this.datePipe.transform(this.audioService.time[chat.id] * 1000, 'mm:ss');
    }

    if (origin === 'chat') {
      let contact = chat.other;

      if (this.userService.getUser().status.unavailable
        || (contact && contact.validation && (!contact.validation.verified || contact.validation.disabled))
        //|| (this.userService.getUser().profile.patient &&)
      )
        return this.tr.instant('chat.cantType');
      // TODO: fer per pacient
      //((isSelfUnavailable|| !contact?.validation?.verified || contact?.validation?.disabled || (isPatient && !patientChatWrittingPermission)) ? 'chat.cantType' : 'chat.type') | translate

      return this.tr.instant('chat.type');
    } else if (origin === 'group') {
      if (this.chatToolbarDisabled('group', chat)) return this.tr.instant('chat.cantType');
      else return this.tr.instant('chat.type');
    }
  }

  chatToolbarHeight(event) {
    this.textarea = event.target;
    // textarea.style.overflow = 'hidden';
    this.textarea.style.height = 'auto';
    let threshold = this.platform.is('capacitor') ? 188 : 230;
    if (this.textarea.scrollHeight < threshold) {
      this.textarea.style.height = this.textarea.scrollHeight + 'px';
      if (this.scrolledToBottom) {
        this.doScrollToBottom$.next(this.textarea.scrollHeight);
      }
    } else {
      this.textarea.style.height = threshold + 'px';
      if (this.scrolledToBottom) {
        this.doScrollToBottom$.next(0);
      }
    }
  }

  public async scrollEvent(event, document?) {
    const scrollElement = await event.target;
    this.detectBottom(scrollElement);
    if (document.getElementById('unreadId')) this.detectScrollFirstUnread(document);
    if (!this.scrolledToUnread || !this.scrolledToBottom) this.userScrolled = true;
  }

  public async detectScrollFirstUnread(document) {
    this.scrolledToUnread = this.hasScrolledToFirstUnread(document);
  }

  public hasScrolledToFirstUnread(document) {
    let el = document.getElementById('unreadId');
    if (!el) return true;
    if (this.scrolledToBottom) {
      this.userScrolled = true;
      return true;
    }
    let parent = document.getElementById('scrollList');
    const { top } = el.getBoundingClientRect();
    const bounding = parent.getBoundingClientRect();
    let scrollThreshold = 3;
    if (Math.abs(top - bounding.top) <= scrollThreshold) {
      return true;
    }
    return false;
  }

  public async detectBottom(scrollElement) {

    let scrollThreshold = 1 * scrollElement.clientHeight / 100; //5%

    if (scrollElement.scrollTop < 0) return; // ignorar el "bounce effect" d'ios

    if ((scrollElement.scrollHeight - scrollElement.scrollTop - scrollThreshold) <= scrollElement.clientHeight) {
      this.scrolledToBottom = true;
      this.scrolledToBottomFirst = true;
      if (this.platform.is('ios')) {
        if ((scrollElement.clientHeight + scrollElement.scrollTop) <= scrollElement.scrollHeight)
          this.lastScrollPosition = scrollElement.scrollTop;
        else return; // ignorar el "bounce effect" d'ios
      } else this.lastScrollPosition = scrollElement.scrollTop; //TODO: per simplificar podriem només mantenir la opció d'ios (mira els límits)...
      return;
    } else {
      this.scrolledToBottom = false;
    }
    this.lastScrollPosition = scrollElement.scrollTop;
  }

  public async messageBubbleClicked(message, chatId) {
    if (!message || !message.file) return;
    const file = message.file;
    const type = file.type;
    const name = file.name;
    const url = file.url;
    if (this.fileService.isImage(message.file.type)) {
      return; //handled in ImageThumbComponent
    } else if (this.fileService.isAudio(message.file.type)) {
      return;// console.log("AUDIO CLICKED");
    } else if (this.platform.is('capacitor') && this.fileService.isOpenable(type)) {
      this.fileService.showOpenWarning(url, name, type);
    }
    else if (!this.platform.is('capacitor')) {
      await Browser.open({ url })
    }
    else {
      this.fileService.showDownloadWarning(url, name, type);
    }
  }

  public async showImageModal(message, chatId) {
    if (!this.fileService.imageIsLoaded(chatId, message.id)) return;
    if (this.imageModal) {
      await this.imageModal.dismiss();
    }
    this.imageModal = await this.modalCtrl.create({
      component: GalleryComponent,
      componentProps: {
        'chatId': chatId,
        'messageId': message.id
      },
      cssClass: this.platform.is('capacitor') ? '' : 'custom-image-gallery-modal',
      showBackdrop: true,
      backdropDismiss: true,
      id: "imageModal"

    });
    return await this.imageModal.present();
  }

  public async presentAttachModal(e: Event, origin, chat, inputFile: ElementRef) {

    // await this.asService.presentAttachSheet();
    // let data = await this.asService.actionSheet.onDidDismiss();
    const role = await this.attachOptionsPopover(e);
    let cameraOptions: ImageOptions = {
      quality: 100,
      allowEditing: false,
      resultType: CameraResultType.Uri,
      source: CameraSource.Camera,
      saveToGallery: false
    };
    try {
      let file = '';
      switch (role) {
        case 'camera':
          let image = await Camera.getPhoto(cameraOptions);
          file = image.webPath;
          break;
        case 'image':
          if (!this.platform.is('capacitor')) {
            file = await inputFile.nativeElement.click();
          } else {
            cameraOptions.source = CameraSource.Photos;
            let image = await Camera.getPhoto(cameraOptions);
            file = image.webPath;
          }
          break;
        case 'file':
          file = await inputFile.nativeElement.click();
          break;
        default:
          break;
      }
  
      if (!file) return;
      await this.loadingService.presentLoading();
      await this.sendFile(origin, chat, file);
      await this.loadingService.dismissLoading();
    } catch (error) {
      console.error(error);
    }
  }

  private async attachOptionsPopover(e: Event) {
    //Botons generals
    let messageOptionsItems = [
      {
        text: this.tr.instant('general.camera'),
        icon: 'camera',
        role: 'camera'
      },
      {
        text: this.tr.instant('general.gallery'),
        icon: 'image',
        role: 'image'
      },
      {
        text: this.tr.instant('general.file'),
        icon: 'document',
        role: 'file'
      }
    ]

    const role = await this.popoverService.presentPopover(e, messageOptionsItems, 'round');
    return role;
  }

  async toastForwardMsg() {
    let translations = {};
    await this.tr.get(['general', 'alerts']).forEach(x => translations = x);

    let toast = await this.toastCtrl.create({
      message: translations['alerts'].forward_toast,
      duration: 5000,
      position: 'top',
      buttons: [
        {
          text: 'Ok',
          role: 'cancel',
          handler: () => {
          }
        }
      ]
    });
    await toast.present();
  }

  async presentForwardAlert(origin = 'chat', chat) {
    let translations = {};
    await this.tr.get(['general', 'alerts']).forEach(x => translations = x);
    let alert = await this.alertCtrl.create({
      header: translations['alerts'].forward,
      message: this.forwardMsg.content.length > 25 ? this.forwardMsg.content.substring(0, 24) + '...' : this.forwardMsg.content,
      buttons: [
        {
          text: translations['general'].yes,
          handler: async () => {
            await this.sendMessage(origin, chat, { forward: true });
          }
        },
        {
          text: translations['general'].no,
          role: 'cancel',
          handler: () => {
            this.forwardMsg = null;
          }
        }
      ]
    });

    await alert.present();
  }

  public async handleFileFromInput(origin, chat, inputFile) {
    if (!inputFile.nativeElement.files) return;

    const files: File[] = inputFile.nativeElement.files;
    let file = files[0];
    for (let i = 0; i < files.length; i++) {
      file = files[i];
      if (!file) {
        continue
      }
      await this.loadingService.presentLoading();
      await this.sendFile(origin, chat, file);
      await this.loadingService.dismissLoading();
    }
    return file;
  }

  public async sendFile(origin, chat, inputFile, voiceMsg = false) {
    let file: any = {};
    let fileTask: UploadResult;

    try {
      if (origin === 'chat') {
        fileTask = await this.fileService.upload(
          `/chats/${this.userService.getOrganization().id}/${chat.id}/${this.userService.getUser().id}`,
          inputFile.name,
          inputFile,
          true,
          voiceMsg
        );
      } else if (origin === 'group') {
        fileTask = await this.fileService.upload(
          `/groups/${this.userService.getOrganization().id}/${chat.id}/${this.userService.getUser().id}`,
          inputFile.name,
          inputFile,
          true,
          voiceMsg
        );
      }
    } catch (e) {
      await this.loadingService.dismissLoading();
      await this.alertService.showError();
      return;
    }
    let url;
    try {
      url = await getDownloadURL(fileTask.ref);
    } catch (e) {
      await this.loadingService.dismissLoading();
      await this.alertService.showError();
      return;
    }
    if (!url) {
      await this.loadingService.dismissLoading();
      await this.alertService.showError();
      return;
    }

    if (typeof inputFile === 'string') {
      inputFile = await this.fileService.getFileFromLocalURL(inputFile);
    }
    let thumb: any;
    if (this.fileService.isImage(inputFile.type)) {
      thumb = await this.fileService.generateThumbnail(inputFile);
    }
    let res_thumb = {};
    if (thumb) {
      res_thumb = {
        thumb: thumb.dataURL,
        width: thumb.originalWidth,
        height: thumb.originalHeight
      };
    }

    file = {
      type: fileTask.metadata.contentType,
      url,
      name: fileTask.metadata.name,
      size: fileTask.metadata.size,
      ...res_thumb
    };
    if (voiceMsg) file.voice = true;

    if (file) {
      await this.sendMessage(origin, chat, { file });
      await this.loadingService.dismissLoading();
      return;
    }
    await this.loadingService.dismissLoading();
    return;
  }



  public getMessageName(message, chat, contact) {
    let user = this.userService.getUser();
    let isPatient = user.profile.patient;
    return this.isFromMe(message) ? (chat.patient ? (user.profile.name_public ? user.profile.name_public : user.profile.name) : user.profile.name) : (isPatient ? (contact.profile.name_public ? contact.profile.name_public : contact.profile.name) : contact.profile.name)
  }

  public getContactName(contact) {
    let user = this.userService.getUser();
    if (!user || !user.profile) return '';
    let isPatient = user.profile.patient;
    if (contact && contact.profile)
      return isPatient ? (contact.profile.name_public ? contact.profile.name_public : contact.profile.name) : contact.profile.name;

    return '';
  }

  public getExternal(contact) {
    let orgKey = this.userService.getOrganization().id;
    return contact.profile && contact.profile.external && contact.profile.external[orgKey] ? contact.profile.external[orgKey] : false;
  }

  public getMessageDayOrTime(ts) {
    return this.timeService.getDate(ts, { timeForToday: true }).toString();
  }

  markMessageAsPriority(message, chat, origin: string): Promise<any> {
    const orgKey = this.userService.getOrganization().id;
    switch (origin) {
      case 'chat':
        return updateDoc(doc(this.fs.firestore,
          'orgs',
          orgKey,
          'chats',
          chat.id,
          'messages',
          message.id
        ), { priority: true });

      case 'group':
        return updateDoc(doc(this.fs.firestore,
          'orgs',
          orgKey,
          'groups',
          chat.id,
          'messages',
          message.id
        ), { priority: true });
    }
  }

  notifyMessagePriority(message, chat, origin: string) {
    //TODO: api post notifyPriority --> canviar a una funció!
    return this.comm.notifyPriority(message, chat, origin);
  }

  addReaction(message, chat, origin: string, reaction: string): Promise<any> {
    const orgKey = this.userService.getOrganization().id;
    const batch = writeBatch(this.fs.firestore);
    let messageRef;

    //comprovar que el users.id si la reacció actual és diferent a la nova que canvii users.id: nova reacció i reaction antiga: increment(-1) i nova increment(1)
    //su chat.broadcast no permetre reaccions
    //su chat.patient no permetre reaccions???
    //un cop reaccionat es crea missatge o notificacio?
    switch (origin) {
      case 'chat':
        messageRef = doc(this.fs.firestore,
          'orgs',
          orgKey,
          'chats',
          chat.id,
          'messages',
          message.id
        );


        //TODO: send message amb el batch.

        break;
      case 'group':
        messageRef = doc(this.fs.firestore,
          'orgs',
          orgKey,
          'groups',
          chat.id,
          'messages',
          message.id
        );
        break;
    }


    let lastReaction = message?.reactions?.users[this.userService.getUser().id]?.reaction;

    if(lastReaction != undefined) {
      batch.set(messageRef,{ reactions: {
        emojis: {
          [lastReaction]: increment(-1)
        },
        total: increment(-1)
        
      }},{ merge: true });
    }

    batch.set(messageRef,{ reactions: {
      emojis: {
        [reaction]: increment(1)
      },
      users: {
        [this.userService.getUser().id]: {
          reaction,
          date: serverTimestamp()
        }
      },
      total: increment(1)
      
    }},{ merge: true });


    let newId = doc(collection(this.fs.firestore, '_')).id;
    let reactionRef;

    switch (origin) {
      case 'chat':
        reactionRef = doc(this.fs.firestore,
          'orgs',
          orgKey,
          'chats',
          chat.id,
          'messages',
          message.id,
          'reactions',
          newId
        );
        break;
      case 'group':
        reactionRef = doc(this.fs.firestore,
          'orgs',
          orgKey,
          'groups',
          chat.id,
          'messages',
          message.id,
          'reactions',
          newId
        );
        break;
    }

    console.log("Add 1")
    if (message.from != this.userService.getUser().id) {
      batch.set(reactionRef,{
        reaction: reaction,
        time: serverTimestamp(),
        message: {
          from: message.from
        },
        user: {
          id: this.userService.getUser().id,
          name: this.userService.getUser().profile.name,
          picture: this.userService.getUser().profile.smallPicture ?? false
        }
      },{ merge: true });
    }

    return batch.commit();
  }

  deleteReaction(message, chat, origin: string, userId): Promise<any> {
    const orgKey = this.userService.getOrganization().id;
    const batch = writeBatch(this.fs.firestore);
    let messageRef;

    //comprovar que el users.id si la reacció actual és diferent a la nova que canvii users.id: nova reacció i reaction antiga: increment(-1) i nova increment(1)
    //su chat.broadcast no permetre reaccions
    //su chat.patient no permetre reaccions???
    //un cop reaccionat es crea missatge o notificacio?
    switch (origin) {
      case 'chat':
        messageRef = doc(this.fs.firestore,
          'orgs',
          orgKey,
          'chats',
          chat.id,
          'messages',
          message.id
        );


        //TODO: send message amb el batch.

        break;
      case 'group':
        messageRef = doc(this.fs.firestore,
          'orgs',
          orgKey,
          'groups',
          chat.id,
          'messages',
          message.id
        );
        break;
    }


    let lastReaction = message?.reactions?.users[userId]?.reaction;

    batch.set(messageRef,{ reactions: {
      emojis: {
        [lastReaction]: increment(-1)
      },
      total: increment(-1),
      users: {
        [userId]: deleteField()
      }
      
    }},{ merge: true });

    // batch.delete(messageRef['reactions.users']);
    return batch.commit();
  }


  /*
  let fromName = chatData.patient
             ? (user.profile.patient
                 ? Utils.encodeName(user.name, user.id)
                 : user.profile.name_public ? user.profile.name_public : user.profile.name)
             : user.profile.name;
         await batch.update(messageRef, 'fromName', fromName);
   */

  async markMessageAsDelivered(chatRef: DocumentReference, messagesRefs: DocumentReference[], origin = 'chat') {
    const batch = writeBatch(this.fs.firestore);
    const chatSnap = await getDoc(chatRef);
    const chatData = chatSnap.data() || {};
    const lastMessageId = (chatData.lastMessage || {}).id;
    const now = serverTimestamp();
    for (const messageRef of messagesRefs) {
      if (origin === 'chat') {
        const messageSnap = await getDoc(messageRef);
        if (!messageSnap || !messageSnap.data()) continue;
        const delivered = messageSnap.data().delivered;

        if (!delivered) {
          await batch.set(messageRef, { delivered: now }, { merge: true });
        }
        if (messageSnap.id === lastMessageId) {
          await batch.update(chatRef, 'lastMessage.delivered', now);
        }
      }
      else {
        const user = this.userService.getUser();
        const userInfoRef = doc(this.fs.firestore, messageRef.path, 'userInfo', user.id);
        const messageSnap = await getDoc(userInfoRef);
        const delivered = (messageSnap.data() || {}).delivered;
        if (!delivered) await batch.set(userInfoRef, { delivered: now }, { merge: true });
      }

    }
    await batch.commit();
  }

  public loadUserStatus() {
    const user = this.userService.getUser();
    const org = this.userService.getOrganization();
    const userStatusQuery = query(
      collection(this.fs.firestore, `orgs/${org.id}/userStatus`),
      where(`userChat.${user.id}`, '==', true)
    );
    collectionChanges(userStatusQuery).pipe(
      takeUntil(this.authService.onSignOut$)
    ).subscribe((changes) => {
      // Apply changes
      changes.forEach((change: DocumentChange<any>) => this.applyUserStatusChange(change, user));

    });
  }

  public applyUserStatusChange(change: DocumentChange, user) {
    if (change.type === 'added' || change.type === 'modified') {
      const uid: any = change.doc.id;
      const data = change.doc.data();
      let chat: any = Object.values(this.chats$$).find((chatBehaviour: any) => {
        const chat = chatBehaviour.getValue();
        const users = Object.keys(chat.users);
        if (users.length < 2) return false;
        return users[0] === uid || users[1] === uid;

      });


      if (!chat) chat = Object.values(this.patientChats$$).find((chatBehaviour: any) => {
        const chat = chatBehaviour.getValue();
        const users = Object.keys(chat.users);
        if (users.length < 2) return false;
        return users[0] === uid || users[1] === uid;
      });


      if (user.id !== uid && chat) {
        chat.next({ ...chat.getValue(), id: chat.getValue().id, other: { ...chat.getValue().other, status: data.status } });
      }
      else this.notAppliedUserStatChanges.add(change);
    }
  }

  public async deleteMessage(message, chat, origin) {
    await this.loadingService.presentLoading();

    const orgKey = this.userService.getOrganization().id;
    const user = this.userService.getUser();
    if (message.content) delete message.content;
    try {
      let res;
      if (origin === 'chat') {
        origin = 'chats';
        res = await addDoc(collection(this.fs.firestore,
          'orgs',
          orgKey,
          'chats',
          chat.id,
          'delete_messages'
        ), {
          chat: chat.id,
          origin: origin,
          message: message,
          at: serverTimestamp(),
          user: {
            id: user.id,
            name: user && user.profile ? user.profile.name : 'noname'
          }
        });
      } else if (origin === 'group') {
        origin = 'groups';
        res = await addDoc(collection(this.fs.firestore,
          'orgs',
          orgKey,
          'groups',
          chat.id,
          'delete_messages'
        ), {
          group: chat.id,
          origin: origin,
          message: message,
          at: serverTimestamp(),
          user: {
            id: user.id,
            name: user && user.profile ? user.profile.name : 'noname'
          }
        });
      }
      let dismiss = false;
      let msgRef = doc(this.fs.firestore, 'orgs', orgKey, origin, chat.id, 'delete_messages', res.id);
      docSnapshots(msgRef)
        .pipe(
          distinctUntilChanged(),
          takeUntil(this.messageDeleted$)
        ).subscribe((snap) => {
          if (snap && snap.data().result) {
            this.loadingService.dismissLoading();
            dismiss = true;
            this.messageDeleted$.next(true);
          }
        })
      setTimeout(async () => {
        if (!dismiss) await this.loadingService.dismissLoading();
      }, 6000)
    } catch (e) {
      await this.loadingService.dismissLoading();
    }
    return;
  }

  public applyPendingUserStatChanges(user) {
    const changes = Array.from(this.notAppliedUserStatChanges);
    // Apply userInfo changes
    changes.forEach((change: DocumentChange<any>) => {
      this.notAppliedUserStatChanges.delete(change);
      this.applyUserStatusChange(change, user);
    });
  }
  public translateConnectionDetailStatus(other, translations) {
    let connectionStatus;
    if (other.status) {
      connectionStatus = this.userService.getConnectionStatus(other);
      if (connectionStatus === 'ONLINE')
        other.status.status_text = translations['chat'].online;
      else if (connectionStatus === "AWAY")
        other.status.status_text = translations['chat'].lastConn + " " + translations['chat'].recently;
      else if (connectionStatus === "OFFLINE") {
        if (other.status.lastConn)
          other.status.status_text = translations['chat'].lastConn + " " + (this.timeService.getDate(other.status.lastConn.toDate())).toLowerCase();
        else if (other.status.visibility && other.validation && !other.validation.verified)
          other.status.status_text = translations['chat'].neverConn;
      }
      else if (connectionStatus === 'UNAVAILABE') other.status.status_text = translations['contact'].unavailable;
      else if (connectionStatus === 'DISABLED') other.status.status_text = translations['contact'].disabled.title;

      if (!other.status.status_text) {
        other.status.status_text = translations['chat'].neverConn;
      }
    } else {
      other.status = {};
      other.status.status_text = translations['chat'].neverConn;
    }
    return other;
  }

  public uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }


  public removeMember(user, group) {
    const orgKey = this.userService.getOrganization().id;
    let userKeys = Object.keys(group.users);

    let batch = writeBatch(this.fs.firestore);
    let now = serverTimestamp();
    let sender = this.userService.getUser();

    let ref = doc(this.fs.firestore, 'users', user.id, 'groupsInfo', group.id);

    batch.update(ref, { timeRemoved: now, unreadCount: 0, removedBy: sender.id }); //FORÇAR SORTIDA DEL GRUP

    ref = doc(this.fs.firestore, 'orgs', orgKey, 'groups', group.id);

    batch.set(ref, { users: { [user.id]: false } }, { merge: true });
    ref = doc(collection(this.fs.firestore, 'orgs', orgKey, 'groups', group.id, 'messages'));
    let destUsers = 0; //destUsers els que siguin users[uid] = true, els false (eliminats del grup) NO.
    userKeys.forEach(key => {
      if (group.users[key] === true) destUsers++;
    });
    let message: any = {
      type: GroupMessageTypes.RemoveUser,
      content: user.id,
      from: sender.id,
      fromName: (group.patient && sender.profile.name_public) ? sender.profile.name_public : sender.profile.name,
      removeName: (group.patient) ? (user.profile.patient ? Utils.encodeName(user.profile.name, user.id) : (user.profile.name_public ? user.profile.name_public : user.profile.name)) : user.profile.name,
      destUsers: destUsers,
      seenUsers: 0,
      sent: now,
      info: true
    };
    if (sender.profile.patient) message.patient = true;
    batch.set(ref, message);
    return batch.commit();
  }

  private deletedGroupLastMessage() {
    return { decrContent: this.tr.instant('group.outOfGroup'), info: true, userDeleted: true }
  }

  generateListInfoMessage(message, patientsGroup: boolean = false): Observable<string> {
    if (!message.from) {
      return;
    }
    // return this._userService.fs_getUser(message.from).take(1).switchMap((user: User) => {
    let translationKey = `group.infoMessage.${message.type}`;

    const uid = this.userService.getUser().id;

    if (message.content === uid) {
      translationKey += '-self';
    }

    if (message.from === uid) {
      translationKey += '-me';
    }

    if ((message.from === message.content) && (message.from !== uid)) {
      translationKey += '-same';
    }

    let params = { contactName: '...', userName: '...', groupName: message.content };
    return this.tr.get(translationKey, params);
  }


  async changeGroupPicture(file, group, ok = null) {
    let orgKey = this.userService.getOrganization().id;
    let groupKey = group.id;
    let sender = this.userService.getUser();
    let batch = writeBatch(this.fs.firestore);
    let ref;
    let message: any = {
      type: GroupMessageTypes.ChangePicture,
      from: sender.id,
      fromName: (group.patient && sender.profile.name_public) ? sender.profile.name_public : sender.profile.name,
      sent: serverTimestamp(),
      info: true
    }
    if ((file === null || !file) && !ok) {
      await this.loadingService.dismissLoading();
      await this.confirmRemovePicture(group);
      return;
    } else if (ok && (file === null || !file)) {
      await this.loadingService.presentLoading();

      ref = doc(this.fs.firestore, 'orgs', orgKey, 'groups', groupKey);
      batch.update(ref, { picture: null, smallPicture: null });
      message.type = GroupMessageTypes.RemovePicture;
    } else {
      await this.fileService.upload(`/groupPicture/${orgKey}/${groupKey}`, file.name, file);
    }

    ref = doc(collection(this.fs.firestore, 'orgs', orgKey, 'groups', groupKey, 'messages'));
    batch.set(ref, message);
    await batch.commit();
    return;
  }


  generateInfoMessage(message, patientsGroup: boolean = false): Observable<string> {

    if (this.messagesProcessed[message.id]) {
      return this.messagesProcessed[message.id];
    }
    if (!message.from) {
      return;
    }
    // return this._userService.fs_getUser(message.from).take(1).switchMap((user: User) => {
    // if (!this._userService._users[message.from]) {
    //   this._userService.fs_forceGetUser(message.from);
    //   return;
    // }

    let params$: Observable<any> | null = null;

    let translationKey = `group.infoMessage.${message.type}`;

    const uid = this.userService.getUser().id;

    if (message.content === uid) {
      translationKey += '-self';
    }

    if (message.from === uid) {
      translationKey += '-me';
    }

    if ((message.from === message.content) && (message.from !== uid)) {
      translationKey += '-same';
    }
    let _params: any = {
      contactName: message.fromName
    };
    let userName = '';
    switch (message.type) {
      case GroupMessageTypes.Create:
      case GroupMessageTypes.ChangePicture:
      case GroupMessageTypes.RemovePicture:
      case GroupMessageTypes.ChangeName:
        params$ = of({ ..._params, groupName: message.content });
        break;
      case GroupMessageTypes.AddUser:
      case GroupMessageTypes.RemoveUser:
        let messageUser = message.removeName ? message.removeName : message.addName;
        let userName = patientsGroup ? messageUser : messageUser;
        params$ = of({ ..._params, userName });
        break;
    }

    if (!params$) {
      params$ = of(_params, userName);
    }
    this.messagesProcessed[message.id] = params$.pipe(
      first(),
      switchMap(params => this.tr.get(translationKey, params))
    );
    return this.messagesProcessed[message.id];
    // });
  }


  async confirmRemovePicture(group) {
    const data = { groupName: group.name };
    let translations = [];
    await this.tr.get(['groupDetails.picture.confirmRemove', 'general.remove', 'general.cancel'], data).forEach(tr => translations = tr);
    let alert = await this.alertCtrl.create(<any>{
      message: translations['groupDetails.picture.confirmRemove'],
      buttons: [
        {
          text: translations['general.cancel'],
          role: 'cancel',
        },
        {
          text: translations['general.remove'],
          cssClass: 'alert-button-destructive',
          handler: () => {
            this.changeGroupPicture(null, group, 'ok');
          }
        }
      ]
    });
    await alert.present();
  }


  public changeGroupName(name, group) {

    if ((name.length == 0) || (name.length > GROUP_NAME_MAX_LENGTH)) {
      return this.alertService.showError('errors.group_name');
    }
    let batch = writeBatch(this.fs.firestore);
    let orgKey = this.userService.getOrganization().id;
    let sender = this.userService.getUser();
    let ref = doc(this.fs.firestore, 'orgs', orgKey, 'groups', group.id);
    batch.set(ref, { name }, { merge: true });

    ref = ref = doc(collection(this.fs.firestore, 'orgs', orgKey, 'groups', group.id, 'messages'));
    batch.set(ref, {
      type: GroupMessageTypes.ChangeName,
      content: name,
      from: sender.id,
      fromName: (group.patient && sender.profile.name_public) ? sender.profile.name_public : sender.profile.name,
      sent: serverTimestamp(),
      info: true
    });
    return batch.commit();

  }

  getPatientChatWrittingPermission(chatId: string, patientId: string) {
    const ref = doc(this.fs.firestore, 'users', patientId, 'chatsInfo', chatId);
    return docData(ref);
  }

  changePattienChatWrittingPermission(chatId: string, patientId: any, value: boolean): Promise<any> {
    const ref = doc(this.fs.firestore, 'users', patientId, 'chatsInfo', chatId);
    return updateDoc(ref, { canWrite: value });
  }

  changePattienChattingPermission(patientId: any, value: boolean): Promise<any> {
    const ref = doc(this.fs.firestore, 'users', patientId);
    return setDoc(ref, { users: { [this.userService.getUser().id]: value } }, { merge: true });
  }

  //prevent keyboard-hide on sendMessage
  public stopBubble(event) {
    event.preventDefault();
    event.stopPropagation(); //Stops event bubbling
  }


  public transformChat(chat: any, user, origin = 'chat') {
    if (origin === 'chat') {

      // Find other user
      const users = Object.keys(chat.userParams);
      const other = (users[0] === this.userService.getUser().id ? users[1] : users[0]);

      // Decrypt last message
      const lastMessage = chat.lastMessage || {};
      lastMessage.decrContent = lastMessage.content
        ? ChatService.processHTML(this.es.AESdecrypt(lastMessage.content, chat.id)) :
        null;

      let otherObj = { ...chat.other, ...chat.userParams[other] };

      // Remove public picture if patient and doctor has public picture disabled
      if (user && user.profile && user.profile.patient && otherObj && otherObj.profile && !otherObj.profile.picture_public) {
        otherObj.profile.smallPicture = null;
        otherObj.profile.picture = null;
      }

      // Glue together
      chat = { ...chat, other: otherObj, lastMessage };
      return chat;
    }
    else {
      let group = chat;

      const hasBeenDeleted = !(group.users || {})[user.id];
      if (hasBeenDeleted) group.userDeleted = true;
      else group.userDeleted = false;
      let lastMessage;
      if (!hasBeenDeleted) {
        lastMessage = group.lastMessage || {};
        lastMessage.decrContent = lastMessage.content
          ? ChatService.processHTML(this.es.AESdecrypt(lastMessage.content, group.id)) :
          null;
      } else {
        lastMessage = this.deletedGroupLastMessage();
      }
      group = { ...group, lastMessage };
      return group;
    }
  }


  getChat(orgKey, chatKey): Promise<any> {
    const ref = doc(this.fs.firestore, 'orgs', orgKey, 'chats', chatKey);
    return getDoc(ref);
  }

  async getChatByUsersIds(uid1, uid2) {
    let res = await getDocs(query(collection(this.fs.firestore, 'orgs', this.userService.getOrganization().id, 'chats'),
      where('users.' + uid1, '==', true),
      where('users.' + uid2, '==', true)
    ))
    if (res && res && res.docs)
      return Promise.resolve(res.docs.map((doc: any) => { return { id: doc.id, ...doc.data() } })[0])
    else return []
  }
  /**
   * AUDIO RECORDING
   **/

  async recordAudio(origin, chat) {
    try {
      await this.audioService.recordAudio(chat);
      this.audioService.audioTimeout$
        .pipe(distinctUntilChanged(),
          takeUntil(this.audioService.audioEnded$))
        .subscribe((file) => {
          if (file) {
            this.stopAndSendAudio(origin, chat, file);
          }
        });
    } catch (e) { }
  }

  async stopAndSendAudio(origin, chat, timeouFile?) {
    let file = timeouFile ? timeouFile : await this.audioService.stopAudio(chat);
    if (!file) return;
    await this.sendFile(origin, chat, file, true);
    await this.loadingService.dismissLoading();
    await this.audioService.audioEnded$.next();
  }

  cancelAudio(chat) {
    this.audioService.cancelAudio(chat);
  }

  async share(origin, chat) {
    const share = this.shareObject;
    switch (share.type) {
      case ('text'):
        this.inputValue[chat.id] = share.data;
        break;

      case ('image'):
      case ('file'):
        const name = share.name;
        const blob: any = await this.fileService.dataURItoBlob(share.content.data, share.mime);
        const file = this.fileService.blobToFile(blob, name);
        await this.sendFile(origin, chat, file, false);
        await this.loadingService.dismissLoading();
        break;
    }
    this.shareObject = null;
  }
}

export enum GroupMessageTypes {
  Create = 'c',
  Message = 'm',
  AddUser = 'a',
  RemoveUser = 'r',
  ChangeName = 'n',
  ChangePicture = 'p',
  RemovePicture = 'rp',
}
