// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.warning {
  color: red;
}

.longtitle {
  text-overflow: inherit;
  white-space: normal;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/privacity/privacity.component.scss"],"names":[],"mappings":"AAAA;EACI,UAAA;AACJ;;AAEA;EACI,sBAAA;EACA,mBAAA;AACJ","sourcesContent":[".warning{\n    color: red;\n}\n\n.longtitle{\n    text-overflow: inherit;\n    white-space: normal;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
