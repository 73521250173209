import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TimeService } from 'src/services/time.service';
import { UserService } from 'src/services/user.service';
import { LoadingService } from 'src/services/loading.service';

@Component({
  selector: 'app-add-unavailable-rules',
  templateUrl: './add-unavailable-rules.component.html',
  styleUrls: ['./add-unavailable-rules.component.scss'],
})
export class AddUnavailableRulesComponent implements OnInit {

  public initialTime: any;
  public finalTime: any;
  public allDay: boolean;
  public timeError: boolean = false;
  public generalError: boolean = false;
  public monday: boolean;
  public tuesday: boolean;
  public wednesday: boolean;
  public thursday: boolean;
  public friday: boolean;
  public saturday: boolean;
  public sunday: boolean;

  constructor(
    public modalCtrl: ModalController,
    public timeService: TimeService,
    public userService: UserService,
    public loadingService: LoadingService) { }


  ngOnInit() { }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  change() {
    this.timeError = false;
    this.generalError = false;


    //check times are selected
    if (!this.allDay && !this.finalTime && !this.initialTime) {
      this.generalError = true;
    }

    //check day is selected
    if (!(this.monday || this.tuesday || this.wednesday || this.thursday || this.friday || this.saturday || this.sunday)) {
      this.generalError = true;
    }

    if (!this.generalError && !this.timeError) {
      this.addRule();
    }
  }

  async addRule() {
    if (!this.finalTime) {
      this.finalTime = await this.timeService.newDate(23, 59);
    } else {
      // this.finalTime = await this.timeService.getTime(this.finalTime);
    }
    if (!this.initialTime) {
      this.initialTime = await this.timeService.newDate(0, 0);
    } else {
      // this.initialTime = await this.timeService.getTime(this.initialTime);
    }

    if (this.initialTime < this.finalTime) {
      var tempData = {
        initialTime: this.initialTime ? this.initialTime : null,
        finalTime: this.finalTime ? this.finalTime : null,
        allDay: this.allDay ? this.allDay : false,
        monday: this.monday ? this.monday : false,
        tuesday: this.tuesday ? this.tuesday : false,
        wednesday: this.wednesday ? this.wednesday : false,
        thursday: this.thursday ? this.thursday : false,
        friday: this.friday ? this.friday : false,
        saturday: this.saturday ? this.saturday : false,
        sunday: this.sunday ? this.sunday : false
      }
      await this.loadingService.presentLoading();
      await this.userService.addUnavailableRule(tempData);
      await this.loadingService.dismissLoading();
      await this.dismiss();
    } else {
      let endInitial = await this.timeService.newDate(23, 59);
      let startFinal = await this.timeService.newDate(0, 0);
      await this.loadingService.presentLoading();
      let tempDataInitial = {
        initialTime: this.initialTime,
        finalTime: endInitial,
        allDay: false,
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false,
      }
      let tempDataFinal = {
        initialTime: startFinal,
        finalTime: this.finalTime,
        allDay: false,
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false,
      }
      if (this.monday) {
        tempDataInitial.monday = true;
        tempDataFinal.tuesday = true;
      }
      if (this.tuesday) {
        tempDataInitial.tuesday = true;
        tempDataFinal.wednesday = true;
      }
      if (this.wednesday) {
        tempDataInitial.wednesday = true;
        tempDataFinal.thursday = true;
      }
      if (this.thursday) {
        tempDataInitial.thursday = true;
        tempDataFinal.friday = true;
      }
      if (this.friday) {
        tempDataInitial.friday = true;
        tempDataFinal.saturday = true;
      }
      if (this.saturday) {
        tempDataInitial.saturday = true;
        tempDataFinal.sunday = true;
      }
      if (this.sunday) {
        tempDataInitial.sunday = true;
        tempDataFinal.monday = true;
      }
      await this.userService.addUnavailableRule(tempDataFinal);
      await this.userService.addUnavailableRule(tempDataInitial);
      await this.loadingService.dismissLoading();
      await this.dismiss();
    }
  }

}
