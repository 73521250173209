// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input {
  width: 100%;
  border: none;
}
input:focus-visible {
  outline: none;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/phone-input/phone-input.component.scss"],"names":[],"mappings":"AAMA;EACI,WAAA;EACA,YAAA;AALJ;AAOI;EACI,aAAA;AALR","sourcesContent":["// .iti {\n//     --iti-path-flags-1x: url('node_modules/intl-tel-input/build/img/flags.png');\n//     --iti-path-flags-2x: url('node_modules/intl-tel-input/build/img/flags@2x.png');\n//     --iti-path-globe-1x: url('node_modules/intl-tel-input/build/img/globe.png');\n//     --iti-path-globe-2x: url('node_modules/intl-tel-input/build/img/globe@2x.png');\n//   }\ninput {\n    width: 100%;\n    border: none;\n\n    &:focus-visible {\n        outline: none;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
