import { Injectable } from '@angular/core';
import { doc, getDoc } from 'firebase/firestore';
import { collection as collectionSnapshots, doc as docSnapshots } from 'rxfire/firestore';

import { ColorGenerator } from 'src/app/shared/text-img/color-generator';
import { UserService } from './user.service';
import { map, takeUntil } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { TimeService } from './time.service';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { addDoc, collection, deleteDoc, deleteField, getDocs, orderBy, query, QueryConstraint, serverTimestamp, setDoc, updateDoc, where, writeBatch } from "firebase/firestore";
import { FirebaseService } from './firebase.service';
@Injectable({
  providedIn: 'root'
})
export class SecureComService {
  public templateUpdate$ = new Subject<any>();
  public campaignObj$ = new ReplaySubject<any>();

  constructor(public fs: FirebaseService,
    public authService: AuthService,
    public timeService: TimeService,
    public tr: TranslateService,
    public http: HttpClient,
    public us: UserService) {

  }


  getSecureComs$(start, end, all = false): Observable<any> {
    let scquery;
    if (all) {
      scquery = query(
        collection(this.fs.firestore, `orgs/${this.us.getOrganization().id}/secureComm`),
        orderBy('rcvd', 'desc')
      )
      // this.fs.firestore
      //   .collection(`orgs/${this.us.getOrganization().id}/secureComm`,
      //     ref => ref
      //       .orderBy('rcvd', 'desc')
      // )
    } else {
      scquery = query(
        collection(this.fs.firestore, `orgs/${this.us.getOrganization().id}/secureComm`),
        orderBy('rcvd', 'desc'),
        where('rcvd', '<', end),
        where('rcvd', '>=', start)
      )
      // query = this.fs.firestore
      //   .collection(`orgs/${this.us.getOrganization().id}/secureComm`,
      //     ref => ref
      //       .orderBy('rcvd', 'desc')
      //       .where('rcvd', '<', end)
      //       .where('rcvd', '>=', start)
      //   )
    }

    return collectionSnapshots(scquery)
      .pipe(takeUntil(this.authService.onSignOut$),
        map((docs: any) => docs.map((snap: any) => {
          let res: any = { id: snap.id, ...snap.data() }
          res.com_date = res.checked && res.checkedInfo && res.checkedInfo.date ? res.checkedInfo.date : res.rcvd;
          return res;
        })));
    // query.snapshotChanges().pipe(
    // takeUntil(this.authService.onSignOut$),
    // map((docs: any) => docs.map((snap: any) => {
    //   let res: any = { id: snap.payload.doc.id, ...snap.payload.doc.data() }
    //   res.com_date = res.checked && res.checkedInfo && res.checkedInfo.date ? res.checkedInfo.date : res.rcvd;
    //   return res;
    // }))
    // );
  }

  async getSecureComs(filter?): Promise<any> {
    let res;
    let query;
    if (filter === 'confirmed') {
      query = query(
        collection(this.fs.firestore, `orgs/${this.us.getOrganization().id}/secureComm`),
        orderBy('rcvd', 'desc'),
        where('ok', '==', true)
      )
      res = await getDocs(query);
      // res = await this.fs.firestore.firestore
      //   .collection('orgs')
      //   .doc(this.us.getOrganization().id)
      //   .collection('secureComm')
      //   .orderBy('rcvd', 'desc')
      //   .where('ok', '==', true)
      //   .get();
    } else if (filter === 'failed') {
      query = query(
        collection(this.fs.firestore, `orgs/${this.us.getOrganization().id}/secureComm`),
        orderBy('rcvd', 'desc'),
        where('ok', '==', false)
      )
      res = await getDocs(query);
      // res = await this.fs.firestore.firestore
      //   .collection('orgs')
      //   .doc(this.us.getOrganization().id)
      //   .collection('secureComm')
      //   .orderBy('rcvd', 'desc')
      //   .where('ok', '==', false)
      //   .get();
    } else {
      query = query(
        collection(this.fs.firestore, `orgs/${this.us.getOrganization().id}/secureComm`),
        orderBy('rcvd', 'desc')
      )
      res = await getDocs(query);
      // res = await this.fs.firestore.firestore
      //   .collection('orgs')
      //   .doc(this.us.getOrganization().id)
      //   .collection('secureComm')
      //   .orderBy('rcvd', 'desc')
      //   .get();
    }
    return Promise.resolve(res.docs.map((doc: any) => { return { id: doc.id, ...doc.data() } }))
  }

  async getSecureCom(id): Promise<any> {
    let res = await getDoc(doc(this.fs.firestore, `orgs/${this.us.getOrganization().id}/secureComm/${id}`));
    // let res = await this.fs.firestore.firestore
    //   .collection('orgs')
    //   .doc(this.us.getOrganization().id)
    //   .collection('secureComm')
    //   .doc(id)
    //   .get();
    return { id: res.id, ...res.data() }
  }

  getSecureCom$(id): Observable<any> {
    return docSnapshots(doc(this.fs.firestore, `orgs/${this.us.getOrganization().id}/secureComm/${id}`))
      .pipe(
        takeUntil(this.authService.onSignOut$),
        map((snap: any) => ({ id: snap.id, ...snap.data() }))
      )
    // return this.fs.firestore
    //   .collection('orgs')
    //   .doc(this.us.getOrganization().id)
    //   .collection('secureComm')
    //   .doc(id)
    //   .snapshotChanges()
    //   .pipe(
    //     takeUntil(this.authService.onSignOut$),
    //     map((snap: any) => ({ id: snap.payload.id, ...snap.payload.data() }))
    //   );
  }

  getCampaignSC(campaignId): Promise<any> {
    return new Promise(async (resolve, reject) => {


      let scQuery = query(
        collection(this.fs.firestore, `orgs/${this.us.getOrganization().id}/secureComm`),
        where('campaign.id', '==', campaignId),
        orderBy('rcvd', 'asc')
      )
      let snaps = await getDocs(scQuery);

      // let snaps = await this.fs.firestore.firestore
      //   .collection('orgs')
      //   .doc(this.us.getOrganization().id)
      //   .collection('secureComm')
      //   .where('campaign.id', '==', campaignId)
      //   .orderBy('rcvd', 'asc')
      //   .get();
      resolve(snaps.docs.map((doc: any) => {
        let sc = doc.data();
        if (sc.rcvd) sc.rcvd = this.timeService.dbToViewDateTimeFormat(sc.rcvd.toDate())
        if (sc.seen && sc.seen.date && sc.seen.date.toDate()) sc.seen = this.timeService.dbToViewDateTimeFormat(sc.seen.date.toDate())
        if (sc.publicForm && sc.publicForm.answered) sc.formAnswered = this.timeService.dbToViewDateTimeFormat(sc.publicForm.answered.toDate())
        return { id: doc.id, ...sc }
      }))
    })
  }

  async updateSCCampaign(scms): Promise<any> {
    let count = 0;
    let batch = writeBatch(this.fs.firestore);

    let campaign = {
      release: "duCCKenNQJ2sdyyBuy53"
    }

    let orgKey = this.us.getOrganization().id;
    for (let i = 0; i < scms.length; i++) {
      let sc = scms[i];
      let ref = doc(this.fs.firestore, `orgs/${orgKey}/secureComm/${sc.id}`)
      // this.fs.firestore.firestore
      //   .collection('orgs')
      //   .doc(orgKey)
      //   .collection('secureComm')
      //   .doc(sc.id)
      batch.set(ref, { campaign }, { merge: true })
      count++;

      if (count === 400) {
        await batch.commit();
        count = 0;
        batch = writeBatch(this.fs.firestore);
      }
    }
    return batch.commit();
  }

  async updatePFCampaign(pfs): Promise<any> {
    let count = 0;
    let batch = writeBatch(this.fs.firestore);

    let campaign = {
      release: "duCCKenNQJ2sdyyBuy53",
    }
    let orgKey = this.us.getOrganization().id;
    for (let i = 0; i < pfs.length; i++) {
      let pf = pfs[i];
      let ref = doc(this.fs.firestore, `orgs/${orgKey}/publicForms/${pf.id}`)
      // let ref = this.fs.firestore.firestore
      //   .collection('orgs')
      //   .doc(orgKey)
      //   .collection('publicForms')
      //   .doc(pf.id)
      batch.set(ref, { campaign }, { merge: true })
      count++;

      if (count === 400) {
        await batch.commit();
        count = 0;
        batch = writeBatch(this.fs.firestore);
      }
    }
    return batch.commit();
  }

  getCampaignSCRelease(campaignId, releaseId): Promise<any> {
    return new Promise(async (resolve, reject) => {
      let campaingQuery = query(collection(this.fs.firestore, `orgs/${this.us.getOrganization().id}/secureComm`),
        where('campaign.id', '==', campaignId),
        where('campaign.release', '==', releaseId),
        orderBy('rcvd', 'asc'))
      let snaps = await getDocs(campaingQuery);

      // let snaps = await this.fs.firestore.firestore
      //   .collection('orgs')
      //   .doc(this.us.getOrganization().id)
      //   .collection('secureComm')
      //   .where('campaign.id', '==', campaignId)
      //   .where('campaign.release', '==', releaseId)
      //   .orderBy('rcvd', 'asc')
      //   .get();

      resolve(snaps.docs.map((doc: any) => {
        let sc = doc.data();
        if (sc.rcvd) sc.rcvd = this.timeService.dbToViewDateTimeFormat(sc.rcvd.toDate())
        if (sc.seen && sc.seen.date && sc.seen.date.toDate()) sc.seen = this.timeService.dbToViewDateTimeFormat(sc.seen.date.toDate())
        if (sc.publicForm && sc.publicForm.answered) sc.formAnswered = this.timeService.dbToViewDateTimeFormat(sc.publicForm.answered.toDate())
        return { id: doc.id, ...sc }
      }))
    })
  }

  getCampaignPF(campaignId): Promise<any> {
    return new Promise(async (resolve, reject) => {
      let pfQuery = query(collection(this.fs.firestore, `orgs/${this.us.getOrganization().id}/publicForms`),
        where('campaign.id', '==', campaignId),
        orderBy('sent', 'asc'))
      let snaps = await getDocs(pfQuery);

      // let snaps = await this.fs.firestore.firestore
      //   .collection('orgs')
      //   .doc(this.us.getOrganization().id)
      //   .collection('publicForms')
      //   .where('campaign.id', '==', campaignId)
      //   .orderBy('sent', 'asc')
      //   .get();
      resolve(snaps.docs.map((doc: any) => {
        let pf = doc.data();
        if (pf.sent) pf.sent = this.timeService.dbToViewDateTimeFormat(pf.sent.toDate())
        return { id: doc.id, ...pf }
      }))
    })
  }

  getCampaignPFRelease(campaignId, releaseId): Promise<any> {
    return new Promise(async (resolve, reject) => {
      let pfRQuery = query(collection(this.fs.firestore, `orgs/${this.us.getOrganization().id}/publicForms`),
        where('campaign.id', '==', campaignId),
        where('campaign.release', '==', releaseId),
        orderBy('sent', 'asc'))
      let snaps = await getDocs(pfRQuery);

      // let snaps = await this.fs.firestore.firestore
      //   .collection('orgs')
      //   .doc(this.us.getOrganization().id)
      //   .collection('publicForms')
      //   .where('campaign.id', '==', campaignId)
      //   .where('campaign.release', '==', releaseId)
      //   .orderBy('sent', 'asc')
      //   .get();
      resolve(snaps.docs.map((doc: any) => {
        let pf = doc.data();
        if (pf.sent) pf.sent = this.timeService.dbToViewDateTimeFormat(pf.sent.toDate())
        return { id: doc.id, ...pf }
      }))
    })
  }

  getCampaignReleases(campaignId): Promise<any> {
    return new Promise(async (resolve, reject) => {
      let crQuery = query(collection(this.fs.firestore, `orgs/${this.us.getOrganization().id}/campaigns/${campaignId}/releases`),
        orderBy('time', 'desc'));

      let snaps = await getDocs(crQuery);

      // let snaps = await this.fs.firestore.firestore
      //   .collection('orgs')
      //   .doc(this.us.getOrganization().id)
      //   .collection('campaigns')
      //   .doc(campaignId)
      //   .collection('releases')
      //   .orderBy('time', 'desc')
      //   .get();
      resolve(snaps.docs.map((doc: any) => {
        let pf = doc.data();
        return { id: doc.id, ...pf }
      }))
    })
  }

  getCampaignRelease(id, release): Promise<any> {
    return new Promise(async (resolve, reject) => {
      let snap = await getDoc(doc(this.fs.firestore, `orgs/${this.us.getOrganization().id}/campaigns/${id}/releases/${release}`));
      // let snap = await this.fs.firestore.firestore
      //   .collection('orgs')
      //   .doc(this.us.getOrganization().id)
      //   .collection('campaigns')
      //   .doc(id)
      //   .collection('releases')
      //   .doc(release)
      //   .get();
      resolve({ id: snap.id, ...snap.data() })
    })
  }

  async checkSecureCom(com): Promise<any> {
    let batch = writeBatch(this.fs.firestore);
    let ref = doc(this.fs.firestore, `orgs/${this.us.getOrganization().id}/secureComm/${com.id}`);
    batch.set(ref, {
      checked: true,
      checkedInfo: {
        id: this.us.getUser().id,
        name: this.us.getUser().profile.name,
        date: serverTimestamp()
      }
    }, { merge: true });
    return batch.commit();
    // return this.fs.firestore.firestore
    //   .collection('orgs')
    //   .doc(this.us.getOrganization().id)
    //   .collection('secureComm')
    //   .doc(com.id)
    //   .set({
    //     checked: true,
    //     checkedInfo: {
    //       id: this.us.getUser().id,
    //       name: this.us.getUser().profile.name,
    //       date: serverTimestamp()
    //     }
    //   }, { merge: true })
  }

  async getPublicForms(): Promise<any> {
    let pfQuery = query(collection(this.fs.firestore, `orgs/${this.us.getOrganization().id}/publicForms`),
      orderBy('sent', 'asc')
    )
    let res = await getDocs(pfQuery);
    // let res = await this.fs.firestore.firestore
    //   .collection('orgs')
    //   .doc(this.us.getOrganization().id)
    //   .collection('publicForms')
    //   .orderBy('sent', 'asc')
    //   .get();
    return Promise.resolve(res.docs.map((doc: any) => { return { id: doc.id, ...doc.data() } }))
  }

  getPublicForms$(status?, mine = false, start?, end?, organization?, all = false): Observable<any> {
    let orgid = organization ? organization : this.us.getOrganization().id

    let queryList: QueryConstraint[] = [];
    if (mine) queryList.push(where('assignation.to.id', '==', this.us.getUser().id))
    if (status) {
      queryList.push(where('status', '==', status))
      if (status === 'closed') {
        if (!all) {
          if (end) queryList.push(where('answer.at', '<', end));
          if (start) queryList.push(where('answer.at', '>=', start));
        }
        queryList.push(orderBy('answer.at', 'desc'));
      }
      if (!status || status != 'closed') queryList.push(orderBy('sent', 'asc'));
    }

    let pfQuery = query(collection(this.fs.firestore, `orgs/${orgid}/publicForms`),
      ...queryList
    )

    return collectionSnapshots(pfQuery).pipe(
      takeUntil(this.authService.onSignOut$),
      map((docs: any) => docs.map((snap: any) => {
        let res: any = {
          id: snap.id,
          ...snap.data(),
          sent: this.timeService.dbToViewDateFormat(snap.data().sent.toDate(), 'DD/MM/YYYY HH:mm')
        }
        if (res.seen && res.seen.value) res.user_seen = this.tr.instant('general.yes');
        else res.user_seen = this.tr.instant('general.no')

        if (res.assignation && res.assignation.by)
          res.assignation_by_name = res.assignation.by.name;

        if (res.assignation && res.assignation.to)
          res.assignation_to_name = res.assignation.to.name;

        if (res.social_privacy) res.social_privacy = this.tr.instant('general.yes');
        else res.social_privacy = this.tr.instant('general.no')

        if (res.assignation && res.assignation.at)
          res.assigned_at = this.timeService.dbToViewDateFormat(snap.data().assignation.at.toDate(), 'DD/MM/YYYY HH:mm')

        if (res.answer && res.answer.at)
          res.answer_at = this.timeService.dbToViewDateFormat(snap.data().answer.at.toDate(), 'DD/MM/YYYY HH:mm')
        return res;
      }
      ))
    );

    // return this.fs.firestore
    //   .collection(`orgs/${orgid}/publicForms`,
    //     (ref: any) => {
    //       if (mine) ref = ref.where('assignation.to.id', '==', this.us.getUser().id)
    //       if (status) {
    //         ref = ref.where('status', '==', status)
    //         if (status === 'closed') {
    //           if (!all) {
    //             if (end) ref = ref.where('answer.at', '<', end)
    //             if (start) ref = ref.where('answer.at', '>=', start)
    //           }
    //           ref = ref.orderBy('answer.at', 'desc')
    //         }
    //       }
    //       if (!status || status != 'closed') ref = ref.orderBy('sent', 'asc')
    //       return ref
    //     }
    //   )
    //   .snapshotChanges().pipe(
    //     takeUntil(this.authService.onSignOut$),
    //     map((docs: any) => docs.map((snap: any) => {
    //       let res: any = {
    //         id: snap.payload.doc.id,
    //         ...snap.payload.doc.data(),
    //         sent: this.timeService.dbToViewDateFormat(snap.payload.doc.data().sent.toDate(), 'DD/MM/YYYY HH:mm')
    //       }
    //       if (res.seen && res.seen.value) res.user_seen = this.tr.instant('general.yes');
    //       else res.user_seen = this.tr.instant('general.no')

    //       if (res.assignation && res.assignation.by)
    //         res.assignation_by_name = res.assignation.by.name;

    //       if (res.assignation && res.assignation.to)
    //         res.assignation_to_name = res.assignation.to.name;

    //       if (res.social_privacy) res.social_privacy = this.tr.instant('general.yes');
    //       else res.social_privacy = this.tr.instant('general.no')

    //       if (res.assignation && res.assignation.at)
    //         res.assigned_at = this.timeService.dbToViewDateFormat(snap.payload.doc.data().assignation.at.toDate(), 'DD/MM/YYYY HH:mm')

    //       if (res.answer && res.answer.at)
    //         res.answer_at = this.timeService.dbToViewDateFormat(snap.payload.doc.data().answer.at.toDate(), 'DD/MM/YYYY HH:mm')

    //       return res;
    //     }
    //     ))
    //   );
  }

  async getPublicForm(id): Promise<any> {
    let res = await getDoc(doc(this.fs.firestore, 'orgs', this.us.getOrganization().id, 'publicForms', id));
    // let res = await this.fs.firestore.firestore
    //   .collection('orgs')
    //   .doc(this.us.getOrganization().id)
    //   .collection('publicForms')
    //   .doc(id)
    //   .get();
    return { id: res.id, ...res.data() }
  }

  async getPublicFormFiles(id): Promise<any> {
    let snaps = await getDocs(collection(this.fs.firestore, 'orgs', this.us.getOrganization().id, 'publicForms', id, 'files'))
    // let snaps = await this.fs.firestore.firestore
    //   .collection('orgs')
    //   .doc(this.us.getOrganization().id)
    //   .collection('publicForms')
    //   .doc(id)
    //   .collection('files')
    //   .get();
    return snaps.docs.map((snap) => { return { id: snap.id, ...snap.data() } })
  }

  getPublicForm$(id): Observable<any> {
    return docSnapshots(doc(this.fs.firestore, `orgs/${this.us.getOrganization().id}/publicForms/${id}`))
      .pipe(
        takeUntil(this.authService.onSignOut$),
        map((snap: any) => ({ id: snap.id, ...snap.data() }))
      );
    // return this.fs.firestore
    //   .collection('orgs')
    //   .doc(this.us.getOrganization().id)
    //   .collection('publicForms')
    //   .doc(id)
    //   .snapshotChanges()
    //   .pipe(
    //     takeUntil(this.authService.onSignOut$),
    //     map((snap: any) => ({ id: snap.payload.id, ...snap.payload.data() }))
    //   );
  }

  async getFormTemplate(id): Promise<any> {
    let orgKey = this.us.getOrganization().id;
    let snap = await getDoc(doc(this.fs.firestore, 'orgs', orgKey, 'formTemplates', id));
    // let snap = await this.fs.firestore.firestore.collection('orgs')
    //   .doc(orgKey)
    //   .collection('formTemplates')
    //   .doc(id)
    //   .get();
    return { id: snap.id, ...snap.data() }
  }

  async getFormTemplates(type): Promise<any> {
    let orgKey = this.us.getOrganization().id;

    let ftQuery = query(collection(this.fs.firestore, "orgs", orgKey, 'formTemplates'),
      where("type", "==", type));

    let res = await getDocs(ftQuery)

    // let res = await this.fs.firestore.firestore.collection('orgs')
    //   .doc(orgKey)
    //   .collection('formTemplates')
    //   .where('type', '==', type)
    //   .get();
    let templates = res.docs.map((doc: any) => { return { id: doc.id, ...doc.data() } });
    templates.sort((a, b) => {
      if (!a.name) return 1;
      if (!b.name) return -1;
      return a.name.localeCompare(b.name);
    })
    return Promise.resolve(templates)
  }

  async deleteFormTemplate(id): Promise<any> {
    let orgKey = this.us.getOrganization().id;
    return deleteDoc(doc(this.fs.firestore, "orgs", orgKey, "formTemplates", id));
    // return this.fs.firestore.firestore.collection('orgs')
    //   .doc(orgKey)
    //   .collection('formTemplates')
    //   .doc(id)
    //   .delete()
  }

  async updateFormTemplate(id, form): Promise<any> {
    let orgKey = this.us.getOrganization().id;
    if (id != 'new') {
      return setDoc(doc(this.fs.firestore, `orgs/${orgKey}/formTemplates/${id}`), form, { merge: true })
      // return this.fs.firestore.firestore.collection('orgs')
      //   .doc(orgKey)
      //   .collection('formTemplates')
      //   .doc(id)
      //   .set(form, { merge: true })
    } else {
      delete form.id;
      return addDoc(collection(this.fs.firestore, "orgs", orgKey, "formTemplates"), form);
      // return this.fs.firestore.firestore.collection('orgs')
      //   .doc(orgKey)
      //   .collection('formTemplates')
      //   .add(form)
    }
  }

  async updateTemplateFormField(id, content): Promise<any> {
    let orgKey = this.us.getOrganization().id;
    if (id === 'new') {
      return addDoc(collection(this.fs.firestore, "orgs", orgKey, "formTemplates"), { content, type: 'form' });
      // return this.fs.firestore.firestore.collection('orgs')
      //   .doc(orgKey)
      //   .collection('formTemplates')
      //   .add({ content, type: 'form' })
    }
    return setDoc(doc(this.fs.firestore, `orgs/${orgKey}/formTemplates/${id}`), { content }, { merge: true })
    // return this.fs.firestore.firestore.collection('orgs')
    //   .doc(orgKey)
    //   .collection('formTemplates')
    //   .doc(id)
    //   .set({ content }, { merge: true })
  }

  updateTemplateField(id, obj, form?): Promise<any> {
    let orgKey = this.us.getOrganization().id;
    if (id === 'new') {
      if (form) obj = { ...form, ...obj }
      return addDoc(collection(this.fs.firestore, "orgs", orgKey, "formTemplates"), { ...obj, type: 'form' });
      // return this.fs.firestore.firestore.collection('orgs')
      //   .doc(orgKey)
      //   .collection('formTemplates')
      //   .add({ ...obj, type: 'form' })
    }
    return setDoc(doc(this.fs.firestore, `orgs/${orgKey}/formTemplates/${id}`), obj, { merge: true })

    // return this.fs.firestore.firestore.collection('orgs')
    //   .doc(orgKey)
    //   .collection('formTemplates')
    //   .doc(id)
    //   .set(obj, { merge: true })
  }

  async getCampaigns(): Promise<any> {
    let orgKey = this.us.getOrganization().id;
    return new Promise(async (resolve, reject) => {
      let cQuery = query(collection(this.fs.firestore, "orgs", orgKey, 'campaigns'), orderBy('time', 'desc'))
      let res = await getDocs(cQuery);
      // let res = await this.fs.firestore.firestore.collection('orgs')
      //   .doc(orgKey)
      //   .collection('campaigns')
      //   .orderBy('time', 'desc')
      //   .get();
      let campaigns = res.docs.map((doc: any) => { return { ...doc.data(), id: doc.id } });
      resolve(campaigns);
    });
  }

  async getCampaign(id): Promise<any> {
    let orgKey = this.us.getOrganization().id;
    let res = await getDoc(doc(this.fs.firestore, 'orgs', orgKey, 'campaigns', id));
    // let res = await this.fs.firestore.firestore.collection('orgs')
    //   .doc(orgKey)
    //   .collection('campaigns')
    //   .doc(id)
    //   .get();
    return { ...res.data(), id: res.id }
  }
  async getCampaignByTag(name): Promise<any> {
    let orgKey = this.us.getOrganization().id;
    return new Promise(async (resolve, reject) => {
      let cQuery = query(collection(this.fs.firestore, "orgs", orgKey, "campaigns"),
        where('tag', '==', name.toLowerCase()));
      let res = await getDocs(cQuery);
      // let res = await this.fs.firestore.firestore.collection('orgs')
      //   .doc(orgKey)
      //   .collection('campaigns')
      //   .where('tag', '==', name.toLowerCase())
      //   .get();
      let campaigns = res.docs.map((doc: any) => { return { id: doc.id, ...doc.data() } });
      resolve(campaigns[0])
    });
  }

  async assignPfToUser(pfid, user, ftid, model): Promise<any> {
    let batch = writeBatch(this.fs.firestore);

    let orgKey = this.us.getOrganization().id;
    let ref = doc(this.fs.firestore, 'orgs', orgKey, 'publicForms', pfid);
    // let ref = this.fs.firestore.firestore
    //   .collection('orgs')
    //   .doc(orgKey)
    //   .collection('publicForms')
    //   .doc(pfid);

    let assignation: any = {
      to: {
        id: user.id,
        name: user.profile.name,
        email: user.profile.email
      },
      by: {
        id: this.us.getUser().id,
        name: this.us.getUser().profile.name,
        email: this.us.getUser().profile.email,
      },
      at: serverTimestamp()
    }
    let newId = doc(collection(this.fs.firestore, '_')).id;

    let assRef = doc(this.fs.firestore, 'orgs', orgKey, 'publicForms', pfid, 'assignations',newId);
    // let assRef = this.fs.firestore.firestore
    //   .collection('orgs')
    //   .doc(orgKey)
    //   .collection('publicForms')
    //   .doc(pfid)
    //   .collection('assignations')
    //   .doc();

    assignation.ass_id = assRef.id;

    if (model && model.type) assignation.type = model.type;
    batch.set(assRef, { ...assignation, formTemplate: ftid })

    batch.set(ref, {
      assignation,
      status: 'assigned'
    }, { merge: true })
    ref = doc(this.fs.firestore, 'users', user.id, 'organizationInfo', orgKey);

    // ref = this.fs.firestore.firestore
    //   .collection('users')
    //   .doc(user.id)
    //   .collection('organizationInfo')
    //   .doc(orgKey)
    batch.set(ref, {
      modules: {
        publicForm: true
      }
    }, { merge: true })

    ref = doc(this.fs.firestore, 'users', user.id);

    // ref = this.fs.firestore.firestore
    //   .collection('users')
    //   .doc(user.id)
    batch.set(ref, {
      modules: {
        [orgKey]: {
          publicForm: true
        }
      }
    }, { merge: true })
    return batch.commit();
  }

  async cancelAssignation(pfid, model) {
    let batch = writeBatch(this.fs.firestore);

    let orgKey = this.us.getOrganization().id;
    let ref = doc(this.fs.firestore, 'orgs', orgKey, 'publicForms', pfid);
    // let ref = this.fs.firestore.firestore
    //   .collection('orgs')
    //   .doc(orgKey)
    //   .collection('publicForms')
    //   .doc(pfid);
    batch.set(ref, {
      status: 'new',
      assignation: deleteField()
    }, { merge: true });

    if (model && model.assignation && model.assignation.ass_id) {
      let snap = await getDoc(doc(this.fs.firestore, 'orgs', orgKey, 'publicForms', pfid, 'assignations', model.assignation.ass_id));
      // let snap = await this.fs.firestore.firestore
      //   .collection('orgs')
      //   .doc(orgKey)
      //   .collection('publicForms')
      //   .doc(pfid)
      //   .collection('assignations')
      //   .doc(model.assignation.ass_id)
      //   .get()

      if (snap && snap.exists) {
        batch.set(snap.ref, {
          status: 'canceled',
          canceled: {
            by: this.us.getUser().id,
            at: serverTimestamp()
          }
        }, { merge: true })
      }
    }
    return batch.commit();
  }

  answerPf(pfid, answer, closed = false): Promise<any> {
    const orgKey = this.us.getOrganization().id;
    let batch = writeBatch(this.fs.firestore)

    let body: any = {
      uid: this.us.getUser().id,
      answer
    }
    if (closed) {
      body.closedByUser = true;
      body.autoResponse = true;
    }
    let newId = doc(collection(this.fs.firestore, '_')).id;

    let ref = doc(this.fs.firestore, 'orgs', orgKey, 'publicForms', pfid, 'answers', newId)
    // let ref = this.fs.firestore.firestore
    //   .collection('orgs')
    //   .doc(orgKey)
    //   .collection('publicForms')
    //   .doc(pfid)
    //   .collection('answers')
    //   .doc()
    batch.set(ref, {
      ...body
    });
    return batch.commit();
  }

  closePf(pfid, auto, answer): Promise<any> {
    let batch = writeBatch(this.fs.firestore);
    let orgKey = this.us.getOrganization().id;
    let ref = doc(this.fs.firestore, 'orgs', orgKey, 'publicForms', pfid);
    // let ref = this.fs.firestore.firestore
    //   .collection('orgs')
    //   .doc(orgKey)
    //   .collection('publicForms')
    //   .doc(pfid)
    batch.set(ref, {
      status: 'closed',
      answer: {
        message: answer ? answer : '',
        closedByUser: true,
        autoResponse: auto,
        at: serverTimestamp(),
        by: {
          id: this.us.getUser().id,
          name: this.us.getUser().profile.name,
          email: this.us.getUser().profile.email
        }
      }
    }, { merge: true })
    return batch.commit();
  }



  public getTypeColor(type) {
    return ColorGenerator.getColor(type);
  }

  public getStatusColor(status) {
    let color = "primary";
    switch (status) {
      case 'new':
        color = "primary";
        break
      case 'assigned':
        color = "warning";
        break;
      case 'resolved':
        color = 'gray';
        break;
      case 'closed':
        color = 'gray'
        break;
    }
    return color;
  }

  async createRelease(orgKey, release, id, releaseIdTmp) {
    setDoc(doc(this.fs.firestore, 'orgs', orgKey, 'campaigns', id, 'releases', releaseIdTmp), { ...release });
    // return this.fs.firestore.firestore
    //   .collection('orgs')
    //   .doc(orgKey)
    //   .collection('campaigns')
    //   .doc(id)
    //   .collection('releases')
    //   .doc(releaseIdTmp)
    //   .set({ ...release })
  }

  async createSC(orgKey, scomms, release?) {
    let batch = writeBatch(this.fs.firestore)
    let count = 0;
    for (let i = 0; i < scomms.length; i++) {
      let sc = scomms[i];
      let newId = doc(collection(this.fs.firestore, '_')).id;
      let ref = doc(this.fs.firestore, 'orgs', orgKey, 'createCom', newId);
      // let ref = this.fs.firestore.firestore
      //   .collection('orgs')
      //   .doc(orgKey)
      //   .collection('createCom')
      //   .doc(this.fs.firestore.createId())
      let data = { ...sc };
      if (release) data.release = release;
      batch.set(ref, { ...data });
      count++;
      if (count === 248) {
        await batch.commit();
        batch = writeBatch(this.fs.firestore);
        count = 0;
      }
    }
    return batch.commit();
  }

  async updateTemplate(tmp): Promise<any> {
    let id = tmp.id ? tmp.id : doc(collection(this.fs.firestore, '_')).id;
    if (tmp && !tmp.id) {
      await setDoc(doc(this.fs.firestore, 'orgs', this.us.getOrganization().id, 'formTemplates', id), tmp);

      // await this.fs.firestore.firestore
      //   .collection('orgs')
      //   .doc(this.us.getOrganization().id)
      //   .collection("formTemplates")
      //   .doc(id)
      //   .set(tmp)
    } else {
      await updateDoc(doc(this.fs.firestore, 'orgs', this.us.getOrganization().id, 'formTemplates', id), tmp);

      // await this.fs.firestore.firestore
      //   .collection('orgs')
      //   .doc(this.us.getOrganization().id)
      //   .collection("formTemplates")
      //   .doc(id)
      //   .update(tmp)
    }
    let snap = await getDoc(doc(this.fs.firestore, 'orgs', this.us.getOrganization().id, 'formTemplates', id));
    // let snap = await this.fs.firestore.firestore
    //   .collection('orgs')
    //   .doc(this.us.getOrganization().id)
    //   .collection("formTemplates")
    //   .doc(id)
    //   .get();
    return { id: snap.id, ...snap.data() }
  }

  sendForm(orgKey, formObject, refId): Promise<any> {
    return this.http.put<any>(`${environment.secureCommUrl}/org/${orgKey}/publicForm`, {
      content: formObject,
      refId
    }).toPromise();
  }

}
