// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
      @keyframes disableClickWhilePresentingModal {
        0% {pointer-events: none;}
        99% {pointer-events: none;}
        100% {pointer-events: auto;}
      }

      .messageOptionsDisabledClick {
        animation-name: disableClickWhilePresentingModal;
        animation-duration: 0.5s;
        --background: transparent;

        ion-item {
            animation-name: disableClickWhilePresentingModal;
            animation-duration: 0.5s;
        }
      }
      
      .content {
        background-color: #fff;
        border-radius: 20px;

        ion-item {
          --ion-item-background: transparent;
        }

        ion-icon {
          font-size: 20px;
          margin-right: 10px;
        }
      }

      .reaction-icons {
        margin: auto;
        justify-content: space-between;
        text-align: center;
        font-size: 20px;
        margin-bottom: .5em;
        background-color: #fff;
        border-radius: 20px;
        height: 48px;
        padding: 0 6px;

        ion-col {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
        }
      }
    `, "",{"version":3,"sources":["webpack://./src/services/popover.component.ts"],"names":[],"mappings":";MACM;QACE,IAAI,oBAAoB,CAAC;QACzB,KAAK,oBAAoB,CAAC;QAC1B,MAAM,oBAAoB,CAAC;MAC7B;;MAEA;QACE,gDAAgD;QAChD,wBAAwB;QACxB,yBAAyB;;QAEzB;YACI,gDAAgD;YAChD,wBAAwB;QAC5B;MACF;;MAEA;QACE,sBAAsB;QACtB,mBAAmB;;QAEnB;UACE,kCAAkC;QACpC;;QAEA;UACE,eAAe;UACf,kBAAkB;QACpB;MACF;;MAEA;QACE,YAAY;QACZ,8BAA8B;QAC9B,kBAAkB;QAClB,eAAe;QACf,mBAAmB;QACnB,sBAAsB;QACtB,mBAAmB;QACnB,YAAY;QACZ,cAAc;;QAEd;YACI,eAAe;YACf,aAAa;YACb,mBAAmB;YACnB,uBAAuB;QAC3B;MACF","sourcesContent":["\n      @keyframes disableClickWhilePresentingModal {\n        0% {pointer-events: none;}\n        99% {pointer-events: none;}\n        100% {pointer-events: auto;}\n      }\n\n      .messageOptionsDisabledClick {\n        animation-name: disableClickWhilePresentingModal;\n        animation-duration: 0.5s;\n        --background: transparent;\n\n        ion-item {\n            animation-name: disableClickWhilePresentingModal;\n            animation-duration: 0.5s;\n        }\n      }\n      \n      .content {\n        background-color: #fff;\n        border-radius: 20px;\n\n        ion-item {\n          --ion-item-background: transparent;\n        }\n\n        ion-icon {\n          font-size: 20px;\n          margin-right: 10px;\n        }\n      }\n\n      .reaction-icons {\n        margin: auto;\n        justify-content: space-between;\n        text-align: center;\n        font-size: 20px;\n        margin-bottom: .5em;\n        background-color: #fff;\n        border-radius: 20px;\n        height: 48px;\n        padding: 0 6px;\n\n        ion-col {\n            cursor: pointer;\n            display: flex;\n            align-items: center;\n            justify-content: center;\n        }\n      }\n    "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
