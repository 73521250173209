import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UserService } from './user.service';
import { AuthService } from './auth.service';
import { collection, doc, getDoc, getDocs, query, QuerySnapshot, where } from 'firebase/firestore';
import { FirebaseService } from './firebase.service';

@Injectable({
  providedIn: 'root'
})
export class AwardsService {

  constructor(
    public modalCtrl: ModalController,
    public fs: FirebaseService,
    public userService: UserService,
    public authService: AuthService
  ) {

  }

  getUserStats() {
    return getDoc(doc(this.fs.firestore,
      'orgs',
      this.userService.getOrganization().id,
      'userStats',
      this.userService.getUser().id
    ));
      
  }
  chatsStats() {
    const q = query(
      collection(this.fs.firestore, `orgs/${this.userService.getOrganization().id}/chats`),
      where(`users.${this.userService.getUser().id}`, '==', true)
    );
    return getDocs(q)
      .then(async (chats: any) => {
        if (chats && chats.docs) return chats.docs.length
        return 0;
      });
  }


  groupsStats() {
    const q = query(
      collection(this.fs.firestore, `orgs/${this.userService.getOrganization().id}/groups`),
      where(`users.${this.userService.getUser().id}`, '==', true)
    );
    return getDocs(q)
      .then(async (groups: any) => {
        if (groups && groups.docs) return groups.docs.length
        return 0;
      });
  }


  accessessStats() {
    return getDocs(collection(
      this.fs.firestore,
      'users',
      this.userService.getUser().id,
      'accesses'
    )).then(async (snaps: QuerySnapshot) => {
        return snaps.docs.map((snap) => { return { id: snap.id, ...snap.data() } })
      })
  }

  groupCreator() {
    return getDocs(query(
      collection(this.fs.firestore,'orgs', this.userService.getOrganization().id, 'groups'),
      where('createdBy', '==', this.userService.getUser().id)
    )).then(async (groups: any) => {
        if (groups && groups.docs && groups.docs.length > 0) return true;
        else return false;
      })
  }

  hasDevice() {
    return getDocs(query(
      collection(this.fs.firestore, 'users', this.userService.getUser().id, 'devices'),
      where(`type`, '==', 'app')
    ))
      .then((devices) => {
        if (devices && devices.docs && devices.docs.length > 0) return true;
        else return false;
      })
  }

  hasUnavailableRules() {
    return getDocs(collection(this.fs.firestore,
      'users',
      this.userService.getUser().id,
      'unavailableRules'
    )).then((unav) => {
        if (unav && unav.docs && unav.docs.length > 0) return true;
        else return false;
      })
  }

}
