// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.clicable {
  cursor: pointer;
}

.right {
  float: right;
}

.left {
  float: left;
}

.divider-header {
  color: #000;
}
.divider-header .notes-icon {
  margin-right: 5px;
}

.loader {
  --background: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.note-content {
  -webkit-user-select: text !important;
          user-select: text !important;
  white-space: pre-line !important;
}

ion-note {
  font-size: 1.4rem;
  align-self: flex-start;
  margin-top: 14px;
}

ion-col.name {
  padding-left: 0 !important;
}

ion-list {
  overflow-y: scroll;
  max-height: 20rem;
}

.messages-list {
  overflow-y: scroll;
  max-height: 20rem;
}

.no-margin {
  margin-top: 0px;
  margin-bottom: 0px;
}

ion-item {
  background: transparent !important;
}
ion-item ion-label {
  margin: 0 0 13px 0 !important;
}

.chat-bubble-container {
  margin-top: 0.8rem;
  width: 100%;
}

.chat-bubble-item {
  margin-top: 0.8rem;
}
.chat-bubble-item info-message {
  margin: auto;
}
.chat-bubble-item .chat-bubble-container {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/chat/chat.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;AACF;;AACA;EACE,YAAA;AAEF;;AAAA;EACE,WAAA;AAGF;;AAAA;EACE,WAAA;AAGF;AADE;EACE,iBAAA;AAGJ;;AACA;EACE,mBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AAEF;;AACA;EACE,oCAAA;UAAA,4BAAA;EACA,gCAAA;AAEF;;AACA;EACE,iBAAA;EACA,sBAAA;EACA,gBAAA;AAEF;;AACA;EACE,0BAAA;AAEF;;AACA;EACE,kBAAA;EACA,iBAAA;AAEF;;AACA;EACE,kBAAA;EACA,iBAAA;AAEF;;AACA;EACE,eAAA;EACA,kBAAA;AAEF;;AACA;EACE,kCAAA;AAEF;AACE;EACE,6BAAA;AACJ;;AAGA;EACE,kBAAA;EACA,WAAA;AAAF;;AAGA;EACE,kBAAA;AAAF;AACE;EACE,YAAA;AACJ;AACE;EACE,WAAA;AACJ","sourcesContent":[".clicable {\n  cursor: pointer;\n}\n.right {\n  float: right;\n}\n.left {\n  float: left;\n}\n\n.divider-header {\n  color: #000;\n\n  .notes-icon {\n    margin-right: 5px;\n  }\n}\n\n.loader {\n  --background: white;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.note-content {\n  user-select: text !important;\n  white-space: pre-line !important;\n}\n\nion-note {\n  font-size: 1.4rem;\n  align-self: flex-start;\n  margin-top: 14px;\n}\n\nion-col.name {\n  padding-left: 0 !important;\n}\n\nion-list {\n  overflow-y: scroll;\n  max-height: 20rem;\n}\n\n.messages-list {\n  overflow-y: scroll;\n  max-height: 20rem;\n}\n\n.no-margin {\n  margin-top: 0px;\n  margin-bottom: 0px;\n}\n\nion-item {\n  background: transparent !important;\n\n  // chat-bubbles are wrapped inside ion-label when ion-item is rendered\n  ion-label {\n    margin: 0 0 13px 0 !important;\n  }\n}\n\n.chat-bubble-container {\n  margin-top: 0.8rem;\n  width: 100%;\n}\n\n.chat-bubble-item {\n  margin-top: 0.8rem;\n  info-message{\n    margin: auto;\n  }\n  .chat-bubble-container {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
