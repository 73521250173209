import { Component, OnInit, OnDestroy, Input, ChangeDetectorRef } from '@angular/core';
import { Subject } from 'rxjs';
import { TimeService } from 'src/services/time.service';
import { Platform, ActionSheetController, AlertController, LoadingController, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { CommunicationService } from 'src/services/communication.service';
import { takeUntil } from 'rxjs/operators';
import { LoadingService } from 'src/services/loading.service';
import { NavProxyService } from 'src/services/nav-proxy-service.service';
import { ViewDetailPage } from 'src/app/module-navigation/views/view-detail/view-detail.page';
import { UserService } from 'src/services/user.service';
import * as moment from 'moment';

@Component({
  selector: 'notes-component',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss'],
})

export class NotesComponent implements OnInit, OnDestroy {

  public noteValue: any;
  public bloc: any;
  public notes: any = [];
  public loading: boolean = false;
  public loaded: boolean = false;
  public item: any;
  public module_id: any;
  public order = 'asc';

  public onDestroy$ = new Subject<void>();
  public alert;
  public forceLoadBloc$ = new Subject<boolean>();
  public notesOrder$ = new Subject<boolean>();

  @Input()
  set notesValue(notes: any) {
    if (notes) {
      this.notesOrder$.next(true);
      this.notes = notes;
      this.notes.forEach((note) => {
        this.updateNoteByConditions(note);
      })
    }
  }

  @Input()
  set notesBloc(bloc: any) {
    this.bloc = bloc;
    this.notesOrder$.next(true);

    if (bloc.event_load) {
      this.forceLoadBloc$.next(true);
    }
  }

  @Input()
  set detailItem(item) {
    if (item) {
      this.item = item;
      this.forceLoadBloc$.next(true);
    }
  }

  @Input()
  set moduleId(module_id) {
    if (module_id) {
      this.module_id = module_id;
      this.forceLoadBloc$.next(true);
    }
  }

  constructor(
    public navProxy: NavProxyService,
    public timeService: TimeService,
    public platform: Platform,
    public tr: TranslateService,
    public actionSheetCtrl: ActionSheetController,
    public alertCtrl: AlertController,
    public commService: CommunicationService,
    public loadingCtrl: LoadingController,
    public loadingService: LoadingService,
    public toastCtrl: ToastController,
    public userService: UserService
  ) {
    this.forceLoadBloc$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((val) => {
        if (!this.loaded && this.item && this.bloc && this.bloc.event_load && this.module_id) {
          this._loadBloc(this.bloc.event_load);
        }
      });
    this.notesOrder$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        if (this.notes && this.bloc) {
          if (this.bloc.content.order === 'desc') {
            if (this.bloc.content && this.bloc.content.order === 'desc' && this.order != this.bloc.content.order) {
              this.order = this.bloc.content.order;
              this.notes.reverse();
            }
          }
        }
      })
  }

  ngOnInit() {
  }

  getNotes() {
  }

  _updateNote(action: any, actionData: any = [], note: any) {
    if (action === 'delete' && note) {
      let index = this.notes.indexOf(note);
      this.notes.splice(index, 1);
      return true;
    } else if (action === 'insert') {
      actionData.forEach(result => {
        if (this.order === 'desc') {
          this.notes.unshift(result);
        } else {
          this.notes.push(result);
        }
      });
      this.noteValue = '';
      return true;
    } else if (action === 'update') {
      actionData.forEach(result => {
        let res = result.tag.split('.');
        let field = res[0];
        let tag = res[1];
        let index = this.notes.indexOf(note);
        this.notes[index][tag] = result.value;
      });
      return true;
    }
    return false;
  }

  async clickAction(action, note?) {
    if (action.params && action.params.action === 'create' && !this.noteValue) {
      return;
    }
    await this.loadingService.presentLoading();
    try {
      let params = Object.assign({}, action.params);
      var keys = Object.keys(params);
      keys.forEach(key => {
        if (params[key].tag) {
          var tag = params[key].tag;
          params[key] = this.getValue(tag, note);
        } else {
          params[key] = params[key];
        }
      });

      let op = action.op ? action.op : this.bloc.op;
      let event_parent = this.bloc.event_parent ? this.bloc.event_parent : 'mirth';

      try {
        let result = await this.commService.getFunctionPromise(op, params, event_parent)

        if (event_parent === 'mirth' && result['data'] && result['data']['MessageCode'] === 200) {
          if (event_parent === 'mirth' && result['data']['action']) {
            var res_action = result['data']['action'];
            var actionData = result['data']['actionData'];
            var applied = this._updateNote(res_action, actionData, note);
            if (!applied) {
              this.showErrorAlert('error');
            }
          }
        } else if (result['data']) {
          var res_action = result['data']['action'];
          if (op === 'delete') {
            let index = this.notes.indexOf(note);
            this.notes.splice(index, 1);
          } else if (op === 'create') {
            if (this.order === 'desc') {
              this.notes.unshift(result['data']);
            } else {
              this.notes.push(result['data']);
            }
            this.noteValue = '';
          } else if (op === 'update') {
            let index = this.notes.indexOf(note);
            this.notes[index] = { ...this.notes[index], ...result['data'] };
            if (action.condition) {
              this.updateNoteByConditions(result['data'], action, index);
            }
          }
        } else {
          await this.showErrorAlert(result['data']['ErrorMessage']);
        }
      } catch (e) {
        await this.showErrorAlert('Error: ' + e);
      }
    } catch (e) {
      await this.showErrorAlert('Error: ' + e);
    };
    await this.loadingService.dismissLoading();

  }

  async showNoteOptions(note: any) {
    if (note) {
      let translations = [];
      await this.tr.get(['general']).forEach(tr => translations = tr);

      let buttons = [];
      if (note['rowActions'] || note['actions']) {
        let actions = note['rowActions'] || note['actions'];

        actions.forEach(action => {
          buttons.push({
            text: this.bloc.actions[action].label ? this.bloc.actions[action].label : '',
            icon: this.bloc.actions[action].icon.name ? this.bloc.actions[action].icon.name : '',
            cssClass: this.bloc.actions[action].icon.color ? this.bloc.actions[action].icon.color : (this.bloc.actions[action].icon.role === 'destructive') ? '' : 'primary',
            role: this.bloc.actions[action].icon.role ? this.bloc.actions[action].icon.role : '',
            handler: async () => {
              if (this.bloc.actions[action].confirmAction) {
                await this.confirmAction(this.bloc.actions[action].confirmAction, this.bloc.actions[action], note);
              } else {
                await this.clickAction(this.bloc.actions[action], note);
              }
            }
          });
        });
      }

      buttons.push({
        text: translations['general'].cancel,
        role: 'cancel',
        cssClass: '',
        icon: !this.platform.is('ios') ? 'close' : null,
      });
      const actionSheet = await this.actionSheetCtrl.create({
        header: translations['general'].options,
        cssClass: 'action-sheets-chats-list',
        buttons
      });
      await actionSheet.present();
    }
  }

  async confirmAction(confirmAction: any, action: any, note: any) {
    let translations = [];
    await this.tr.get(['general', 'census']).forEach(tr => translations = tr);

    if (this.alert) {
      await this.alert.dismiss();
      this.alert = null;
    }
    let buttons = [];
    confirmAction.buttons.forEach(btn => {
      buttons.push({
        text: btn.title,
        role: btn.role,
        handler: () => {
          if (btn.role === 'confirm') {
            this.clickAction(action, note);
          }
        }
      });
    });
    this.alert = await this.alertCtrl.create({
      header: confirmAction.title,
      buttons
    });
    await this.alert.present();

  }

  //TODO: OUT OF THE COMPONENT!
  async clickBlock(bloc: any) {
    if (bloc.event) {
      let op = bloc.event;
      let event_parent = bloc.event_parent;
      let param = this.getValue(bloc.params);

      await this.loadingService.presentLoading();
      try {
        let resp = await this.commService.getFunctionPromise(op, param, event_parent)

        if (resp.data && resp.result === 200) {
          await this.navProxy.pushDetail(ViewDetailPage, { item: this.item, vistaDetall: resp.data.vistaDetall, module_id: this.module_id });
        } else {
          await this.showToast(resp.result);
        }
      } catch (error) {
        await this.showToast()
      }
      await this.loadingService.dismissLoading();
    }
  }

  getValue(val: any, note?: any) {
    if (!val) {
      return;
    }

    let params = [];
    if (val && val.tag) {
      params = val.tag.split('.');
    } else {
      params = val.split('.');
    }

    let field = params[0];
    let tag = params[1];
    let value = null;
    let type = val.type;

    if (field === 'medxat') {
      if (tag === 'uid') {
        return this.userService.getUser().id;
      }
      return this.userService.getUser().profile[tag];
    } else if (field === 'row') {
      value = note[tag];
      if (type && type === 'datetime') {
        let _form = val.format || 'DD/MM/YYYY HH:mm'
        value = moment(value).format(_form);
      }
      return value;
    } else if (field === 'input') {
      //TODO: automatizar segons l'input, ara està forçat a text
      if (tag === 'data') {
        return this.noteValue;
      } else if (tag === 'type') {
        return 'text';
      }
    } else if (field === 'module') {
      if (tag === 'id') {
        return this.module_id;
      }
    } else {
      if (this.item[field]) {
        this.item[field].forEach(element => {
          if (element['tag'] === tag) {
            value = element.value;
            if (type && type === 'datetime') {
              let format = val.format || element.format;
              let _form = format || 'DD/MM/YYYY HH:mm'
              value = moment(value).format(_form);
            }
          }
        });
      }
    }
    return value;
  }

  async _loadBloc(event) {
    // console.log("LOAD BLOC");
    this.loading = true;
    if (!event) {
      this.loading = false;
      return;
    }
    try {
      let params = Object.assign({}, event.params);
      var keys = Object.keys(params);
      keys.forEach(key => {
        if (params[key].tag) {
          var tag = params[key].tag;
          params[key] = this.getValue(tag);
        }
      });

      let op = event.op ? event.op : this.bloc.op;
      let event_parent = this.bloc.event_parent ? this.bloc.event_parent : event.event_parent;

      let result = await this.commService.getFunctionPromise(op, params, event_parent);

      if (result && result['data']) {
        this.notes = result['data'];
        if (this.bloc.content)
          this.order = this.bloc.content.order;
        if (this.order === 'desc') {
          this.notes.reverse();
        }
        this.notes.forEach(note => {
          this.updateNoteByConditions(note);
        });

        this.loaded = true;
      } else {
        await this.showErrorAlert();
      }
      this.loading = false;

    } catch (error) {
      await this.showErrorAlert(error.message);
    }
  }

  updateNoteByConditions(note, action?: any, index?) {
    if (!this.bloc || !this.bloc.actions) {
      return;
    }

    if (action && (index || index === 0)) {
      if (action.condition) {
        if (this.getValue(action.condition, note) === action.condition.value) {
          this.notes[index] = {
            ...this.notes[index],
            ...action.condition.update
          }
        }
      }
      return;
    }

    index = this.notes.indexOf(note);
    let actions = Object.keys(this.bloc.actions);
    actions.forEach(label => {
      action = this.bloc.actions[label];
      if (action && action.condition) {
        if (this.getValue(action.condition, note) === action.condition.value) {
          this.notes[index] = {
            ...this.notes[index],
            ...action.condition.update
          }
        }
      }
    });
  }

  async showErrorAlert(message?: string) {
    let translations = [];
    await this.tr.get(['general']).forEach(tr => translations = tr);
    let alert = await this.alertCtrl.create({
      header: 'Error',
      subHeader: message,
      buttons: [{
        text: translations['general'].ok,
        role: 'destructive'
      }]
    });
    this.loading = false;
    return alert.present();
  }

  mobileView() {
    return (this.platform.is('mobile') || this.platform.is('mobileweb')) && !(this.platform.is('tablet') || this.platform.is('ipad'));
  }

  async showToast(message: string = '') {
    let translations = [];
    await this.tr.get(['general']).forEach(tr => translations = tr);
    if (!message || message === '') {
      message = translations['general'].error_loading;
    } else {
      message = translations['general'].error_loading + " Error " + message;
    }
    const done = await this.toastCtrl.create({
      message,
      duration: 3000,
      position: 'bottom',
    });
    return done.present();

  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.bloc = null;
  }
}

// export interface NotesBloc {
//   op: string; //operació a cridar
//   event_parent?: string;
//   actions?: any;
//   color: any;
//   header: any; //{title: "NP: notes privades vinculades a episodi"}
//   id: number;
//   params?: any; //{key: "NumEpisodi", tag: "infoIngres.NumEpisodi", shared: false, icon: "document", color: "#0070c0"}
//   content: {
//     tag: string,
//     order?: any;
//   }; //"notes.notesPrivades"
//   type: string;
//   event?: string; //event del click
//   event_load?: any;
// }

// export interface Note {
//   content: string;
//   creator: string;
//   date?: string;
//   id?: number;
//   params?: any;
//   status?: string;
//   type: string;
//   rowActions?: any;
// }