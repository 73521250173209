import { Component, OnInit, OnDestroy, EventEmitter } from '@angular/core';
import { ViewSuperPage } from '../view-super/view-super';
import { CommunicationService } from 'src/services/communication.service';
import { TimeService } from 'src/services/time.service';
import { NavParams, ToastController, LoadingController, AlertController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { NetworkService } from 'src/services/network.service';
import { ModulesService } from 'src/services/modules.service';
import { NavigationService } from 'src/services/navigation.service';
import { DeviceService } from 'src/services/device.service';
import { UntypedFormGroup } from '@angular/forms';
import { NavProxyService } from 'src/services/nav-proxy-service.service';

import * as moment from 'moment';
import { distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-view-detail',
  templateUrl: './view-detail.page.html',
  styleUrls: ['./view-detail.page.scss'],
})
export class ViewDetailPage extends ViewSuperPage implements OnInit, OnDestroy {

  public index;
  public itemHeader;
  public userNotes: any = [];
  public noteValue: any;
  public formModule: any = {};
  public rootView: boolean = false; //if view has to load an event to have an item
  public disableActions: boolean = false; //if calendar diferent date

  //estadístiques
  public stats;
  public results;
  public chartsLoaded: boolean = false;
  public statsMode: boolean = false;
  public formsReportMode: boolean = false;

  public conditionalStyle: any;

  public form: UntypedFormGroup;
  public module_id;
  public app_id;
  public params;
  public newItem;
  public filtresAplicats;

  public hideForms = [];
  public showForms = [];

  public hideActions = [];
  public showActions = [];
  public autoDisplay: EventEmitter<any> = new EventEmitter();
  public canExecute = true;

  constructor(
    public commService: CommunicationService,
    public timeService: TimeService,

    public navProxy: NavProxyService,
    public navParams: NavParams,
    public tr: TranslateService,
    public toastController: ToastController,
    public loadingCtrl: LoadingController,
    public networkService: NetworkService,
    public mcs: ModulesService,
    public navigationService: NavigationService,
    public alertCtrl: AlertController,
    public deviceService: DeviceService,
    public platform: Platform) {
    super(
      navProxy,
      navParams,
      tr,
      toastController,
      loadingCtrl,
      networkService,
      mcs,
      navigationService,
      alertCtrl,
      deviceService,
      platform);
  }

  async ngOnInit() {
    await super.ngOnInit();

    this.vistaDetall = this.navParams.get('vistaDetall');
    this.newItem = this.navParams.get('newItem');
    this.index = this.navParams.get('index');
    this.statsMode = this.navParams.get('statsMode');
    this.formsReportMode = this.navParams.get('formsReportMode');
    this.formModule = this.navParams.get('formModule');
    this.rootView = this.navParams.get('rootView') || false;
    this.disableActions = this.navParams.get('disableActions') || false;
    this.filtresAplicats = this.navParams.get('filters');
    this.app_id = this.navParams.get('app_id');
    this.params = this.navParams.get('params') || {};

    //estadístiques 
    this.stats = this.navParams.get('stats');
    this.results = this.navParams.get('results');

    //IF RESULT CLICKED FROM LIST IS A FORM
    if (this.item && this.item.form) {
      this.form = this.mcs.toFormGroup(this.item.form);
    }

    //has rootView --> has to load event
    if (this.rootView) {
      this.getFuncio(this.event);
    }

    this.forceLoadBloc$
      .pipe(
        filter(x => x),
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
        takeUntil(this.onDestroy$))
      .subscribe((event: any) => {
        if (!event) return;
        if (event.ignore_result === false) event.ignore_result = false
        else event.ignore_result = true;
        this.formAction(event.op, event.event_parent, event.params, event.warning, event.actions, event.ignore_result);
      })

    this.startView();



  }


  ionViewDidEnter() {
    //IF STATS ARE CLICKED FROM LIST
    if (this.stats && this.results) {
      this.loadCharts();
    }
  }

  startView() {
    //if result exists (from list click or getFuncio load)
    if (this.item && this.vistaDetall) {
      this.view = this.vistaDetall;
      this.itemHeader = "";

      //IF RESULT CLICKED FROM LIST IS A FORM
      if (this.item && this.item.form) {
        this.form = this.mcs.toFormGroup(this.item.form);
      }

      this.initHeader();
      //INITIALIZE ALL BLOCS AS VISIBLE
      this.vistaDetall['content'].forEach(bloc => {
        this.showBlock[bloc.id] = true;
      });

      this.initView();
    }
  }

  initHeader() {
    if (this.formsReportMode && this.formModule) {
      this.itemHeader = this.formModule.title;
    } else if (this.vistaDetall['config'] && this.vistaDetall['config'].title) {
      let config = this.vistaDetall['config'].title;
      this.itemHeader = config.content.label;

      if (!this.newItem && config.content.tags && config.content.tags.length > 0) {
        this.itemHeader = '';
        let pre_tag = (config.content.pre_tag || config.content.pre_tag === '') ? config.content.pre_tag : '(';
        let post_tag = (config.content.post_tag || config.content.post_tag === '') ? config.content.post_tag : ')';
        let separator = (config.content.separator || config.content.separator === '') ? config.content.separator : ' - ';
        config.content.tags.forEach(tag => {
          if (tag) {
            //TODO: valorar si passem des de MIRTH el separator i els labels
            this.itemHeader += this.itemHeader === '' ? this.getValue(tag.tag, tag.format) : separator + this.getValue(tag.tag, tag.format);
          }
        });
        this.itemHeader = config.content.label ? config.content.label + ' ' + pre_tag + this.itemHeader + post_tag : this.itemHeader;
      }
    }
  }

  async getFuncio(event?) {
    this.presentLoading();
    await super.getFuncio(event);

    let params = { 'module_id': this.id, app_id: this.app_id, ...this.params };
    try {
      let result = await this.commService.getFunctionPromise(this.event, params, this.event_parent)
      if (result && result['data'] && result['data'].results[0]) {
        this.item = result['data'].results[0];
        if (result.detail_view && result.detail_view.view) {
          this.vistaDetall = result.detail_view.view;
        }
        if (this.content) {
          this.content.resize();
        }
        this.startView();
      }
      await this.dismissLoading();
    } catch (error) {
      this.dismissLoading();
    }
    this.dismissLoading();
  }

  getNotesKey(bloc: any) {
    if (bloc.key) {
      return this.getValue(bloc.key);
    }
    return null;
  }

  //NOT IN USE
  getBlocTagProperty(item: any, bloc: string, tag: any, property: string) {
    let value = '';
    item[bloc].forEach(field => {
      if (field['tag'] === tag) {
        value = field[property];
      }
    });
    return value;
  }

  getValue(val: string, format?, origin?) {
    let itm;
    if (origin) {
      itm = origin;
    } else if (this.item) {
      itm = this.item;
    }
    let params = val.split('.');
    let bloc = params[0];
    let tag = params[1];
    let value = null;
    let type = '';
    if (itm[bloc]) {
      itm[bloc].forEach(field => {
        if (field['tag'] === tag) {
          value = field.value;
          type = field.type;
        }
      });
    }
    if (format && format.type && format.type != type) {
      type = format.type;
      format = format.datetime_format;
    }
    if (type && type === 'datetime') {
      let _form = format || 'DD/MM/YYYY HH:mm'
      value = moment(value).format(_form);
    }
    return value;
  }

  getField(val: string) {
    if (val) {
      let params = val.split('.');
      let bloc = params[0];
      let tag = params[1];
      let value = null;
      if (this.item[bloc]) {
        this.item[bloc].forEach(field => {
          if (field['tag'] === tag) {
            value = field;
          }
        });
      }
      return value;
    }
    return;
  }


  //RETURNS THE BLOCKS OF THE ARRAY WITH THE SAME TAG (and different index)
  getArrayValues(val: string) {
    let params = val.split('.');
    let bloc = params[0];
    let tag = params[1];
    let value = [];
    if (this.item[bloc]) {
      this.item[bloc].forEach(field => {
        if (field['tag'] === tag) {
          value.push(field);
        }
      });
    }
    return value;
  }

  getValueArray(val: string, index: number) {
    let params = val.split('.');
    let bloc = params[0];
    let tag = params[1];
    let value = null;
    if (this.item[bloc]) {
      this.item[bloc].forEach(field => {
        if (field['tag'] === tag && field['index'] === index) {
          value = field.value;
        }
      });
    }
    return value;
  }

  getLabel(val: string, origin?) {
    let itm;
    if (origin) {
      itm = origin;
    } else if (this.item) {
      itm = this.item;
    }
    let params = val.split('.');
    let bloc = params[0];
    let tag = params[1];
    let value = null;
    if (itm[bloc]) {
      itm[bloc].forEach(field => {
        if (field['tag'] === tag) {
          value = field.label;
        }
      });
    }
    return value;
  }

  getUnit(val: string, origin?) {
    let itm;
    if (origin) {
      itm = origin;
    } else if (this.item) {
      itm = this.item;
    }
    let params = val.split('.');
    let bloc = params[0];
    let tag = params[1];
    let value = null;
    if (itm[bloc]) {
      itm[bloc].forEach(field => {
        if (field['tag'] === tag) {
          value = field.unit;
        }
      });
    }
    return value;
  }

  getSubLabel(val: string, origin?) {
    let itm;
    if (origin) {
      itm = origin;
    } else if (this.item) {
      itm = this.item;
    }
    let params = val.split('.');
    let bloc = params[0];
    let tag = params[1];
    let value = null;
    if (itm[bloc]) {
      itm[bloc].forEach(field => {
        if (field['tag'] === tag) {
          value = field.sublabel;
        }
      });
    }
    return value;
  }

  getLabelArray(val: string, index: number) {
    let params = val.split('.');
    let bloc = params[0];
    let tag = params[1];
    let value = null;
    if (this.item[bloc]) {
      this.item[bloc].forEach(field => {
        if (field['tag'] === tag && field['index'] === index) {
          value = field.label;
        }
      });
    }
    return value;
  }

  getStyle(element) {
    let val = element.tag;

    let params = val.split('.');
    let bloc = params[0];
    let tag = params[1];
    let value = null;
    if (this.item[bloc]) {
      this.item[bloc].forEach(field => {
        if (field['tag'] === tag) {
          value = field.format;
        }
      });
    }
    if (value) {
      return value;
    }

    if (element.conditional_format && element.conditional_format) {
      element.conditional_format.forEach(condition => {
        if (!condition.value) {
          return;
        }
        let conditional_value = null;

        if (!condition.tag) {
          conditional_value = this.getValue(element.tag);
        } else {
          conditional_value = this.getValue(condition.tag);
        }
        if (conditional_value && (condition.value.toString().toLowerCase() === conditional_value.toString().toLowerCase())) {
          value = condition.format;
        }
      });
    }


    return value;
  }

  getStyleArray(element: any, index: number) {
    let val = element.tag;
    let params = val.split('.');
    let bloc = params[0];
    let tag = params[1];
    let value = null;
    if (this.item[bloc]) {
      this.item[bloc].forEach(field => {
        if (field['tag'] === tag && field['index'] === index) {
          value = field.format;
        }
      });
    }

    if (value) {
      return value;
    }
    if (element.conditional_format) {
      element.conditional_format.forEach(res => {
        // console.log("res", res.value);
        if (res.value.toString().toLowerCase() === this.getValueArray(val, index).toString().toLowerCase()) {
          value = res.format;
        }
      });
    }
    return value;
  }

  getIcon(val: string) {
    let params = val.split('.');
    let bloc = params[0];
    let tag = params[1];
    let value = null;
    if (this.item[bloc]) {
      this.item[bloc].forEach(field => {
        if (field['tag'] === tag) {
          value = field.icon;
        }
      });
    }
    return value;
  }

  blockHasEvent(val: string) {
    let params = val.split('.');
    let bloc = params[0];
    let tag = params[1];
    let value = null;
    if (this.item[bloc]) {
      this.item[bloc].forEach(field => {
        if (field['tag'] === tag) {
          value = field.event;
        }
      });
    }
    if (value) {
      return true;
    }
    return false;
  }

  blockHasEventArray(val: string, index: number) {
    let params = val.split('.');
    let bloc = params[0];
    let tag = params[1];
    let value = null;
    if (this.item[bloc]) {
      this.item[bloc].forEach(field => {
        if (field['tag'] === tag && field['index'] === index) {
          value = field.event;
        }
      });
    }
    if (value) {
      return true;
    }
    return false;
  }

  clickInnerBlock(val: string) {
    let params = val.split('.');
    let bloc = params[0];
    let tag = params[1];
    let value = null;
    if (this.item[bloc]) {
      this.item[bloc].forEach(field => {
        if (field['tag'] === tag) {
          value = field.event;
          if (value) {
            this.clickBlock(value);
          }
        }
      });
    }
  }

  clickInnerBlockArray(val: string, index: number) {
    let params = val.split('.');
    let bloc = params[0];
    let tag = params[1];
    let value = null;
    if (this.item[bloc]) {
      this.item[bloc].forEach(field => {
        if (field['tag'] === tag && field['index'] === index) {
          value = field.event;
          if (value) {
            this.clickBlock(value);
          }
        }
      });
    }
  }

  async clickBlock(event: any) {
    if (event) {
      let mirth_op = event.op;
      let param = this.getValue(event.params);

      await this.startLoading();
      param = { ...param, log: this.log, app_id: this.app_id, ...this.params };
      try {

        let result = await this.commService.getFunctionPromise(mirth_op, param)

        if (result['data']['MessageCode'] === 200) {
          //TODO: mirar rootView i pushMaster?
          try {
            await this.navProxy.pushDetail(ViewDetailPage, { item: this.item, vistaDetall: result['data']['actionData'] });
          } catch (error) {
            await this.showToast();
          }
          await this.endLoading();
        } else {
          await this.showToast(result['data']['ErrorMessage']);
          await this.endLoading();
        }
      } catch (error) {
        await this.showToast()
        await this.endLoading();
      }
    }
  }

  getBlockStats(bloc) {
    let tempStats = [];
    let block = bloc.content;
    if (!block) {
      return;
    }
    if (block.tags) {
      let data = [];
      block.tags.forEach(tag => {
        let res;
        res = this.getValuesFromAllResults(tag, block.label);
        data.push(res);
      });
      block.data = data;
    } else {
      let data = this.getValuesFromAllResults(block.tag, block.label);
      block.data = data;
    }
    tempStats.push(block);

    let stat = { ...block };
    return stat;
  }

  //FORM
  onSubmit() {
    // console.log("FORM", JSON.stringify(this.form.value));
  }

  async formAction(event, event_parent, _params = {}, warning?, actions?, ignore_result = false) {
    if (!this.canExecute) return;
    this.canExecute = false;
    if (warning) {
      let translations = {};
      await this.tr.get(['general']).forEach(x => translations = x);
      let alert = await this.alertCtrl.create({
        header: warning.title,
        message: warning.message,
        backdropDismiss: false,
        buttons: [
          {
            text: translations['general'].no,
            role: 'cancel',
          },
          {
            text: translations['general'].yes,
            handler: async () => {
              this.executeAction(event, event_parent, _params, warning, actions, ignore_result);
            }
          }

        ]
      });
      await alert.present();
    } else {
      this.executeAction(event, event_parent, _params, warning, actions, ignore_result);
    }
    setTimeout(() => { this.canExecute = true }, 1000)

  }

  async executeAction(event, event_parent, _params = {}, warning?, actions?, ignore_result?) {
    let report_id = '';
    let created_at = '';
    let updated_at = '';

    if (event === "custom_toggle") {
      if (actions) {
        if (actions.show) {
          for (let i = 0; i < actions.show.length; i++) {
            let field = actions.show[i];
            let fields = field.split('.');
            let type = fields[0];
            if (type === 'form') {
              let index = this.hideForms.indexOf(field);
              if (index != -1) this.hideForms.splice(index, 1);
              this.showForms.push(field);
            } else if (type === 'action') {
              let tag = fields[1];
              let index = this.hideActions.indexOf(tag);
              this.showActions.push(tag);
              if (index != -1) this.hideActions.splice(index, 1);

            }
          }
        }
        if (actions.hide) {
          for (let i = 0; i < actions.hide.length; i++) {
            let field = actions.hide[i];
            let fields = field.split('.');
            let type = fields[0];
            if (type === 'form') {
              let index = this.hideForms.indexOf(field);
              if (index === -1) this.hideForms.push(field);

              index = this.showForms.indexOf(field)
              if (index != -1) this.showForms.splice(index, 1);
            } else if (type === 'action') {
              let tag = fields[1];
              let index = this.hideActions.indexOf(tag);
              if (index === -1) this.hideActions.push(tag);

              index = this.showActions.indexOf(tag);
              if (index != -1) this.showActions.splice(index, 1);
            }
          }
        }

        if (actions.auto_display) {
          let field = actions.auto_display.split('.');
          let type = field[0];
          let tag = field[1];
          if (type === 'form') {
            this.autoDisplay.emit(tag);
          }
        }
      }


      await this.endLoading()
      return;
    }


    await this.startLoading();


    //TODO: si a l'actions fiquem un params agafar el valor del tag que indiqui
    if (this.item.info) {
      this.item.info.forEach(element => {
        if (element.tag === 'report_id') {
          report_id = element.value;
        } else if (element.tag === 'created_at') {
          created_at = element.value;
        } else if (element.tag === 'updated_at') {
          updated_at = element.value || '';
        }
      });
    }

    let params = {
      report_id,
      report: { ...this.form.value },
      module_id: this.module_id,
      created_at,
      updated_at,
      log: this.log,
      app_id: this.app_id,
      ...this.params
    }

    if (_params) {
      var keys = Object.keys(_params);
      keys.forEach(key => {
        if (_params[key].tag) {
          var tag = _params[key].tag;
          _params[key] = this.getValue(tag);
        }
      });

      params = { ...params, ..._params };
    }
    if (this.formsReportMode && this.formModule) {
      params['tag'] = this.formModule.tag;
    }
    if (this.form && this.item && this.item.form) {
      params['form'] = this.item.form;
    }

    let result;
    let error = false;

    try {
      setTimeout(async () => {
        await this.endLoading();
        if (!result) await this.showToast();
      }, 10000);

      result = await this.commService.getFunctionPromise(event, params, event_parent)

      if (result.data) {
        let cntinue = true;
        if (result.data.inner_notification && result.data.inner_notification && result.data.inner_notification.length > 0) {
          let notifications = result.data.inner_notification;
          for (let j = 0; j < notifications.length; j++) {
            let notification = notifications[j];
            if (notification.type === 'pop_up') cntinue = false;
            await this.showAlert(notification.label, notification.message);
            await this.endLoading();
          }

          if (!cntinue) {
            this.endLoading();
            this.initHeader();
            return;
          }
        }

        if (ignore_result) {
          this.endLoading();
          this.initHeader();
          return;
        }

        this.item = result.data;
        if (this.newItem) {
          this.mcs.newItem$.next(this.item);
          this.newItem = false;
          this.index = 0;
          this.hideForms = [];
        }


        if (this.item.delete) {
          let deleteItem = {
            item: this.item,
            index: this.index
          }
          this.mcs.deleteItem$.next(deleteItem);
          this.mcs.closeDetail$.next(true);
        } else {
          let update = {
            item: this.item,
            index: this.index
          }
          if (this.formsReportMode && this.formModule) {
            update['tag'] = this.formModule.tag;
          }
          if (this.item && this.item.form) {
            this.form = this.mcs.toFormGroup(this.item.form);
          }
          this.mcs.updateItem$.next(update);
        }

      }
      this.endLoading();
      this.initHeader();
      error = false;
    } catch (error) {
      await this.endLoading();
      await this.showToast();
      error = true;
    }
    this.endLoading();
    if (actions && actions.close_when_read){
      if(!error) this.mcs.closeDetail$.next(true);
    }
  }

  getRowHeight(content: any) {
    if (content.length > 1) {
      return '';
    } else {
      return '100%';
    }
  }

  getColor(val) {
    return val;
  }


  applyColumnWidth(arr: any) {
    if (!arr || arr.length === 0 || arr.length <= 2) {
      return false;
    }
    if (this.mobileView()) {
      if (arr.length >= 3) {
        return true;
      } else {
        return false;
      }
    }
    if (arr.length >= 5) {
      return true;
    }
    return false;
  }

  showTag(elem) {
    if (!elem) return;
    let form = this.getField(elem.tag);
    if (this.newItem && form && form.hide_on_start && this.showForms.indexOf(elem.tag) === -1) return false;
    return this.hideForms.indexOf(elem.tag) === -1;
  }

  showAction(action) {
    if (!action) return;
    let tag = action.tag;
    if (this.newItem && action.hide_on_start && this.showActions.indexOf(action.tag) === -1) return false;
    return this.hideActions.indexOf(tag) === -1;
  }

  getValuesFromAllResults(val, label) {
    let values = [];
    let labels = [];
    let sublabel;
    let unit;
    this.results.forEach(result => {
      let res_value = this.getValue(val, null, result); //Y axes
      let res_label = this.getValue(label, null, result); //X axes
      if (!sublabel) {
        sublabel = this.getSubLabel(val, result);
        sublabel = sublabel ? sublabel : this.getLabel(val, result);
      }
      if (!res_value) {
        res_value = 0;
      }
      if (!unit) {
        unit = this.getUnit(val, result);
      }
      values.push(res_value);
      labels.push(res_label);
    });
    values.reverse();
    labels.reverse();
    return { data: values, labels, label: sublabel, unit };
  }

  filterActionsByTags(tags, actions) {
    let res = [];
    actions.forEach(action => {
      if (action && action.tag && tags.indexOf(action.tag) != -1) res.push(action);
    });
    return res;
  }

  formQuestionAction(actions) {
    if (actions) {
      let finalAction: any;
      for (let i = 0; i < actions.length; i++) {
        let action = actions[i];
        if (action.conditional_value) {
          let tag = action.conditional_value.tag;
          let value = action.conditional_value.value;
          let itemValue = this.getValue(tag);
          if (value === 'custom_value_exists' && itemValue)
            finalAction = action;
          else if (value === 'custom_value_no_exists' && !itemValue)
            finalAction = action;
          else if (value && value.toString() && value.toString().toLowerCase() && itemValue && itemValue.toString() && itemValue.toString().toLowerCase() && value.toString().toLowerCase() === itemValue.toString().toLowerCase())
            finalAction = action;
        } else
          finalAction = action;

        if (finalAction) break;
      }
      if (finalAction) this.formAction(finalAction.event, finalAction.event_parent, finalAction.params, finalAction.warning, finalAction.actions);
    }
  }

  //STATS AND CHARTS
  loadCharts() {
    let tempStats = [];
    this.stats.forEach(stat => {
      if (stat.tags) {
        let data = [];
        stat.tags.forEach(tag => {
          let res;
          res = this.getValuesFromAllResults(tag, stat.label);
          data.push(res);
        });
        stat.data = data;
      } else {
        let data = this.getValuesFromAllResults(stat.tag, stat.label);
        stat.data = data;
      }
      tempStats.push(stat);
    });
    this.stats = tempStats;
    this.chartsLoaded = true;
  }

  mobileView() {
    return (this.platform.is('mobile') || this.platform.is('mobileweb')) && !(this.platform.is('tablet') || this.platform.is('ipad'));
  }

  headerForceMobileView() {
    return (this.vistaDetall['config'] && this.vistaDetall['config'].headers && this.vistaDetall['config'].headers.force_mobile === true);
  }

  async ngOnDestroy() {
    this.itemSelected = null;
    this.mcs.closeDetail$.next(true);
    this.form = null;
    this.item = null;

    super.ngOnDestroy();
    this.newItem = false;
    this.onDestroy$.next();
  }


}
